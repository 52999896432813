import { EmploymentStatus } from '@frontend/utils';
import { InsurancePremiumStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const employmentStatusMessages = defineMessages<EmploymentStatus>({
  ACTIVE: {
    id: 'employmentStatusMessages.ACTIVE',
    defaultMessage: 'I tjänst',
  },
  OFF_DUTY: {
    id: 'employmentStatusMessages.OFF_DUTY',
    defaultMessage: 'Tjänstledig',
  },
  PARENTAL_LEAVE: {
    id: 'employmentStatusMessages.PARENTAL_LEAVE',
    defaultMessage: 'Föräldraledig',
  },
  TERMINATED: {
    id: 'employmentStatusMessages.TERMINATED',
    defaultMessage: 'Avslutad anställning',
  },
});

export const pensionBenefitStatusMessages =
  defineMessages<InsurancePremiumStatus>({
    ACTIVE: {
      id: 'pensionBenefitStatusMessages.ACTIVE',
      defaultMessage: 'Aktiv',
    },
    ACTIVE_WAIVER: {
      id: 'pensionBenefitStatusMessages.ACTIVE_WAIVER',
      defaultMessage: 'Premiebefriad',
    },
    COMPLETE: {
      id: 'pensionBenefitStatusMessages.COMPLETE',
      defaultMessage: 'Utbetald',
    },
    INACTIVE: {
      id: 'pensionBenefitStatusMessages.INACTIVE',
      defaultMessage: 'Ej aktiv',
    },
    OPEN: {
      id: 'pensionBenefitStatusMessages.OPEN',
      defaultMessage: 'Aktiv',
    },
    PENDING_ACTIVATION: {
      id: 'pensionBenefitStatusMessages.PENDING_ACTIVATION',
      defaultMessage: 'Pågående',
    },
  });

export const insuranceComponentStatusMessages = defineMessages({
  NEW: {
    id: 'insuranceComponentStatusMessages.NEW',
    defaultMessage: 'Tecknas',
  },
  ACTIVE: {
    id: 'insuranceComponentStatusMessages.ACTIVE',
    defaultMessage: 'Aktiv',
  },
  NOT_SPECIFIED: {
    id: 'insuranceComponentStatusMessages.NOT_SPECIFIED',
    defaultMessage: 'Ospecificerad',
  },
  PAID_UP: {
    id: 'insuranceComponentStatusMessages.PAID_UP',
    defaultMessage: 'Fribrev',
  },
  PAID_OUT: {
    id: 'insuranceComponentStatusMessages.PAID_OUT',
    defaultMessage: 'Utbetald',
  },
  PAYING_OUT: {
    id: 'insuranceComponentStatusMessages.PAYING_OUT',
    defaultMessage: 'Under utbetalning',
  },
});
