import { commonMessages } from 'common/messages';
import { useIntl } from 'components/formats';
import React from 'react';

export const NoValue: React.FC = () => {
  const { formatMessage } = useIntl();
  return <span aria-label={formatMessage(commonMessages.noValue)}>–</span>;
};

export default NoValue;
