import { media } from '@frontend/ui';
import nordeaNodeHero from 'assets/nordeaNodeHero.png';
import React from 'react';
import styled from 'styled-components';

import { AUTH_CONTAINER_WIDTH } from '../AuthContainer';

const MediaContainer = styled.div`
  width: calc(100% - ${AUTH_CONTAINER_WIDTH}rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #00005e 0%, #212121 100%);
  ${media.lessThan('desktop')`
  display: none;
`}
`;

const HeroImage = styled.div`
  height: 35rem;
  width: 28.5rem;
  background-image: url('${nordeaNodeHero}');
  background-size: contain;
  background-repeat: no-repeat;
`;

export const AuthMedia: React.FC = () => (
  <MediaContainer>
    <HeroImage />
  </MediaContainer>
);
