import { defineMessages } from 'react-intl';

export const featurePreviewMessages = defineMessages({
  step1Header: {
    id: 'featurePreviewMessages.step1Header',
    defaultMessage: `Välkommen till Nordea Node`,
  },
  step1Body: {
    id: 'featurePreviewMessages.step1Body',
    defaultMessage: `Vi är glada att ha dig ombord. Nordea Node gör det enkelt att förstå dina förmåner och är designat för att hjälpa dig öka ditt finansiella välmående, idag och under pensionen. Låt oss ge dig en kort introduktion och visa dig de viktigaste funktionerna.`,
  },
  step1SecondaryAction: {
    id: 'featurePreviewMessages.step1SecondaryAction',
    defaultMessage: `Nej tack`,
  },
  step1PrimaryAction: {
    id: 'featurePreviewMessages.step1PrimaryActiopn',
    defaultMessage: `Kom igång`,
  },
  step2Header: {
    id: 'featurePreviewMessages.step2Header',
    defaultMessage: `Hem`,
  },
  step2Body: {
    id: 'featurePreviewMessages.step2Body',
    defaultMessage: `Få en överblick över din anställning och dina förmåner från din arbetsgivare.`,
  },
  step3Header: {
    id: 'featurePreviewMessages.step3Header',
    defaultMessage: `Förmåner`,
  },
  step3Body: {
    id: 'featurePreviewMessages.step3Body',
    defaultMessage: `Unna dig något extra. Se och nyttja dina erbjudanden och rabatter.`,
  },
  step4Header: {
    id: 'featurePreviewMessages.step4Header',
    defaultMessage: `Pension`,
  },
  step4Body: {
    id: 'featurePreviewMessages.step4Body',
    defaultMessage: `Sammanställ din pension och se vad du kan göra för att få ett rikare liv under pensionen.`,
  },
  step5Header: {
    id: 'featurePreviewMessages.step5Header',
    defaultMessage: `Försäkringar`,
  },
  step5Body: {
    id: 'featurePreviewMessages.step5Body',
    defaultMessage: `Se dina försäkringar och få råd om du borde komplettera ditt försäkringsskydd.`,
  },
  step6Header: {
    id: 'featurePreviewMessages.step6Header',
    defaultMessage: `Total ersättning`,
  },
  step6Body: {
    id: 'featurePreviewMessages.step6Body',
    defaultMessage: `Din ersättning är mer än bara månadslön. Se din totala ersättning.`,
  },
  step7Header: {
    id: 'featurePreviewMessages.step7Header',
    defaultMessage: `Artiklar`,
  },
  step7Body: {
    id: 'featurePreviewMessages.step7Body',
    defaultMessage: `Få koll på den senaste informationen från din arbetsgivare.`,
  },
  step8Header: {
    id: 'featurePreviewMessages.step8Header',
    defaultMessage: `Inställningar`,
  },
  step8Body: {
    id: 'featurePreviewMessages.step8Body',
    defaultMessage: `Se och ändra dina användarinställningar som till exempel byte av språk.`,
  },
  exitAction: {
    id: 'featurePreviewMessages.exitAction',
    defaultMessage: `Avsluta`,
  },
  nextAction: {
    id: 'featurePreviewMessages.nextAction',
    defaultMessage: `Nästa`,
  },
  doneAction: {
    id: 'featurePreviewMessages.doneAction',
    defaultMessage: `Klar`,
  },
  snoozeAction: {
    id: 'featurePreviewMessages.snoozeAction',
    defaultMessage: `Senare`,
  },
  settingsTourHeader: {
    id: 'featurePreviewMessages.settingsTourHeader',
    defaultMessage: 'Inställningar',
  },
  settingsTourBody: {
    id: 'featurePreviewMessages.settingsTourBody',
    defaultMessage:
      'Nu hittar du dina inställningar för din användare, som till exempel byte av språk, här.',
  },
  settingsTourAction: {
    id: 'featurePreviewMessages.settingsTourAction',
    defaultMessage: 'Uppfattat!',
  },
  adminShortcutsTourHeader: {
    id: 'featurePreviewMessages.adminShortcutsTourHeader',
    defaultMessage: 'Nu introducerar vi genvägar',
  },
  adminShortcutsTourBody: {
    id: 'featurePreviewMessages.adminShortcutsTourBody',
    defaultMessage:
      'Här hittar du genvägar till de vanligaste administrativa ändringarna. Allt för att göra din vardag lite mer effektiv.',
  },
});
