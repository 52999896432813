import gql from 'graphql-tag';

export const MEMBERSHIP_ACCOUNTING_QUERY = gql`
  query membershipAccountingQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      userAccountId
      firstName
      lastName
      accountingCost {
        from
        to
        accountingCost {
          object {
            id
            name
            dimension {
              id
            }
          }
        }
      }
    }
  }
`;

export const MEMBERSHIP_REMUNERATIONS_QUERY = gql`
  query membershipRemunerationsQuery(
    $companyId: ID!
    $userAccountId: ID!
    $employmentId: ID!
    $hasEmploymentId: Boolean!
  ) {
    employment(id: $employmentId) @include(if: $hasEmploymentId) {
      id
      period
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      employment {
        from
        to
        remuneration {
          from
          to
          type {
            id
            name
          }
          value: price
          currency
          unitCode
        }
      }
    }
  }
`;

export const MEMBERSHIP_PROPERTIES_QUERY = gql`
  query membershipPropertiesQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      properties {
        id
        property
      }
    }
  }
`;
