export type AgreementSubLinkType =
  | 'itp-1'
  | 'itp-2'
  | 'saf-lo'
  | 'kap-kl'
  | 'akap-kl'
  | 'akap-kr'
  | 'pa-kfs'
  | 'pa-16-1'
  | 'pa-16-2'
  | null;

export enum ValidAgreementTypeNames {
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  ITP = 'ITP',
  ITP1 = 'ITP1',
  KAPKL = 'KAPKL',
  PA161 = 'PA161',
  PA162 = 'PA162',
  PAKFS09 = 'PAKFS09',
  SAFLO = 'SAFLO',
}

export type ValidAgreementType = keyof typeof ValidAgreementTypeNames;

export const isValidCollectiveAgreement = (
  value?: string | null,
): value is ValidAgreementType =>
  Object.keys(ValidAgreementTypeNames).includes(value as ValidAgreementType);

export const CollectiveAgreementRouteName: Record<
  ValidAgreementType,
  AgreementSubLinkType
> = {
  ITP1: 'itp-1',
  ITP: 'itp-2',
  SAFLO: 'saf-lo',
  KAPKL: 'kap-kl',
  AKAPKL: 'akap-kl',
  AKAPKR: 'akap-kr',
  PAKFS09: 'pa-kfs',
  PA161: 'pa-16-1',
  PA162: 'pa-16-2',
};

export const getCollectiveAgreementTypeFromUrl = (
  url,
): AgreementSubLinkType => {
  const linkArray = url.split('/');
  const agreementTypeRoutes = Object.values(CollectiveAgreementRouteName);
  const linkItem = linkArray.filter(
    link => agreementTypeRoutes.indexOf(link) !== -1,
  );
  if (linkItem.length) {
    return linkItem[0];
  }
  return null;
};
