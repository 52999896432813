import {
  discoverSectionQuery_Query,
  salaryExchangeEntitlement_FlexEntitlementConnection_edges_FlexEntitlementEdge_node as FlexEntitlement,
  salaryExchangeEntitlement_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexSalaryExchangeEntitlement as FlexSalaryExchangeEntitlement,
} from 'app/apollo/graphql/types';
import qs from 'query-string';
import { LinkProps } from 'react-router-dom';
import { getAdvinansPlusStatus } from 'utils/advinans-plus-status';

import { ActionType, ExternalLink } from '..';
import { getNudgePlusAdvice } from './get-nudge-plus-advice';
import { getSalaryExchangeNudges } from './get-salary-exchange-nudges';
import { Context } from './types';

interface Args {
  contexts: Context[];
  isFriSignedWithEmail: boolean;
  isPoaEffective: boolean | null;
  isPpmConnected: boolean;
  pathname: string;
  data?: discoverSectionQuery_Query;
}

export interface ActionProps {
  action: ActionType;
  primaryLink: LinkProps['to'] | ExternalLink;
  show: boolean;
  hasHero?: boolean;
  // eslint-disable-next-line
  messageValues?: { [key: string]: any };
  secondaryLink?: string;
}

const isSalaryExchangeEntitlement = (
  entitlement: FlexEntitlement,
): entitlement is FlexSalaryExchangeEntitlement =>
  entitlement.__typename === 'FlexSalaryExchangeEntitlement';

export const getFilteredActions = ({
  contexts,
  data,
  isFriSignedWithEmail,
  isPoaEffective,
  isPpmConnected,
  pathname,
}: Args): ActionProps[] | null => {
  const hasInsuranceDiscount = !!data?.voluntaryGroupEntitlement?.edges.length;

  if (!data?.viewer) {
    return null;
  }

  const advinansPlusStatus = getAdvinansPlusStatus(data.viewer);

  const { nudgePlusAdvice, nudgePlusContext } = getNudgePlusAdvice(
    contexts,
    advinansPlusStatus,
    data,
  );

  const pensionTransferMeeting = {
    schedulingLink: undefined,
    scheduledTime: undefined,
  };

  const schedulingLink = pensionTransferMeeting?.schedulingLink;
  const scheduledTime = pensionTransferMeeting?.scheduledTime;
  const canBookTransferMeeting = !!schedulingLink && !scheduledTime;

  const sxEntitlement = data?.salaryExchangeEntitlement?.edges
    .map(({ node }) => node)
    .find(isSalaryExchangeEntitlement);

  const { show: showSx, messageValues: sxMessageValues } =
    getSalaryExchangeNudges({
      contexts,
      salaryExchangeEntitlement: sxEntitlement,
      salaryExchange: data?.salaryExchange,
      salaryExchangeRequests: data?.salaryExchangeRequests?.edges.map(
        ({ node }) => node,
      ),
      bonusSalaryExchangeRequests: data?.bonusSalaryExchangeRequests?.edges.map(
        ({ node }) => node,
      ),
    });

  const actions: ActionProps[] = [
    {
      primaryLink: { href: schedulingLink ?? '' },
      action: 'bookTransferMeeting',
      show:
        canBookTransferMeeting &&
        contexts.some(context =>
          [Context.PENSION, Context.TRANSFERABLE_PENSION_INSURANCE].includes(
            context,
          ),
        ),
      hasHero: true,
    },
    {
      primaryLink: {
        pathname: isFriSignedWithEmail
          ? '/connected-services/connect'
          : '/upgrade-fri',
        search: isFriSignedWithEmail
          ? undefined
          : '?next=/connected-services/connect',
        state: { next: pathname },
      },
      action: 'optimizePension',
      show:
        (!isPoaEffective || !isPpmConnected) &&
        contexts.includes(Context.PENSION),
      hasHero: true,
    },
    {
      primaryLink: '/pension/salary-exchange',
      action: 'bonusExchange',
      show: showSx.onetimeExchange,
      messageValues: sxMessageValues.onetimeExchange,
    },
    {
      primaryLink: '/pension/salary-exchange',
      action: 'salaryExchange',
      show: showSx.initOngoingExchange,
      messageValues: sxMessageValues.initOngoingExchange,
    },
    {
      primaryLink: '/pension/salary-exchange',
      action: 'changeSx',
      show: showSx.editOngoingExchange,
    },
    {
      primaryLink: {
        pathname: '/pension/plus/sign',
        search: qs.stringify({
          context: nudgePlusContext,
        }),
      },
      action: 'advinansPlus',
      hasHero: true,
      show: nudgePlusAdvice,
    },
    {
      primaryLink: '/benefits/rabatt-forsakringar',
      action: 'insuranceDiscount',
      hasHero: true,
      show: hasInsuranceDiscount && contexts.includes(Context.INSURANCES),
    },
  ];

  return actions.filter(({ show }) => show);
};
