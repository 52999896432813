import { featurePreviewMessages } from 'common/messages/feature-preview';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { OnboardingTourTooltip } from '../OnboardingTourTooltip';

export const PARENT_ID = 'onboarding-tour-preview-step-2';

export const Step2 = () => (
  <OnboardingTourTooltip
    title={<FormattedMessage {...featurePreviewMessages.step2Header} />}
    description={<FormattedMessage {...featurePreviewMessages.step2Body} />}
    parentId={PARENT_ID}
  />
);
