import gql from 'graphql-tag';

export const EDIT_SALARY_QUERY = gql`
  query editSalaryQuery($companyId: ID!, $userAccountId: ID!) {
    company(id: $companyId) {
      id
      remunerationTypes {
        edges {
          node {
            id
            currency
            name
            unitCode
          }
        }
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      employment {
        from
        to
        remuneration {
          from
          to
          type {
            id
            name
          }
          value: price
          currency
          unitCode
        }
      }
    }
  }
`;
