import {
  economicProfileDetails,
  employmentDetails,
  plusProfileDetails,
  userAccountDetails,
} from 'common/fragments';
import gql from 'graphql-tag';

export const CREATE_PLUS_PROFILE_MUTATION = gql`
  mutation createPlusProfileMutation($input: CreatePlusProfileInput!) {
    createPlusProfile(input: $input) {
      profile {
        ...plusProfileDetails
      }
    }
  }
  ${plusProfileDetails}
`;

export const UPDATE_PLUS_ADVICE_MUTATION = gql`
  mutation updatePlusAdviceMutation($input: UpdatePlusAdviceInput!) {
    updatePlusAdvice(input: $input) {
      advice {
        id
      }
    }
  }
`;

export const SIGN_PLUS_MUTATION = gql`
  mutation signPlusMutation {
    signPlus {
      ticketId
      autoStartToken
      qrData
    }
  }
`;

export const UPDATE_PLUS_SUGGESTION_STATUS_MUTATION = gql`
  mutation updatePlusSuggestionStatusMutation(
    $input: UpdatePlusSuggestionStatusInput!
  ) {
    updatePlusSuggestionStatus(input: $input) {
      suggestion {
        id
        status
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation loginMutation($input: LoginInput!) {
    login(input: $input) {
      ticketId
      autoStartToken
      qrData
    }
  }
`;

export const CANCEL_LOGIN_MUTATION = gql`
  mutation cancelLoginMutation($input: CancelLoginInput!) {
    cancelLogin(input: $input) {
      clientMutationId
    }
  }
`;

export const ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION = gql`
  mutation assertFullyCapableForWorkMutation(
    $input: AssertFullyCapableForWorkInput!
  ) {
    assertFullyCapableForWork(input: $input) {
      clientMutationId
    }
  }
`;

export const SIGNUP_FRI_MUTATION = gql`
  mutation signupFriMutation($input: SignupFri2Input!) {
    signupFri2(input: $input) {
      clientMutationId
    }
  }
`;

export const ADD_EMPLOYMENT_MUTATION = gql`
  mutation addEmploymentMutation($input: AddEmploymentInput!) {
    addEmployment(input: $input) {
      employment {
        ...employmentDetails
      }
    }
  }
  ${employmentDetails}
`;

export const SELECT_PENSION_PROVIDER_MUTATION = gql`
  mutation selectPensionProviderMutation(
    $input: FlexSelectPensionProviderInput
  ) {
    selectPensionProvider(input: $input) {
      autoStartToken
      ticketId
    }
  }
`;

export const UPDATE_USER_ACCOUNT_MUTATION = gql`
  mutation updateUserAccountMutation($input: UpdateUserAccountInput!) {
    updateUserAccount(input: $input) {
      userAccount {
        ...userAccountDetails
      }
    }
  }
  ${userAccountDetails}
`;

export const UPDATE_ECONOMIC_PROFILE_MUTATION = gql`
  mutation updateEconomicProfileMutation($input: EconomicProfileInput!) {
    updateEconomicProfile(input: $input) {
      economicProfile {
        ...economicProfileDetails
      }
    }
  }
  ${economicProfileDetails}
`;

export const SIGN_POA_MUTATION = gql`
  mutation signPoaMutation($input: SignPoaInput) {
    signPoa(input: $input) {
      ticketId
      autoStartToken
    }
  }
`;

export const INGEST_PAYSLIP_FILE_MUTATION = gql`
  mutation ingestPayslipFileMutation($input: IngestPayslipInput!) {
    ingestPayslipFile(input: $input) {
      success
    }
  }
`;

export const CANCEL_BANK_ID_TICKET_MUTATION = gql`
  mutation cancelBankIdTicketMutation($input: FlexCancelBankIdTicket!) {
    cancelBankIdTicket(input: $input) {
      clientMutationId
    }
  }
`;
