import { A, BodyMedium, Button, Grid, GridCell } from '@frontend/ui';
import EXTERNAL_URLS from 'app/lib/external-urls';
import { cookieMessages } from 'common/messages/cookie';
import { FormattedMessage } from 'components/formats';
import { initGA } from 'contexts/google/analytics';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';

import { getTopDomain } from './util';

const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  border: none;
  width: 100%;
  background: ${p => p.theme.primary};
  padding: 0.875rem;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${p => p.theme.onPrimary};
`;

const InvertedLink = styled(A)`
  color: ${p => p.theme.onPrimary};
`;

const InvertedButton = styled(Button)`
  margin-right: 0.5rem;
  flex-shrink: 0;
  color: ${p => p.theme.onPrimary} !important;
  border-color: ${p => p.theme.onPrimary} !important;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const THIRD_PARTY_COOKIES = 'adv_third_party_cookies';

export enum COOKIE_OPTIONS {
  ALLOW = 'ALLOW',
  BLOCK = 'BLOCK',
}

export const allowed = (value: unknown) => value === COOKIE_OPTIONS.ALLOW;
const rootDomain = getTopDomain();

export const CookieBanner: React.FC = () => {
  /* This cookie is shared with Advinans.se. Any changes to the name of the cookie or its values
  must be updated in the codebase of Advinans.se for the site settings to apply to the app
  or the app settings to apply to the site */
  const [cookies, setCookie, removeCookie] = useCookies<string>([
    THIRD_PARTY_COOKIES,
  ]);

  const handleClick = (option: COOKIE_OPTIONS) => {
    const oneYearFromNow = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1),
    );

    setCookie(THIRD_PARTY_COOKIES, option, {
      path: '/',
      expires: oneYearFromNow,
    });

    if (allowed(option)) {
      initGA(true);
    }
  };

  useEffect(() => {
    if (
      !(
        cookies.adv_third_party_cookies &&
        allowed(cookies.adv_third_party_cookies)
      )
    ) {
      ['_ga', '_gid', '_gat'].forEach(cookie =>
        removeCookie(cookie, {
          path: '/',
          domain: `.${rootDomain}`,
        }),
      );
    }
  }, [cookies.adv_third_party_cookies]);

  if (cookies.adv_third_party_cookies) {
    return null;
  }

  return (
    <Container>
      <Grid>
        <GridCell span={8}>
          <TextWrapper>
            <BodyMedium>
              <FormattedMessage
                {...cookieMessages.description}
                values={{
                  link: (
                    <InvertedLink
                      target="_blank"
                      href={EXTERNAL_URLS.nordeaNodeCookies}
                    >
                      <FormattedMessage {...cookieMessages.linkText} />
                    </InvertedLink>
                  ),
                }}
              />
            </BodyMedium>
          </TextWrapper>
        </GridCell>
        <GridCell desktop={4} tablet={8}>
          <ButtonContainer>
            <InvertedButton
              text
              onClick={() => handleClick(COOKIE_OPTIONS.BLOCK)}
            >
              <FormattedMessage {...cookieMessages.blockButtonText} />
            </InvertedButton>
            <InvertedButton onClick={() => handleClick(COOKIE_OPTIONS.ALLOW)}>
              <FormattedMessage {...cookieMessages.allowButtonText} />
            </InvertedButton>
          </ButtonContainer>
        </GridCell>
      </Grid>
    </Container>
  );
};
