import axios from 'axios';

export const getSession = async (token: string): Promise<void> => {
  await axios({
    method: 'post',
    url: `${window.env.API}/session`,
    withCredentials: true,
    data: {
      token,
    },
  });
};
