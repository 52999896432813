import { EffectiveLocale } from 'containers/IntlProviderWrapper';
import React, { useContext } from 'react';

export interface ContentfulResponse {
  fields: object;
  sys: { contentType: { sys: { id: string } } };
}

export interface ContenfulEntry {
  error: boolean;
  id: string;
  loading: boolean;
  queryFired: boolean;
  data?: ContentfulResponse;
}

export type ContentfulGetEntry = (id: string, locale: EffectiveLocale) => void;

type Entry = Record<string, ContenfulEntry>;

export interface ContentfulStore {
  entries: { 'en-US': Entry; 'sv-SE': Entry };
  getEntry: ContentfulGetEntry;
}

const ContentfulContext = React.createContext<ContentfulStore>({
  entries: { 'sv-SE': {}, 'en-US': {} },
  getEntry: () => {},
});

export const useContentfulStore = () => useContext(ContentfulContext);

export default ContentfulContext;
