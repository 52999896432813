import {
  APP_BAR_HEIGHT,
  Drawer as DrawerUI,
  IconButton,
  ScrollableMenuArea as ScrollableMenuAreaComponent,
  titleMediumCSS,
} from '@frontend/ui';
import { menuOpen } from '@frontend/ui/icons';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import { a11yMessages } from 'common/messages/a11y';
import { useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useCurrentUser } from 'contexts/current-user';
import CustomCompanyLogo from 'features/layout/CustomCompanyLogo';
import PortalLogo from 'features/layout/PortalLogo';
import React from 'react';
import styled from 'styled-components';
import { SMALL_SCREEN_RESOLUTION } from 'utils/constants';
import { isAdminView } from 'utils/is-admin-view';

import { AdminMenuContent } from './components/AdminMenuContent';
import { UserMenuContent } from './components/UserMenuContent';
import { useHasAdminMenuContent } from './lib/use-has-admin-menu-content';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  height: 4rem;
  margin-bottom: 0.25rem;
`;

const CompanyName = styled.div`
  ${titleMediumCSS};
  color: ${p => p.theme.onSurface};
  padding-left: 0.375rem;
`;

const Drawer = styled(DrawerUI)`
  padding-top: 0;
  padding-left: env(safe-area-inset-right, 0.75rem);
`;

const ScrollableMenuArea = styled(ScrollableMenuAreaComponent)<{
  withOffsetTop?: boolean;
}>`
  ${p =>
    p.withOffsetTop &&
    `margin-top: ${APP_BAR_HEIGHT}px; padding-top: 0.25rem;`};
`;

const Ul = styled.ul`
  padding: 0.25rem;
  margin: 0;

  a {
    margin: 0.1875rem 0;
  }
`;

export const NavigationDrawer: React.FC = () => {
  const isSmallScreen = useIsSmallScreen(SMALL_SCREEN_RESOLUTION);

  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { isDrawerOpen, setIsDrawerOpen } = useNavigationContext();

  const { hasSalaryExchange, loading, error } = useHasAdminMenuContent();

  const intl = useIntl();

  if (loading) {
    return <TopLoading />;
  }

  if (!isSmallScreen && !isDrawerOpen) {
    return null;
  }

  const withOffsetTop = !isSmallScreen && isDrawerOpen;

  return (
    <Drawer
      modal={isSmallScreen}
      open={!!isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      aria-hidden={!isDrawerOpen}
      id="navigation-drawer"
    >
      <nav>
        <ScrollableMenuArea withOffsetTop={withOffsetTop}>
          {isSmallScreen && (
            <HeaderWrapper>
              <IconButton
                icon={menuOpen}
                label={intl.formatMessage(a11yMessages.menu)}
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              />
              {currentCompany?.logoUrl ? (
                <CustomCompanyLogo
                  imageUrl={currentCompany.logoUrl}
                  alt={currentCompany.displayName}
                />
              ) : currentCompany ? (
                <CompanyName>{currentCompany.displayName}</CompanyName>
              ) : (
                <PortalLogo />
              )}
            </HeaderWrapper>
          )}
          {/* Render admin menu content if admin view is active  */}
          {error && <GraphQlError error={error} />}
          <Ul role="menu">
            {isAdminView() && !!currentCompany ? (
              <AdminMenuContent
                hasSalaryExchange={hasSalaryExchange}
                currentCompany={currentCompany}
              />
            ) : (
              <UserMenuContent />
            )}
          </Ul>
        </ScrollableMenuArea>
      </nav>
    </Drawer>
  );
};
