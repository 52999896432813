import { useMutation } from '@apollo/client';
import {
  terminateEmploymentMutation,
  terminateEmploymentMutationVariables,
  UpdateEmploymentInput,
} from 'app/apollo/graphql/types';
import { useCurrentUser } from 'contexts/current-user';

import { FormValues } from '..';
import { TERMINATE_EMPLOYMENT_MUTATION } from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
}

type Submit = (values: FormValues) => Promise<void>;

export const useSubmit = ({ onCompleted }: Params) => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const [terminateEmployment, { error: submissionError }] = useMutation<
    terminateEmploymentMutation,
    terminateEmploymentMutationVariables
  >(TERMINATE_EMPLOYMENT_MUTATION, {
    update: cache => {
      cache.evict({
        id: `CompanyAnalytics:${currentCompany?.id}`,
        fieldName: 'numberOfActiveEmployments',
      });
      cache.gc();
    },
  });

  const submit: Submit = async (values: FormValues) => {
    try {
      // UserAccount is guaranteed through form validation
      if (!currentCompany || !values.employment) {
        return;
      }

      const input: UpdateEmploymentInput = {
        id: values.employment.value.employmentId,
        effectiveUntil: values.effectiveDate,
      };

      await terminateEmployment({
        variables: { input },
      });
      onCompleted();
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
