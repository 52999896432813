import { LanguageSelector } from 'components/LanguageSelector';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import React from 'react';
import styled from 'styled-components';

import { AUTH_FORM_WRAPPER_PADDING } from '../AuthFormWrapper';

const MenuWrapper = styled.div`
  margin: 2.5rem 0;
  padding: 0 ${AUTH_FORM_WRAPPER_PADDING}rem;
`;

export const LanguageSelect: React.FC = () => {
  const { locale: currentLocale, setLocale } = useIntlContext();

  return (
    <MenuWrapper>
      <LanguageSelector setLocale={setLocale} value={currentLocale} />
    </MenuWrapper>
  );
};
