import { Icon } from '@frontend/ui';
import {
  accountTree,
  article,
  beachAccess,
  homeFilled,
  loyalty,
  money,
  shield,
} from '@frontend/ui/icons';
import { menuMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import {
  ONBOARDING_TOUR_PARENT_ID_2,
  ONBOARDING_TOUR_PARENT_ID_3,
  ONBOARDING_TOUR_PARENT_ID_4,
  ONBOARDING_TOUR_PARENT_ID_5,
  ONBOARDING_TOUR_PARENT_ID_6,
  ONBOARDING_TOUR_PARENT_ID_7,
} from 'features/feature-preview/features/onboarding-tour';
import React from 'react';

interface Company {
  id: string;
  hasTotalCompensation?: boolean;
}

interface Args {
  currentUser: {
    currentCompany?: Company;
  };
}

export function getUserMenuItems({ currentUser }: Args) {
  return {
    companyHome: {
      id: ONBOARDING_TOUR_PARENT_ID_2,
      icon: <Icon icon={homeFilled} size="medium" decorative />,
      link: '/',
      title: <FormattedMessage {...menuMessages.home} />,
      navigation: true,
      exact: true,
      show: !!currentUser.currentCompany,
    },
    companyBenefits: {
      id: ONBOARDING_TOUR_PARENT_ID_3,
      icon: <Icon icon={loyalty} size="medium" decorative />,
      link: '/benefits',
      title: <FormattedMessage {...menuMessages.benefits} />,
      navigation: true,
      show: true,
    },
    pension: {
      id: ONBOARDING_TOUR_PARENT_ID_4,
      icon: <Icon icon={beachAccess} size="medium" decorative />,
      link: '/pension',
      title: <FormattedMessage {...menuMessages.pension} />,
      navigation: true,
      show: true,
    },
    insurances: {
      id: ONBOARDING_TOUR_PARENT_ID_5,
      icon: <Icon icon={shield} size="medium" decorative />,
      link: '/insurances',
      title: <FormattedMessage {...menuMessages.insurance} />,
      navigation: true,
      show: true,
    },
    totalCompensation: {
      id: ONBOARDING_TOUR_PARENT_ID_6,
      icon: <Icon icon={money} size="medium" decorative />,
      link: '/total-compensation',
      title: <FormattedMessage {...menuMessages.totalCompensation} />,
      navigation: true,
      show: !!currentUser.currentCompany?.hasTotalCompensation,
    },
    internalNews: {
      id: ONBOARDING_TOUR_PARENT_ID_7,
      icon: <Icon icon={article} size="medium" decorative />,
      link: '/internal-news',
      title: <FormattedMessage {...menuMessages.news} />,
      navigation: true,
      show: !!currentUser.currentCompany,
    },
    home: {
      id: ONBOARDING_TOUR_PARENT_ID_2,
      icon: <Icon icon={homeFilled} size="medium" decorative />,
      link: '/',
      title: <FormattedMessage {...menuMessages.home} />,
      navigation: true,
      exact: true,
      show: !currentUser.currentCompany,
    },
    connectedServices: {
      icon: <Icon icon={accountTree} size="medium" decorative />,
      link: '/connected-services',
      title: <FormattedMessage {...menuMessages.connectedServices} />,
      navigation: true,
      show: true,
    },
  };
}
