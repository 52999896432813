import { DocumentNode, useMutation } from '@apollo/client';
import { LinearProgress, ModalFooter } from '@frontend/ui';
import { TicketStatus } from 'app/apollo/graphql/types';
import { commonMessages, formMessages, signMessages } from 'common/messages';
import { a11yMessages } from 'common/messages/a11y';
import { BankIdSignLogo } from 'components/BankIDSignInfo';
import { FormattedMessage, useIntl } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import { Modal, ModalBody } from 'components/Modal';
import { PROGRESS_STATUS } from 'features/auth/common/constants';
import { ProgressMessage } from 'features/auth/common/ProgressMessage';
import { signBankIdWithSameDevice } from 'features/auth/login/lib/sign-bankid-with-same-device';
import { useSignBankIdContext } from 'features/sign-graphql/context';
import QRCode from 'qrcode.react';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

export const CenteredQRCode = styled(QRCode)`
  margin: 1.5rem auto;
  display: block;
`;

export const QrCodeHelperText = styled.p`
  text-align: center;
  margin-bottom: 0;
`;

interface CancelSignButtonProps {
  onCancelMutation: DocumentNode;
  onError: () => void;
  ticketId: string;
}

const CancelSignButton: React.FC<CancelSignButtonProps> = ({
  onCancelMutation,
  onError,
  ticketId,
}) => {
  const [cancelSign, { loading }] = useMutation(onCancelMutation, {
    onError,
    variables: { input: { ticketId } },
  });

  return (
    <FullWidthButton
      text
      loading={loading}
      onClick={() => cancelSign()}
      style={{ marginTop: '0.75rem' }}
    >
      <FormattedMessage {...commonMessages.cancel} />
    </FullWidthButton>
  );
};

export const BankIDSignModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    state: {
      ticketId,
      progressStatus,
      autoStartToken,
      qrData,
      onCancelMutation,
    },
    clearState,
  } = useSignBankIdContext();
  const { formatMessage } = useIntl();

  if (!progressStatus || !ticketId) {
    return null;
  }

  const openBankId = (): void =>
    signBankIdWithSameDevice({ ticketId, history, location, autoStartToken });

  const isProgressing = progressStatus === TicketStatus.PROCESSING;

  return (
    <Modal
      isOpen
      zIndex={99}
      size="small"
      contentLabel={formatMessage(signMessages.signBankId)}
      withQrCode={!!qrData}
    >
      <ModalBody>
        {qrData ? (
          <>
            <CenteredQRCode value={qrData} bgColor="transparent" />
            <QrCodeHelperText>
              <FormattedMessage {...formMessages.scrapeWithQRCode} />
            </QrCodeHelperText>
          </>
        ) : (
          <>
            <BankIdSignLogo />
            <LinearProgress
              label={formatMessage(a11yMessages.loading)}
              aria-live="polite"
            />
            <ProgressMessage>
              {PROGRESS_STATUS[progressStatus] || PROGRESS_STATUS.GENERAL}
            </ProgressMessage>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <FullWidthButton onClick={() => openBankId()}>
          <FormattedMessage {...commonMessages.openBankIdSameDevice} />
        </FullWidthButton>
        {onCancelMutation && !isProgressing && (
          <CancelSignButton
            onCancelMutation={onCancelMutation}
            onError={clearState}
            ticketId={ticketId}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
