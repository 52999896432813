import gql from 'graphql-tag';

export const CHANGE_BENEFIT_PACKAGE_MUTATION = gql`
  mutation changeBenefitPackageMutation(
    $input: UpdateMembershipBenefitPackageInput!
  ) {
    updateMembershipBenefitPackage(input: $input) {
      benefitPackageId
    }
  }
`;
