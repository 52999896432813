import gql from 'graphql-tag';

export const fundDetails = gql`
  fragment fundDetails on Fund {
    id
    name
    isin
    type
    index
    riskIndicator
    providerHoldingCompany
    assetClass
    tags {
      id
    }
  }
`;

export const availableBenchmarkDetails = gql`
  fragment availableBenchmarkDetails on Benchmark {
    id
    name
    periodicReturns {
      asOf
      return1M
      return3M
      returnYTD
      return1Y
      return3Y
      return5Y
      returnSinceStart
    }
  }
`;

export const exposureDetails = gql`
  fragment exposureDetails on Exposure {
    name
    share
  }
`;

export const fundPerformanceDetails = gql`
  fragment fundPerformanceDetails on FundPerformanceItem {
    date: at
    value
    benchmarks {
      value
    }
  }
`;

export const economicProfileDetails = gql`
  fragment economicProfileDetails on EconomicProfile {
    sustainabilityCategory
    riskPreference
    monthlyIncome
    monthlyExpenses
    assets
    liabilities
    collectiveAgreement
    pensionAge
  }
`;

export const userAccountDetails = gql`
  fragment userAccountDetails on UserAccount {
    id
    email
    phone
    appData
    localePreference
    customerSupportCode
    hasSignedFri
    economicProfile {
      ...economicProfileDetails
    }
    poa(includeDisabled: true) {
      effective
      disabled
    }
  }
  ${economicProfileDetails}
`;

export const plusProfileDetails = gql`
  fragment plusProfileDetails on PlusProfile {
    userAccountId
    monthlyIncome
    monthlyExpenses
    assets
    liabilities
    activityLevel
    sustainabilityPreferences {
      sustainabilityCategory
      hasCustomPreferences
      environmentallySustainableInvestmentsMinShare
      sustainableInvestmentsMinShare
      principalAdverseIndicatorEnvironmental
      principalAdverseIndicatorSocial
    }
    riskPreference
    zt
    withdrawalPeriod
    createdAt
  }
`;

/// b2b

export const companyDetails = gql`
  fragment companyDetails on Company {
    id
    registrationNumber
    name
    displayName
    logoUrl
    email
    phone
    hasJobOffers
    salaryReviewMonth
    accountClosureMonth
    legalEntityType
    newsletterOptOut
    address {
      line1
      line2
      co
      postalCode
      city
    }
    emailLoginAllowed
    employeeEmailLanguage
    managedBy
  }
`;

export const pageInfoDetails = gql`
  fragment pageInfoDetails on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const totalCompensationDetails = gql`
  fragment totalCompensationDetails on TotalCompensationItem2 {
    key: id
    category
    includedInCompensation
    annualCompensation
  }
`;

export const companyOverview = gql`
  fragment companyOverview on Company {
    id
    name
  }
`;

export const costCenterDetails = gql`
  fragment costCenterDetails on CostCenter {
    id
    identifier
    name
  }
`;

export const membershipOverview = gql`
  fragment membershipOverview on Membership {
    id
    userAccountId
    givenName
    lastName
    costCenter {
      id
      name
    }
    employment {
      from
      to
      status {
        from
        to
        type {
          id
        }
      }
    }
    naturalPersonIdentifiers
    monthlySalary
    membershipBenefitPackage {
      benefitPackage {
        id
        name
      }
    }
  }
`;

export const payslipOverviewDetails = gql`
  fragment payslipOverviewDetails on Payslip {
    id
    paidAt
    importDate
  }
`;

export const employmentActivityDetails = gql`
  fragment employmentActivityDetails on EmploymentActivity {
    id
    type
    period
    rate
  }
`;

export const employmentSalaryDetails = gql`
  fragment employmentSalaryDetails on EmploymentSalary {
    monthlySalary
    effectiveDate
  }
`;

export const employmentDetails = gql`
  fragment employmentDetails on Employment {
    id
    period
    rate
    membership {
      id
      userAccountId
      givenName
      lastName
    }
    activities {
      edges {
        node {
          ...employmentActivityDetails
        }
      }
    }
    salaries {
      edges {
        node {
          ...employmentSalaryDetails
        }
      }
    }
  }
  ${employmentActivityDetails}
  ${employmentSalaryDetails}
`;

export const membershipAdminDetails = gql`
  fragment membershipAdminDetails on Membership {
    id
    userAccountId
    roles
    naturalPersonIdentifiers
    benefitPackageUpdatedAt
    givenName
    firstName
    lastName
    email
    phone
    monthlySalary
    collectiveAgreement
    employeeNumber
    vacationDaysSaved
    vacationDaysPaid
    vacationDaysUnpaid
    vacationDaysAdvance
    vacationDaysPerYear
    companyCarMonthlyValue
    employerContributionShare
    incomeTaxTable
    fitnessContributionBalance
    totalCompensation: totalCompensation2 {
      ...totalCompensationDetails
    }
    membershipBenefitPackage {
      benefitPackage {
        id
        name
      }
    }
    salaries {
      edges {
        node {
          monthlySalary
          effectiveDate
          createdAt
        }
      }
    }
    payslips {
      edges {
        node {
          ...payslipOverviewDetails
        }
      }
    }
  }
  ${totalCompensationDetails}
  ${payslipOverviewDetails}
`;

export const internalPostAttachmentDetails = gql`
  fragment internalPostAttachmentDetails on InternalPostAttachment {
    id
    name
    type
    size
    url
  }
`;

export const internalPostDetails = gql`
  fragment internalPostDetails on InternalPost {
    id
    title
    description
    body
    hero: imageUrl(size: 1920)
    publishedAt
    status
    important
    tags
    benefitPackages {
      edges {
        node {
          id
          name
        }
      }
    }
    orgUnitIds
    organizationalUnits {
      edges {
        node {
          id
          name
        }
      }
    }
    attachments {
      ...internalPostAttachmentDetails
    }
  }
  ${internalPostAttachmentDetails}
`;

export const internalPostSummary = gql`
  fragment internalPostSummary on InternalPost {
    id
    title
    important
    publishedAt
  }
`;

export const internalPostOverview = gql`
  fragment internalPostOverview on InternalPost {
    id
    title
    description
    important
    tags
    publishedAt
    image: imageUrl(size: 600)
  }
`;

export const invoiceDetails = gql`
  fragment invoiceDetails on Invoice {
    id
    companyId
    payableAmount
    invoiceNumber
    issueDate
    dueDate
    supplierType
    shredded
    peppolUrl: url(format: PEPPOL)
    sie4Url: url(format: SIE4)
    pdfUrl: url(format: PDF)
    htmlUrl: url(format: HTML)
    excelUrl: url(format: XLSX)
    csvUrl: url(format: CSV)
  }
`;

export const salaryReportDetails = gql`
  fragment salaryReportDetails on SalaryReport {
    id
    companyId
    year
    month
    name
    excelUrl: url(format: EXCEL)
  }
`;

export const projectionItemDetails = gql`
  fragment projectionItemDetails on ProjectionItem {
    date
    projectedReturn: estimatedValue
    badReturn: estimatedValue(percentile: 10)
    goodReturn: estimatedValue(percentile: 67)
    invested
  }
`;

export const payslipImportDetails = gql`
  fragment payslipImportDetails on PayslipImport {
    filename
    status
    importDate
  }
`;
