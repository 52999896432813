import { companyCarCardQuery_Query } from 'app/apollo/graphql/types';

interface CompanyCardCardData {
  /**
   * The monthly value of the company car
   */
  companyCarMonthlyValue: string;
}

/**
 * Retrieves the data needed for the company car card from the query response
 * or returns null if the card should not be shown
 */
export const getCompanyCarCardData = (
  data?: companyCarCardQuery_Query,
): CompanyCardCardData | null => {
  const companyCarMonthlyValue = data?.membership?.companyCarMonthlyValue;

  if (!companyCarMonthlyValue) {
    return null;
  }

  return { companyCarMonthlyValue };
};
