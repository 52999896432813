import gql from 'graphql-tag';

export const GOOGLE_ANALYTICS_QUERY = gql`
  query googleAnalyticsQuery {
    viewer {
      id
    }
    session {
      authenticationMethod
    }
  }
`;

export const GOOGLE_ANALYTICS_COMPANY_QUERY = gql`
  query googleAnalyticsCompanyQuery($id: ID!) {
    company(id: $id) {
      id
      registrationNumber
    }
  }
`;
