import { commonMessages } from 'common/messages';
import { loginMessages } from 'common/messages/login';
import { FormattedMessage } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

interface Props {
  cancelLogin: () => void;
  qrCode: string;
  signWithSameDevice: () => void;
}

export const LoginProgressSection: React.FC<Props> = ({
  cancelLogin,
  signWithSameDevice,
  qrCode,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <Wrapper>
      <div style={{ marginBottom: '2rem' }}>
        <QRCode size={136} value={qrCode} />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <FormattedMessage {...loginMessages.generalProgress} />
      </div>
      <FullWidthButton
        onClick={() => {
          signWithSameDevice();
          setLoading(true);
        }}
        loading={loading}
        extended
      >
        <FormattedMessage {...commonMessages.openBankIdSameDevice} />
      </FullWidthButton>
      <FullWidthButton
        text
        onClick={() => cancelLogin()}
        style={{ marginTop: '0.75rem' }}
        extended
      >
        <FormattedMessage {...commonMessages.cancel} />
      </FullWidthButton>
    </Wrapper>
  );
};
