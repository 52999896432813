import { useMutation } from '@apollo/client';
import { toMoney } from '@frontend/utils';
import {
  AddCustomItemInput,
  editSalaryMutation,
  editSalaryMutationVariables,
} from 'app/apollo/graphql/types';
import { MEMBERSHIP_REMUNERATIONS_QUERY } from 'common/queries/membership';
import { useCurrentUser } from 'contexts/current-user';
import { monthToDate } from 'validations';

import { FormValues } from '..';
import { EDIT_SALARY_MUTATION } from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
}

type Submit = (values: FormValues) => Promise<void>;

export const useSubmit = ({ onCompleted }: Params) => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const [addRemuneration, { error: submissionError }] = useMutation<
    editSalaryMutation,
    editSalaryMutationVariables
  >(EDIT_SALARY_MUTATION);

  const submit: Submit = async (values: FormValues) => {
    try {
      const { remunerationType: itemTypeId, amount, effectiveDate } = values;

      // UserAccount is guaranteed through form validation
      if (!currentCompany || !values.userAccount) {
        return;
      }

      const input: AddCustomItemInput = {
        userAccountId: values.userAccount.value,
        companyId: currentCompany?.id,
        itemTypeId,
        value: toMoney(amount),
        from: monthToDate(effectiveDate),
      };

      await addRemuneration({
        refetchQueries: [
          {
            query: MEMBERSHIP_REMUNERATIONS_QUERY,
            variables: {
              companyId: currentCompany?.id,
              // The 'employmentId' is not used in the query since we pass
              // 'hasEmploymentId: false',  but it is required as input,
              // otherwise apollo will fail on this query
              employmentId: '',
              hasEmploymentId: false,
              userAccountId: values.userAccount.value,
            },
          },
        ],
        variables: { input },
      });
      onCompleted();
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
