import { MenuItem } from 'components/Menu';
import { getUserMenuItems } from 'containers/NavigationDrawer/lib/get-user-menu-items';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';

export const UserMenuContent: React.FC = () => {
  const currentUser = useCurrentUser();

  const {
    home: { show: showHome, ...homeRest },
    pension: { show: showPension, ...pensionRest },
    insurances: { show: showInsurances, ...insurancesRest },
    connectedServices: {
      show: showConnectedServices,
      ...connectedServicesRest
    },
    companyHome: { show: showCompanyHome, ...companyHomeRest },
    totalCompensation: {
      show: showTotalCompensation,
      ...totalCompensationRest
    },
    companyBenefits: { show: showCompanyBenefits, ...companyBenefitsRest },
    internalNews: { show: showInternalNews, ...internalNewsRest },
  } = getUserMenuItems(currentUser);

  return (
    <>
      {showCompanyHome && <MenuItem {...companyHomeRest} />}
      {showHome && <MenuItem {...homeRest} />}
      {showCompanyBenefits && <MenuItem {...companyBenefitsRest} />}
      {showPension && <MenuItem {...pensionRest} />}
      {showInsurances && <MenuItem {...insurancesRest} />}
      {showTotalCompensation && <MenuItem {...totalCompensationRest} />}
      {showInternalNews && <MenuItem {...internalNewsRest} />}
      {showConnectedServices && <MenuItem {...connectedServicesRest} />}
    </>
  );
};
