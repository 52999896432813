import { defineMessages } from 'react-intl';

export const footerMessages = defineMessages({
  commonQuestions: {
    id: 'footerMessages.commonQuestions',
    defaultMessage: 'Vanliga frågor',
  },
  environmentPolicy: {
    id: 'footerMessages.environmentPolicy',
    defaultMessage: 'Vår miljöpolicy',
  },
  safetyWork: {
    id: 'footerMessages.safetyWork',
    defaultMessage: 'Vårt säkerhetsarbete',
  },
  law: {
    id: 'footerMessages.law',
    defaultMessage: 'Juridisk information',
  },
  formsAndAgreements: {
    id: 'footerMessages.formsAndAgreements',
    defaultMessage: 'Blanketter',
  },
  termsAndConditions: {
    id: 'footerMessages.termsAndConditions',
    defaultMessage: 'Användarvillkor',
  },
  cookies: {
    id: 'footerMessages.cookies',
    defaultMessage: 'Cookies',
  },
  personalData: {
    id: 'footerMessages.personalData',
    defaultMessage: 'Personuppgifter',
  },
  scrollTop: {
    id: 'footerMessages.scrollTop',
    defaultMessage: 'Till toppen',
  },
  contact: {
    id: 'footerMessages.contact',
    defaultMessage: 'Kontakta oss',
  },
  emailContact: {
    id: 'footerMessages.emailContact',
    defaultMessage: 'E-post {email}',
  },
  phoneContact: {
    id: 'footerMessages.phoneContact',
    defaultMessage: 'Ring {phone}',
  },
  openingHours: {
    id: 'footerMessages.openingHours',
    defaultMessage: 'Kundtjänst är öppen vardagar kl. 9-16.',
  },
  investmentDisclaimer: {
    id: 'footerMessages.investmentDisclaimer',
    defaultMessage: `Investeringar i värdepapper och fonder innebär alltid en risk. En
    investering kan både öka och minska i värde och det är inte säkert att
    du får tillbaka det investerade kapitalet. Nordea Node står under tillsyn
    av Finansinspektionen.`,
  },
  aboutNordeaNode: {
    id: 'footerMessages.aboutNordeaNode',
    defaultMessage: 'Om Nordea Node',
  },
});
