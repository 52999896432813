import { JobOfferStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

import { commonMessages } from './';
import { poaMessages } from './external-connection';
import { offerFormMessages } from './offers';
import { totalCompensationMessages } from './total-compensation';

export const jobOfferMessages = defineMessages({
  jobOffer: {
    id: 'jobOfferMessages.jobOffer',
    description: 'Job offer',
    defaultMessage: 'Jobberbjudande',
  },
  jobOfferTitle: {
    id: 'jobOfferMessages.jobOfferTitle',
    description: 'Job offers',
    defaultMessage: 'Jobberbjudanden',
  },
  jobOfferDescr: {
    id: 'jobOfferMessages.jobOfferDescr',
    defaultMessage: `Med jobberbjudanden är det enkelt att kommunicera total ersättning och förmåner till potentiella kandidater. Fyll i detaljerna och dela.`,
  },
  newJobOffer: {
    id: 'jobOfferMessages.newJobOffer',
    description: 'New job offer',
    defaultMessage: 'Nytt jobberbjudande',
  },
  createJobOffer: {
    id: 'jobOfferMessages.createJobOffer',
    description: 'Create a job offer',
    defaultMessage: 'Skapa jobberbjudande',
  },
  updateJobOffer: {
    id: 'jobOfferMessages.updateJobOffer',
    description: 'Update job offer',
    defaultMessage: 'Uppdatera jobberbjudande',
  },
  monthlySalary: {
    id: 'jobOfferMessages.monthlySalary',
    description: 'Monthly salary (kr/month)',
    defaultMessage: 'Månadslön (kr/mån)',
  },
  wellnessBenefit: {
    id: 'jobOfferMessages.wellnessBenefit',
    description: 'Wellnessbenefit (kr/year)',
    defaultMessage: 'Friskvårdsbidrag (kr/år)',
  },
  yearlyVacationDays: {
    id: 'jobOfferMessages.yearlyVacationDays',
    description: 'Yearly vacation days (days/year)',
    defaultMessage: 'Semester (dagar/år)',
  },
  optionalBenefits: {
    id: 'jobOfferMessages.optionalBenefits',
    description: 'Optional benefit',
    defaultMessage: 'Andra förmåner',
  },
  lunchAllowance: {
    id: 'jobOfferMessages.lunchAllowance',
    description: 'Lunch allowance (kr/month)',
    defaultMessage: 'Lunchförmån (kr/mån)',
  },
  companyCar: {
    id: 'jobOfferMessages.companyCar',
    description: 'Company car benefit (kr/month)',
    defaultMessage: 'Företagsbil (kr/mån)',
  },
  discounts: {
    id: 'jobOfferMessages.discounts',
    description: 'Discounts',
    defaultMessage: 'Erbjudanden',
  },
  writeProspectDetails: {
    id: 'jobOfferMessages.writeProspectDetails',
    description: 'Description of the job offer',
    defaultMessage: 'Beskrivning av jobberbjudandet',
  },
  backToOffer: {
    id: 'jobOfferMessages.backToOffer',
    description: 'Tillbaka till erbjudandet',
    defaultMessage: 'Tillbaka till erbjudandet',
  },
  noJobOffer: {
    id: 'jobOfferMessages.noJobOffer',
    description: 'There is no job offer',
    defaultMessage: 'Det finns inga jobberbjudanden',
  },
  publicNotification: {
    id: 'jobOfferMessages.publicNotification',
    description: 'The notification when the job offer is public',
    defaultMessage:
      'Erbjudandet är tillgängligt för delning. För att dela erbjudandet, skicka länken till din kandidat. {link}',
  },
  privateNotification: {
    id: 'jobOfferMessages.privateNotification',
    description: 'The notification when the job offer is private',
    defaultMessage:
      'Erbjudandet är endast synligt för dig tills du väljer att publicera det. Du kan se det på denna adress: {link}',
  },
  expiredNotification: {
    id: 'jobOfferMessages.expiredNotification',
    description: 'The notification when the job offer is expired',
    defaultMessage:
      'Erbjudandets giltighetstid har passerat. För att göra erbjudandet synligt igen, sätt ett nytt datum.',
  },
  hideOffer: {
    id: 'jobOfferMessages.hideOffer',
    description: 'Hide offer',
    defaultMessage: 'Dölj erbjudandet',
  },
  copyLink: {
    id: 'jobOfferMessages.copyLink',
    description: 'Copy link',
    defaultMessage: 'Kopiera länk',
  },
  copied: {
    id: 'jobOfferMessages.copied',
    description: 'Copied',
    defaultMessage: 'Kopierad!',
  },
  showOffer: {
    id: 'jobOfferMessages.showOffer',
    description: 'Show offer',
    defaultMessage: 'Publicera erbjudandet',
  },
  deleteSubmissionError: {
    id: 'jobOfferMessages.deleteSubmissionError',
    description: 'The offer is not deleted, try again.',
    defaultMessage: 'Det gick inte att  ta bort jobberbjudandet. Försök igen.',
  },
  created: {
    id: 'jobOfferMessages.created',
    description: 'The job offer is created',
    defaultMessage: 'Jobberbjudandet har skapats',
  },
  updated: {
    id: 'jobOfferMessages.updated',
    description: 'The job offer is updated',
    defaultMessage: 'Jobberbjudandet har uppdaterats',
  },
  deleted: {
    id: 'jobOfferMessages.deleted',
    description: 'The job offer is deleted',
    defaultMessage: 'Jobberbjudandet har tagits bort',
  },
  setNewValidDate: {
    id: 'jobOfferMessages.setNewValidDate',
    description: 'Set new valid date',
    defaultMessage: 'Sätt ny giltighetstid',
  },
  totalCompensationAtCompany: {
    id: 'jobOfferMessages.totalCompensationAtCompany',
    description:
      '/j/:id - section header - total compensation at {companyName}',
    defaultMessage: 'Din totala ersättning hos {companyName}',
  },
  confirmRemoveJobOffer: {
    id: 'jobOfferMessages.confirmRemoveJobOffer',
    defaultMessage: 'Ta bort jobberbjudandet?',
  },
  confirmRemoveJobOfferDescription: {
    id: 'jobOfferMessages.confirmRemoveJobOfferDescription',
    defaultMessage:
      'Jobberbjudandet kommer att tas bort och eventuella länkar till det kommer sluta fungera.',
  },
  yourBenefitsAtCompanyName: {
    id: 'jobOfferMessages.yourBenefitsAtCompanyName',
    description: 'Your benefits at {companyName}',
    defaultMessage: 'Dina förmåner hos {companyName}',
  },
  greeting: {
    id: 'jobOfferMessages.greeting',
    defaultMessage: 'Hej {name}!',
  },
  incentiveProgram: {
    id: 'jobOfferMessages.incentiveProgram',
    defaultMessage: 'Incitamentsprogram',
  },
  aboutTheRoleAtCompanyName: {
    id: 'jobOfferMessages.aboutTheRoleAtCompanyName',
    defaultMessage: 'Om rollen hos {companyName}',
  },
});

export const collectiveAgreementLabelMessages = defineMessages({
  ITP1: commonMessages.ITP1,
  ITP: commonMessages.ITP,
  SAFLO: commonMessages.SAFLO,
  KAPKL: commonMessages.KAPKL,
  AKAPKL: commonMessages.AKAPKL,
  AKAPKR: commonMessages.AKAPKR,
  PA161: commonMessages.PA161,
  PA162: commonMessages.PA162,
  PAKFS09: commonMessages.PAKFS09,
});

export const jobOfferFormSectionMessages = defineMessages({
  info: {
    id: 'jobOfferFormSectionMessages.info',
    defaultMessage: 'Information',
  },
  compensation: commonMessages.compensation,
  benefits: commonMessages.benefits,
  settings: {
    id: 'jobOfferFormSectionMessages.settings',
    defaultMessage: 'Inställningar',
  },
});

export const jobOfferFormFieldMessages = defineMessages({
  prospect: {
    id: 'jobOfferFormFieldMessages.prospect',
    defaultMessage: 'Kandidatens namn',
  },
  position: {
    id: 'jobOfferFormFieldMessages.position',
    defaultMessage: 'Jobbtitel',
  },
  city: {
    id: 'jobOfferFormFieldMessages.city',
    defaultMessage: 'Primär arbetsort',
  },
  cityHelper: {
    id: 'jobOfferFormFieldMessages.cityHelper',
    defaultMessage: 'T.ex. Stockholm, Malmö eller Göteborg.',
  },
  location: {
    id: 'jobOfferFormFieldMessages.location',
    defaultMessage: 'Platsbeskrivning',
  },
  locationHelper: {
    id: 'jobOfferFormFieldMessages.locationHelper',
    defaultMessage: 'På plats, Hybrid eller Distans.',
  },
  body: {
    id: 'jobOfferFormFieldMessages.body',
    defaultMessage: 'Jobbeskrivning',
  },
  birthDate: {
    id: 'jobOfferFormFieldMessages.birthDate',
    defaultMessage: 'Födelsedatum',
  },
  addAvatar: {
    id: 'jobOfferFormFieldMessages.addAvatar',
    defaultMessage: 'Välj profilbild',
  },
  editAvatar: {
    id: 'jobOfferFormFieldMessages.editAvatar',
    defaultMessage: 'Ändra profilbild',
  },
  addCover: {
    id: 'jobOfferFormFieldMessages.addCover',
    defaultMessage: 'Välj omslagsbild',
  },
  editCover: {
    id: 'jobOfferFormFieldMessages.editCover',
    defaultMessage: 'Ändra omslagsbild',
  },
  monthlySalary: commonMessages.monthlySalary,
  remunerationAdd: {
    id: 'jobOfferFormFieldMessages.remunerationAdd',
    defaultMessage: 'Ange fler lönearter',
  },
  wellness: totalCompensationMessages.fitnessContribution,
  vacation: totalCompensationMessages.vacation,
  benefitPackage: commonMessages.benefitPackage,
  collectiveAgreement: commonMessages.collectiveAgreement,
  lunch: commonMessages.lunchAllowance,
  companyCar: totalCompensationMessages.companyCar,
  showDiscounts: {
    id: 'jobOfferFormFieldMessages.showDiscounts',
    defaultMessage: 'Visa erbjudanden och rabatter',
  },
  showDiscountsHelper: {
    id: 'jobOfferFormFieldMessages.showDiscountsHelper',
    defaultMessage:
      'Om kandidaten omfattas av Nordea Nodes förmånsportal med erbjudanden och rabatter kan du välja att visa även dessa i jobberbjudandet.',
  },
  incentive: {
    id: 'jobOfferFormFieldMessages.incentive',
    defaultMessage: 'Omfattas av incitamentsprogram',
  },
  incentiveHelper: {
    id: 'jobOfferFormFieldMessages.incentiveHelper',
    defaultMessage:
      'Här kan du ange om kandidaten omfattas av ett incitamentsprogram. Om rutan inte är markerad visas inte sektionen för incitamentprogram i erbjudandet. Du kan beskriva incitamentsprogrammet i textfältet nedan och bifoga filer innehållande mer information.',
  },
  validity: {
    id: 'jobOfferFormFieldMessages.validity',
    defaultMessage: 'Välj giltighetstid',
  },
  untilFurtherNotice: poaMessages.until,
  until: poaMessages.toAndIncluding,
  untilHelperText: offerFormMessages.validTo,
  visible: commonMessages.visibility,
  visibleHelperText: {
    id: 'jobOfferFormFieldMessages.visibleHelperText',
    defaultMessage:
      'Om erbjudandet är synligt går det att komma åt med en länk utan att vara inloggad.',
  },
  attachFiles: {
    id: 'jobOfferFormFieldMessages.attachFiles',
    defaultMessage: 'Bifoga filer',
  },
  selectFiles: {
    id: 'jobOfferFormFieldMessages.selectFiles',
    defaultMessage: 'Välj filer',
  },
});

export const jobOfferStatusMessages = defineMessages<JobOfferStatus>({
  PRIVATE: {
    id: 'jobOfferStatusMessages.private',
    defaultMessage: 'Ej aktivt',
  },
  PUBLIC: {
    id: 'jobOfferStatusMessages.public',
    defaultMessage: 'Aktivt',
  },
  EXPIRED: {
    id: 'jobOfferStatusMessages.expired',
    defaultMessage: 'Ej aktivt',
  },
});
