import {
  Button,
  ButtonLayout,
  RichTooltip,
  RichTooltipProps,
} from '@frontend/ui';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import { featurePreviewMessages } from 'common/messages/feature-preview';
import { FormattedMessage } from 'components/formats';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useFeaturePreview } from 'features/feature-preview';
import { getActiveStepCount } from 'features/feature-preview/utils/get-active-step-count';
import React from 'react';
import { SMALL_SCREEN_RESOLUTION } from 'utils/constants';

export const OnboardingTourTooltip: React.FC<RichTooltipProps> = ({
  title,
  description,
  parentId,
  position,
}) => {
  const { complete, dismiss, next, activeIndex, steps } = useFeaturePreview();
  const { setIsDrawerOpen } = useNavigationContext();
  const isSmallScreen = useIsSmallScreen(SMALL_SCREEN_RESOLUTION);

  const activeStepCount = getActiveStepCount(steps);

  return (
    <RichTooltip
      position={position}
      title={title}
      description={description}
      stepper={`${activeIndex}/${activeStepCount}`}
      parentId={parentId}
      actions={
        <ButtonLayout align="right">
          {activeIndex === activeStepCount ? (
            <Button text onClick={complete}>
              <FormattedMessage {...featurePreviewMessages.doneAction} />
            </Button>
          ) : (
            <>
              <Button
                text
                onClick={() => {
                  dismiss();
                  if (isSmallScreen) {
                    setIsDrawerOpen(false);
                  }
                }}
              >
                <FormattedMessage {...featurePreviewMessages.exitAction} />
              </Button>
              <Button
                text
                onClick={() => {
                  next();
                  // Close the drawer before moving on to the tooltip for user menu
                  // which is not part of the navigation drawer
                  if (isSmallScreen && activeIndex === activeStepCount - 1) {
                    setIsDrawerOpen(false);
                  }
                }}
              >
                <FormattedMessage {...featurePreviewMessages.nextAction} />
              </Button>
            </>
          )}
        </ButtonLayout>
      }
    />
  );
};
