import BankIdLogo from 'assets/bankIdLogo.svg';
import { a11yMessages } from 'common/messages/a11y';
import { loginMessages } from 'common/messages/login';
import { FormattedMessage, useIntl } from 'components/formats';
import React from 'react';
import styled from 'styled-components';

const BankIdImgHolder = styled.figure`
  height: 204px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    height: 96px;
  }
`;

export const BankIdSignLogo = () => {
  const { formatMessage } = useIntl();

  return (
    <BankIdImgHolder>
      <img src={BankIdLogo} alt={formatMessage(a11yMessages.bankIdLogo)} />
    </BankIdImgHolder>
  );
};

const BankIdInfoLogo = styled.div`
  margin-right: 1.5rem;

  img {
    width: 42px;
    margin-bottom: -7px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`;

const BankIDSignInfo: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <BankIdInfoLogo>
        <img src={BankIdLogo} alt={formatMessage(a11yMessages.bankIdLogo)} />
      </BankIdInfoLogo>
      <div>
        <p>
          <FormattedMessage {...loginMessages.signWithBankId} />
        </p>
      </div>
    </Wrapper>
  );
};

export default BankIDSignInfo;
