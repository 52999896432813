import gql from 'graphql-tag';

export const UPDATE_APP_DATA_MUTATION = gql`
  mutation updateAppDataMutation($input: UpdateUserAccountInput!) {
    updateUserAccount(input: $input) {
      userAccount {
        id
        appData
      }
    }
  }
`;
