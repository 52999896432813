import {
  insuranceCardQuery_entitlements_FlexEntitlementConnection as Entitlement,
  insuranceCardQuery_Query,
} from 'app/apollo/graphql/types';
import { isCollectiveAgreementWithRiskInsurances } from 'features/benefits/insurances/utils/is-collective-agreement-with-risk-insurances';
import { isRiskInsuranceTypeName } from 'features/benefits/utils/types';
import { ValidAgreementType } from 'features/pension/lib/get-collective-agreement-type-from-url';

interface RiskInsurances {
  accident: boolean;
  health: boolean;
  life: boolean;
  sickness: boolean;
}

const userRiskInsurances = (
  entitlements: Entitlement | null,
  validCollectiveAgreement: ValidAgreementType | null,
): RiskInsurances | null => {
  const insurances = {
    sickness: false,
    health: false,
    accident: false,
    life: false,
  };

  const riskInsuranceTypes = entitlements
    ? entitlements.edges
        .map(edge => edge.node.benefit.type)
        .filter(isRiskInsuranceTypeName)
    : [];
  if (riskInsuranceTypes.length) {
    insurances.sickness = riskInsuranceTypes.some(
      type => type === 'euro_accident_plansjuk',
    );
    insurances.health = riskInsuranceTypes.some(
      type => type === 'euro_accident_privataccess',
    );
    insurances.accident = riskInsuranceTypes.some(
      type => type === 'euro_accident_accident',
    );
    insurances.life = riskInsuranceTypes.some(
      type => type === 'euro_accident_life' || type === 'euro_accident_tgl',
    );
  }
  if (validCollectiveAgreement) {
    insurances.sickness = true;
    insurances.accident = true;
    insurances.life = true;
  }
  if (Object.values(insurances).some(value => value === true)) {
    return insurances;
  }
  return null;
};

interface InsuranceCardData {
  insurances: RiskInsurances;
}

/**
 * Retrieves the relevant insurance card data from the query response
 * or returns null if the user has no access to insurances.
 */
export const getInsuranceCardData = (
  data?: insuranceCardQuery_Query,
): InsuranceCardData | null => {
  if (!data?.entitlements && !data?.membership?.collectiveAgreement) {
    return null;
  }

  const entitlements = data?.entitlements;
  const collectiveAgreement = data?.membership?.collectiveAgreement;

  const validCollectiveAgreement = isCollectiveAgreementWithRiskInsurances(
    collectiveAgreement,
  )
    ? collectiveAgreement
    : null;

  if (!entitlements && !validCollectiveAgreement) {
    return null;
  }

  const insurances = userRiskInsurances(
    entitlements ?? null,
    validCollectiveAgreement,
  );

  if (!insurances) {
    return null;
  }

  return { insurances };
};
