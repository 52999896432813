import { useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  changeBenefitPackageQuery,
  changeBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import accountAggregationSuccess from 'assets/illustrations/accountAggregationSuccess.svg';
import { commonMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { afterFadeout, Modal, ModalBody, ModalHeader } from 'components/Modal';
import { useCurrentUser } from 'contexts/current-user';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { useQuery } from 'utils/use-query';

import { FormValues } from '..';
import { CHANGE_BENEFIT_PACKAGE_QUERY } from '../graphql/queries';

export const ChangeBenefitPackageSuccessModal = () => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search);

  const isOpen = 'change-benefit-package-success' in search;

  const { values, resetForm } = useFormikContext<FormValues>();

  const handleClose = () => {
    stripSearchParams(history, location, ['change-benefit-package-success']);
    afterFadeout(resetForm);
  };

  const companyId = currentCompany?.id;
  const userAccountId = values.userAccount?.value;

  const { data } = useQuery<
    changeBenefitPackageQuery,
    changeBenefitPackageQueryVariables
  >(CHANGE_BENEFIT_PACKAGE_QUERY, {
    fetchPolicy: 'cache-only',
    variables: {
      companyId: companyId ?? '',
      userAccountId: userAccountId ?? '',
    },
  });

  const newBenefitPackage = data?.company?.benefitPackages?.edges.find(
    ({ node }) => node.id === values.benefitPackage,
  )?.node;

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} size="medium">
      <ModalHeader illustration={accountAggregationSuccess}>
        <FormattedMessage
          {...adminShortcutMessages.changeBenefitPackageSuccess}
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          {...adminShortcutMessages.changeBenefitPackageSuccessDescription}
          values={{
            benefitPackage: newBenefitPackage?.name,
            employee: values.userAccount?.label,
            effectiveDate: new Date(values.effectiveDate),
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button onClick={handleClose} text>
            <FormattedMessage {...commonMessages.close} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

export const getChangeBenefitPackageSuccessLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'change-benefit-package-success',
});
