import { IconButton } from '@frontend/ui';
import { help as _help } from '@frontend/ui/icons';
import { commonMessages } from 'common/messages';
import DefaultA from 'components/DefaultA';
import { FormattedMessage, useIntl } from 'components/formats';
import { SideSheet } from 'components/SideSheet';
import React, { useState } from 'react';

interface Props {
  children: (args: { helpButton: React.ReactNode }) => React.ReactNode;
  help?: React.ReactNode;
}

export const WithSideSheet: React.FC<Props> = ({ help, children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const helpButton = (
    <IconButton
      label={formatMessage(commonMessages.help)}
      size="small"
      onClick={() => setOpen(_open => !_open)}
      icon={_help}
    />
  );

  return (
    <>
      {children({ helpButton })}
      <SideSheet
        setOpen={setOpen}
        open={open}
        title={<FormattedMessage {...commonMessages.help} />}
        footer={
          <span>
            <b>
              <FormattedMessage {...commonMessages.questions} />
            </b>{' '}
            <DefaultA
              style={{ display: 'inline-block' }}
              href="mailto:support@nordeanode.se"
            >
              support@nordeanode.se
            </DefaultA>
          </span>
        }
      >
        {help}
      </SideSheet>
    </>
  );
};
