import { snoozeFeaturePreview } from 'app/browser-store';
import { FeatureType, FeatureTypeStatus } from 'contexts/current-user';

import { Step } from './';

interface StepAction {
  type: 'STEP';
}

interface CompleteAction {
  type: 'COMPLETE';
}

interface DismissAction {
  type: 'DISMISS';
}

interface SnoozeAction {
  type: 'SNOOZE';
}

interface InitializeAction {
  feature: FeatureType;
  type: 'INIT';
}

type Action =
  | InitializeAction
  | StepAction
  | CompleteAction
  | DismissAction
  | SnoozeAction;

interface State {
  features: {
    [key in FeatureType]: Step[];
  };
  setAppData: (records: {
    [key in FeatureType]?: FeatureTypeStatus;
  }) => Promise<void>;
  activeFeature?: FeatureType;
  activeIndex?: number;
}

export const reducer = (state: State, action: Action): State => {
  const { activeFeature, activeIndex, setAppData } = state;

  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        activeFeature: action.feature,
        activeIndex: 0,
      };
    case 'STEP':
      return {
        ...state,
        activeIndex: (activeIndex ?? 0) + 1,
      };
    case 'COMPLETE':
      if (activeFeature) {
        // mark as completed because new onboarding tour already includes settings step
        if (activeFeature === FeatureType.ONBOARDING_TOUR) {
          setAppData({
            SETTINGS_TOUR: FeatureTypeStatus.COMPLETED,
            ONBOARDING_TOUR: FeatureTypeStatus.COMPLETED,
          });
        } else {
          setAppData({ [activeFeature]: FeatureTypeStatus.COMPLETED });
        }
      }

      return {
        ...state,
        activeFeature: undefined,
        activeIndex: undefined,
      };

    case 'DISMISS':
      if (activeFeature) {
        // mark as dismissed so we don't show two tours in a row
        if (activeFeature === FeatureType.ONBOARDING_TOUR) {
          setAppData({
            SETTINGS_TOUR: FeatureTypeStatus.DISMISSED,
            ONBOARDING_TOUR: FeatureTypeStatus.DISMISSED,
          });
        } else {
          setAppData({ [activeFeature]: FeatureTypeStatus.DISMISSED });
        }
      }

      return {
        ...state,
        activeFeature: undefined,
        activeIndex: undefined,
      };
    case 'SNOOZE':
      if (activeFeature) {
        snoozeFeaturePreview(activeFeature);
      }

      return {
        ...state,
        activeFeature: undefined,
        activeIndex: undefined,
      };
    default:
      return state;
  }
};
