import {
  APP_BAR_HEIGHT,
  ChipSet as _ChipSet,
  Icon,
  PageBody,
  PageHeader,
  PageTitle,
  PageTitleBar,
  useComponentSize,
} from '@frontend/ui';
import { useHideOnScroll } from 'app/lib/use-hide-on-scroll';
import { a11yMessages } from 'common/messages/a11y';
import { AssistChip, Props as AssistChipProps } from 'components/AssistChip';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/Menu';
import { RoutedTab, RoutedTabBar } from 'components/RoutedTabBar';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'containers/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { TopBanner } from 'features/banner';
import { CookieBanner } from 'features/cookie-banner';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ActionHolderProps {
  hide?: boolean;
}

const ChipSet = styled(_ChipSet)<ActionHolderProps>`
  padding-left: 2rem;
  flex-wrap: nowrap;

  // In order to hide the chip set when it's too wide to fit in the title bar
  // we set the visibility to hidden and the position to absolute instead of display: none.
  // The reason for this is that we must persist the width of the chip set to not re-trigger
  // calulations which would cause the chip set to flicker.
  ${p =>
    p.hide &&
    `
      visibility: hidden;
      position: absolute;
  `}
`;

const KebabMenuHolder = styled.div<ActionHolderProps>`
  ${p => p.hide && `display: none;`}
`;

interface Props {
  children: React.ReactNode;
  actions?: AssistChipProps[];
  appBarTitle?: React.ReactNode;
  parentLink?: string;
  standalone?: boolean;
  tabs?: RoutedTab[];
  title?: React.ReactNode;
  trackPageOnUnmount?: boolean;
}

export const Page: React.FC<Props> = ({
  appBarTitle,
  children,
  title,
  tabs,
  actions,
  parentLink,
  standalone,
  trackPageOnUnmount = true,
}) => {
  const { setPage } = useNavigationContext();

  useEffect(() => {
    setPage({ appBarTitle, parentLink });
  }, [parentLink, appBarTitle]);

  useAppBarNavigationStack({
    trackPageOnUnmount,
  });

  const hideHeader = useHideOnScroll({ offsetValue: APP_BAR_HEIGHT });

  const [showChipSet, setshowChipSet] = useState(false);
  const titleBarRef = useRef(null);
  const { width: titleBarWith } = useComponentSize(titleBarRef);
  const titleRef = useRef(null);
  const { width: titleWidth } = useComponentSize(titleRef);
  const chipSetRef = useRef(null);
  const { width: chipSetWidth } = useComponentSize(chipSetRef);

  useEffect(() => {
    setshowChipSet(chipSetWidth + titleWidth < titleBarWith);
  }, [titleBarWith, titleWidth, chipSetWidth]);

  const showPageHeader = !!(title || tabs || actions?.length);

  return (
    <>
      {!standalone && showPageHeader && (
        <PageHeader hide={hideHeader}>
          <PageTitleBar ref={titleBarRef}>
            <PageTitle ref={titleRef}>{title}</PageTitle>
            {!!actions?.length && (
              <>
                <ChipSet ref={chipSetRef} hide={!showChipSet}>
                  {actions.map((action, i) => (
                    <AssistChip key={i} {...action} />
                  ))}
                </ChipSet>
                <KebabMenuHolder hide={showChipSet}>
                  <KebabMenu label={a11yMessages.profileButton}>
                    {actions.map((action, i) => (
                      <MenuItem
                        key={i}
                        title={action.text}
                        icon={
                          <Icon
                            icon={action.leadingIcon}
                            size="small"
                            decorative
                          />
                        }
                        onClick={action.onClick}
                        link={action.to}
                      />
                    ))}
                  </KebabMenu>
                </KebabMenuHolder>
              </>
            )}
          </PageTitleBar>
          {!!tabs && <RoutedTabBar tabs={tabs} />}
          <CookieBanner />
          <TopBanner />
        </PageHeader>
      )}
      <PageBody>{children}</PageBody>
    </>
  );
};
