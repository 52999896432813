import { AppBarLogo } from '@frontend/ui';
import { menuMessages } from 'common/messages';
import { useIntl } from 'components/formats';
import { NavLink } from 'components/Links/NavLink';
import React from 'react';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  align-self: center;
  display: inline-block;
`;

interface Props {
  alt: string;
  imageUrl: string;
}

const CustomCompanyLogo: React.FC<Props> = ({ imageUrl, alt }) => {
  const intl = useIntl();

  return (
    <StyledNavLink aria-label={intl.formatMessage(menuMessages.home)} to="/">
      <AppBarLogo src={imageUrl} alt={alt} />
    </StyledNavLink>
  );
};

export default CustomCompanyLogo;
