import { useIntlContext } from 'containers/IntlProviderWrapper';
import format from 'date-fns/format';
import enUS from 'date-fns/locale/en-US';
import sv from 'date-fns/locale/sv';
import React from 'react';

interface Props {
  value: string;
  monthYearOnly?: boolean;
}

// XXX Kill me when we have react-intl@3.x-support
// sv locales should be supported then
export const FormattedLongDate: React.FC<Props> = ({
  value,
  monthYearOnly,
}) => {
  const { locale } = useIntlContext();
  return (
    <span>
      {format(new Date(value), monthYearOnly ? 'MMMM y' : 'd MMMM yyyy', {
        locale: locale === 'en-US' ? enUS : sv,
      })}
    </span>
  );
};
