import { defineMessages } from 'react-intl';

export const externalConnectionMessages = defineMessages({
  scrapeSuccess: {
    id: 'externalConnectionMessages.scrapeSuccess',
    defaultMessage:
      'Uppdatering av Pensionsmyndigheten är avslutad. Vi kommer automatiskt uppdatera värdet på ditt sparande utifrån din nuvarande portfölj.',
  },
  poaUpdateSuccess: {
    id: 'externalConnectionMessages.poaUpdateSuccess',
    defaultMessage: 'Vi hämtar information om dina pensioner och försäkringar.',
  },
  savings: {
    id: 'externalConnectionMessages.savings',
    defaultMessage: 'Sparande',
  },
  noInformationTitle: {
    id: 'externalConnectionMessages.noInformationTitle',
    description: 'No information to show title',
    defaultMessage: 'Ingen information att visa',
  },
  noInformationDescription: {
    id: 'externalConnectionMessages.noInformationDescription',
    description: 'No information to show description',
    defaultMessage: 'Om vi har detaljerad information så visas den här',
  },
  numberOfItems: {
    id: 'externalConnectionMessages.numberOfItems',
    description: 'Description shown for number of items on a link',
    defaultMessage: `{count, plural, 
        =0 {Inga konton}
        =1 {# konto} 
        other {# konton}}`,
  },
  connectAccount: {
    id: 'externalConnectionMessages.connectAccount',
    description: 'Connect account',
    defaultMessage: 'Lägg till information',
  },
  typeOfSavings: {
    id: 'externalConnectionMessages.typeOfSavings',
    description: 'Type of savings',
    defaultMessage: 'Typ av sparande',
  },
  shareValue: {
    id: 'externalConnectionMessages.sharedValue',
    description: 'Your part of the value',
    defaultMessage: 'Din andel av värdet',
  },
  productCode: {
    id: 'externalConnectionMessages.productCode',
    description: 'Agreement',
    defaultMessage: 'Avtal',
  },
  productCodeInputHelperText: {
    id: 'externalConnectionMessages.productCodeInputHelperText',
    description: 'Agreement input helper text.',
    defaultMessage:
      'Påverkar fondutbud, avgifter och flyttregler vid rådgivning',
  },
  addCollectiveAgreement: {
    id: 'externalConnectionMessages.addCollectiveAgreement',
    description: 'Add collective agreement',
    defaultMessage: 'Lägg till kollektivavtal',
  },
  changeCollectiveAgreement: {
    id: 'externalConnectionMessages.changeCollectiveAgreement',
    description: 'Change collective agreement',
    defaultMessage: 'Ändra kollektivavtal',
  },
  chooseCollectiveAgreement: {
    id: 'externalConnectionMessages.chooseCollectiveAgreement',
    description: 'Choose collective agreement',
    defaultMessage: 'Välj kollektivavtal',
  },
  scrapeFailed: {
    id: 'externalConnectionMessages.scrapeFailed',
    description: 'Message used for tooltip to say scrape has failed',
    defaultMessage: 'Pensionsmyndigheten kunde inte anslutas. Försök igen.',
  },
  deleteLinkMessage: {
    id: 'externalConnectionMessages.deleteLinkMessage',
    defaultMessage: `Vill du verkligen ta bort den här anslutningen? Om du tar bort anslutningen kommer vi radera all hämtad information.`,
  },
  linkDeletedDescr: {
    id: 'externalConnectionMessages.linkDeletedDescr',
    defaultMessage: `Din anslutning till {link} har tagits bort`,
  },
  connectInstitutes: {
    id: 'externalConnectionMessages.connectInstitutes',
    defaultMessage: 'Anslut bolag',
  },
  connectPpm: {
    id: 'externalConnectionMessages.connectPpm',
    defaultMessage: 'Anslut Pensionsmyndigheten',
  },
  done: {
    id: 'externalConnectionMessages.done',
    defaultMessage: `Klar`,
  },
  ap7Header: {
    id: 'externalConnectionMessages.ap7Header',
    defaultMessage: 'Din premiepension förvaltas genom AP7 Såfa',
  },
  ap7Description: {
    id: 'externalConnectionMessages.ap7Description',
    defaultMessage:
      'AP7 Såfa är statens förval för dig som inte valt ett eget alternativ för din premiepension. Det är en blandning av två fonder, AP7 Aktiefond och AP7 Räntefond, utifrån din ålder. Förvaltningsavgiften i AP7 varierar därför med din ålder mellan 0,04 % och 0,11 % per år. Vi kan tyvärr inte visa din aktuella förvaltningsavgift eller beräkna annan statistik i dagsläget.',
  },
  connectPpmTitle: {
    id: 'externalConnectionMessages.connectPpmTitle',
    defaultMessage: 'Anslut Pensionsmyndigheten direkt?',
  },
  connectPpmDescription: {
    id: 'externalConnectionMessages.connectPpmDescription',
    defaultMessage:
      'För att se din allmänna pension i portalen kan du ansluta Pensionsmyndigheten via BankID. Du kan uppdatera eller ta bort informationen när som helst.',
  },
  ppmConnectSuccessfulTitle: {
    id: 'externalConnectionMessages.ppmConnectSuccessfulTitle',
    defaultMessage: 'Pensionsmyndigheten anslutet',
  },
  ppmConnectSuccessfulDescription: {
    id: 'externalConnectionMessages.ppmConnectSuccessfulDescription',
    defaultMessage: 'Du kan när som helst uppdatera informationen',
  },
  yourPensionToday: {
    id: 'externalConnectionMessages.yourPensionToday',
    defaultMessage: 'Din pension idag',
  },
});

export const externalInsuranceMessages = defineMessages({
  lifeLong: {
    id: 'externalInsuranceMessages.lifeLong',
    description: 'Life long',
    defaultMessage: 'Livsvarigt',
  },
  oneTime: {
    id: 'externalInsuranceMessages.oneTime',
    description: 'One time payment',
    defaultMessage: 'Engångsbelopp',
  },
  toBeneficiary: {
    id: 'externalInsuranceMessages.toBeneficiary',
    description: 'to designated beneficiaries in {months} monthes',
    defaultMessage: 'till utsedd förmånstagare i {months} mån',
  },
  medicalInvalidity: {
    id: 'externalInsuranceMessages.medicalInvalidity',
    description: 'Medical invalidity',
    defaultMessage: 'Med. inv. (kr)',
  },
  economicInvalidity: {
    id: 'externalInsuranceMessages.economicInvalidity',
    description: 'Economic invalidity',
    defaultMessage: 'Ekon. inv. (kr)',
  },
  insuranceCompany: {
    id: 'externalInsuranceMessages.insuranceCompany',
    description: 'Insurance company',
    defaultMessage: 'Försäkringsbolag',
  },
  insuranceNumber: {
    id: 'externalInsuranceMessages.insuranceNumber',
    description: 'Insurance number',
    defaultMessage: 'Försäkringsnummer',
  },
  term: {
    id: 'externalInsuranceMessages.term',
    description: 'Gäller',
    defaultMessage: 'Gäller',
  },
  managementType: {
    id: 'externalInsuranceMessages.managementType',
    defaultMessage: 'Förvaltningstyp',
  },
  withdrawalPeriod: {
    id: 'externalInsuranceMessages.withdrawalPeriod',
    defaultMessage: 'Avtalad uttagsålder',
  },
  withdrawalLength: {
    id: 'externalInsuranceMessages.withdrawalLength',
    defaultMessage: 'Avtalad uttagslängd',
  },
  policyHolderName: {
    id: 'externalInsuranceMessages.policyHolderName',
    defaultMessage: 'Försäkringen tecknas av',
  },
  survivorsProtection: {
    id: 'externalInsuranceMessages.survivorsProtection',
    defaultMessage: 'Efterlevandeskydd',
  },
  externalInsuranceTableHeader: {
    id: 'externalInsuranceMessages.externalInsuranceTableHeader',
    defaultMessage: 'Detaljerad information',
  },
  plusSuggestionsHeader: {
    id: 'externalInsuranceMessages.plusSuggestionsHeader',
    defaultMessage: 'Optimera din pension',
  },
  plusSuggestionsDesc: {
    id: 'externalInsuranceMessages.plusSuggestionsDesc',
    defaultMessage:
      'Baserat på informationen vi har om denna tjänstepension idag kan den inte flyttas till oss. Här följer en rekommendation på ny fondportfölj för hur du kan optimera tjänstepensionen hos ditt nuvarande försäkringsbolag med hänsyn till dina preferenser.',
  },
  informationRetrieved: {
    id: 'externalInsuranceMessages.informationRetrieved',
    defaultMessage: 'Information hämtad',
  },
});

export const externalInsuranceManagementTypeMessages = defineMessages({
  DEPOSITORY: {
    id: 'externalInsuranceManagementTypeMessages.DEPOSITORY',
    defaultMessage: 'Depåförvaltning',
  },
  UNIT: {
    id: 'externalInsuranceManagementTypeMessages.UNIT',
    defaultMessage: 'Fondförvaltning',
  },
  TRADITIONAL: {
    id: 'externalInsuranceManagementTypeMessages.TRADITIONAL',
    defaultMessage: 'Traditionell förvaltning',
  },
});

export const externalInsuranceManagementTypeShortMessages = defineMessages({
  DEPOSITORY: {
    id: 'externalInsuranceManagementTypeShortMessages.DEPOSITORY',
    defaultMessage: 'Depå',
  },
  UNIT: {
    id: 'externalInsuranceManagementTypeShortMessages.UNIT',
    defaultMessage: 'Fond',
  },
  TRADITIONAL: {
    id: 'externalInsuranceManagementTypeShortMessages.TRADITIONAL',
    defaultMessage: 'Trad',
  },
});

export const externalInsuranceStatusMessages = defineMessages({
  NEW: {
    id: 'externalInsuranceStatusMessages.NEW',
    defaultMessage: 'Nyteckning',
  },
  EFFECTIVE: {
    id: 'externalInsuranceStatusMessages.EFFECTIVE',
    defaultMessage: 'Gällande',
  },
  PAYING_OUT: {
    id: 'externalInsuranceStatusMessages.PAYING_OUT',
    defaultMessage: 'Utbetalning',
  },
  PAID_UP: {
    id: 'externalInsuranceStatusMessages.PAID_UP',
    defaultMessage: 'Fribrev',
  },
  PAID_IN_FULL: {
    id: 'externalInsuranceStatusMessages.PAID_IN_FULL',
    defaultMessage: 'Slutbetald',
  },
});

export const poaMessages = defineMessages({
  poaValidity: {
    id: 'poaMessages.poaValidity',
    defaultMessage: 'Giltighetstid',
  },
  until: {
    id: 'poaMessages.until',
    defaultMessage: 'Tills vidare',
  },
  toAndIncluding: {
    id: 'poaMessages.toAndIncluding',
    defaultMessage: 'Till och med',
  },
  fetchMoreInfo: {
    id: 'poaMessages.fetchMoreInfo',
    defaultMessage: 'Hämta ytterligare information',
  },
  connectPpmTitle: {
    id: 'poaMessages.connectPpmTitle',
    defaultMessage: 'Anslut Pensionsmyndigheten',
  },
  connectPpmDescription: {
    id: 'poaMessages.connectPpmDescription',
    defaultMessage: `För att få en samlad bild över ditt pensionssparande, anslut direkt till Pensionsmyndigheten för att se information om din allmänna pension.`,
  },
  connectServicesTitle: {
    id: 'poaMessages.connectServicesTitle',
    defaultMessage: 'Hämta information automatiskt',
  },
  connectServicesNoInstitutesDescription: {
    id: 'poaMessages.connectServicesNoInstitutesDescription',
    defaultMessage:
      'Du har inga anslutna försäkringsbolag än. Genom att ansluta direkt till Pensionsmyndigheten och lämna en informationsfullmakt via Fullmaktskollen kan vi automatiskt hämta din information från flertal försäkringsbolag. Du kommer då kunna se ditt pensionssparande och dina försäkringar här. Det tar ca 2-4 veckor innan vi fått svar från alla bolag.',
  },
  connectServicesDescription: {
    id: 'poaMessages.connectServicesDescription',
    defaultMessage:
      'Genom att ansluta direkt till Pensionsmyndigheten och lämna en informationsfullmakt via Fullmaktskollen kan vi automatiskt hämta din information från flertal försäkringsbolag. Du kommer då kunna se ditt pensionssparande och dina försäkringar här. Det tar ca 2-4 veckor innan vi fått svar från alla bolag.',
  },
  leavePoa: {
    id: 'poaMessages.leavePoa',
    defaultMessage: 'Lämna fullmakt',
  },
  revoke: {
    id: 'poaMessages.revoke',
    defaultMessage: 'Återkalla fullmakten',
  },
  fetchNewInformation: {
    id: 'poaMessages.fetchNewInformation',
    defaultMessage: 'Hämta ny information',
  },
  fetchingInProgress: {
    id: 'poaMessages.fetchingInProgress',
    defaultMessage: 'Informationsinhämtning pågår',
  },
  poaDescription: {
    id: 'poaMessages.poaDescription',
    defaultMessage: `För att få en samlad bild över ditt pensionssparande, lämna en informationsfullmakt via Fullmaktskollen så kan vi automatiskt hämta din information från flertal försäkringsbolag. Det tar ca 2-4 veckor att få svar från alla försäkringsbolag.`,
  },
  poaDescrTitle: {
    id: 'poaMessages.poaDescrTitle',
    defaultMessage: 'Lämna en informationsfullmakt',
  },
  poaIsActive: {
    id: 'poaMessages.poaIsActive',
    defaultMessage: 'Du har en aktiv informationsfullmakt',
  },
  fetchInformationTitle: {
    id: 'poaMessages.fetchInformationTitle',
    defaultMessage: 'Hämta information',
  },
  signPoaTitle: {
    id: 'poaMessages.signPoaTitle',
    defaultMessage: 'Skriv under fullmakt',
  },
  signPoaHeader: {
    id: 'poaMessages.signPoaHeader',
    defaultMessage: 'Informationsfullmakt',
  },
  signPoaDescr_1: {
    id: 'poaMessages.signPoaDescr_1',
    defaultMessage: `Fullmaktshavaren, eller den som denne skriftligen sätter i sitt ställe, har rätt att inhämta information beträffande mitt försäkringsskydd rörande`,
  },
  signPoaDescr_Item1: {
    id: 'poaMessages.signPoaDescr_Item1',
    defaultMessage: `av mig ägda personförsäkringar, inkl. vårdförsäkring,`,
  },
  signPoaDescr_Item2: {
    id: 'poaMessages.signPoaDescr_Item2',
    defaultMessage: `kollektivt avtalade försäkringar där jag är insatt som försäkrad,`,
  },
  signPoaDescr_Item3: {
    id: 'poaMessages.signPoaDescr_Item3',
    defaultMessage: `övriga försäkringsavtal där jag är insatt som försäkrad.`,
  },
  signPoaDescr_2: {
    id: 'poaMessages.signPoaDescr_2',
    defaultMessage: `Fullmakten avser även rätt att inhämta all den information som jag själv har rätt att få del av avseende såväl min premie-, inkomst- och garantipension som, i förekommande fall, min allmänna tilläggspension (ATP), samt även information om mitt sparande i IPS och pensionsutfästelse tryggad i pensionsstiftelse eller genom kontoavsättning.`,
  },
  fmkPersonalDataTitle: {
    id: 'poaMessages.fmkPersonalDataTitle',
    defaultMessage: 'Så hanteras personuppgifter inom Fullmaktskollen',
  },
  fmkPersonalDataDescr: {
    id: 'poaMessages.fmkPersonalDataDescr',
    defaultMessage: `Fullmaktskollen i Sverige AB behandlar dina personuppgifter, ditt namn, ditt personnummer, din e-postadress och ditt mobilnummer. Fullmaktskollen behandlar också de personuppgifter som framgår i dina fullmakter. All behandling av dina personuppgifter sker i enlighet med gällande lagstiftning. Syftet med behandlingen är att kunna tillhandahålla tjänsten. För utförligare information om vilka behandlingar som sker av dina personuppgifter se {link}.`,
  },
  poaSignError: {
    id: 'poaMessages.poaSignError',
    defaultMessage:
      'Din informationsfullmakt kunde inte skrivas under. Försök igen.',
  },
  whatIsPoa: {
    id: 'poaMessages.whatIsPoa',
    defaultMessage: 'Vad är en fullmakt?',
  },
  willISee: {
    id: 'poaMessages.willISee',
    defaultMessage:
      'Kommer jag att kunna se alla mina försäkringar när jag lämnar en fullmakt?',
  },
  willISeeDescr: {
    id: 'poaMessages.willISeeDescr',
    defaultMessage: `<p>När du lämnar en fullmakt kommer vi be om information från de försäkringsbolag som är anslutna till Fullmaktskollen. Exempel på försäkringsbolag som inte är anslutna eller inte svarar på informationsförfrågan är Avanza och Folksam, så de eventuella försäkringar du har där kommer inte visas upp i listan över dina försäkringar.</p>
    <p>Den information vi får från försäkringbolagen kan ibland även vara otillräcklig eller felaktig och kan inte visas upp på ett korrekt sätt, varav vi väljer att inte visa upp försäkringen. Exempel är försäkringar från Bliwa.</p>`,
  },
  whatIsPoaDescr: {
    id: 'poaMessages.whatIsPoaDescr',
    defaultMessage: `En fullmakt ger en särskilt utsedd person eller ett företag (fullmaktshavaren) rätt att utföra handlingar på uppdrag av någon annan (fullmaktsgivaren).`,
  },
  whatIsInfoPoa: {
    id: 'poaMessages.whatIsInfoPoa',
    defaultMessage:
      'Vad är en informationsfullmakt och hur kan jag återkalla den?',
  },
  whatIsInfoPoaDescr: {
    id: 'poaMessages.whatIsInfoPoaDescr',
    defaultMessage: `Informationsfullmakt är en fullmakt som är begränsad till att ge fullmaktshavaren rätt att få viss information om försäkringar och pensioner, till exempel från ett försäkringsbolag. Fullmakten kan vara tidsbegränsad eller gälla tills vidare. Du kan återkalla fullmakten när som helst. Om fullmakten är registrerad på fullmaktskollen.se kan du enkelt ändra eller återkalla den där. Läs mer på {link}.`,
  },
  whatIsFmk: {
    id: 'poaMessages.whatIsFmk',
    defaultMessage: 'Vad är Fullmaktskollen?',
  },
  whatIsFmkDescr: {
    id: 'poaMessages.whatIsFmkDescr',
    defaultMessage: `Fullmaktskollen.se är en tjänst för att hantera fullmakter inom liv- och pensionsområdet. Fullmaktskollen.se gör det enkelt för privatpersoner och företag att få överblick över sina fullmakter och hur länge de gäller. I tjänsten går det också att skriva under fullmakter och återkalla dem.`,
  },
  successScreenTitle: {
    id: 'poaMessages.successScreenTitle',
    defaultMessage: `Informationsfullmakt lämnad`,
  },
  successScreenDescr: {
    id: 'poaMessages.successScreenDescr',
    defaultMessage: `Tack för att du lämnade en informationsfullmakt till oss! Vi kommer nu automatiskt hämta information om dina pensioner och försäkringar från de försäkringsbolag som är kopplade till Fullmaktskollen. Det tar ca 2-4 veckor att få svar från alla bolag.`,
  },
  poaCardHeader: {
    id: 'poaMessages.poaCardHeader',
    defaultMessage: 'Hämta information automatiskt',
  },
  poaCardDescription: {
    id: 'poaMessages.poaCardDescription',
    defaultMessage:
      'Vi hämtar information om dina pensioner och försäkringar från nedanstående försäkringsbolag genom en informationsfullmakt. Informationshämtningen kan ta ca 2-4 veckor och du kan följa statusen av din informationsfullmakt under Anslutna bolag.',
  },
  signPoaBanner: {
    id: 'poaMessages.signPoaBanner',
    defaultMessage:
      'Hämta information om dina pensioner och försäkringar automatiskt',
  },
  outdatedPoaBanner: {
    id: 'poaMessages.outdatedPoaBanner',
    defaultMessage:
      'Förnya din fullmakt för att få en fullständig bild av dina pensioner och försäkringar',
  },
  awaitingResponsePoaBanner: {
    id: 'poaMessages.awaitingResponsePoaBanner',
    defaultMessage:
      'Vi hämtar information om dina pensioner och försäkringar. Det tar ca 2-4 veckor att få svar.',
  },
  followStatus: {
    id: 'poaMessages.followStatus',
    defaultMessage: 'Följ status',
  },
  outdatedPoaAction: {
    id: 'poaMessages.outdatedPoaAction',
    defaultMessage: 'Förnya',
  },
  yourConnectedServices: {
    id: 'poaMessages.yourConnectedServices',
    defaultMessage: 'Dina anslutna försäkringsbolag',
  },
  yourConnectedServicesDescr: {
    id: 'poaMessages.yourConnectedServicesDescr',
    defaultMessage:
      'De försäkringsbolag som visas nedan grundar sig på uppgifter som vi inhämtat från din arbetsgivare och som vi fått från ditt försäkringsbolag genom digital informationssamling eller genom informationsfullmakt. Du kan vara kund hos ytterligare försäkringsbolag där vi saknar information.',
  },
  poasIsInactive: {
    id: 'poaMessages.poasIsInactive',
    defaultMessage: 'Du har återkallat din informationsfullmakt',
  },
  feedbackInactivePoa: {
    id: 'poaMessages.feedbackInactivePoa',
    defaultMessage:
      'Vi hämtar inte längre information från dina anslutna försäkringsbolag eftersom att du har återkallat din fullmakt. För att få uppdaterad information, lämna en fullmakt på nytt.',
  },

  // Old messages
  feedbackActivePoa: {
    id: 'poa.feedbackActivePoa',
    defaultMessage:
      'Vi hämtar automatiskt information från Fullmaktskollens anslutna försäkringsbolag. Informationen som skickas från försäkringsbolagen är inte alltid komplett och därför kan det vara felaktig information som visas upp. För att återkalla din fullmakt, logga in på Fullmaktskollen.',
  },
});

export const externalItemLabelMessages = defineMessages({
  endowment: {
    id: 'externalItemLabel.endowment',
    description: 'endowment insurance',
    defaultMessage: 'Kapitalförsäkring',
  },
  privatePension: {
    id: 'externalItemLabel.privatePension',
    description: 'private pension insurance',
    defaultMessage: 'Privat pensionsförsäkring',
  },
  collectivelyAgreedTjp: {
    id: 'externalItemLabel.collectivelyAgreedTjp',
    description: 'collectively agreed occupational pension',
    defaultMessage: 'Kollektivavtalad tjänstepension',
  },
});

export const cumulativeTransferablePensionMessages = defineMessages({
  header: {
    id: 'cumulativeTransferablePensionMessages.header',
    defaultMessage: 'Din pension om du flyttar till oss',
  },
  projectedReturnAtCurrentCompany: {
    id: 'cumulativeTransferablePensionMessages.projectedReturnAtCurrentCompany',
    defaultMessage: 'Uppskattat värde hos nuvarande bolag',
  },
});
