import {
  CollectiveAgreement,
  collectiveAgreementCardQuery_Query,
  TotalCompensationCategory2 as TotalCompensationCategory,
  TotalCompensationCollectiveAgreement,
  TotalCompensationId2 as TotalCompensationId,
} from 'app/apollo/graphql/types';
import BigNumber from 'bignumber.js';
import {
  AgreementSubLinkType,
  CollectiveAgreementRouteName,
} from 'features/pension/lib/get-collective-agreement-type-from-url';
import { COLLECTIVE_AGREEMENT_LABEL } from 'utils/constants';

const isValidCollectiveAgreement = (
  collectiveAgreement: string | null,
): collectiveAgreement is CollectiveAgreement =>
  !!collectiveAgreement && !!COLLECTIVE_AGREEMENT_LABEL[collectiveAgreement];

interface CollectiveAgreementCardData {
  /**
   * The route to the collective agreement page
   */
  collectiveAgreementRoute: Exclude<AgreementSubLinkType, null>;
  /**
   * The monthly pension contributions from the collective agreement
   */
  collectiveAgreementTotalCompensation: number;
  /**
   * The collective agreement on the current membership
   */
  collectiveAgreementType: CollectiveAgreement;
}

/**
 * Retrieves the data needed for the collective agreement card from the
 * query resoponse or returns null if the card should not be shown
 */
export const getCollectiveAgreementCardData = (
  data?: collectiveAgreementCardQuery_Query,
): CollectiveAgreementCardData | null => {
  if (!data?.membership) {
    return null;
  }

  const { membership } = data;

  if (!isValidCollectiveAgreement(membership.collectiveAgreement)) {
    return null;
  }

  const collectiveAgreementType = membership.collectiveAgreement;
  /**
   * Calculate monthly pension premiums from the collective agreement
   *
   * TotalCompensationId PENSION refers Advinans pension, thus should be filtered out
   * @returns the sum of monthly pension premiums from collective agreement
   */
  const collectiveAgreementTotalCompensation = membership.totalCompensation
    .filter(
      t =>
        TotalCompensationCollectiveAgreement[t.key] &&
        t.category === TotalCompensationCategory.PENSION &&
        t.key !== TotalCompensationId.PENSION,
    )
    .reduce(
      (total, t) =>
        total +
        (t?.annualCompensation
          ? new BigNumber(t.annualCompensation).dividedBy(12).toNumber()
          : 0),
      0,
    );

  const collectiveAgreementRoute =
    CollectiveAgreementRouteName[collectiveAgreementType];

  return {
    collectiveAgreementType,
    collectiveAgreementTotalCompensation,
    collectiveAgreementRoute,
  };
};
