import { useIntl } from 'components/formats';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { MessageDescriptor } from 'react-intl';
// eslint-disable-next-line
import { Route as TitledRoute, RouteComponentProps,RouteProps } from 'react-router-dom';

export const stripParentSegments = (title: string) => title.split(' - ')[0];

const TitleContext = React.createContext<string>('Nordea Node');
export const useRouteTitle = () => useContext(TitleContext);

interface WithTitleProps {
  children?: React.ReactNode;
  title?: MessageDescriptor | string | null;
}

export const WithTitle: React.FC<WithTitleProps> = ({ title, children }) => {
  const { formatMessage } = useIntl();
  return (
    <TitleContext.Consumer>
      {value => {
        const _title =
          title && typeof title !== 'string' ? formatMessage(title) : title;
        const _value = _title ? `${_title} - ${value}` : value;

        return (
          <>
            <Helmet key={_value}>
              <title>{_value}</title>
            </Helmet>
            <TitleContext.Provider value={_value}>
              {children}
            </TitleContext.Provider>
          </>
        );
      }}
    </TitleContext.Consumer>
  );
};

interface Props
  extends Omit<WithTitleProps, 'children'>,
    Omit<RouteProps, 'render'> {
  children?:
    | React.ReactNode
    | ((props: RouteComponentProps) => React.ReactNode);
}

export const Route: React.FC<Props> = ({ component, children, ...props }) => {
  const { title } = props;

  if (!title) {
    return (
      <TitledRoute {...props} component={component}>
        {children}
      </TitledRoute>
    );
  }

  if (component) {
    const Component = component;

    return (
      <TitledRoute
        {...props}
        render={routeProps => (
          <WithTitle title={title}>
            <Component {...routeProps} />
          </WithTitle>
        )}
      />
    );
  }

  return (
    <WithTitle title={title}>
      <TitledRoute {...props}>{children}</TitledRoute>
    </WithTitle>
  );
};
