import { headlineMediumCSS, headlineSmallCSS, media } from '@frontend/ui';
import styled from 'styled-components';

export const AuthTitle = styled.h1`
  ${headlineMediumCSS};
  ${media.lessThan('tablet')`
    ${headlineSmallCSS};
  `}
  text-align: center;
  margin-bottom: 1rem;
  color: ${p => p.theme.primary};
`;
