import { SearchableSelectOption } from '@frontend/ui';
import { validationMessages } from 'common/messages';
import { FormattedMessage, IntlShape } from 'components/formats';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import { SelectEmployeeUserAccountField } from 'features/admin-shortcuts/select-employee-user-account';
import React from 'react';
import * as Yup from 'yup';

export const selectEmployerValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    userAccount: Yup.object().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface SelectEmployerFormValues {
  userAccount: SearchableSelectOption | null;
}

export const selectEmployerInitialValues: SelectEmployerFormValues = {
  userAccount: null,
};

export const SelectEmployeHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.editSalary} />
);

export const SelectEmployeeBody: React.FC = () => (
  <SelectEmployeeUserAccountField inModal name="userAccount" required />
);
