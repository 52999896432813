import gql from 'graphql-tag';

export const REGISTER_ABSENCE_MUTATION = gql`
  mutation registerAbsenceMutation($input: AddEmploymentActivityInput!) {
    addEmploymentActivity(input: $input) {
      employmentActivity {
        id
      }
    }
  }
`;
