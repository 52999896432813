import { Figure, Icon } from '@frontend/ui';
import { directionsRun } from '@frontend/ui/icons';
import {
  fitnessContributionCardQuery,
  fitnessContributionCardQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/admin/employees/employee';
import { commonMessages } from 'common/messages';
import { dashboardMessages } from 'common/messages/dashboard';
import { totalCompensationMessages } from 'common/messages/total-compensation';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { LinkItem, SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from 'utils/use-query';

import { FITNESS_CONTRIBUTION_CARD_QUERY } from './graphql/queries';
import { getFitnessContributionData } from './utils/get-fitness-contribution-card-data';

interface WellnessBenefitCardProps {
  annualAllowance?: string | number;
  balance?: string | number;
  link?: LinkItem;
  preview?: boolean;
}

export const WellnessBenefitCard: React.FC<WellnessBenefitCardProps> = ({
  balance,
  annualAllowance,
  preview,
  link,
}) => {
  const balanceFigure = preview ? (
    <Figure
      size="medium"
      title={<FormattedCurrency value="-" currency="SEK" />}
      description={<FormattedMessage {...commonMessages.available} />}
    />
  ) : balance != null ? (
    <Figure
      size="medium"
      title={
        <FormattedCurrency
          value={parseFloat(balance.toString())}
          currency="SEK"
        />
      }
      description={<FormattedMessage {...commonMessages.available} />}
    />
  ) : null;

  const annualAllowanceFigure = preview ? (
    <Figure
      size="medium"
      title={<FormattedCurrency value="-" currency="SEK" />}
      description={<FormattedMessage {...commonMessages.perYear} />}
    />
  ) : annualAllowance ? (
    <Figure
      size="medium"
      title={
        <FormattedCurrency
          value={parseFloat(annualAllowance.toString())}
          currency="SEK"
        />
      }
      description={<FormattedMessage {...commonMessages.perYear} />}
    />
  ) : null;

  return (
    <CardGridCell>
      <SnapshotCard
        icon={
          <Icon icon={directionsRun} color="primary" size="medium" decorative />
        }
        title={
          <FormattedMessage
            {...totalCompensationMessages.fitnessContribution}
          />
        }
        link={link}
        figure1={balanceFigure || annualAllowanceFigure}
        figure2={balanceFigure ? annualAllowanceFigure : null}
      />
    </CardGridCell>
  );
};

type MatchParams = Partial<EmployeeRouteMatchParams>;

const isAdminView = (params: MatchParams): params is EmployeeRouteMatchParams =>
  'userAccountId' in params;
interface Props {
  notLinked?: boolean;
  totalCompensation?: boolean;
  userAccountId?: string;
}

export const FitnessContributionCard: React.FC<Props> = ({
  notLinked,
  totalCompensation,
}) => {
  const { currentUser } = useCurrentUser();
  const { params } = useRouteMatch<MatchParams>();
  const userAccountId = isAdminView(params)
    ? params.userAccountId
    : currentUser.userAccountId;
  const companyId = currentUser.currentCompany?.id ?? '';

  const { data } = useQuery<
    fitnessContributionCardQuery,
    fitnessContributionCardQueryVariables
  >(FITNESS_CONTRIBUTION_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    isEmpty: d => !getFitnessContributionData(d, totalCompensation),
    variables: {
      userAccountId,
      membershipCompanyId: companyId,
      benefitTypeNames: ['epassi', 'wellness'],
      hasMembership: !!currentUser.currentCompany,
      entitlementsCompanyId: currentUser?.currentCompany?.id,
    },
  });

  const fitnessContributionData = getFitnessContributionData(
    data,
    totalCompensation,
  );

  if (!fitnessContributionData) {
    return null;
  }

  const { id, fitnessContributionBalance, allowance } = fitnessContributionData;

  const link =
    !id || notLinked
      ? undefined
      : {
          to: `/benefits/${id}`,
          label: (
            <FormattedMessage {...dashboardMessages.showWellnessBenefit} />
          ),
        };

  return (
    <WellnessBenefitCard
      annualAllowance={allowance}
      balance={fitnessContributionBalance ?? undefined}
      link={link}
    />
  );
};
