import { ApolloError } from '@apollo/client';
import { isNotNullOrUndefined } from '@frontend/utils';
import { formMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import styled from 'styled-components';

const StyledUl = styled.ul`
  margin-top: 1.5rem;
`;

/**
 * Retrieves the error messages from a graphql request. May return an empty
 * array, for instance when no exposed error is thrown in backend.
 *
 * @returns the array of error messages
 */
const getGraphQLErrorMessages = (error: ApolloError): string[] =>
  error.graphQLErrors.map(({ message }) => message);

interface GraphQlErrorProps {
  error?: ApolloError;
  inModal?: boolean;
}

export const GraphQlError: React.FC<GraphQlErrorProps> = ({
  error,
  inModal,
}) => {
  if (error?.graphQLErrors?.length) {
    const graphQLErrors = getGraphQLErrorMessages(error);
    const uniqueGraphQLErrors = Array.from(new Set(graphQLErrors));
    const errorMessagesString = uniqueGraphQLErrors.join(', ');

    return (
      <NotificationCard inModal={inModal} type="error">
        {errorMessagesString}
      </NotificationCard>
    );
  }
  return (
    <NotificationCard inModal={inModal} type="error">
      <FormattedMessage {...formMessages.queryErrorMessage} />
    </NotificationCard>
  );
};

interface GraphQlErrorsProps {
  errors: Array<ApolloError | undefined>;
  title?: React.ReactNode;
}

export const GraphQlErrors: React.FC<GraphQlErrorsProps> = ({
  errors: _errors,
  title: _title,
}) => {
  const errors = _errors.filter(isNotNullOrUndefined);

  if (!errors.length) {
    return null;
  }

  const graphQLErrors = errors.flatMap(error => getGraphQLErrorMessages(error));
  const uniqueGraphQLErrors = Array.from(new Set(graphQLErrors));

  const title = _title || (
    <FormattedMessage {...formMessages.queryErrorMessage} />
  );

  // If the number of unique errors passed to this component is strictly
  // less than 2 we don't want to render the errors in an HTML list.
  if (uniqueGraphQLErrors.length < 2) {
    return (
      <NotificationCard type="error" title={title}>
        {uniqueGraphQLErrors[0]}
      </NotificationCard>
    );
  }

  return (
    <NotificationCard type="error" title={title}>
      <StyledUl>
        {uniqueGraphQLErrors.map((errorMessage, i) => (
          <li key={i}>{errorMessage}</li>
        ))}
      </StyledUl>
    </NotificationCard>
  );
};
