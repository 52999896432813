import { Banner, IconContent } from '@frontend/ui';
import { schedule } from '@frontend/ui/icons';
import React, { useContext, useEffect, useId, useMemo, useState } from 'react';

interface Banner {
  icon: IconContent;
  message: React.ReactNode;
  actions?: React.ReactNode;
}
interface BannerInternal extends Banner {
  display: boolean;
  id: string;
}

interface ContextProps {
  banners: BannerInternal[];
  pushBanner: (banner: BannerInternal) => void;
  removeBanner: (id: string) => void;
}

const BannerContext = React.createContext<ContextProps>({
  banners: [],
  pushBanner: () => null,
  removeBanner: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const BannerContextProvider: React.FC<Props> = ({ children }) => {
  const [bannerState, setBannerState] = useState<BannerInternal[]>([]);

  const pushBanner = (banner: BannerInternal) =>
    setBannerState(banners => {
      const editIndex = banners.findIndex(_banner => _banner.id === banner.id);
      return editIndex !== -1
        ? Object.assign([], banners, { [editIndex]: banner })
        : [...banners, banner];
    });

  const removeBanner = (id: string) =>
    setBannerState(banners => banners.filter(banner => banner.id !== id));

  const value = useMemo(
    () => ({
      banners: bannerState,
      pushBanner,
      removeBanner,
    }),
    [bannerState],
  );

  return (
    <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
  );
};

interface BannerProps {
  clearBanner: () => void;
  setBanner: (banner: Banner) => void;
}

export const useBanner = (): BannerProps => {
  const id = useId();
  const { banners, pushBanner, removeBanner } = useContext(BannerContext);

  const mountBanner = () =>
    pushBanner({ id, display: false, message: 'PENDING', icon: schedule });

  const unmountBanner = () => removeBanner(id);

  /* We mount each banner on banner component mount to preserve
  specified render order, despite delayed setBanner call due to data fetching.
  For the same reason, we make sure to preserve index of updated banners,
  and avoid unmounting of hidden banners should they be shown again.
  */

  useEffect(() => {
    mountBanner();
    return unmountBanner;
  }, []);

  const setBanner = (_banner: Banner) =>
    pushBanner({ id, display: true, ..._banner });

  const clearBanner = () => {
    const editBanner = banners.find(_banner => _banner.id === id);
    if (editBanner) {
      pushBanner({ ...editBanner, display: false });
    }
  };

  return {
    setBanner,
    clearBanner,
  };
};

interface RenderBanner {
  animate: boolean;
  banner?: BannerInternal;
}

const useRenderBanner = (): RenderBanner => {
  const { banners } = useContext(BannerContext);

  return {
    banner: banners.filter(banner => banner.display)[0],
    /* When all banners are unmounted, don't use animation.
    Treat mounted, but not used, banners as non existing
    to prevent animation when the final banner is unmounted, but the 
    next page has mounted, but not rendered, a new banner. */
    animate: !!banners.filter(banner => banner.message !== 'PENDING').length,
  };
};

export const TopBanner: React.FC = () => {
  // The initial render of TopBanner may contain outdated/cleared banner states
  // from a previous page due to the async nature of state updates.
  // Any banner which exists on the initial render is outdated.
  // We use isInitRender to prevent flashing outdated banners.
  const [isInitRender, setIsInitRender] = useState(true);

  const { banner: next, animate } = useRenderBanner();
  const [last, set] = useState<Banner>();
  const banner = next ?? last;

  useEffect(() => {
    if (isInitRender) {
      setIsInitRender(false);
    }
  }, [isInitRender]);

  useEffect(() => {
    if (next) {
      set(next);
    }
  }, [next]);

  if (isInitRender || !banner) {
    return null;
  }

  return (
    <Banner
      icon={banner.icon}
      actions={banner.actions}
      $display={!!next}
      animate={animate}
    >
      {banner.message}
    </Banner>
  );
};
