import { DataValue } from '@apollo/client/react/hoc';
import { viewerQuery } from 'app/apollo/graphql/types';
import { commonMessages, menuMessages } from 'common/messages';
import { errorMessages } from 'common/messages/error';
import { jobOfferMessages } from 'common/messages/job-offer';
import { loginMessages } from 'common/messages/login';
import { salaryExchangeAgreementMessages } from 'common/messages/salary-exchange';
import { securityMessages } from 'common/messages/security-messages';
import { upgradeFriMessages } from 'common/messages/upgrade-fri';
import { VIEWER_QUERY } from 'common/queries';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useIntl } from 'components/formats';
import OutdatedBrowser from 'components/OutdatedBrowser';
import { TopLoading } from 'components/TopLoading';
import { Route } from 'containers/Route';
import {
  Confirmation,
  ConfirmationContextProvider,
} from 'contexts/confirmation';
import {
  CurrentUserContext,
  CurrentUserContextProvider,
} from 'contexts/current-user';
import { Login } from 'features/auth/login';
import { Logout } from 'features/auth/logout';
import { FeaturePreviewProvider } from 'features/feature-preview';
import { Layout } from 'features/layout';
import { NotificationCenter } from 'features/notifications/containers';
import { UserSettingsRedirects } from 'features/settings/redirects';
import { BankIDSignModal } from 'features/sign-graphql/bank-id-sign-modal';
import { TransitionCenter } from 'features/transition-center';
import { unsubscribeMessages } from 'features/unsubscribe/messages';
import { UpgradeFriOverlay } from 'features/upgrade-fri';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  Redirect,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'utils/use-query';

import { IndexRoute } from './pages';
import { EconomicProfile } from './pages/economic-profile';
import { ADMIN_ROOT_PATHS, EMPLOYEE_ROOT_PATHS } from './pages/root-paths';

const SalaryExchangeDocumentRoute = lazy(
  () => import('features/salary-exchange/admin/document'),
);
const FundSummary = lazy(() => import('components/FundSummary'));
const UserSettingsRoute = lazy(() => import('./pages/settings'));
const ConnectedServicesRoute = lazy(() => import('./pages/connected-services'));
const InsuranceRoute = lazy(() => import('./pages/insurances'));
const JobOffersRoute = lazy(() => import('./pages/job-offers'));
const UnsubscribeRoute = lazy(() => import('./pages/unsubscribe'));
const LegacyOrgRoutes = lazy(() => import('./pages/legacy-org'));
const LegacyEconomyRoutes = lazy(() => import('./pages/legacy-economy'));
const AdminRoute = lazy(() => import('./pages/admin'));
const InternalNewsRoute = lazy(() => import('./pages/internal-news'));
const BenefitPortalTotalCompensationRoute = lazy(
  () => import('./pages/total-compensation'),
);
const PayslipsRoute = lazy(() => import('./pages/payslips'));
const BenefitRoutes = lazy(() => import('./pages/benefits'));
const PensionRoutes = lazy(() => import('./pages/pension'));
const PensionTransferRoute = lazy(() => import('./pages/pension-transfer'));
const LegacySignupRoutes = lazy(() => import('./pages/legacy-signup'));

// Idle time of 1 800 000 ms = 30 min
const IDLE_TIME = 1800000;

// Poll viewer once every minute
const VIEWER_POLL_INTERVAL = 60000;

const PortalRoute: React.FC<RouteComponentProps> = ({
  location: { pathname },
}) => {
  const { formatMessage } = useIntl();
  const [idle, setIdle] = useState(false);

  useIdleTimer({ onIdle: () => setIdle(true), timeout: IDLE_TIME });

  if (idle) {
    return (
      <Redirect
        to={{
          pathname: '/logout',
          state: {
            from: pathname,
            message: formatMessage(loginMessages.inactivityMessage),
          },
        }}
      />
    );
  }

  return (
    <ConfirmationContextProvider>
      <CurrentUserContextProvider>
        <FeaturePreviewProvider>
          <CurrentUserContext.Consumer>
            {({ currentUser: { currentCompany } }) => (
              <>
                <Confirmation />
                <Layout>
                  <ErrorBoundary>
                    <Suspense fallback={<TopLoading />}>
                      <Switch>
                        <Route
                          path="/economy"
                          component={LegacyEconomyRoutes}
                        />
                        <Route
                          title={menuMessages.connectedServices}
                          path="/org"
                          component={LegacyOrgRoutes}
                        />
                        <Route path="/signup" component={LegacySignupRoutes} />
                        <Route
                          title={menuMessages.profile}
                          path="/profile"
                          component={UserSettingsRedirects}
                        />
                        <Route
                          title={menuMessages.userSettings}
                          path={EMPLOYEE_ROOT_PATHS.settings}
                          component={UserSettingsRoute}
                        />
                        <Route
                          title={menuMessages.insurance}
                          path={EMPLOYEE_ROOT_PATHS.insurances}
                          component={InsuranceRoute}
                        />
                        <Route
                          title={menuMessages.connectedServices}
                          path={EMPLOYEE_ROOT_PATHS.connectedServices}
                          component={ConnectedServicesRoute}
                        />
                        <Route
                          title={menuMessages.pension}
                          path={EMPLOYEE_ROOT_PATHS.pension}
                          component={PensionRoutes}
                        />
                        <Route
                          title={commonMessages.payslips}
                          path={EMPLOYEE_ROOT_PATHS.payslips}
                          component={PayslipsRoute}
                        />
                        <Route
                          title={commonMessages.administration}
                          path={ADMIN_ROOT_PATHS.index}
                          component={AdminRoute}
                        />
                        <Route
                          title={menuMessages.benefits}
                          path={EMPLOYEE_ROOT_PATHS.benefits}
                          component={BenefitRoutes}
                        />
                        <Route
                          title={menuMessages.pensionTransfer}
                          path={EMPLOYEE_ROOT_PATHS.pensionTransfer}
                          component={PensionTransferRoute}
                        />
                        <Route
                          title={upgradeFriMessages.wizardHeader}
                          path={EMPLOYEE_ROOT_PATHS.upgradeFri}
                          component={UpgradeFriOverlay}
                        />
                        <Route
                          title={menuMessages.economicProfile}
                          path={EMPLOYEE_ROOT_PATHS.economicProfile}
                          component={EconomicProfile}
                        />
                        <Route
                          title={menuMessages.home}
                          path={EMPLOYEE_ROOT_PATHS.index}
                          exact
                          component={IndexRoute}
                        />

                        {/* Redirects for legacy pages in the app */}
                        <Route path="/kyc(.*)">
                          <Redirect to="/" />
                        </Route>
                        <Route path="/portfolios(.*)">
                          <Redirect to="/" />
                        </Route>
                        <Route path="/savings(.*)">
                          <Redirect to="/" />
                        </Route>
                        <Route path="/offers(.*)">
                          {({ match }) => (
                            <Redirect to={`/benefits${match?.params[0]}`} />
                          )}
                        </Route>
                        {currentCompany && (
                          <Route
                            title={menuMessages.news}
                            path={EMPLOYEE_ROOT_PATHS.internalNews}
                            component={InternalNewsRoute}
                          />
                        )}
                        {currentCompany?.hasTotalCompensation && (
                          <Route
                            title={menuMessages.totalCompensation}
                            path={EMPLOYEE_ROOT_PATHS.totalCompensation}
                            component={BenefitPortalTotalCompensationRoute}
                          />
                        )}
                        <Route
                          title={errorMessages.title}
                          component={EmptyStatePage}
                        />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                </Layout>
              </>
            )}
          </CurrentUserContext.Consumer>
        </FeaturePreviewProvider>
      </CurrentUserContextProvider>
    </ConfirmationContextProvider>
  );
};

type Props = DataValue<viewerQuery, object> & viewerQuery;

const AdaptableRootContainer = styled.div`
  height: 100%;
  padding: env(safe-area-inset-top, 0.75rem) env(safe-area-inset-right, 0.75rem)
    env(safe-area-inset-bottom, 0.75rem) env(safe-area-inset-left, 0.75rem);
`;

interface AdaptableRootProps {
  children: React.ReactNode;
}

const AdaptableRoot: React.FC<AdaptableRootProps> = ({ children }) => (
  <AdaptableRootContainer>{children}</AdaptableRootContainer>
);

const Root: React.FC<Props> = () => {
  const { pathname, state, search } = useLocation();
  const [shouldPoll, setShouldPoll] = useState(false);
  const { data, loading, refetch } = useQuery<viewerQuery>(VIEWER_QUERY, {
    errorPolicy: 'all',
    pollInterval: shouldPoll ? VIEWER_POLL_INTERVAL : undefined,
  });

  const isLoggedIn = Boolean(data?.viewer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setShouldPoll(isLoggedIn);
  }, [isLoggedIn]);

  if (loading) {
    return <TopLoading />;
  }

  return (
    <AdaptableRoot>
      <Suspense fallback={<TopLoading />}>
        <>
          <OutdatedBrowser />
          <NotificationCenter />
          <TransitionCenter pathname={pathname} />
          <BankIDSignModal />
          {!isLoggedIn ? (
            <Switch>
              <Route title={loginMessages.loginTitle} path="/login">
                {(props: RouteComponentProps) => (
                  <Login {...props} onSignSuccess={refetch} />
                )}
              </Route>
              <Route
                title={securityMessages.fondSummary}
                path="/fund-summary/:securityId"
                component={FundSummary}
              />
              <Route
                title={jobOfferMessages.jobOffer}
                path="/j/:jobOfferId"
                component={JobOffersRoute}
              />
              <Redirect
                from="/signup/fri"
                to={{ pathname: '/login', search }}
              />
              <Route
                title={unsubscribeMessages.unsubscribe}
                path="/unsubscribe/:unsubscribeId"
                component={UnsubscribeRoute}
              />
              <Redirect
                to={{
                  pathname: '/login',
                  search: `?next=${pathname}`,
                  state,
                }}
              />
            </Switch>
          ) : (
            <Switch>
              <Route title={loginMessages.loginTitle} path="/login">
                {(p: RouteComponentProps) => (
                  <Login {...p} onSignSuccess={refetch} />
                )}
              </Route>
              <Route path="/logout" component={Logout} />
              <Route
                title={salaryExchangeAgreementMessages.agreementShortTitle}
                path="/org/document/salary-exchange/:companyId/:salaryExchangeRequestId"
                component={SalaryExchangeDocumentRoute}
              />
              <Route
                title={securityMessages.fondSummary}
                path="/fund-summary/:securityId"
                component={FundSummary}
              />
              <Route
                title={jobOfferMessages.jobOffer}
                path="/j/:jobOfferId"
                component={JobOffersRoute}
              />
              <Route
                title={unsubscribeMessages.unsubscribe}
                path="/unsubscribe/:unsubscribeId"
                component={UnsubscribeRoute}
              />
              <Route path="/" component={PortalRoute} />
            </Switch>
          )}
        </>
      </Suspense>
    </AdaptableRoot>
  );
};

export default Root;
