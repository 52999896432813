import { TotalCompensationId2 as TotalCompensationId } from 'app/apollo/graphql/types';
import { GraphItemId } from 'features/total-compensation/utils/aggregate-total-comp-chart-data';
import { defineMessages } from 'react-intl';

import { commonMessages } from './';
import { commonBenefitMessages } from './benefits';

export const totalCompensationMessages = defineMessages({
  value: {
    id: 'totalCompensationMessages.value',
    description: 'Värde (kr/mån)',
    defaultMessage: 'Värde (kr/mån)',
  },
  sum: {
    id: 'totalCompensationMessages.sum',
    description: 'Summa',
    defaultMessage: 'Summa',
  },
  totalCompensation: {
    id: 'totalCompensationMessages.totalCompensation',
    description: 'Total ersättning',
    defaultMessage: 'Total ersättning',
  },
  totalCompensationPerMonth: {
    id: 'totalCompensationMessages.totalCompensationPerMonth',
    defaultMessage: 'Total ersättning per månad',
  },
  vacation: {
    id: 'totalCompensationMessages.vacation',
    description: 'Semester',
    defaultMessage: 'Semester',
  },
  saved: {
    id: 'totalCompensationMessages.saved',
    description: 'Saved',
    defaultMessage: 'Sparade',
  },
  daysSaved: {
    id: 'totalCompensationMessages.daysSaved',
    description: 'Saved',
    defaultMessage: 'Varav sparade',
  },
  daysPaid: {
    id: 'totalCompensationMessages.daysPaid',
    description: 'Paid',
    defaultMessage: 'Varav betalda',
  },
  daysUnpaid: {
    id: 'totalCompensationMessages.daysUnpaid',
    description: 'Unpaid',
    defaultMessage: 'Varav obetalda',
  },
  daysAdvance: {
    id: 'totalCompensationMessages.daysAdvance',
    description: 'Advanced',
    defaultMessage: 'Varav förskott',
  },
  daysPerYear: {
    id: 'totalCompensationMessages.daysPerYear',
    description: 'Per år',
    defaultMessage: 'Per år',
  },
  remaining: {
    id: 'totalCompensationMessages.remaining',
    description: 'Remaining',
    defaultMessage: 'Kvarvarande',
  },
  fitnessContribution: {
    id: 'totalCompensationMessages.fitnessContribution',
    description: 'Friskvårdsbidrag',
    defaultMessage: 'Friskvårdsbidrag',
  },
  companyCar: {
    id: 'totalCompensationMessages.companyCar',
    description: 'Företagsbil',
    defaultMessage: 'Företagsbil',
  },
  insurance: {
    id: 'totalCompensationMessages.insurance',
    description: 'Riskförsäkringar',
    defaultMessage: 'Riskförsäkringar',
  },
  insurancesViaCollectiveAgreement: {
    id: 'totalCompensationMessages.insurancesViaCollectiveAgreement',
    description: 'Via your collective agreement',
    defaultMessage: 'Via ditt kollektivavtal',
  },
  insurancesViaEmployer: {
    id: 'totalCompensationMessages.insurancesViaEmployer',
    description: 'Extra insurances through your employer',
    defaultMessage: 'Extra försäkringar via din arbetsgivare',
  },
  benefitValue: {
    id: 'totalCompensationMessages.benefitValue',
    description: 'Förmånsvärde',
    defaultMessage: 'Förmånsvärde',
  },
  myInsurances: {
    id: 'totalCompensationMessages.myInsurances',
    description: 'Mina försäkringar',
    defaultMessage: 'Mina försäkringar',
  },
  noCompensationData: {
    id: 'totalCompensationMessages.noCompensationData',
    description: 'No information for total compensation',
    defaultMessage:
      'För att se total ersättning måste en administratör lägga in information i systemet',
  },
  noCompensationDataHeading: {
    id: 'totalCompensationMessages.noCompensationDataHeading',
    description: 'Heading for information missing in total compensation',
    defaultMessage: 'Ingen information',
  },
  netSalaryDescription: {
    id: 'totalCompensationMessages.netSalaryDescription',
    description: 'Net salary description',
    defaultMessage: 'Lön som sätts in på ditt bankkonto.',
  },
  netSalaryDeduction: {
    id: 'totalCompensationMessages.netSalaryDeduction',
    description: 'Net salary deduction',
    defaultMessage: 'Nettolöneavdrag',
  },
  monthlySalaryDescription: {
    id: 'totalCompensationMessages.monthlySalaryDescription',
    description: 'Salary before tax',
    defaultMessage: `Lön före skatt.`,
  },
  incomeTaxDescription: {
    id: 'totalCompensationMessages.incomeTaxDescription',
    description: 'Income tax description',
    defaultMessage:
      'Din arbetsgivare drar av skatt innan du får ut din lön. Skatten beräknas på din kontanta lön och andra skattepliktiga förmåner.',
  },
  pensionDescription: {
    id: 'totalCompensationMessages.pensionDescription',
    description: 'Pension description',
    defaultMessage: 'Pension som din arbetsgivare betalar för dig.',
  },
  salaryExchangeDescription: {
    id: 'totalCompensationMessages.salaryExchangeDescription',
    description: 'Salary exchange description',
    defaultMessage:
      'Bruttolöneavdrag som växlas för extra pensionsavsättningar.',
  },
  companyCarDescription: {
    id: 'totalCompensationMessages.companyCarDescription',
    description: 'Company car description',
    defaultMessage:
      'Det skattepliktiga värdet för privat användande av bilförmån.',
  },
  lunchAllowanceDescription: {
    id: 'totalCompensationMessages.lunchAllowanceDescription',
    description: 'Lunch allowance description',
    defaultMessage: 'Värdet av din lunchförmån varje månad',
  },
  specialPayrollTaxDescription: {
    id: 'totalCompensationMessages.specialPayrollTaxDescription',
    description: 'Special payroll tax description',
    defaultMessage:
      'Din arbetsgivare betalar 24,26 % på tjänstepension och sjukförsäkringskostnader i särskild löneskatt.',
  },
  BTP1Description: {
    id: 'totalCompensationMessages.BTP1Description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension BTP 1. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  ITPKDescription: {
    id: 'totalCompensationMessages.ITPKDescription',
    description: 'ITPKDescription',
    defaultMessage:
      'ITPK är en del av den pension som din arbetsgivare betalar för dig då du tillhör ITP 2.',
  },
  ITPDescription: {
    id: 'totalCompensationMessages.ITPDescription',
    description: 'ITP description',
    defaultMessage:
      'ITP 2 är en förmånsbestämd pension som din arbetsgivare betalar för dig. Vad den kommer att ge dig beror på din lön och hur länge du arbetat och haft ITP 2.',
  },
  ITP1Description: {
    id: 'totalCompensationMessages.ITP1Description',
    description: 'ITP1 description',
    defaultMessage:
      'Du omfattas av ITP 1 genom din arbetsgivare. Information om försäkringsgivare hittar du på dina sidor hos Collectum.',
  },
  KAPKLDescription: {
    id: 'totalCompensationMessages.KAPKLDescription',
    description: 'KAP-KL description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension KAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  AKAPKLDescription: {
    id: 'totalCompensationMessages.AKAPKLDescription',
    description: 'AKAP-KL description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension AKAP-KL. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  AKAPKRDescription: {
    id: 'totalCompensationMessages.AKAPKRDescription',
    description: 'AKAP-KR description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension AKAP-KR. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  SAFLODescription: {
    id: 'totalCompensationMessages.SAFLODescription',
    description: 'SAF-LO description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension SAF-LO. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  PAKFS09Description: {
    id: 'totalCompensationMessages.PAKFS09Description',
    description: 'PA-KFS 09 description',
    defaultMessage:
      'Du omfattas av den kollektivavtalade tjänstepension PA-KFS 09. Tjänstepensionen tryggar din pension och ger extra skydd för din familj.',
  },
  employersContributionsDescription: {
    id: 'totalCompensationMessages.employersContributionsDescription',
    description: 'Employers contributions description',
    defaultMessage:
      'Din arbetsgivare betalar {employerContributionShare} på det totala värdet av lön och förmåner i arbetsgivaravgifter.',
  },
  totalCompensationDisclaimer: {
    id: 'totalCompensationMessages.totalCompensationDisclaimer',
    description: 'Total compensation disclaimer',
    defaultMessage:
      'Uppgifter om total ersättning baseras på uppgifter från lönefiler och unyttjade förmåner som vi känner till. Vissa värden är uppskattade.',
  },
  showAvailableTotalCompensation: {
    id: 'totalCompensationMessages.showAvailableTotalCompensation',
    description: 'Show total compensation in benefit portal',
    defaultMessage: 'Visa total ersättning i förmånsportalen',
  },
  latestPayslip: {
    id: 'totalCompensationMessages.latestPayslip',
    description: 'Latest payslip',
    defaultMessage: 'Senaste lönespecifikation',
  },
  noPayslip: {
    id: 'totalCompensationMessages.noPayslip',
    description: 'No payslip',
    defaultMessage: 'Ingen lönespecifikation',
  },
  payslipPaidDate: {
    id: 'totalCompensationMessages.payslipPaidDate',
    description: 'Paid date',
    defaultMessage: 'Utbetalningsdatum',
  },
  showTotalCompensation: {
    id: 'totalCompensationMessages.showTotalCompensation',
    description: 'Show total compensation',
    defaultMessage: 'Visa total ersättning',
  },
  bonus: {
    id: 'totalCompensationMessages.bonus',
    defaultMessage: 'Bonus',
  },
  bonusDescription: {
    id: 'totalCompensationMessages.bonusDescription',
    defaultMessage: 'Ersättning utöver din lön.',
  },
  variableCompensation: {
    id: 'totalCompensationMessages.variableCompensation',
    defaultMessage: 'Rörlig ersättning',
  },
  salaryAfterSalaryExchange: {
    id: 'totalCompensationMessages.salaryAfterSalaryExchange',
    defaultMessage: 'Lön efter löneväxling',
  },
  annualBonus: {
    id: 'totalCompensationMessages.annualBonus',
    defaultMessage: 'Årsbonus',
  },
  wellnessAllowance: {
    id: 'totalCompensationMessages.wellnessAllowance',
    defaultMessage: 'Friskvårdsförmån',
  },
  wellnessAllowanceDesc: {
    id: 'totalCompensationMessages.wellnessAllowanceDesc',
    defaultMessage:
      'Ditt årliga friskvårdsbidrag via Epassi delat på 12 månader.',
  },
});

export const totalCompensationConfigMessages = defineMessages({
  pageLede: {
    id: 'totalCompensationConfigMessages.pageLede',
    defaultMessage:
      'Lön och ersättning utgörs av uppgifter från lönefiler och utnyttjade förmåner för att ge anställda en så bra översikt av sin månatliga ersättning som möjligt. Du som administratör kan bestämma vad anställda ska se och inte.',
  },
  benefitPackagesDesc: {
    id: 'totalCompensationConfigMessages.benefitPackagesDesc',
    defaultMessage:
      'De förmånsgrupper du väljer får tillgång till en översikt för Lön och ersättning under sin profil. ',
  },
  configTitle: {
    id: 'totalCompensationConfigMessages.configTitle',
    defaultMessage: 'Välj vad som ska inkluderas',
  },
  configDesc: {
    id: 'totalCompensationConfigMessages.configDesc',
    defaultMessage:
      'Du som administratör kan välja vad anställda ska se under Lön och ersättning. Om enskilda användare saknar någon av de valda punkterna kommer det inte att synas.',
  },
  vacationDays: {
    id: 'totalCompensationConfigMessages.vacationDays',
    defaultMessage: 'Semesterdagar',
  },
  updateSuccess: {
    id: 'totalCompensationConfigMessages.updateSuccess',
    defaultMessage: 'Ändringarna har sparats',
  },
});

export const totalCompensationTitleMessages =
  defineMessages<TotalCompensationId>({
    INCOME_TAX: commonMessages.incomeTax,
    NET_SALARY: commonMessages.netSalary,
    CASH_SALARY: totalCompensationMessages.salaryAfterSalaryExchange,
    SALARY_EXCHANGE: commonMessages.salaryExchange,
    PENSION: commonBenefitMessages.occupationalPension,
    SPECIAL_PAYROLL_TAX: commonMessages.specialPayrollTax,
    EMPLOYERS_CONTRIBUTIONS: commonMessages.employersContributions,
    COMPANY_CAR: commonMessages.companyCar,
    BTP1: commonMessages.BTP1,
    ITP: commonMessages.ITP,
    ITP1: commonMessages.ITP1,
    ITPK: commonMessages.ITPK,
    KAPKL: commonMessages.KAPKL,
    AKAPKL: commonMessages.AKAPKL,
    AKAPKR: commonMessages.AKAPKR,
    SAFLO: commonMessages.SAFLO,
    PAKFS09: commonMessages.PAKFS09,
    LUNCH_ALLOWANCE: commonMessages.lunchAllowance,
    GROSS_SALARY: commonMessages.salaryBeforeTax,
    SALARY_EXCHANGE_DEDUCTION: commonMessages.salaryExchange,
    VARIABLE_SALARY: totalCompensationMessages.bonus,
    WELLNESS: totalCompensationMessages.wellnessAllowance,
  });

export const totalCompensationDescriptionMessages =
  defineMessages<TotalCompensationId>({
    INCOME_TAX: totalCompensationMessages.incomeTaxDescription,
    NET_SALARY: totalCompensationMessages.netSalaryDescription,
    CASH_SALARY: totalCompensationMessages.monthlySalaryDescription,
    SALARY_EXCHANGE: totalCompensationMessages.salaryExchangeDescription,
    PENSION: totalCompensationMessages.pensionDescription,
    SPECIAL_PAYROLL_TAX: totalCompensationMessages.specialPayrollTaxDescription,
    EMPLOYERS_CONTRIBUTIONS:
      totalCompensationMessages.employersContributionsDescription,
    COMPANY_CAR: totalCompensationMessages.companyCarDescription,
    LUNCH_ALLOWANCE: totalCompensationMessages.lunchAllowanceDescription,
    BTP1: totalCompensationMessages.BTP1Description,
    ITP: totalCompensationMessages.ITPDescription,
    ITP1: totalCompensationMessages.ITP1Description,
    ITPK: totalCompensationMessages.ITPKDescription,
    KAPKL: totalCompensationMessages.KAPKLDescription,
    AKAPKL: totalCompensationMessages.AKAPKLDescription,
    AKAPKR: totalCompensationMessages.AKAPKRDescription,
    SAFLO: totalCompensationMessages.SAFLODescription,
    PAKFS09: totalCompensationMessages.PAKFS09Description,
    GROSS_SALARY: totalCompensationMessages.monthlySalaryDescription,
    SALARY_EXCHANGE_DEDUCTION:
      totalCompensationMessages.salaryExchangeDescription,
    VARIABLE_SALARY: totalCompensationMessages.bonusDescription,
    WELLNESS: totalCompensationMessages.wellnessAllowanceDesc,
  });

export const totalCompensationLegendMessages = defineMessages<GraphItemId>({
  NET_SALARY: commonMessages.salary,
  CASH_SALARY: {
    id: 'totalCompensationLegendMessages.CASH_SALARY',
    defaultMessage: '{cashSalaryMessage}',
  },
  INCOME_TAX: commonMessages.incomeTax,
  SALARY_EXCHANGE: commonMessages.salaryExchange,
  PENSION: commonBenefitMessages.occupationalPension,
  COMPANY_CAR: commonMessages.companyCar,
  EMPLOYERS_CONTRIBUTIONS: commonMessages.employersContributions,
  BTP1: commonMessages.BTP1,
  ITPK: commonMessages.ITPK,
  ITP: commonMessages.ITP,
  ITP1: commonMessages.ITP1,
  KAPKL: commonMessages.KAPKL,
  AKAPKL: commonMessages.AKAPKL,
  AKAPKR: commonMessages.AKAPKR,
  SAFLO: commonMessages.SAFLO,
  PAKFS09: commonMessages.PAKFS09,
  LUNCH_ALLOWANCE: commonMessages.lunchAllowance,
  SPECIAL_PAYROLL_TAX: commonMessages.specialPayrollTax,
  SALARY_EXCHANGE_DEDUCTION: commonMessages.salaryExchange,
  VARIABLE_SALARY: totalCompensationMessages.variableCompensation,
  GROSS_SALARY: commonMessages.salaryBeforeTax,
  BENEFITS: commonMessages.benefits,
  WELLNESS: totalCompensationMessages.wellnessAllowance,
});
