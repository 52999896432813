import { Form, Formik, FormikHelpers } from '@frontend/formik';
import {
  Button,
  IconButton,
  ModalBodyStep,
  ModalFooter,
  ModalFooterStepperIndicator,
  ModalFooterStepperLayout,
  ModalHeaderStep,
} from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { commonMessages } from 'common/messages';
import { a11yMessages } from 'common/messages/a11y';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import {
  afterFadeout,
  Modal,
  ModalBody,
  ModalHeader,
  Step,
  useModalStepper,
} from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { ConfirmBody, ConfirmHeader } from './steps/confirm';
import {
  EnterDetailsBody,
  EnterDetailsFormValues,
  EnterDetailsHeader,
  enterDetailsInitialValues,
  enterDetailsValidationSchema,
} from './steps/enter-details';
import {
  SelectEmployeeBody,
  SelectEmployeHeader,
  SelectEmployerFormValues,
  selectEmployerInitialValues,
  selectEmployerValidationSchema,
} from './steps/select-employee';
import { EditSalarySuccessModal, getEditSalarySuccessLink } from './success';
import { useSubmit } from './utils/use-submit';

export type FormValues = SelectEmployerFormValues & EnterDetailsFormValues;

const steps: Step[] = [
  {
    body: <SelectEmployeeBody />,
    header: <SelectEmployeHeader />,
    validationSchema: selectEmployerValidationSchema,
  },
  {
    body: <EnterDetailsBody />,
    header: <EnterDetailsHeader />,
    validationSchema: enterDetailsValidationSchema,
  },
  {
    body: <ConfirmBody />,
    header: <ConfirmHeader />,
  },
];

export const EditSalaryModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search);

  const isOpen = 'edit-salary' in search;

  const handleClose = () => {
    stripSearchParams(history, location, ['edit-salary']);
  };

  const {
    activeStep,
    isFirstStep,
    isLastStep,
    stepsWithIndex,
    goToNext,
    goToPrevious,
    resetStepper,
  } = useModalStepper({ steps });

  const onCompleted = () => {
    handleClose();
    history.push(getEditSalarySuccessLink(location));
    afterFadeout(() => {
      resetStepper();
    });
  };

  const { submit, submissionError } = useSubmit({ onCompleted });

  const handleNext = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (isLastStep) {
      await submit(values);
      return;
    }
    helpers.setSubmitting(false);
    goToNext();
  };

  const initialValues: FormValues = {
    ...selectEmployerInitialValues,
    ...enterDetailsInitialValues,
  };

  const { validationSchema } = activeStep;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleNext}
      validationSchema={validationSchema?.(intl)}
    >
      {({ isSubmitting, resetForm }) => (
        <>
          <Modal
            isOpen={isOpen}
            onRequestClose={() => {
              handleClose();
              afterFadeout(() => {
                resetForm();
                resetStepper();
              });
            }}
            size="medium"
          >
            <Form noValidate>
              <ModalHeader
                stepperCloseAction={
                  <IconButton
                    icon={clear}
                    label={formatMessage(a11yMessages.close)}
                    onClick={() => {
                      handleClose();
                      afterFadeout(() => {
                        resetForm();
                        resetStepper();
                      });
                    }}
                    size="small"
                  />
                }
              >
                {stepsWithIndex.map(step => (
                  <ModalHeaderStep
                    activeStep={activeStep.index}
                    key={step.index}
                    stepIndex={step.index}
                  >
                    {step.header}
                  </ModalHeaderStep>
                ))}
              </ModalHeader>
              <ModalBody>
                {stepsWithIndex.map(step => (
                  <ModalBodyStep
                    activeStep={activeStep.index}
                    key={step.index}
                    stepIndex={step.index}
                  >
                    {step.body}
                    {isLastStep && submissionError && (
                      <GraphQlError error={submissionError} inModal />
                    )}
                  </ModalBodyStep>
                ))}
              </ModalBody>
              <ModalFooter>
                <ModalFooterStepperLayout
                  leftAction={
                    <Button disabled={isFirstStep} onClick={goToPrevious} text>
                      <FormattedMessage {...commonMessages.back} />
                    </Button>
                  }
                  rightAction={
                    <Button loading={isSubmitting} text type="submit">
                      <FormattedMessage
                        {...(isLastStep
                          ? commonMessages.confirm
                          : commonMessages.next)}
                      />
                    </Button>
                  }
                  stepIndicator={
                    <ModalFooterStepperIndicator
                      activeStep={activeStep.index}
                      label={formatMessage(a11yMessages.progress)}
                      nSteps={stepsWithIndex.length}
                      stepLabel={i =>
                        formatMessage(a11yMessages.progressStep, {
                          step: i + 1,
                          nSteps: stepsWithIndex.length,
                        })
                      }
                    />
                  }
                />
              </ModalFooter>
            </Form>
          </Modal>
          <EditSalarySuccessModal />
        </>
      )}
    </Formik>
  );
};

export const getEditSalaryLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'edit-salary',
});
