import { DurationUnit } from '@frontend/utils';
import { ImportStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const signupMessages = defineMessages({
  hasFundKnowledgeDescription: {
    id: 'signupMessages.hasFundKnowledgeDescription',
    defaultMessage:
      'Fonder kan både öka och minska i värde och det är inte säkert att du får tillbaka det investerade kapitalet.',
  },
  hasFundKnowledge: {
    id: 'signupMessages.hasFundKnowledge',
    defaultMessage:
      'Jag förstår att det innebär en risk att investera i fonder',
  },
  fundInfoHeader: {
    id: 'signupMessages.fundInfoHeader',
    defaultMessage: `Vad är fonder?`,
  },
  fundInfoParagraph1: {
    id: 'signupMessages.fundInfoParagraph1',
    defaultMessage: `En fond beskrivs enklast som en sammansättning av underliggande tillgångar, som aktier och räntepapper. Att spara i fonder innebär att äga andelar i fonden och därmed de underliggande tillgångarna. Bakom en fond står ett fondbolag som tar ut avgifter för att förvalta och administrera fonden.`,
  },
  fundInfoParagraph2: {
    id: 'signupMessages.fundInfoParagraph2',
    defaultMessage: `Aktiefonder ger chans till högre avkastning än räntefonder men innebär högre risk. Fonder kan både öka och minska i värde och det är inte säkert att du får tillbaka det investerade kapitalet.`,
  },
  nordeaNodeFundManagementHeader: {
    id: 'signupMessages.nordeaNodeFundManagementHeader',
    defaultMessage: 'Hur sköter ni mitt sparande?',
  },
  nordeaNodeFundManagementDescr: {
    id: 'signupMessages.nordeaNodeFundManagementDescr',
    defaultMessage:
      'Vi investerar dina pengar i aktie- och räntefonder utifrån en lämplig strategi. Vi erbjuder enkla former av finansiella instrument till låga avgifter. Vi justerar risken i ditt sparande utifrån din ekonomiska situation, ditt syfte och mål med sparandet, din riskprofil, och vår egna bedömning av din förmåga att klara av förluster.',
  },
  preferencesHeader: {
    id: 'signupMessages.preferencesHeader',
    defaultMessage: 'Risk och hållbarhetsprofil',
  },
});

export const activityLevelMessages = defineMessages({
  activityLevelActive: {
    id: 'activityLevel.active',
    defaultMessage: `Personligt`,
  },
  activityLevelActiveDescription: {
    id: 'activityLevel.activeDescription',
    defaultMessage: `Jag kan tänka mig att vara aktiv vart 3:e år`,
  },
  activityLevelNotActive: {
    id: 'activityLevel.notActive',
    defaultMessage: `Enkelt`,
  },
  activityLevelNotActiveDescription: {
    id: 'activityLevel.notActiveDescription',
    defaultMessage: `Jag föredrar ett sparande jag inte behöver se över`,
  },
  activeProfileNotificationTitle: {
    id: 'activityLevel.activeProfileNotificationTitle',
    defaultMessage: `Du har valt personlig rådgivning`,
  },
  passiveProfileNotificationTitle: {
    id: 'activityLevel.passiveProfileNotificationTitle',
    defaultMessage: `Du har valt enkel rådgivning`,
  },
  activeProfileNotificationText: {
    id: 'activityLevel.activeProfileNotificationText',
    defaultMessage: `Personligt innebär skräddarsydda val där du bör vara aktiv och se över din pension minst vart 3:e år. Ett mer personligt sparande möjliggör, men garanterar inte, en bättre tillväxt på din pension.`,
  },
});

export const insuranceManagementTypeMessages = defineMessages({
  depository: {
    id: 'insuranceManagementType.depository',
    defaultMessage: `Depåförsäkring`,
  },
  unit: {
    id: 'insuranceManagementType.unit',
    defaultMessage: `Fondförsäkring`,
  },
  traditional: {
    id: 'insuranceManagementType.traditional',
    defaultMessage: `Traditionell försäkring`,
  },
});

export const assetClassMessages = defineMessages({
  equity: {
    id: 'assetClass.equity',
    defaultMessage: `Aktier`,
  },
  fixedIncome: {
    id: 'assetClass.fixedIncome',
    defaultMessage: `Räntepapper`,
  },
  cash: {
    id: 'assetClass.cash',
    defaultMessage: `Kontanter`,
  },
  cashLetter: {
    id: 'assetClass.cashLetter',
    defaultMessage: `K`,
  },
  other: {
    id: 'assetClass.other',
    defaultMessage: `Annat`,
  },
  currency: {
    id: 'assetClass.currency',
    defaultMessage: `Likvider`,
  },
  tableAssetClass: {
    id: 'assetClass.tableAssetClass',
    defaultMessage: `{assetClass, select,
      EQUITY {Aktier}
      FIXED_INCOME {Räntepapper}
      other {Övrigt}}`,
  },
});

export const commonMessages = defineMessages({
  or: {
    id: 'common.or',
    description: 'or',
    defaultMessage: 'eller',
  },
  apply: {
    id: 'common.apply',
    description: 'Apply',
    defaultMessage: 'Ansök',
  },
  and: {
    id: 'common.and',
    description: 'and',
    defaultMessage: 'och',
  },
  age: {
    id: 'common.age',
    description: 'Age',
    defaultMessage: 'Ålder',
  },
  from: {
    id: 'common.from',
    description: 'From',
    defaultMessage: 'Från',
  },
  to: {
    id: 'common.to',
    description: 'To',
    defaultMessage: 'Till',
  },
  bank: {
    id: 'common.bank',
    description: 'Bank',
    defaultMessage: 'Bank',
  },
  exit: {
    id: 'common.exit',
    description: 'Exit',
    defaultMessage: 'Avsluta',
  },
  activated: {
    id: 'common.activated',
    description: 'Activated',
    defaultMessage: 'Aktiverad',
  },
  cancelled: {
    id: 'common.cancelled',
    description: 'Cancelled',
    defaultMessage: 'Återkallad',
  },
  selectMonth: {
    id: 'common.selectMonth',
    description: 'Select month',
    defaultMessage: 'Välj månad',
  },
  selectDay: {
    id: 'common.selectDay',
    description: 'Select day',
    defaultMessage: 'Välj dag',
  },
  active: {
    id: 'common.active',
    description: 'Active',
    defaultMessage: 'Aktiv',
  },
  tags: {
    id: 'common.tags',
    description: 'Tags',
    defaultMessage: 'Taggar',
  },
  attachedFiles: {
    id: 'common.attachedFiles',
    description: 'Attached files',
    defaultMessage: 'Bifogade filer',
  },
  quantity: {
    id: 'common.quantity',
    description: 'Quantity',
    defaultMessage: 'Antal',
  },
  perYear: {
    id: 'common.perYear',
    description: 'Per year',
    defaultMessage: 'Per år',
  },
  capacity: {
    id: 'common.capacity',
    description: 'Capacity',
    defaultMessage: 'Utrymme',
  },
  requested: {
    id: 'common.requested',
    description: 'Requested',
    defaultMessage: 'Ansökt',
  },
  year: {
    id: 'common.year',
    defaultMessage: 'År',
  },
  atYear: {
    id: 'common.atYear',
    defaultMessage: '{year} år',
  },
  perMonth: {
    id: 'common.perMonth',
    description: 'Per month',
    defaultMessage: 'Per månad',
  },
  month: {
    id: 'common.month',
    description: 'Month',
    defaultMessage: 'Månad',
  },
  refers: {
    id: 'common.refers',
    description: 'Refers',
    defaultMessage: 'Avser',
  },
  reset: {
    id: 'common.reset',
    description: 'Reset',
    defaultMessage: 'Återställ',
  },
  poa: {
    id: 'common.poa',
    description: 'Power of attorney',
    defaultMessage: 'Fullmakt',
  },
  informationPoa: {
    id: 'common.informationPoa',
    description: 'Information power of attorney',
    defaultMessage: 'Informationsfullmakt',
  },
  adviceDate: {
    id: 'common.adviceDate',
    description: 'adviceDate',
    defaultMessage: 'Rådgivningstillfälle',
  },
  connect: {
    id: 'common.connect',
    description: 'Connect',
    defaultMessage: 'Anslut',
  },
  skip: {
    id: 'common.skip',
    description: 'Skip',
    defaultMessage: 'Hoppa över',
  },
  share: {
    id: 'common.share',
    description: 'Share',
    defaultMessage: 'Andel',
  },
  yourShare: {
    id: 'common.yourShare',
    description: 'Your share',
    defaultMessage: 'Din andel',
  },
  accountDetails: {
    id: 'common.accountDetails',
    description: 'Account details',
    defaultMessage: 'Kontouppgifter',
  },
  additionalInformation: {
    id: 'common.additionalInformation',
    description: 'Additional information',
    defaultMessage: 'Ytterligare uppgifter',
  },
  corporation: {
    id: 'common.corporation',
    description: 'Corporation',
    defaultMessage: 'Bolag',
  },
  selectCorporation: {
    id: 'common.selectCorporation',
    description: 'Select corporation',
    defaultMessage: 'Välj bolag',
  },
  mortgage: {
    id: 'common.mortgage',
    description: 'Mortgage',
    defaultMessage: 'Bolån',
  },
  editProfile: {
    id: 'common.editProfile',
    description: 'Edit profile',
    defaultMessage: 'Ändra profil',
  },
  ethical: {
    id: 'common.ethical',
    description: 'ethical',
    defaultMessage: 'Etiskt',
  },
  ethicalLong: {
    id: 'common.ethicalLong',
    description: 'Only ethical funds',
    defaultMessage: 'Endast etiska placeringar',
  },
  sustainable: {
    id: 'common.sustainable',
    description: 'Sustainable',
    defaultMessage: 'Hållbart',
  },
  sustainableLong: {
    id: 'common.sustainableLong',
    description: 'Only sustainable funds',
    defaultMessage: 'Endast hållbara fonder',
  },
  noPreferences: {
    id: 'common.noPreferences',
    description: 'No preferences',
    defaultMessage: 'Inga preferenser',
  },
  highRisk: {
    id: 'common.highRisk',
    description: 'High risk',
    defaultMessage: 'Hög risk',
  },
  mediumRisk: {
    id: 'common.mediumRisk',
    description: 'Medium risk',
    defaultMessage: 'Mellanhög risk',
  },
  lowRisk: {
    id: 'common.lowRisk',
    description: 'Low risk',
    defaultMessage: 'Låg risk',
  },
  co2RiskValue: {
    id: 'common.co2RiskValue',
    defaultMessage: `{riskScore, select,
      LOW {Låg}
      MEDIUM_LOW {Medellåg}
      MEDIUM {Medel}
      MEDIUM_HIGH {Medelhög}
      HIGH {Hög}
      other {}
    }`,
  },
  savings: {
    id: 'common.savings',
    description: 'Savings',
    defaultMessage: 'Sparande',
  },
  tax: {
    id: 'common.tax',
    description: 'Tax',
    defaultMessage: 'Skatt',
  },
  incomeTax: {
    id: 'common.incomeTax',
    description: 'Income tax',
    defaultMessage: 'Inkomstskatt',
  },
  loans: {
    id: 'common.loans',
    description: 'Loans',
    defaultMessage: 'Lån',
  },
  insurances: {
    id: 'common.insurances',
    description: 'Insurances',
    defaultMessage: 'Försäkringar',
  },
  myEconomy: {
    id: 'common.myEconomy',
    description: 'My economy',
    defaultMessage: 'Din ekonomi',
  },
  registrationNumber: {
    id: 'common.registrationNumber',
    description: 'Organisationsnummer',
    defaultMessage: 'Organisationsnummer',
  },
  nid: {
    id: 'common.nid',
    defaultMessage: 'ID',
  },
  nidLong: {
    id: 'common.nidLong',
    defaultMessage: 'Identifierare',
  },
  personalIdentityNumber: {
    id: 'common.personalIdentityNumber',
    description: 'Personnummer',
    defaultMessage: 'Personnummer',
  },
  naturalPersonIdentifier: {
    id: 'common.naturalPersonIdentifier',
    defaultMessage: 'Personnummer eller samordningsnummer',
  },
  admin: {
    id: 'common.admin',
    description: 'Adminstratör',
    defaultMessage: 'Administratör',
  },
  administration: {
    id: 'common.administration',
    description: 'Administration',
    defaultMessage: 'Administration',
  },
  employee: {
    id: 'common.employee',
    description: 'Anställd',
    defaultMessage: 'Anställd',
  },
  employees: {
    id: 'common.employees',
    description: 'Anställda',
    defaultMessage: 'Anställda',
  },
  employeeName: {
    id: 'common.employeeName',
    description: 'employee name',
    defaultMessage: `Anställdes namn`,
  },
  employer: {
    id: 'common.employer',
    description: 'employer',
    defaultMessage: `Arbetsgivare`,
  },
  employerName: {
    id: 'common.employerName',
    description: 'employer name',
    defaultMessage: `Arbetsgivarens namn`,
  },
  benefit: {
    id: 'common.benefit',
    description: 'Benefit',
    defaultMessage: 'Förmån',
  },
  benefits: {
    id: 'common.benefits',
    description: 'Benefits',
    defaultMessage: 'Förmåner',
  },
  benefitPackage: {
    id: 'common.benefitPackage',
    description: 'Benefit package',
    defaultMessage: 'Förmånsgrupp',
  },
  noBenefitPackage: {
    id: 'common.noBenefitPackage',
    description: 'Benefit package',
    defaultMessage: 'Ingen förmånsgrupp',
  },
  benefitPackages: {
    id: 'common.benefitPackages',
    description: 'Benefit packages',
    defaultMessage: 'Förmånsgrupper',
  },
  company: {
    id: 'common.company',
    description: 'Company',
    defaultMessage: 'Företag',
  },
  companySettings: {
    id: 'common.companySettings',
    description: 'Company settings',
    defaultMessage: 'Företagsinställningar',
  },
  companyInformation: {
    id: 'common.companyInformation',
    description: 'Company information',
    defaultMessage: 'Företagsinformation',
  },
  updateCompanyInformation: {
    id: 'common.updateCompanyInformation',
    description: 'Update company information',
    defaultMessage: 'Ändra företagsuppgifter',
  },
  yourContactInformation: {
    id: 'common.yourContactInformation',
    description: 'Your contact information',
    defaultMessage: 'Dina kontaktuppgifter',
  },
  costCenter: {
    id: 'common.costCenter',
    description: 'Cost center',
    defaultMessage: 'Kostnadsställe',
  },
  costReporting: {
    id: 'common.costReporting',
    description: 'Cost reporting',
    defaultMessage: 'Kostnadsrapportering',
  },
  organizationalUnit: {
    id: 'common.organizationalUnit',
    description: 'Organizational unit',
    defaultMessage: 'Avdelning',
  },
  costCenterAndBenefitPackage: {
    id: 'common.costCenterAndBenefitPackage',
    description: 'Cost center and benefit package',
    defaultMessage: 'Kostnadsställe och förmånsgrupp',
  },
  news: {
    id: 'common.news',
    description: 'Artiklar',
    defaultMessage: 'Artiklar',
  },
  name: {
    id: 'common.name',
    description: 'Namn',
    defaultMessage: 'Namn',
  },
  givenName: {
    id: 'common.givenName',
    description: 'Förnamn',
    defaultMessage: 'Förnamn',
  },
  lastName: {
    id: 'common.lastName',
    description: 'Efternamn',
    defaultMessage: 'Efternamn',
  },
  address: {
    id: 'common.address',
    description: 'Adress',
    defaultMessage: 'Adress',
  },
  invoiceAddress: {
    id: 'common.invoiceAddress',
    description: 'Fakturaadress',
    defaultMessage: 'Faktureringsadress',
  },
  addressLine1: {
    id: 'common.addressLine1',
    description: 'Fakturaadress rad 1',
    defaultMessage: 'Adressrad 1',
  },
  addressLine2: {
    id: 'common.addressLine2',
    description: 'Fakturaadress rad 2',
    defaultMessage: 'Adressrad 2',
  },
  co: { id: 'common.co', description: 'C/o', defaultMessage: 'C/o' },
  postalCode: {
    id: 'common.postalCode',
    description: 'Postnummer',
    defaultMessage: 'Postnummer',
  },
  city: {
    id: 'common.city',
    description: 'Postort',
    defaultMessage: 'Postort',
  },
  email: {
    id: 'common.email',
    description: 'E-postadress',
    defaultMessage: 'E-post',
  },
  phone: {
    id: 'common.phone',
    description: 'Telefonnummer',
    defaultMessage: 'Telefonnummer',
  },
  office: {
    id: 'common.office',
    description: 'Kontor',
    defaultMessage: 'Kontor',
  },
  officeContact: {
    id: 'common.officeContact',
    description: 'Kontaktperson',
    defaultMessage: 'Kontaktperson',
  },
  date: {
    id: 'common.date',
    description: 'Datum',
    defaultMessage: 'Datum',
  },
  category: {
    id: 'common.category',
    description: 'Kategori',
    defaultMessage: 'Kategori',
  },
  categorization: {
    id: 'common.categorization',
    description: 'Categorization',
    defaultMessage: 'Kategorisering',
  },
  superAdmin: {
    id: 'common.superAdmin',
    description: 'Superadministratör',
    defaultMessage: 'Huvudadministratör',
  },
  create: {
    id: 'common.create',
    description: 'Skapa',
    defaultMessage: 'Skapa',
  },
  continue: {
    id: 'common.continue',
    description: 'Continue',
    defaultMessage: 'Gå vidare',
  },
  search: {
    id: 'common.search',
    description: 'Search',
    defaultMessage: 'Sök',
  },
  searchEmployees: {
    id: 'common.searchEmployees',
    description: 'Search among employees',
    defaultMessage: 'Sök bland anställda',
  },
  send: {
    id: 'common.send',
    description: 'Send',
    defaultMessage: 'Skicka',
  },
  update: {
    id: 'common.update',
    description: 'Update',
    defaultMessage: 'Uppdatera',
  },
  card: {
    id: 'common.card',
    description: 'Kort',
    defaultMessage: 'Kort',
  },
  detail: {
    id: 'common.detail',
    description: 'Detaljerad',
    defaultMessage: 'Detaljer',
  },
  informationDetails: {
    id: 'common.informationDetails',
    description: 'Information details',
    defaultMessage: 'Uppgifter',
  },
  updateInformationDetails: {
    id: 'common.updateInformationDetails',
    description: 'Update information details',
    defaultMessage: 'Ändra uppgifter',
  },
  updatePremiumValue: {
    id: 'common.updatePremiumValue',
    description: 'Update premium value for pension insurance',
    defaultMessage: 'Ändra premie',
  },
  via: { id: 'common.via', description: 'Via', defaultMessage: 'Via' },
  all: { id: 'common.all', description: 'Alla', defaultMessage: 'Alla' },
  yes: { id: 'common.yes', description: 'Ja', defaultMessage: 'Ja' },
  no: { id: 'common.no', description: 'Nej', defaultMessage: 'Nej' },
  add: {
    id: 'common.add',
    description: 'Add',
    defaultMessage: 'Lägg till',
  },
  addRow: {
    id: 'common.addRow',
    description: 'Add row',
    defaultMessage: 'Lägg till rad',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Stäng',
  },
  remove: {
    id: 'common.remove',
    description: 'Remove',
    defaultMessage: 'Ta bort',
  },
  clearSelected: {
    id: 'common.clearSelected',
    description: 'Clear selected',
    defaultMessage: 'Rensa markerade',
  },
  salary: {
    id: 'common.salary',
    description: 'Lön (kr)',
    defaultMessage: 'Lön',
  },
  salaryBeforeTax: {
    id: 'common.salaryBeforeTax',
    description: 'monthly salary',
    defaultMessage: `Månadslön`,
  },
  updateSalary: {
    id: 'common.updateSalary',
    description: 'Update salary',
    defaultMessage: 'Ändra lön',
  },
  netSalary: {
    id: 'common.netSalary',
    description: 'Net salary',
    defaultMessage: 'Lön efter skatt',
  },
  grossSalary: {
    id: 'common.grossSalary',
    description: 'Gross salary',
    defaultMessage: 'Bruttolön',
  },
  compensation: {
    id: 'common.compensation',
    description: 'Compensation',
    defaultMessage: 'Ersättning',
  },
  monthlySalaryOptOut: {
    id: 'common.monthlySalaryOptOut',
    description: 'Monthly salary opt out',
    defaultMessage: 'Månadslön saknas',
  },
  pension: {
    id: 'common.pension',
    description: 'Pension',
    defaultMessage: 'Pension',
  },
  vacation: {
    id: 'common.vacation',
    description: 'Vacation',
    defaultMessage: 'Semester',
  },
  fileName: {
    id: 'common.fileName',
    description: 'Filnamn',
    defaultMessage: 'Filnamn',
  },
  totalFundFees: {
    id: 'common.totalFundFees',
    defaultMessage: 'Totala fondavgifter',
  },
  totalFees: {
    id: 'common.totalFees',
    defaultMessage: 'Totala avgifter',
  },
  interest: {
    id: 'common.interest',
    defaultMessage: 'Ränta',
  },
  balance: {
    id: 'common.balance',
    description: 'Saldo',
    defaultMessage: 'Saldo',
  },
  available: {
    id: 'common.available',
    description: 'Tillgängligt',
    defaultMessage: 'Tillgängligt',
  },
  total: {
    id: 'common.total',
    description: 'Totalt',
    defaultMessage: 'Totalt',
  },
  days: {
    id: 'common.days',
    description: 'dagar',
    defaultMessage: 'dagar',
  },
  xDays: {
    id: 'common.xDays',
    description: 'X days (pluralized)',
    defaultMessage: '{count, plural, one {# dag} other {# dagar}}',
  },
  nRowsSelected: {
    id: 'common.nRowsSelected',
    defaultMessage:
      '{count, plural, one {# rad markerad} other {# rader markerade}}',
  },
  accountType: {
    id: 'common.accountType',
    description: 'Account type',
    defaultMessage: 'Kontotyp',
  },
  accountNumber: {
    id: 'common.accountNumber',
    description: 'Account number',
    defaultMessage: 'Kontonummer',
  },
  status: {
    id: 'common.status',
    description: 'Status',
    defaultMessage: 'Status',
  },
  field: {
    id: 'common.field',
    description: 'Fält',
    defaultMessage: 'Fält',
  },
  value: {
    id: 'common.value',
    description: 'Värde',
    defaultMessage: 'Värde',
  },
  column: {
    id: 'common.column',
    description: 'Column',
    defaultMessage: 'Kolumn',
  },
  importedColumn: {
    id: 'common.importedColumn',
    description: 'Imported column',
    defaultMessage: 'Importerad kolumn',
  },
  startWith: {
    id: 'common.startWith',
    description: 'Från och med',
    defaultMessage: 'Fr.o.m.',
  },
  monthlySalary: {
    id: 'common.monthlySalary',
    description: 'Salary',
    defaultMessage: 'Månadslön',
  },
  report: {
    id: 'common.report',
    description: 'report',
    defaultMessage: 'Rapport',
  },
  registered: {
    id: 'common.registered',
    description: 'Registrerad',
    defaultMessage: 'Inlagd',
  },
  insuranceNumber: {
    id: 'common.insuranceNumber',
    description: 'Försäkringsnummer',
    defaultMessage: 'Försäkringsnummer',
  },
  startingAt: {
    id: 'common.startingAt',
    description: 'Starting at',
    defaultMessage: 'Fr.o.m.',
  },
  endingOn: {
    id: 'common.endingOn',
    description: 'Ending on',
    defaultMessage: 'T.o.m.',
  },
  effectiveDate: {
    id: 'common.effectiveDate',
    description: 'Effective date',
    defaultMessage: 'Gäller fr.o.m.',
  },
  effectiveUntil: {
    id: 'common.effectiveUntil',
    description: 'Effective until',
    defaultMessage: 'Gäller t.o.m.',
  },
  insuranceProvider: {
    id: 'common.insuranceProvider',
    description: 'Försäkringsgivare',
    defaultMessage: 'Försäkringsbolag',
  },
  euroAccident: {
    id: 'common.euroAccident',
    description: 'Euro Accident',
    defaultMessage: 'Euro Accident',
  },
  taxable: {
    id: 'common.taxable',
    description: 'Skattepliktig ersättning',
    defaultMessage: 'Skattepliktig ersättning',
  },
  level: {
    id: 'common.level',
    description: 'Ersättningsbelopp',
    defaultMessage: 'Ersättningsnivå',
  },
  benefitPackageLabel: {
    id: 'common.benefitPackageLabel',
    description: 'Förmånsgrupp',
    defaultMessage: 'Förmånsgrupp',
  },
  benefitPackageOptOut: {
    id: 'common.benefitPackageOptOut',
    description: 'Benefit package opt out',
    defaultMessage: 'Tilldela inte några förmåner till den anställda',
  },
  benefitPackageDescription: {
    id: 'common.benefitPackageDescription',
    description: 'Benefit package description',
    defaultMessage:
      'Förmånsgrupp styr vilka förmåner den anställda får tillgång till. Förmånsgrupp är obligatoriskt för att aktivera förmåner.',
  },
  employment: {
    id: 'common.employment',
    description: 'Anställning',
    defaultMessage: 'Anställning',
  },
  employments: {
    id: 'common.employments',
    description: 'Anställningar',
    defaultMessage: 'Anställningar',
  },
  owner: {
    id: 'common.owner',
    description: 'Ägare',
    defaultMessage: 'Företagsägare',
  },
  change: {
    id: 'common.change',
    description: 'Ändra',
    defaultMessage: 'Ändra',
  },
  document: {
    id: 'common.document',
    description: 'Dokument',
    defaultMessage: 'Dokument',
  },
  agreements: {
    id: 'common.agreements',
    description: 'Agreements',
    defaultMessage: 'Avtal',
  },
  agreement: {
    id: 'common.agreement',
    description: 'Agreement',
    defaultMessage: 'Avtal',
  },
  filter: {
    id: 'common.filter',
    description: 'Filter',
    defaultMessage: 'Filtrera',
  },
  nFiltersSelected: {
    id: 'common.nFiltersSelected',
    defaultMessage: `{count, plural,
      =0 {Filtrera}
      =1 {# filter valt}
      other {# filter valda}
    }`,
  },
  resetFilters: {
    id: 'common.resetFilters',
    defaultMessage: 'Återställ filter',
  },
  image: {
    id: 'common.image',
    description: 'Image',
    defaultMessage: 'Bild',
  },
  validityPeriod: {
    id: 'common.validityPeriod',
    description: 'Validity period',
    defaultMessage: 'Giltighetsperiod',
  },
  salaryExchange: {
    id: 'common.salaryExchange',
    description: 'Salary exchange',
    defaultMessage: 'Löneväxling',
  },
  companyCar: {
    id: 'common.companyCar',
    description: 'Company car',
    defaultMessage: 'Bilförmån',
  },
  employersContributions: {
    id: 'common.employersContributions',
    description: 'Employers contributions',
    defaultMessage: 'Arbetsgivaravgifter',
  },
  specialPayrollTax: {
    id: 'common.specialPayrollTax',
    description: 'Special payroll tax',
    defaultMessage: 'Särskild löneskatt',
  },
  employersContributionsAndspecialPayrollTax: {
    id: 'common.employersContributionsAndspecialPayrollTax',
    description: 'Employers contributions and special payroll tax',
    defaultMessage: 'Sociala avgifter och särskild löneskatt',
  },
  ITPK: {
    id: 'common.ITPK',
    description: 'ITPK',
    defaultMessage: 'ITPK',
  },
  BTP1: {
    id: 'common.BTP1',
    defaultMessage: 'BTP 1',
  },
  ITP: {
    id: 'common.ITP',
    description: 'ITP 2',
    defaultMessage: 'ITP 2',
  },
  ITP1: {
    id: 'common.ITP1',
    description: 'ITP 1',
    defaultMessage: 'ITP 1',
  },
  KAPKL: {
    id: 'common.KAPKL',
    description: 'KAP-KL',
    defaultMessage: 'KAP-KL',
  },
  AKAPKL: {
    id: 'common.AKAPKL',
    description: 'AKAP-KL',
    defaultMessage: 'AKAP-KL',
  },
  AKAPKR: {
    id: 'common.AKAPKR',
    description: 'AKAP-KR',
    defaultMessage: 'AKAP-KR',
  },
  SAFLO: {
    id: 'common.SAFLO',
    description: 'SAF-LO',
    defaultMessage: 'SAF-LO',
  },
  PA161: {
    id: 'common.PA161',
    description: 'PA161',
    defaultMessage: 'PA 16 avdelning 1',
  },
  PA162: {
    id: 'common.PA162',
    description: 'PA162',
    defaultMessage: 'PA 16 avdelning 2',
  },
  PAKFS09: {
    id: 'common.PAKFS09',
    description: 'PA-KFS 09',
    defaultMessage: 'PA-KFS 09',
  },
  lunchAllowance: {
    id: 'common.lunchAllowance',
    description: 'Lunch allowance',
    defaultMessage: 'Lunchförmån',
  },
  collectiveAgreement: {
    id: 'common.collectiveAgreement',
    description: 'Collective agreement',
    defaultMessage: 'Kollektivavtal',
  },
  import: {
    id: 'common.import',
    description: 'Import',
    defaultMessage: 'Importera',
  },
  importStatus: {
    id: 'common.importStatus',
    description: 'Import status',
    defaultMessage: 'Importstatus',
  },
  noImports: {
    id: 'common.noImports',
    description: 'No imports',
    defaultMessage: 'Inga importer',
  },
  supplier: {
    id: 'common.supplier',
    description: 'Supplier',
    defaultMessage: 'Leverantör',
  },
  management: {
    id: 'common.management',
    description: 'Management',
    defaultMessage: 'Förvaltning',
  },
  premiumPerMonth: {
    id: 'common.premiumPerMonth',
    defaultMessage: '{value, number, ::currency/SEK .} per månad',
  },
  updatingPremiumValue: {
    id: 'common.updatingPremiumValue',
    description: 'Premium is updating message from current {value}',
    defaultMessage:
      'Premien uppdateras (från nuvarande premie {value, number, ::currency/SEK .} per månad)',
  },
  premiumWithUpcomingPremium: {
    id: 'common.premiumWithUpcomingPremium',
    defaultMessage:
      '{premium, number, ::currency/SEK .} per månad (premien kommer att uppdateras till {newPremium, number, ::currency/SEK .} per månad fr.o.m. {newPremiumEffectiveDate})',
  },
  other: {
    id: 'common.other',
    description: 'Other',
    defaultMessage: 'Övrigt',
  },
  employeeNumber: {
    id: 'common.employeeNumber',
    description: 'Employee number',
    defaultMessage: 'Anställningsnummer',
  },
  employmentType: {
    id: 'common.employmentType',
    description: 'Employment type',
    defaultMessage: 'Anställningstyp',
  },
  hoursWorked: {
    id: 'common.hoursWorked',
    description: 'Hours worked',
    defaultMessage: 'Arbetade timmar',
  },
  employeeNumberAbbreviated: {
    id: 'common.employeeNumberAbbreviated',
    description: 'Employee number abbreviated',
    defaultMessage: 'Anst. nr',
  },
  vacationDaysSaved: {
    id: 'common.vacationDaysSaved',
    description: 'Saved vacation days',
    defaultMessage: 'Sparade semesterdagar',
  },
  vacationDaysPaid: {
    id: 'common.vacationDaysPaid',
    description: 'Paid vacation days',
    defaultMessage: 'Betalda semesterdagar',
  },
  vacationDaysUnpaid: {
    id: 'common.vacationDaysUnpaid',
    description: 'Unpaid vacation days',
    defaultMessage: 'Obetalda semesterdagar',
  },
  vacationDaysAdvance: {
    id: 'common.vacationDaysAdvance',
    description: 'Advance vacation days',
    defaultMessage: 'Förskottssemester',
  },
  vacationDaysPerYear: {
    id: 'common.vacationDaysPerYear',
    description: 'Vacation days per year',
    defaultMessage: 'Semesterrätt',
  },
  companyCarMonthlyValue: {
    id: 'common.companyCarMonthlyValue',
    description: 'Company car monthly value',
    defaultMessage: 'Förmånsvärde tjänstebil (kr/mån)',
  },
  fitnessContributionBalance: {
    id: 'common.fitnessContributionBalance',
    description: 'Fitness contribution balance',
    defaultMessage: 'Friskvårdssaldo',
  },
  isOwner: {
    id: 'common.isOwner',
    description: 'Is owner',
    defaultMessage: 'Företagsägare',
  },
  employerContributionShare: {
    id: 'common.employerContributionShare',
    description: 'Employer contribution share',
    defaultMessage: 'Arbetsgivaravgifter (%)',
  },
  incomeTaxTable: {
    id: 'common.incomeTaxTable',
    description: 'Income tax table',
    defaultMessage: 'Skattetabell',
  },
  importEmployees: {
    id: 'common.importEmployees',
    description: 'Import employees',
    defaultMessage: 'Importera anställda',
  },
  importEmployeesTo: {
    id: 'common.importEmployeesTo',
    description: 'Import employees to',
    defaultMessage: 'Importera anställda till {companyName} ({regNbr})',
  },
  requiredField: {
    id: 'common.requiredField',
    description: 'Required field',
    defaultMessage: 'Obligatoriskt fält',
  },
  benefitPackageEffectiveDate: {
    id: 'common.benefitPackageEffectiveDate',
    description: 'Benefit package effective date',
    defaultMessage: 'Förmånsgrupp fr.o.m.',
  },
  monthlySalaryEffectiveDate: {
    id: 'common.monthlySalaryEffectiveDate',
    description: 'Monthly salary effective date',
    defaultMessage: 'Månadslön gäller fr.o.m.',
  },
  title: {
    id: 'common.title',
    description: 'Title',
    defaultMessage: 'Titel',
  },
  description: {
    id: 'common.description',
    description: 'Description',
    defaultMessage: 'Beskrivning',
  },
  labelSEKPerMonth: {
    id: 'common.labelSEKPerMonth',
    description: '{label} (kr/mån)',
    defaultMessage: '{label} (kr/mån)',
  },
  visibility: {
    id: 'common.visibility',
    description: 'Visiblity',
    defaultMessage: 'Synlighet',
  },
  readMore: {
    id: 'common.readMore',
    description: 'Read more',
    defaultMessage: 'Läs mer',
  },
  lastestUpdatedDate: {
    id: 'common.lastestUpdatedDate',
    description: 'Updated: {date}',
    defaultMessage: 'Uppdaterad {date}',
  },
  updatedAt: {
    id: 'common.updatedAt',
    description: 'Updated at',
    defaultMessage: 'Uppdaterad',
  },
  occupationalPension: {
    id: 'common.occupationalPension',
    description: 'Occupational pension',
    defaultMessage: 'Tjänstepension',
  },
  brokeredThrough: {
    id: 'common.brokeredThrough',
    description: 'Through',
    defaultMessage: 'Genom',
  },
  number: {
    id: 'common.number',
    description: 'Number',
    defaultMessage: 'Nummer',
  },
  survivorsProtection: {
    id: 'common.survivorsProtection',
    description: "Survivor's Protection",
    defaultMessage: 'Skydd',
  },
  taxed: {
    id: 'common.taxed',
    description: 'Taxed',
    defaultMessage: 'Beskattas',
  },
  amount: {
    id: 'common.amount',
    description: 'Amount',
    defaultMessage: `Belopp`,
  },
  amountLabel: {
    id: 'common.amountLabel',
    description: 'Amount (kr)',
    defaultMessage: 'Belopp',
  },
  amountPerMonthLabel: {
    id: 'common.amountPerMonthLabel',
    description: 'Amount (kr/month)',
    defaultMessage: `Belopp (kr/mån)`,
  },
  payment: {
    id: 'common.payment',
    description: 'Payment',
    defaultMessage: 'Utbetalning',
  },
  keepInMind: {
    id: 'common.keepInMind',
    description: 'Keep in mind',
    defaultMessage: 'Tänk på',
  },
  back: {
    id: 'common.back',
    description: 'Back',
    defaultMessage: 'Tillbaka',
  },
  next: {
    id: 'common.next',
    defaultMessage: 'Nästa',
  },
  progressStep: {
    id: 'common.progressStep',
    description: 'Steps description in wizards',
    defaultMessage: 'Steg {step} -',
  },
  stepLabel: {
    id: 'common.stepLabel',
    description: 'Steps label in Wizard',
    defaultMessage: '– Steg {step} av {steps}',
  },
  withNordeaNode: {
    id: 'common.withNordeaNode',
    description: '/pension/benefits/pension - subtitle - With Nordea Node',
    defaultMessage: 'Med Nordea Node',
  },
  onePbbDescription: {
    id: 'common.onePbbDescription',
    description: 'Description of one PBB',
    defaultMessage: '1 prisbasbelopp = {value} {year}',
  },
  noPbb: {
    id: 'common.noPbb',
    description: '{value} pbb',
    defaultMessage: '{value} prisbasbelopp',
  },
  dayNo: {
    id: 'common.dayNo',
    description: ' dag {value}',
    defaultMessage: 'dag {value}',
  },
  ibb: {
    id: 'common.ibb',
    description: 'IBB',
    defaultMessage: 'IBB',
  },
  aboutUs: {
    id: 'common.aboutUs',
    description: 'About us',
    defaultMessage: 'Uppgifter om oss',
  },
  aboutYou: {
    id: 'common.aboutYou',
    description: 'About you',
    defaultMessage: 'Uppgifter om dig',
  },
  yourInvestmentProfile: {
    id: 'common.yourInvestmentProfile',
    description: 'Your investment profile',
    defaultMessage: 'Din investeringsprofil',
  },
  otherInformation: {
    id: 'common.otherInformation',
    description: 'Other information',
    defaultMessage: 'Övrig information',
  },
  perPageResult: {
    id: 'common.perPageResult',
    defaultMessage: 'Visa',
  },
  when: {
    id: 'common.when',
    description: 'When',
    defaultMessage: 'När',
  },
  noChosen: {
    id: 'common.noChosen',
    description: '{selected} chosen',
    defaultMessage: '{selected, plural, one {# markerad} other {# markerade}}',
  },
  EmptyState404Message: {
    id: 'common.EmptyState404Message',
    description: 'This page is not available',
    defaultMessage: 'Den här sidan är inte tillgänglig',
  },
  here: {
    id: 'common.here',
    description: 'Used on links',
    defaultMessage: 'här',
  },
  confirm: {
    id: 'common.confirm',
    description: 'Confirm',
    defaultMessage: 'Bekräfta',
  },
  cancel: {
    id: 'common.cancel',
    description: 'Cancel',
    defaultMessage: 'Avbryt',
  },
  review: {
    id: 'common.review',
    defaultMessage: 'Granska',
  },
  single: {
    id: 'common.single',
    description: 'single',
    defaultMessage: 'Singel',
  },
  sambo: {
    id: 'common.sambo',
    description: 'sambo',
    defaultMessage: 'Sambo',
  },
  married: {
    id: 'common.married',
    description: 'married',
    defaultMessage: 'Gift',
  },
  choose: {
    id: 'common.choose',
    description: 'choose',
    defaultMessage: 'Välj',
  },
  kids: {
    id: 'commonBenefitMessages.kids',
    description: 'kids',
    defaultMessage: 'Barn',
  },
  markComplete: {
    id: 'common.markComplete',
    description: 'Mark complete',
    defaultMessage: 'Markera som klar',
  },
  completed: {
    id: 'common.completed',
    description: 'Completed',
    defaultMessage: 'Klar',
  },
  ignore: {
    id: 'common.ignore',
    description: 'Ignore',
    defaultMessage: 'Hoppa över',
  },
  ignored: {
    id: 'common.ignored',
    description: 'Ignored',
    defaultMessage: 'Hoppat över',
  },
  congratulations: {
    id: 'commonBenefitMessages.congratulations',
    description: 'Congratulations',
    defaultMessage: 'Grattis',
  },
  openBankIdSameDevice: {
    id: 'common.openBankIdSameDevice',
    description: 'Open BankID on same device ',
    defaultMessage: 'Öppna BankID på den här enheten',
  },
  partTimeEmployment: {
    id: 'common.partTimeEmployment',
    description: 'Part-time employment',
    defaultMessage: 'Sysselsättningsgrad',
  },
  partTimeEmploymentPercentLabel: {
    id: 'common.partTimeEmploymentPercentLabel',
    description: 'Part-time employment',
    defaultMessage: 'Sysselsättningsgrad',
  },
  slug: {
    id: 'common.slug',
    description: 'Code',
    defaultMessage: 'Kod',
  },
  organization: {
    id: 'common.organization',
    description: 'Organization',
    defaultMessage: 'Organisation',
  },
  payslips: {
    id: 'common.payslips',
    description: 'Payslips',
    defaultMessage: 'Lönespecifikationer',
  },
  payslip: {
    id: 'common.payslip',
    description: 'Payslip',
    defaultMessage: 'Lönespecifikation',
  },
  refersToDate: {
    id: 'common.refersToDate',
    defaultMessage: 'Avser datum',
  },
  requests: {
    id: 'common.requests',
    description: 'Requests',
    defaultMessage: 'Ansökningar',
  },
  calculate: {
    id: 'common.calculate',
    description: 'calculate',
    defaultMessage: 'Gör beräkning',
  },
  activeCount: {
    id: 'common.activeCount',
    description: 'Active',
    defaultMessage: '{count, plural, =1 {Aktiv} other {Aktiva}}',
  },
  toHandle: {
    id: 'common.toHandle',
    description: 'To handle',
    defaultMessage: 'Att hantera',
  },
  skipToMain: {
    id: 'common.skipToMain',
    description: 'Skip to main',
    defaultMessage: 'Hoppa till huvudinnehållet',
  },
  investment: {
    id: 'common.investment',
    description: 'investment',
    defaultMessage: 'Placering',
  },
  extent: {
    id: 'common.extent',
    description: 'extent',
    defaultMessage: 'Omfattning',
  },
  extentPercentLabel: {
    id: 'common.extentPercentLabel',
    description: 'extent',
    defaultMessage: 'Omfattning',
  },
  goBack: {
    id: 'common.goBack',
    description: 'Go back',
    defaultMessage: 'Gå tillbaka',
  },
  ok: {
    id: 'common.ok',
    description: 'Ok',
    defaultMessage: 'Ok',
  },
  showAll: {
    id: 'common.showAll',
    description: 'Show all',
    defaultMessage: 'Visa alla',
  },
  accounts: {
    id: 'common.accounts',
    description: 'Accounts',
    defaultMessage: 'Konton',
  },
  startDate: {
    id: 'common.startDate',
    description: 'Start date',
    defaultMessage: 'Startdatum',
  },
  questions: {
    id: 'common.questions',
    description: 'Questions?',
    defaultMessage: 'Funderingar?',
  },
  help: {
    id: 'common.help',
    description: 'Help',
    defaultMessage: 'Hjälp',
  },
  effectiveFrom: {
    id: 'common.effectiveFrom',
    defaultMessage: 'Tecknad fr.o.m.',
  },
  untilFurtherNotice: {
    id: 'common.untilFurtherNotice',
    defaultMessage: 'Tills vidare',
  },
  greeting: {
    id: 'common.greeting',
    defaultMessage: `{hasGivenName, select,
      true {Välkommen, {givenName}!}
      other {Välkommen!}
    }`,
  },
  deactivate: {
    id: 'common.deactivate',
    defaultMessage: 'Inaktivera',
  },
  deactivated: {
    id: 'common.deactivated',
    defaultMessage: 'Inaktiverad',
  },
  downloadAsPdf: {
    id: 'common.downloadAsPdf',
    defaultMessage: 'Ladda ned som PDF',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Redigera',
  },
  getStarted: {
    id: 'common.getStarted',
    description: 'Message in the top banner button, get started',
    defaultMessage: 'Kom igång',
  },
  sort: {
    id: 'common.sort',
    defaultMessage: 'Sortera',
  },
  sortBy: {
    id: 'common.sortBy',
    defaultMessage: 'Sortera efter',
  },
  ascending: {
    id: 'common.ascending',
    defaultMessage: 'Stigande',
  },
  descending: {
    id: 'common.descending',
    defaultMessage: 'Fallande',
  },
  stop: {
    id: 'common.stop',
    defaultMessage: 'Avsluta',
  },
  insurance: {
    id: 'common.insurance',
    defaultMessage: 'Försäkring',
  },
  nordeaNode: {
    id: 'common.nordeaNode',
    defaultMessage: 'Nordea Node',
  },
  offers: {
    id: 'common.offers',
    defaultMessage: 'Erbjudanden',
  },
  remuneration: {
    id: 'common.remuneration',
    defaultMessage: 'Ersättning',
  },
  endDate: {
    id: 'common.endDate',
    defaultMessage: 'Slutdatum',
  },
  allocationTowardsStocks: {
    id: 'common.allocationTowardsStocks',
    defaultMessage: '{allocation, number, ::percent .} aktier',
  },
  duration: {
    id: 'common.duration',
    defaultMessage: 'Dag {lowerBound}–{upperBound}',
  },
  permanently: {
    id: 'common.permanently',
    defaultMessage: 'Permanent',
  },
  clearFilter: {
    id: 'common.clearFilter',
    defaultMessage: 'Rensa',
  },
  done: {
    id: 'common.done',
    defaultMessage: 'Klar',
  },
  embed: {
    id: 'common.embed',
    defaultMessage: 'Bädda in',
  },
  premium: {
    id: 'common.premium',
    defaultMessage: 'Premie',
  },
  expandName: {
    id: 'common.expandName',
    defaultMessage: 'Expandera {name}',
  },
  selectAllRows: {
    id: 'common.selectAllRows',
    defaultMessage: 'Välj alla rader',
  },
  openMenu: {
    id: 'common.openMenu',
    defaultMessage: 'Öppna meny',
  },
  language: {
    id: 'common.language',
    defaultMessage: 'Språk',
  },
  goToPreviousPage: {
    id: 'common.goToPreviousPage',
    defaultMessage: 'Föregående sida',
  },
  goToNextPage: {
    id: 'common.goToNextPage',
    defaultMessage: 'Nästa sida',
  },
  perPage: {
    id: 'common.perPage',
    defaultMessage: 'Antal träffar per sida',
  },
  chooseDate: {
    id: 'common.chooseDate',
    defaultMessage: 'Välj datum',
  },
  created: {
    id: 'common.created',
    defaultMessage: 'Skapad',
  },
  personAdded: {
    id: 'common.personAdded',
    defaultMessage: 'Anställd tillagd',
  },
  noValue: {
    id: 'common.noValue',
    defaultMessage: 'Ingen uppgift',
  },
  reports: {
    id: 'common.reports',
    defaultMessage: 'Rapporter',
  },
  actor: {
    id: 'common.actor',
    defaultMessage: 'Aktör',
  },
  reportingDate: {
    id: 'common.reportingDate',
    defaultMessage: 'Rapporteringsdatum',
  },
  showBenefit: {
    id: 'common.showBenefit',
    defaultMessage: 'Visa förmån',
  },
  history: {
    id: 'common.history',
    defaultMessage: 'Historik',
  },
  nYears: {
    id: 'common.nYears',
    defaultMessage: '{count} år',
  },
});

export const formMessages = defineMessages({
  create: commonMessages.create,
  send: commonMessages.send,
  update: commonMessages.update,
  continue: commonMessages.continue,
  back: commonMessages.back,
  previous: {
    id: 'formMessages.previous',
    defaultMessage: 'Föregående',
  },
  start: {
    id: 'formMessages.start',
    description: 'Start',
    defaultMessage: 'Sätt igång',
  },
  addBenefits: {
    id: 'formMessages.addBenefits',
    description: 'Add benefits',
    defaultMessage: 'Lägg till förmåner',
  },
  saveAndExit: {
    id: 'formMessages.saveAndExit',
    description: 'Save and exit',
    defaultMessage: 'Spara och avsluta',
  },
  addToBenefitPackage: {
    id: 'formMessages.addToBenefitPackage',
    description: 'Lägg till i förmånsgrupp',
    defaultMessage: 'Lägg till i förmånsgrupp',
  },
  changeBenefitPackage: {
    id: 'formMessages.changeBenefitPackage',
    description: 'Change benefit package',
    defaultMessage: 'Byt förmånsgrupp',
  },
  benefitPackagesPlaceholder: {
    id: 'formMessages.benefitPackagesPlaceholder',
    description: 'Välj förmånsgrupp',
    defaultMessage: 'Välj förmånsgrupp',
  },
  currentBenefitPackage: {
    id: 'formMessages.currentBenefitPackage',
    description: 'Current benefit package',
    defaultMessage: 'Nuvarande förmånsgrupp',
  },
  createOffer: {
    id: 'formMessages.createOffer',
    description: 'Skapa eget erbjudande',
    defaultMessage: 'Skapa eget erbjudande',
  },
  createCompany: {
    id: 'formMessages.createCompany',
    description: 'Skapa företag',
    defaultMessage: 'Skapa företag',
  },
  costCenterDetails: {
    id: 'formMessages.costCenterDetails',
    description: 'Cost center details',
    defaultMessage: 'Kontor',
  },
  costCenterContact: {
    id: 'formMessages.costCenterContact',
    description: 'Cost center contact',
    defaultMessage: 'Kontaktuppgifter',
  },
  addExistingBenefit: {
    id: 'formMessages.addExistingBenefit',
    description: 'Lägg till befintlig förmån',
    defaultMessage: 'Lägg till befintlig förmån',
  },
  searchEmployee: {
    id: 'formMessages.searchEmployee',
    description: 'Sök efter anställd',
    defaultMessage: 'Sök bland anställda',
  },
  searchEmployeeHelperText: {
    id: 'formMessages.searchEmployeeHelperText',
    description:
      'Sök efter anställd (personnummer, förnamn, efternamn och förmånsgrupp)',
    defaultMessage:
      'Sök bland anställda (personnummer, namn eller förmånsgrupp)',
  },
  adminNoEmployee: {
    id: 'formMessages.adminNoEmployee',
    description: 'Employee table empty on admin route',
    defaultMessage: 'Det finns inga anställda att visa',
  },
  adminNoEmployeeText: {
    id: 'formMessages.adminNoEmployeeText',
    description: 'Employee table empty on admin route helper text',
    defaultMessage: 'Anställda som läggs till i portalen kommer att visas här.',
  },
  searchBenefitsHelperText: {
    id: 'formMessages.searchBenefitsHelperText',
    description: 'Sök efter förmåner (förmånnamn, förmånsgrupp, och kategori)',
    defaultMessage: 'Sök bland förmåner (namn, förmånsgrupp eller kategori)',
  },
  searchOffersHelperText: {
    id: 'formMessages.searchOffersHelperText',
    description: 'Sök efter erbjudanden (bolag och kategori)',
    defaultMessage: 'Sök bland erbjudanden (namn, bolag eller kategori)',
  },
  approve: {
    id: 'formMessages.approve',
    description: 'Godkänn',
    defaultMessage: 'Godkänn',
  },
  reject: {
    id: 'formMessages.reject',
    description: 'Avslå',
    defaultMessage: 'Avslå',
  },
  save: {
    id: 'formMessages.save',
    description: 'Spara',
    defaultMessage: 'Spara',
  },
  cancel: commonMessages.cancel,
  retry: {
    id: 'formMessages.retry',
    description: 'Retry',
    defaultMessage: 'Försök igen',
  },
  field: {
    id: 'formMessages.field',
    description: 'Fält',
    defaultMessage: 'Fält',
  },
  createdAt: {
    id: 'formMessages.createdAt',
    description: 'Created at',
    defaultMessage: 'Skapad',
  },
  scrapeWithQRCode: {
    id: 'formMessages.scrapeWithQRCode',
    description:
      'Message displayed when scraping institute which needs login with QR code',
    defaultMessage: `Starta BankID-appen och läs av QR-koden.`,
  },
  finishWizard: {
    id: 'formMessages.finishWizard',
    defaultMessage: `Slutför assistenten`,
  },
  queryErrorMessage: {
    id: 'formMessages.queryErrorMessage',
    description: 'The generic error message when there is a network issue.',
    defaultMessage:
      'Det verkar som att det blev något fel vid inhämtning av data.',
  },
  selectAll: {
    id: 'formMessages.selectAll',
    defaultMessage: 'Välj alla',
  },
  confirmLeaveFormTitle: {
    id: 'formMessages.confirmLeaveFormTitle',
    defaultMessage: 'Lämna sidan?',
  },
  confirmLeaveFormDescription: {
    id: 'formMessages.confirmLeaveFormDescription',
    defaultMessage: 'Du har osparade ändringar som kommer att förloras.',
  },
});

export const menuMessages = defineMessages({
  employees: commonMessages.employees,
  benefits: commonMessages.benefits,
  companySettings: commonMessages.companySettings,
  costReporting: commonMessages.costReporting,
  news: commonMessages.news,
  economy: commonMessages.myEconomy,
  home: {
    id: 'menuMessages.home',
    description: 'Hem',
    defaultMessage: 'Hem',
  },
  notifications: {
    id: 'menuMessages.notificatiosn',
    description: 'Notifications',
    defaultMessage: 'Notifikationer',
  },
  events: {
    id: 'menuMessages.events',
    description: 'Events',
    defaultMessage: 'Händelser',
  },
  jobOffers: {
    id: 'menuMessages.jobOffers',
    description: 'Job offers',
    defaultMessage: 'Jobb',
  },
  reportsAndFiles: {
    id: 'menuMessages.reportsAndFiles',
    description: 'reports and files',
    defaultMessage: 'Rapporter & filer',
  },
  logout: {
    id: 'menuMessages.logout',
    description: 'Logga ut',
    defaultMessage: 'Logga ut',
  },
  overview: {
    id: 'menuMessages.overview',
    description: 'Overview',
    defaultMessage: 'Översikt',
  },
  discounts: {
    id: 'menuMessages.discounts',
    description: 'Erbjudanden',
    defaultMessage: 'Erbjudanden',
  },
  userSettings: {
    id: 'menuMessages.userSettings',
    defaultMessage: 'Användarinställningar',
  },
  salaryExchange: {
    id: 'menuMessages.salaryExchange',
    description: 'Salary exchange',
    defaultMessage: 'Löneväxling',
  },
  totalCompensation: {
    id: 'menuMessages.totalCompensation',
    description: 'Total compensation',
    defaultMessage: 'Lön och ersättning',
  },
  pension: {
    id: 'menuMessages.pension',
    description: 'Pension',
    defaultMessage: 'Pension',
  },
  insurance: {
    id: 'menuMessages.insurance',
    description: 'Insurance',
    defaultMessage: 'Försäkringar',
  },
  connectedServices: {
    id: 'menuMessages.connectedServices',
    defaultMessage: 'Anslutna bolag',
  },
  transactions: {
    id: 'menuMessages.transactions',
    defaultMessage: 'Överföring',
  },
  documents: {
    id: 'menuMessages.documents',
    defaultMessage: 'Dokument',
  },
  profile: {
    id: 'menuMessages.profile',
    defaultMessage: 'Inställningar',
  },
  invoices: {
    id: 'menuMessages.invoices',
    description: 'Invoices',
    defaultMessage: 'Fakturor',
  },
  economicProfile: {
    id: 'menuMessages.economicProfile',
    description: 'Economic profile',
    defaultMessage: 'Ekonomisk profil',
  },
  organizationalUnits: {
    id: 'menuMessages.organizationalUnits',
    description: 'Organizational units',
    defaultMessage: 'Avdelningar',
  },
  billing: {
    id: 'menuMessages.billing',
    description: 'Billing configuration',
    defaultMessage: 'Fakturering',
  },
  portfolio: {
    id: 'menuMessages.portfolio',
    description: 'Portfolio',
    defaultMessage: 'Portfölj',
  },
  accountPlan: {
    id: 'menuMessages.accountPlan',
    description: 'Account plan',
    defaultMessage: 'Kontoplan',
  },
  prognosis: {
    id: 'menuMessages.prognosis',
    description: 'Prognosis',
    defaultMessage: 'Prognos',
  },
  strategy: {
    id: 'menuMessages.strategy',
    defaultMessage: 'Strategi',
  },
  pensionTransfer: {
    id: 'menuMessages.pensionTransfer',
    defaultMessage: 'Flytta pension',
  },
  invoiceLines: {
    id: 'menuMessages.invoiceLines',
    defaultMessage: 'Fakturarader',
  },
  administrators: {
    id: 'menuMessages.administrators',
    defaultMessage: 'Administratörer',
  },
  view: {
    id: 'menuMessages.view',
    defaultMessage: `{isAdminView, select,
      true {Växla till användare}
      other {Växla till administratör}
    }`,
  },
  chooseLanguage: {
    id: 'menuMessages.chooseLanguage',
    defaultMessage: 'Välj språk',
  },
  chooseCompany: {
    id: 'menuMessages.chooseCompany',
    defaultMessage: 'Välj företag',
  },
  employmentEvents: {
    id: 'menuMessages.employmentEvents',
    defaultMessage: 'Anställningshändelser',
  },
  insuranceEvents: {
    id: 'menuMessages.insuranceEvents',
    defaultMessage: 'Försäkringshändelser',
  },
});

export const fileMessages = defineMessages({
  fileName: {
    id: 'fileMessages.fileName',
    description: 'Filnamn',
    defaultMessage: 'Filnamn',
  },
  type: {
    id: 'fileMessages.type',
    description: 'Typ',
    defaultMessage: 'Filtyp',
  },
  size: {
    id: 'fileMessages.size',
    description: 'Storlek',
    defaultMessage: 'Storlek',
  },
  selectFile: {
    id: 'fileMessages.selectFile',
    description: 'Select file',
    defaultMessage: 'Välj fil',
  },
  uploadFile: {
    id: 'fileMessages.uploadFile',
    description: 'Ladda upp fil',
    defaultMessage: 'Ladda upp',
  },
  addMoreFiles: {
    id: 'fileMessages.addMoreFiles',
    description: 'Lägg till fler filer',
    defaultMessage: 'Lägg till fler filer',
  },
  rejected: {
    id: 'fileMessages.rejected',
    description: 'Filen kan inte laddas upp',
    defaultMessage: 'Det gick inte att ladda upp filen. Försök igen.',
  },
  fileUploadTitle: {
    id: 'fileMessages.fileUploadTitle',
    description: 'File upload title',
    defaultMessage: 'Dra och släpp fil här',
  },
  fileUploadDescription: {
    id: 'fileMessages.fileUploadDescription',
    defaultMessage: 'Systemet förstår Excel- och CSV-filer.',
  },
  fileUploadErrorTitle: {
    id: 'fileMessages.fileUploadErrorTitle',
    description: 'File upload error title',
    defaultMessage: 'Filen kunde inte laddas upp',
  },
  fileUploadError: {
    id: 'fileMessages.fileUploadError',
    description: 'File upload error',
    defaultMessage: 'Filen kunde inte laddas upp',
  },
  downloadTemplate: {
    id: 'fileMessage.downloadTemplate',
    description: 'Download template',
    defaultMessage: 'Ladda ned vår mall',
  },
  payslipImport: {
    id: 'fileMessage.payslipImport',
    description: 'Payslip upload prompt',
    defaultMessage: 'Importera lönespecifikation',
  },
  payslipUploadDescription: {
    id: 'fileMessage.payslipUploadDescription',
    description: 'Payslip upload description',
    defaultMessage:
      'Systemet stödjer lönespecificationer i filformaten Swedbank (SUS) och Nordea (LON/LON3K).',
  },
  payslipImportErrorTitle: {
    id: 'fileMessage.payslipImportErrorTitle',
    description: 'Payslip import error title',
    defaultMessage: 'Filen kunde inte importeras',
  },
  payslipImportError: {
    id: 'fileMessage.payslipImportError',
    description: 'Payslip import error',
    defaultMessage: 'Vänligen försök igen eller pröva med en annan fil.',
  },
  payslipImportNewFile: {
    id: 'fileMessage.payslipImportNewFile',
    description: 'Payslip import new file',
    defaultMessage: 'Importera ny fil',
  },
});

export const suffixMessages = defineMessages({
  perMonth: {
    id: 'suffixMessages.perMonth',
    description: '/mån',
    defaultMessage: '/mån',
  },
  perYear: {
    id: 'suffixMessages.perYear',
    description: '/år',
    defaultMessage: '/år',
  },
  krPerMonth: {
    id: 'suffixMessages.krPerMonth',
    description: 'kr/mån',
    defaultMessage: 'kr/mån',
  },
  krPerYear: {
    id: 'suffixMessages.krPerYear',
    description: 'kr/år',
    defaultMessage: 'kr/år',
  },
  kr: {
    id: 'suffixMessages.kr',
    description: 'kr',
    defaultMessage: 'kr',
  },
  percentage: {
    id: 'suffixMessages.percentage',
    description: 'percentage',
    defaultMessage: '%',
  },
  years: {
    id: 'suffixMessages.years',
    description: 'years',
    defaultMessage: 'år',
  },
  months: {
    id: 'suffixMessages.months',
    defaultMessage: 'mån',
  },
  age: {
    id: 'suffixMessages.age',
    description: 'age',
    defaultMessage: 'år',
  },
  days: {
    id: 'suffixMessages.days',
    description: 'days',
    defaultMessage: 'dagar',
  },
  pieces: {
    id: 'suffixMessages.pieces',
    description: 'pieces',
    defaultMessage: 'st',
  },
  percentPerYear: {
    id: 'suffixMessages.percentPerYear',
    defaultMessage: '%/år',
  },
  monthly: {
    id: 'suffixMessages.monthly',
    defaultMessage: 'Per månad',
  },
  annually: {
    id: 'suffixMessages.annually',
    defaultMessage: 'Per år',
  },
  lumpSum: {
    id: 'suffixMessages.lumpSum',
    defaultMessage: 'Engångsbelopp',
  },
});

export const frequencySuffixMessages = defineMessages<DurationUnit>({
  years: {
    id: 'frequencySuffixMessages.years',
    defaultMessage: `{count, plural,
        =0 {}
        one {/år}
        two { varannat år}
        other { var #e år}
      }`,
  },
  months: {
    id: 'frequencySuffixMessages.months',
    defaultMessage: `{count, plural,
        =0 {}
        one {/mån}
        two { varannan månad}
        other { var #e månad}
      }`,
  },
  days: {
    id: 'frequencySuffixMessages.days',
    defaultMessage: `{count, plural,
        =0 {}
        one {/dag}
        two { varannan dag}
        other { var #e dag}
      }`,
  },
});

export const termSuffixMessages = defineMessages<DurationUnit>({
  years: {
    id: 'termSuffixMessages.years',
    defaultMessage: `{count, plural,
        =0 {}
        one { i # år}
        other { i # år}
      }`,
  },
  months: {
    id: 'termSuffixMessages.months',
    defaultMessage: `{count, plural,
        =0 {}
        one { i # månad}
        other { i # månader}
      }`,
  },
  days: {
    id: 'termSuffixMessages.days',
    defaultMessage: `{count, plural,
        =0 {}
        one { i # dag}
        other { i # dagar}
      }`,
  },
});

export const timePeriodSuffixMessages = defineMessages<DurationUnit>({
  days: {
    id: 'timePeriodSuffixMessages.days',
    defaultMessage: '{count, plural, one {# dag} other {# dagar}}',
  },
  months: {
    id: 'timePeriodSuffixMessages.months',
    defaultMessage: '{count, plural, one {# månad} other {# månader}}',
  },
  years: {
    id: 'timePeriodSuffixMessages.years',
    defaultMessage: '{count} år',
  },
});

export const signMessages = defineMessages({
  sign: {
    id: 'signMessages.sign',
    description: 'Skriv under',
    defaultMessage: 'Skriv under',
  },
  signed: {
    id: 'signMessages.signed',
    description: 'Signed',
    defaultMessage: 'Påskriven',
  },
  signatures: {
    id: 'signMessages.signatures',
    description: 'Underskrifter',
    defaultMessage: 'Underskrifter',
  },
  signature: {
    id: 'signMessages.signature',
    description: 'Underskrift',
    defaultMessage: `Underskrift`,
  },
  haveSigned: {
    id: 'signMessages.haveSigned',
    description: 'Har skrivit under',
    defaultMessage: 'Har skrivit under',
  },
  haveNotSigned: {
    id: 'signMessages.haveNotSigned',
    description: 'Underskrift saknas',
    defaultMessage: 'Har inte skrivit under',
  },
  signedEmployee: {
    id: 'signMessages.signedEmployee',
    description: 'signed employee',
    defaultMessage: `Underskrift anställd`,
  },
  signedEmployer: {
    id: 'signMessages.signedEmployer',
    description: 'signed employer',
    defaultMessage: `Underskrift arbetsgivaren`,
  },
  administrator: {
    id: 'signMessage.administrator',
    description: 'Authorized representative (admin)',
    defaultMessage: 'Behörig företrädare (administratör)',
  },
  nordeaNodeInfo: {
    id: 'signMessage.nordeaNodeInfo',
    description: 'Nordea Node information',
    defaultMessage: `Nordea Node AB, Box 3158, 103 63 Stockholm. Orgnr: 559067-5673`,
  },
  signWithBankId: {
    id: 'signMessages.signWithBankId',
    description: 'Sign with bank-id',
    defaultMessage: 'Skriv under avtal med BankID',
  },
  signedWithBankId: {
    id: 'signMessage.signedWithBankId',
    description: 'signed with bankId',
    defaultMessage: 'Skrivs under med BankID',
  },
  signBankId: {
    id: 'signMessages.signBankId',
    defaultMessage: 'Skriv under med BankID',
  },
});

export const validationMessages = defineMessages({
  noEmployments: {
    id: 'validationMessages.noEmployments',
    defaultMessage: 'Användaren måste ha en anställning',
  },
  uniqueField: {
    id: 'validationMessages.uniqueField',
    description: 'Every field can only occur once',
    defaultMessage: 'Varje fält kan bara förekomma en gång',
  },
  uniqueType: {
    id: 'validationMessages.uniqueType',
    defaultMessage: 'Varje typ kan bara förekomma en gång',
  },
  mandatoryField: {
    id: 'validationMessages.mandatoryField',
    description: 'Validation message thrown when field is not filled in',
    defaultMessage: 'Obligatoriskt fält',
  },
  isPositiveNumber: {
    id: 'validationMessages.isPositiveNumber',
    description:
      'Validation message thrown when field is not beeing passed positive value',
    defaultMessage: 'Ange ett värde större än 0',
  },
  isNotOver100: {
    id: 'validationMessages.isNotOver100',
    description: 'Validation message thrown when field is exceeding 100',
    defaultMessage: 'Fältet tillåter inte värden över 100',
  },
  isRequiredCheckboxGroup: {
    id: 'validationMessages.isRequiredCheckboxGroup',
    description: 'At least on option must be selected',
    defaultMessage: 'Du måste välja minst ett alternativ',
  },
  isValidEmail: {
    id: 'validationMessages.isValidEmail',
    description: 'Validation message thrown on invalid email input',
    defaultMessage: 'Ogiltig e-postadress',
  },
  isValidNid: {
    id: 'validationMessages.isValidNid',
    description: 'Validation message thrown on invalid personal number input',
    defaultMessage: 'Ogiltigt personnummer, samordningsnummer eller A-nummer',
  },
  isValidFirstNid: {
    id: 'validationMessages.isValidFirstNid',
    defaultMessage: 'Ogiltigt personnummer eller samordningsnummer',
  },
  invalidIdentityNumber: {
    id: 'validationMessages.invalidIdentityNumber',
    defaultMessage: 'Ogiltigt person- eller organisationsnummer',
  },
  isMax30Chars: {
    id: 'validationMessages.isMax30Chars',
    description: 'Validation message thrown on too long input',
    defaultMessage: 'Maximalt 30 tecken',
  },
  isAbove18: {
    id: 'validationMessages.isAbove18',
    description: 'Validation message thrown when to your to sign up',
    defaultMessage:
      'Vår tjänst är endast tillgänglig för dig som är 18 eller äldre',
  },
  isAbove20: {
    id: 'validationMessages.isAbove20',
    description: 'Validation message thrown when field is below 20',
    defaultMessage: 'Dimensionsnummer under 20 är reserverade',
  },
  isValidPhone: {
    id: 'validationMessages.isValidPhone',
    description: 'Validation message thrown when invalid phone format',
    defaultMessage: 'Felaktigt format',
  },
  isValidBirthYear: {
    id: 'validationMessages.isValidBirthYear',
    description: 'Validation message thrown when birth year',
    defaultMessage: 'Ange ett giltigt årtal',
  },
  retirementAgeInterval: {
    id: 'validationMessages.retirementAgeInterval',
    description:
      'Validation message thrown when retirement age is set lower than 60 or higher than 75',
    defaultMessage: 'Pensionsålder måste vara mellan 60 och 75 år',
  },
  isAfterStartDate: {
    id: 'validationMessages.isAfterStartDate',
    defaultMessage:
      'Ange ett avslutningsdatum som är efter startdatum {startDate}',
  },
  isBeforeMinDate: {
    id: 'validationMessages.isBeforeMinDate',
    description:
      'Validation message thrown when date is before the minimum date',
    defaultMessage: 'Ange ett giltigt datum',
  },
  isBeforeStartDate: {
    id: 'validationMessages.isBeforeStartDate',
    description: 'Validation message thrown when end date is before start date',
    defaultMessage: 'Ange ett slutdatum som inte är innan startdatumet',
  },
  invalidEmploymentRate: {
    id: 'validationMessages.invalidEmploymentRate',
    description:
      'Validation message thrown when employment rate is not between 1 - 100',
    defaultMessage: 'Ange sysselsättningsgrad mellan 1 och 100 procent',
  },
  invalidDate: {
    id: 'validationMessages.invalidDate',
    description:
      'Validation message shown for invalid date (it must match format YYYY-MM-DD)',
    defaultMessage: 'Ange ett giltigt datum (ÅÅÅÅ-MM-DD)',
  },
  invalidMonth: {
    id: 'validationMessages.invalidMonth',
    description:
      'Validation message shown for invalid month (it must match format YYYY-MM)',
    defaultMessage: 'Ange en giltig månad (ÅÅÅÅ-MM)',
  },
  accountingItemEffectiveDateError: {
    id: 'validationMessages.accountingItemEffectiveDateError',
    defaultMessage: 'Ny kostnadsallokering kan inte starta före föregående',
  },
  isValidAddress: {
    id: 'validationMessages.isValidAddress',
    defaultMessage:
      'Adressen måste innehålla en adressrad, stad och postnummer',
  },
  isValidCustomerId: {
    id: 'validationMessages.isValidCustomerId',
    defaultMessage: 'Fel format. Kund-ID måste vara antingen 4 eller 5 tecken',
  },
  isMaxTwoFiles: {
    id: 'validationMessages.isMaxTwoFiles',
    defaultMessage: 'Högst två filer är tillåtna.',
  },
  invalidEmailDomain: {
    id: 'validationMessages.invalidEmailDomain',
    defaultMessage:
      'E-postadressens domän är inte tillåten. Endast följande domäner är tillåtna: {domains}',
  },
});

export const formSubmitMessages = defineMessages({
  editBenefitSuccessMessage: {
    id: 'formSubmitMessages.editBenefitSuccessMessage',
    description: 'Benefit has been updated',
    defaultMessage: 'Förmånen har uppdaterats',
  },
  editContactInformationSubmitSuccess: {
    id: 'formSubmitMessages.editContactInformationSubmitSuccess',
    description: 'Uppgifterna har uppdaterats',
    defaultMessage: 'Uppgifterna har sparats',
  },
  editEmployeeBenefitPackagesSubmitSuccess: {
    id: 'formSubmitMessages.editEmployeeBenefitPackagesSubmitSuccess',
    description:
      'Success message shown when employee has successfully been added to a benefit package',
    defaultMessage: 'Förmånsgrupp har uppdaterats',
  },
  updateEmployeeAdditionalSubmitError: {
    id: 'formSubmitMessages.updateEmployeeAdditionalSubmitError',
    description:
      'Kunde inte uppdatera {field}, vänligen försöker igen senare eller kontakta kundtjänst',
    defaultMessage:
      'Kunde inte uppdatera {field}, vänligen försöker igen senare eller kontakta kundtjänst',
  },
  updateEmployeeAdditionalSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeAdditionalSubmitSuccess',
    description: '{field} har uppdaterats',
    defaultMessage: '{field} har uppdaterats',
  },
  updateEmployeeCostCenterSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeCostCenterSubmitSuccess',
    description: 'Kostnadsställe har uppdaterats',
    defaultMessage: 'Kostnadsställe har sparats',
  },
  updateEmployeeSalarySubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeSalarySubmitSuccess',
    description: 'Lönen har uppdaterats',
    defaultMessage: 'Lön har sparats',
  },
  updateEmployeeStatusSubmitSuccess: {
    id: 'formSubmitMessages.updateEmployeeStatusSubmitSuccess',
    description: 'Status har uppdaterats',
    defaultMessage: 'Status har sparats',
  },
  deleteEmployeeStatusSubmitSuccess: {
    id: 'formSubmitMessages.deleteEmployeeStatusSubmitSuccess',
    description: 'Message saying that membership status has been deleted',
    defaultMessage: 'Status har tagits bort',
  },
  updateFixedPensionPremiumSubmitSuccess: {
    id: 'formSubmitMessages.updateFixedPensionPremiumSubmitSuccess',
    description:
      'Submission success message for updating fixed pension premium',
    defaultMessage: 'Pensionspremie har uppdaterats',
  },
  createOfferSubmitSucess: {
    id: 'formSubmitMessages.createOfferSubmitSucess',
    description: 'Ditt erbjudande har skapats',
    defaultMessage: 'Erbjudandet har sparats',
  },
  createdBenefitPackageMessageText: {
    id: 'formSubmitMessages.createdBenefitPackageMessageText',
    description: 'har skapats',
    defaultMessage: 'Ny förmånsgrupp {name} har sparats',
  },
  deleteEmployeeStatusSubmitError: {
    id: 'formSubmitMessages.deleteEmployeeStatusSubmitError',
    description: 'Message saying that membership status could not be deleted',
    defaultMessage: 'Status kunde inte tas bort',
  },
  updateCompanySuccess: {
    id: 'formSubmitMessages.updateCompanySuccess',
    description: 'Företagets uppgifter har uppdaterats',
    defaultMessage: '{companyName} har uppdaterats',
  },
  addEmployeeSubmitSuccess: {
    id: 'formSubmitMessages.addEmployeeSubmitSuccess',
    description: 'Personen har skapats',
    defaultMessage: 'Den anställda har lagts till',
  },
  createNewsSuccessText: {
    id: 'formSubmitMessages.createNewsSuccessText',
    description: 'Artikeln har skapats',
    defaultMessage: '{title} har sparats',
  },
  deleteNewsSubmitError: {
    id: 'formSubmitMessages.deleteNewsSubmitError',
    description: 'Submit error of deleting internal news',
    defaultMessage: 'Det gick inte att ta bort artikeln. Försök igen.',
  },
  deleteNewsSuccessText: {
    id: 'formSubmitMessages.deleteNewsSuccessText',
    description: 'News has been deleted',
    defaultMessage: 'Artikeln har tagits bort',
  },
  updateNewsSuccessTitle: {
    id: 'formSubmitMessages.updateNewsSuccessTitle',
    description: 'The title of the message when internal news is updated',
    defaultMessage: 'Artikel sparad',
  },
  updateNewsSuccessText: {
    id: 'formSubmitMessages.updateNewsSuccessText',
    description: '{title} is updated',
    defaultMessage: '{title} har sparats',
  },
  signupSubmitError: {
    id: 'formSubmitMessages.signupSubmitError',
    description: 'Error when the submit in signup fails',
    defaultMessage: 'Det gick inte att bli kund. Försök igen.',
  },
  benefitAddedToBenefitPackagesSuccess: {
    id: 'formSubmitMessages.benefitAddedToBenefitPackagesSuccess',
    defaultMessage: `Förmånen har lagts till i {count, plural, =1 {1 förmånsgrupp} other {# förmånsgrupper}}`,
  },
  benefitsAddedToBenefitPackageSuccess: {
    id: 'formSubmitMessages.benefitsAddedToBenefitPackageSuccess',
    defaultMessage: `{count, plural, =1 {1 förmån} other {# förmåner}} har lagts till i förmånsgruppen`,
  },
  offerFormSubmissionSuccess: {
    id: 'formSubmitMessages.offerFormSubmissionSuccess',
    description: 'Success message shown when an offer form is submitted',
    defaultMessage: 'Formuläret har skickats',
  },
  offerFormSubmissionSuccessBook: {
    id: 'formSubmitMessages.offerFormSubmissionSuccessBook',
    description: 'Success message shown when an offer form is submitted',
    defaultMessage: 'Formuläret har skickats, kom ihåg att boka tid',
  },
  updateFileImportSuccess: {
    id: 'formSubmitMessages.updateFileImportSuccess',
    description: 'Success message shown when an import is updated',
    defaultMessage: 'Importen har uppdaterats',
  },
  frontendSubmitError: {
    id: 'formSubmitMessages.frontendSubmitError',
    description:
      'Error shown when the frontend for some reason won´t submit a form',
    defaultMessage: 'Något gick fel. Försök igen',
  },
  book: {
    id: 'formSubmitMessages.book',
    description: 'Book appointment',
    defaultMessage: 'Boka tid',
  },
  bookSynsamDescr: {
    id: 'formSubmitMessages.bookSynsamDescr',
    defaultMessage:
      'Gå till Synsams webbsida och boka tid i den butik som du valde.',
  },
  addEmploymentSuccess: {
    id: 'formSubmitMessages.addEmploymentSuccess',
    defaultMessage: 'Anställningen har lagts till',
  },
  updateEmploymentSuccess: {
    id: 'formSubmitMessages.updateEmploymentSuccess',
    defaultMessage: 'Anställningen har ändrats',
  },
  onSalaryExchangeRequestSent: {
    id: 'formSubmitMessages.onSalaryExchangeRequestSent',
    defaultMessage: 'Din ansökan har tagits emot',
  },
  addEmploymentActivitySuccess: {
    id: 'formSubmitMessages.addEmploymentActivitySuccess',
    defaultMessage: `{type, select,
      OFF_DUTY {Tjänstledighet}
      PARENTAL_LEAVE {Föräldraledighet}
      other {Fel}
    } har lagts till`,
  },
  editEmploymentActivitySuccess: {
    id: 'formSubmitMessages.editEmploymentActivitySuccess',
    defaultMessage: `{type, select,
      OFF_DUTY {Tjänstledighet}
      PARENTAL_LEAVE {Föräldraledighet}
      other {Fel}
    } har ändrats`,
  },
  confirmExitWizard: {
    id: 'formSubmitMessages.confirmExitWizard',
    defaultMessage: 'Du har osparade ändringar som kommer att förloras.',
  },
  birthdateMissing: {
    id: 'formSubmitMessages.birthdateMissing',
    defaultMessage:
      'Du måste ha ett födelsedatum för att gå vidare. För mer information, kontakta Nordea Nodes kundtjänst.',
  },
  pinMissing: {
    id: 'formSubmitMessages.pinMissing',
    defaultMessage:
      'Du måste ha ett svenskt personnummer för att gå vidare. För mer information, kontakta Nordea Nodes kundtjänst.',
  },
  discardChanges: {
    id: 'formSubmitMessages.discardChanges',
    defaultMessage: 'Lämna sidan?',
  },
});

export const fieldRichTextMessages = defineMessages({
  add: commonMessages.add,
  cancel: formMessages.cancel,
  undo: {
    id: 'fieldRichTextMessages.undo',
    description: 'Undo',
    defaultMessage: 'Ångra',
  },
  redo: {
    id: 'fieldRichTextMessages.redo',
    description: 'Redo',
    defaultMessage: 'Gör om',
  },
  removeFormatting: {
    id: 'fieldRichTextMessages.removeFormatting',
    description: 'Remove formatting',
    defaultMessage: 'Rensa formattering',
  },
  h1: {
    id: 'fieldRichTextMessages.h1',
    description: 'H1',
    defaultMessage: 'Rubrik 1',
  },
  h2: {
    id: 'fieldRichTextMessages.h2',
    description: 'H2',
    defaultMessage: 'Rubrik 2',
  },
  h3: {
    id: 'fieldRichTextMessages.h3',
    description: 'H3',
    defaultMessage: 'Rubrik 3',
  },
  h5: {
    id: 'fieldRichTextMessages.h5',
    description: 'H5',
    defaultMessage: 'Ingress',
  },
  blockquote: {
    id: 'fieldRichTextMessages.blockquote',
    description: 'Blockquote',
    defaultMessage: 'Blockcitat',
  },
  code: {
    id: 'fieldRichTextMessages.code',
    description: 'Code',
    defaultMessage: 'Kod',
  },
  blocktype: {
    id: 'fieldRichTextMessages.blocktype',
    description: 'Block Type',
    defaultMessage: 'Block',
  },
  normal: {
    id: 'fieldRichTextMessages.normal',
    description: 'Normal',
    defaultMessage: 'Normal',
  },
  bold: {
    id: 'fieldRichTextMessages.bold',
    description: 'Bold',
    defaultMessage: 'Fetstil',
  },
  italic: {
    id: 'fieldRichTextMessages.italic',
    description: 'Italic',
    defaultMessage: 'Kursiv',
  },
  underline: {
    id: 'fieldRichTextMessages.underline',
    description: 'Underline',
    defaultMessage: 'Understrykning',
  },
  superscript: {
    id: 'fieldRichTextMessages.superscript',
    description: 'Superscript',
    defaultMessage: 'Upphöjt',
  },
  subscript: {
    id: 'fieldRichTextMessages.subscript',
    description: 'Subscript',
    defaultMessage: 'Nedsänkt',
  },
  linkTitle: {
    id: 'fieldRichTextMessages.linkTitle',
    description: 'Link Title',
    defaultMessage: 'Rubrik',
  },
  linkTarget: {
    id: 'fieldRichTextMessages.linkTarget',
    description: 'Link Target',
    defaultMessage: 'Adress',
  },
  linkTargetOption: {
    id: 'fieldRichTextMessages.linkTargetOption',
    description: 'Open link in new window',
    defaultMessage: 'Öppna i nytt fönster',
  },
  link: {
    id: 'fieldRichTextMessages.link',
    description: 'link',
    defaultMessage: 'Länk',
  },
  unlink: {
    id: 'fieldRichTextMessages.unlink',
    description: 'Unlink',
    defaultMessage: 'Ta bort länk',
  },
  unordered: {
    id: 'fieldRichTextMessages.unordered',
    description: 'Unordered',
    defaultMessage: 'Osorterad lista',
  },
  ordered: {
    id: 'fieldRichTextMessages.ordered',
    description: 'Ordered',
    defaultMessage: 'Sorterad lista',
  },
  embedIframe: {
    id: 'fieldRichTextMessages.embedIframe',
    description: 'Embed iframe',
    defaultMessage: 'Bädda in innehåll',
  },
  embedHeight: {
    id: 'fieldRichTextMessages.embedHeight',
    defaultMessage: 'Höjd',
  },
  embedWidth: {
    id: 'fieldRichTextMessages.embedWidth',
    defaultMessage: 'Bredd',
  },
});

export const tagFieldMessages = defineMessages({
  errorMsg: {
    id: 'tagFieldMessages.errorMsg',
    description: 'Taggen du försökte ligga till finns redan',
    defaultMessage: 'Kategorin finns redan',
  },
});

export const importMessages = defineMessages({
  statusFailureWithCount: {
    id: 'importMessages.statusFailureWithCount',
    description: 'Status failure with count',
    defaultMessage: 'Importfel ({count})',
  },
  importFile: {
    id: 'importMessages.importFile',
    description: 'Import file',
    defaultMessage: 'Importera fil',
  },
  downloadFile: {
    id: 'importMessages.downloadFile',
    description: 'Download file',
    defaultMessage: 'Ladda ned fil',
  },
  uploadingFile: {
    id: 'importMessages.uploadingFile',
    description: 'Uploading file',
    defaultMessage: 'Laddar upp fil...',
  },
  skipImport: {
    id: 'importMessages.skipImport',
    description: 'Skip import',
    defaultMessage: 'Importera inte',
  },
  updateColumnsDescription: {
    id: 'importMessages.updateColumnsDescription',
    description: 'Update columns description',
    defaultMessage:
      'Ange hur kolumnerna i filen ska importeras. Välj "Importera inte" om du vill hoppa över en kolumn.',
  },
  noEmployeesImportedText: {
    id: 'importMessages.noEmployeesImportedText',
    description: 'Text describing that no employees imported yet',
    defaultMessage: 'Du har inte ännu importerat några anställda.',
  },
  orgUnits: menuMessages.organizationalUnits,
  createMissingOrgUnitsLabel: {
    id: 'importMessages.createMissingOrgUnitsLabel',
    description: 'Create organizational units automatically',
    defaultMessage:
      'Skapa avdelningar automatiskt baserat på värden i kolumn för Avdelningar',
  },
  benefitPackageEffectiveDateTitle: {
    id: 'importMessages.benefitPackageEffectiveDateTitle',
    description: 'Benefit package effective date title',
    defaultMessage: 'Förval för förmånsgrupp',
  },
  benefitPackageEffectiveDateDescription: {
    id: 'importMessages.benefitPackageEffectiveDateDescription',
    description: 'Benefits package effective date description',
    defaultMessage:
      'Kolumnen ”Förmånsgrupp fr.o.m.” bestämmer när en medarbetare tilldelas en förmånsgrupp. Om värdet saknas gäller istället det förvalda värdet. För nyanställda kan du ange att anställningsdatumet ska vara förval.',
  },
  fallbackBenefitPackageEffectiveDateLabel: {
    id: 'importMessages.fallbackBenefitPackageEffectiveDateLabel',
    description: 'Fallback benefit package effective date label',
    defaultMessage: 'Förvalt värde för ”Förmånsgrupp fr.o.m.”',
  },
  fallbackBenefitPackageEffectiveDateHelperText: {
    id: 'importMessages.fallbackBenefitPackageEffectiveDateHelperText',
    description: 'Fallback benefit package effective date helper text',
    defaultMessage: 'Används endast om värde saknas för en medarbetare',
  },
  benefitsStartWithEmploymentsLabel: {
    id: 'importMessages.benefitsStartWithEmploymentsLabel',
    description: 'Benefits start with employment label',
    defaultMessage: 'Använd anställningsdatum som förval för nyanställda',
  },
  importColumnsError: {
    id: 'importMessages.importColumnsError',
    description: 'Import columns error',
    defaultMessage: 'Fälten för importfilen kunde inte läsas',
  },
  updateColumnsError: {
    id: 'importMessages.updateColumnsError',
    description: 'Update columns error',
    defaultMessage:
      'Någonting gick fel. Vänligen säkerställ att kolumnerna är rätt matchade.',
  },
  invalidColumnsError: {
    id: 'importMessages.invalidColumnsError',
    description: 'Invalid columns error',
    defaultMessage:
      'En eller flera kolumner är inte korrekt matchade. Rader med fel är markerade. Vänligen försök igen.',
  },
  previewPageDescription: {
    id: 'importMessages.previewPageDescription',
    description: 'Preview page description',
    defaultMessage:
      'Följande information kommer att importeras. Vänligen bekräfta och godkänn för att importera.',
  },
  emptyStateTitle: {
    id: 'importMessages.emptyStateTitle',
    description: 'Empty state title',
    defaultMessage: 'Inga förändringar',
  },
  emptyStateDescription: {
    id: 'importMessages.emptyStateDescription',
    description: 'Empty state description',
    defaultMessage:
      'Det finns inga förändringar i filen du laddat upp.\n Pröva att matcha kolumner igen.',
  },
  emptyStateButtonText: {
    id: 'importMessages.emptyStateButtonText',
    description: 'Empty state button text',
    defaultMessage: 'Matcha Kolumner',
  },
  previewTitleEmployees: {
    id: 'importMessages.previewTitleEmployees',
    description: 'Preview title employees',
    defaultMessage: 'Anställningar',
  },
  previewTitleNewEmployees: {
    id: 'importMessages.previewTitleNewEmployees',
    description: 'Preview title new employees',
    defaultMessage: 'Nyanställda',
  },
  previewTitleTerminated: {
    id: 'importMessages.previewTitleTerminated',
    description: 'Preview title terminated',
    defaultMessage: 'Avslutade',
  },
  previewTitleSalaryUpdates: {
    id: 'importMessages.previewTitleSalaryUpdates',
    description: 'Preview title salary updates',
    defaultMessage: 'Löneändringar',
  },
  previewTitleEmploymentActivity: {
    id: 'importMessages.previewTitleEmploymentActivity',
    description: 'Preview title employment activity',
    defaultMessage: 'Ny frånvaro',
  },
  previewTitleUnchanged: {
    id: 'importMessages.previewTitleUnchanged',
    description: 'Preview title unchanged',
    defaultMessage: 'Oförändrade rader',
  },
  employeeStatus: {
    id: 'importMessages.employeeStatus',
    description: 'employee status',
    defaultMessage: 'förändring',
  },
  newEmployee: {
    id: 'importMessages.newEmployee',
    description: 'New employee',
    defaultMessage: 'Nyanställd',
  },
  updatedEmployee: {
    id: 'importMessages.updatedEmployee',
    description: 'Updated employee',
    defaultMessage: 'Ändrad',
  },
  terminatedEmployee: {
    id: 'importMessages.terminatedEmployee',
    description: 'Terminated employee',
    defaultMessage: 'Avslutad',
  },
  updateLoadingTitle: {
    id: 'importMessages.updateLoadingTitle',
    description: 'Update loading title',
    defaultMessage: 'Dina anställda läggs till nu!',
  },
  updateLoadingDescription: {
    id: 'importMessages.updateLoadingDescription',
    description: 'Update loading description',
    defaultMessage: 'Du skickas tillbaka till översikten...',
  },
  generateChangesTitle: {
    id: 'importMessages.generateChangesTitle',
    description: 'Generate changes title',
    defaultMessage: 'Vi ser över dina ändringar!',
  },
  generateChangeDescription: {
    id: 'importMessages.generateChangeDescription',
    description: 'Generate changes description',
    defaultMessage: 'Du skickas vidare till översikten...',
  },
  generateColumnChangeDescription: {
    id: 'importMessages.generateColumnChangeDescription',
    description: 'Generate column change description',
    defaultMessage: 'Du skickas vidare till kolumnmappning...',
  },
  updateErrorDescription: {
    id: 'importMessages.updateErrorDescription',
    description: 'Update error description',
    defaultMessage:
      'En eller flera rader kunde inte importeras från filen, rader med fel är markerade. Vänligen försök igen eller pröva med en annan fil.',
  },
  updateSuccessDescription: {
    id: 'importMessages.updateSuccessDescription',
    description: 'Update success description',
    defaultMessage: 'Filen har importerats utan fel',
  },
  toOverView: {
    id: 'importMessages.toOverView',
    description: 'To overview',
    defaultMessage: 'Till översikt',
  },
  previewTableTitle: {
    id: 'importMessages.previewTableTitle',
    description: 'Preview table title',
    defaultMessage: `{pending, select,
      true {Ändringar}
      other {Genomförda ändringar}}`,
  },
  errorTableRowColumn: {
    id: 'importMessages.errorTableRowColumn',
    description: 'Error table row column',
    defaultMessage: 'Rad',
  },
  errorTableErrorColumn: {
    id: 'importMessages.errorTableErrorColumn',
    description: 'Error table error column',
    defaultMessage: 'Fel',
  },
  remunerationEffectiveDate: {
    id: 'importMessages.remunerationEffectiveDate',
    description: 'Remuneration effective date',
    defaultMessage: '{customType} gäller fr.o.m.',
  },
  balanceEffectiveDate: {
    id: 'importMessages.balanceEffectiveDate',
    description: 'Balance effective date',
    defaultMessage: '{customType} datum',
  },
  overlappingEmploymentsLabel: {
    id: 'importMessages.overlappingEmploymentsLabel',
    defaultMessage: `Importen innehåller anställda som redan har en aktiv anställning på ett annat bolag`,
  },
  overlappingEmploymentsLede: {
    id: 'importMessages.overlappingEmploymentsLede',
    defaultMessage: `<p>Det kan finnas giltiga skäl till att medarbetare har aktiva anställningar i mer än ett bolag i  systemet. Säkerställ att detta är enligt förväntan. Om medarbetare har bytt anställning från ett bolag till ett annat behöver ett slutdatum sättas i det tidigare bolaget för att avsluta anställningen och dess eventuella förmåner.</p><p>Om du vill ange ett slutdatum gör du det under Anställda.</p>`,
  },
  missingEmploymentsLabel: {
    id: 'importMessages.missingEmploymentsLabel',
    defaultMessage: `Importen innehåller inte information om alla medarbetare med en aktiv anställning på företaget`,
  },
  missingEmploymentsLede: {
    id: 'importMessages.missingEmploymentsLede',
    defaultMessage: `<p>Dessa medarbetare har en aktiv anställning men finns inte med i denna import. Om en anställning inte längre ska vara aktiv behöver ett slutdatum sättas för att avsluta anställningen och dess eventuella förmåner. Att enbart genomföra en import utan en medarbetare avslutar inte anställningen i systemet</p><p>Om du vill ange ett slutdatum gör du det under Anställda.</p>`,
  },
  inactiveEmploymentsLabel: {
    id: 'importMessages.inactiveEmploymentsLabel',
    defaultMessage: `Importen innehåller ändringar för medarbetare som saknar aktiv anställning för det angivna datumet`,
  },
  inactiveEmploymentsLede: {
    id: 'importMessages.inactiveEmploymentsLede',
    defaultMessage: `
      <p>Dessa medarbetare har ej en aktiv anställning för en eller flera förändringar som finns i importen. Importen kommer genomföra dessa förändringar även för medarbetare med inaktiva anställningar. Ändringen påverkar dock inte några pensions- eller riskförsäkringar eftersom att det inte finns en aktiv anställning för det valda datumet och därmed inga försäkringar.</p>
      <p>Om personen är felaktigt avslutad i systemet, se över start- och slutdatum under Anställda. Du bör även se över systemet som skapar import-filen så att rätt datum är angivet.</p>
    `,
  },
  newerImportWithChanges: {
    id: 'importMessages.newerImportWithChanges',
    defaultMessage:
      'Importen kan inte godkännas eftersom <newerImportWithChangesHref>följande import är skapad senare</newerImportWithChangesHref> med status "Väntar"',
  },
});

export const importStatusMessages = defineMessages<ImportStatus>({
  CANCELED: {
    id: 'importStatusMessages.CANCELED',
    defaultMessage: 'Avbruten',
  },
  COMPLETE: {
    id: 'importStatusMessages.COMPLETE',
    defaultMessage: 'Importerad',
  },
  FAILURE: {
    id: 'importStatusMessages.FAILURE',
    defaultMessage: 'Importfel',
  },
  PENDING: {
    id: 'importStatusMessages.PENDING',
    defaultMessage: 'Väntar',
  },
  PROCESSING: {
    id: 'importStatusMessages.PROCESSING',
    defaultMessage: 'Bearbetas...',
  },
  WORKING: {
    id: 'importStatusMessages.WORKING',
    defaultMessage: 'Import pågår',
  },
  NO_CHANGES: {
    id: 'importStatusMessages.NO_CHANGES',
    defaultMessage: 'Inga ändringar',
  },
});
export const emptyStateMessages = defineMessages({
  noReports: {
    id: 'emptyStateMessages.noReports',
    description: 'Empty state message saying that there are no reports',
    defaultMessage: 'Det finns inga rapporter att visa',
  },
});
