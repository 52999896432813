import { commonMessages } from 'common/messages';
import {
  totalCompensationLegendMessages,
  totalCompensationMessages,
} from 'common/messages/total-compensation';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { GraphItemId } from './aggregate-total-comp-chart-data';

interface Args {
  hasSalaryExchange: boolean;
  label: GraphItemId;
}

export const mapTotalCompLegend = ({ label, hasSalaryExchange }: Args) => (
  <FormattedMessage
    messages={totalCompensationLegendMessages}
    select={label}
    values={{
      cashSalaryMessage: hasSalaryExchange ? (
        <FormattedMessage
          {...totalCompensationMessages.salaryAfterSalaryExchange}
        />
      ) : (
        <FormattedMessage {...commonMessages.monthlySalary} />
      ),
    }}
  />
);
