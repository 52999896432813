import bankIdLogo from 'assets/bankIdLogo.svg';
import bankIdLogoDisabled from 'assets/bankIdLogoDisabled.svg';
import bankIdLogoWhite from 'assets/bankIdLogoWhite.svg';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

const BankIdLogo = styled.img`
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 1rem;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  label: MessageDescriptor;
  disabled?: boolean;
  filled?: boolean;
}

export const BankIdButtonLabel: React.FC<Props> = ({
  label,
  disabled,
  filled,
}) => (
  <LabelWrapper>
    <BankIdLogo
      src={
        disabled ? bankIdLogoDisabled : filled ? bankIdLogo : bankIdLogoWhite
      }
      role="presentation"
      alt=""
    />
    <FormattedMessage {...label} />
  </LabelWrapper>
);
