import {
  payslipCardQuery_Query,
  payslipOverviewDetails as Payslip,
} from 'app/apollo/graphql/types';

interface PayslipCardData {
  payslip: Payslip;
}

/**
 * Retrieves the relevant payslip card data from the query response
 * or returns null if the user has no access to payslips.
 */
export const getPayslipCardData = (
  data?: payslipCardQuery_Query,
): PayslipCardData | null => {
  if (!data?.payslips?.edges.length) {
    return null;
  }

  const payslip = data.payslips.edges.map(({ node }) => node).shift();

  if (!payslip) {
    return null;
  }

  return { payslip };
};
