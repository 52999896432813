const getRoundedValue = (value: number, total: number) =>
  Math.round((value / total) * 100);

export const getPercentageValue = (values: number[]) => {
  const total = values.reduce((v, acc) => v + acc, 0);
  const percentageValues = values.map((value, i) => {
    if (value) {
      return i === values.length - 1
        ? 100 -
            values
              .slice(0, -1)
              .reduce((a, b) => a + getRoundedValue(b, total), 0)
        : getRoundedValue(value, total);
    }

    return 0;
  });

  return percentageValues;
};
