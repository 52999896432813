import {
  defaultTheme,
  LabelMedium,
  MenuItem,
  NavigationBar as NavigationBarUI,
} from '@frontend/ui';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import { getAdminNavItems, getUserNavItems } from 'components/NavigationRail';
import { NavItem } from 'components/NavItem';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import styled from 'styled-components';
import { isAdminView } from 'utils/is-admin-view';

const NavigationBarComponent = styled(NavigationBarUI)`
  padding-bottom: env(safe-area-inset-bottom, 0.75rem);
`;

interface NavigationBarProps {
  zIndex?: number;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({ zIndex }) => {
  const { isDetailScreen } = useNavigationContext();
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.tablet * 16);

  const _navItems = isAdminView()
    ? currentCompany
      ? getAdminNavItems(currentCompany)
      : []
    : getUserNavItems(currentCompany);

  const navItems = _navItems.filter(
    ({ show, hideOnNavBar }) => !!show && !hideOnNavBar,
  );

  if (!navItems.length) {
    return null;
  }

  const hide = isDetailScreen || !isSmallScreen;

  return (
    <NavigationBarComponent hide={hide} zIndex={zIndex} aria-hidden={hide}>
      {navItems.map(({ label, link, exact, icon }, index) => (
        <NavItem
          key={index}
          to={link}
          exact={exact}
          activeClassName="activeMenu"
          role="menuitem"
        >
          <MenuItem navigation icon={icon} role="none" />
          <LabelMedium>{label}</LabelMedium>
        </NavItem>
      ))}
    </NavigationBarComponent>
  );
};
