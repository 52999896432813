import { defineMessages } from 'react-intl';

export const unsubscribeMessages = defineMessages({
  toNordeaNode: {
    id: 'unsubscribe.toNordeaNode',
    defaultMessage: 'Gå till nordeanode.se',
  },
  successDescription: {
    id: 'unsubscribe.successDescription',
    defaultMessage:
      'Du kommer inte längre att få marknadsföringsmejl från Nordea Node. Notera att du fortfarande kan komma att få servicemeddelanden från oss.',
  },
  successTitle: {
    id: 'unsubscribe.successTitle',
    defaultMessage: 'Du är nu avregistrerad!',
  },
  unsubscribe: {
    id: 'unsubscribe',
    defaultMessage: 'Avregistrera',
  },
  toStart: {
    id: 'unsubscribe.toStart',
    defaultMessage: 'Till startsidan',
  },
  title: {
    id: 'unsubscribe.title',
    defaultMessage: 'Det är tråkigt att du lämnar oss',
  },
  description: {
    id: 'unsubscribe.description',
    defaultMessage:
      'Du kan avregistrera dig från framtida marknadsföringsmejl från Nordea Node genom att klicka på “Avregistrera” nedan. Notera att du fortfarande kan komma att få servicemeddelanden från oss.',
  },
  email: {
    id: 'unsubscribe.email',
    defaultMessage: 'E-post: {email}',
  },
});
