import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  signupFriMutation,
  signupFriMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'common/messages';
import { upgradeFriMessages } from 'common/messages/upgrade-fri';
import { SIGNUP_FRI_MUTATION } from 'common/mutations';
import { useIntl } from 'components/formats';
import { CURRENT_USER_CONTEXT_QUERY } from 'contexts/current-user/graphql/queries';
import { useNotification } from 'features/notifications';
import { FormValues } from 'features/upgrade-fri/index';
import ReactGA from 'react-ga4';

interface Submit {
  submit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [signupFri, { error: submissionError }] = useMutation<
    signupFriMutation,
    signupFriMutationVariables
  >(SIGNUP_FRI_MUTATION, {
    errorPolicy: 'all',
    refetchQueries: [CURRENT_USER_CONTEXT_QUERY],
    awaitRefetchQueries: true,
  });

  const submit = async (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);

    try {
      const res = await signupFri({
        variables: { input: values },
      });

      if (!res.data?.signupFri2) {
        throw new Error();
      }

      ReactGA.event({
        category: 'Onboarding - Fri',
        action: 'Upgrade success',
      });

      setSubmitting(false);

      send({
        message: formatMessage(upgradeFriMessages.upgradeFriSuccess),
        type: 'success',
      });
    } catch {
      ReactGA.event({
        category: 'Onboarding - Fri',
        action: 'Upgrade error',
      });

      setErrors({
        submissionError: formatMessage(formSubmitMessages.frontendSubmitError),
      });
    }
  };

  return { submit, submissionError };
};
