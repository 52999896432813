import { FormattedMessageProps } from 'components/formats';
import { IllustrationType } from 'components/TransitionOverlay';
import React, { useContext, useMemo, useState } from 'react';

interface Action {
  action: () => void;
  label: React.ReactNode;
}

export type OnComplete = (args: { action: () => void }) => void;

export interface Transition {
  illustration: IllustrationType;
  title: FormattedMessageProps | string;
  cancelAction?: Action;
  description?: React.ReactNode;
  modal?: (onComplete: OnComplete) => React.ReactNode;
  /**
   * Don't fade in transition screen background.
   * Simulates transition screen on content free standalone route.
   */
  opaqueFadeIn?: boolean;
  /**
   * Persist transition overlay after primary action has executed.
   * The responsibility of clearing the transition state is handed of
   * to some other transition.
   */
  persistOnPrimary?: boolean;
  primaryAction?: Action;
  progress?: number;
  secondaryAction?: Action;
}
interface TransitionOverlayProps {
  clearTransition: () => void;
  startTransition: (transition: Transition) => void;
  updateTransition: (transition: Partial<Transition>) => void;
  transition?: Transition | null;
}

export const TransitionOverlayContext =
  React.createContext<TransitionOverlayProps>({
    startTransition: (transition: Transition) => transition,
    updateTransition: (transition: Partial<Transition>) => transition,
    clearTransition: () => null,
  });

interface Props {
  children: React.ReactNode;
}
export const TransitionOverlayProvider: React.FC<Props> = ({ children }) => {
  const [transition, set] = useState<Transition | null>(null);

  const startTransition = (_transition: Transition) => set(_transition);
  const updateTransition = (_transition: Partial<Transition>) =>
    set(prevState =>
      prevState
        ? {
            ...prevState,
            ..._transition,
          }
        : null,
    );
  const clearTransition = () => set(null);
  const value = useMemo(
    () => ({
      startTransition,
      updateTransition,
      clearTransition,
      transition,
    }),
    [transition],
  );

  return (
    <TransitionOverlayContext.Provider value={value}>
      {children}
    </TransitionOverlayContext.Provider>
  );
};

export const useTransitionOverlay = (): TransitionOverlayProps =>
  useContext(TransitionOverlayContext);
