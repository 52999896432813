import { useFormikContext } from '@frontend/formik';
import {
  changeBenefitPackageQuery,
  changeBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import { useCurrentUser } from 'contexts/current-user';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { FormValues } from '../..';
import { CHANGE_BENEFIT_PACKAGE_QUERY } from '../../graphql/queries';

export const ConfirmHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.confirmChangeBenefitPackage} />
);

export const ConfirmBody: React.FC = () => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { values } = useFormikContext<FormValues>();

  const companyId = currentCompany?.id;
  const userAccountId = values.userAccount?.value;

  const { data } = useQuery<
    changeBenefitPackageQuery,
    changeBenefitPackageQueryVariables
  >(CHANGE_BENEFIT_PACKAGE_QUERY, {
    fetchPolicy: 'cache-only',
    variables: {
      companyId: companyId ?? '',
      userAccountId: userAccountId ?? '',
    },
  });

  const currentBenefitPackage = data?.membership?.membershipBenefitPackage;
  const newBenefitPackage = data?.company?.benefitPackages?.edges.find(
    ({ node }) => node.id === values.benefitPackage,
  )?.node;

  return (
    <p>
      <FormattedMessage
        {...(currentBenefitPackage
          ? adminShortcutMessages.confirmChangeBenefitPackageDescription
          : adminShortcutMessages.confirmChangeBenefitPackageNoCurrentDescription)}
        values={{
          currentBenefitPackage: currentBenefitPackage?.benefitPackage.name,
          newBenefitPackage: newBenefitPackage?.name,
          employee: values.userAccount?.label,
          effectiveDate: new Date(values.effectiveDate),
        }}
      />
    </p>
  );
};
