import { useMutation } from '@apollo/client';
import {
  updateUserAccountMutation,
  updateUserAccountMutationVariables,
} from 'app/apollo/graphql/types';
import {
  getLocale as getBrowserStoreLocale,
  setLocale as setBrowserStoreLocale,
} from 'app/browser-store';
import { UPDATE_USER_ACCOUNT_MUTATION } from 'common/mutations';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';

import formats from './configure-locale';

export type EffectiveLocale = 'sv-SE' | 'en-US';

interface IntlProviderContext {
  setLocale: (locale: EffectiveLocale, id?: string) => void;
  locale?: EffectiveLocale;
}

/* eslint-disable import/no-unresolved */
const messagesFiles = {
  'sv-SE': import('../../lang/input_sv.json'),
  'en-US': import('../../lang/input_en.json'),
};

const getNearestLocale = (): EffectiveLocale => {
  const shortLocales = Array.from(navigator.languages).map(l =>
    l.indexOf('-') !== -1 ? l.split('-')[0] : l,
  );

  const svIndex = shortLocales.indexOf('sv');
  const enIndex = shortLocales.indexOf('en');
  return svIndex !== -1
    ? enIndex !== -1
      ? svIndex < enIndex
        ? 'sv-SE'
        : 'en-US'
      : 'sv-SE'
    : enIndex !== -1
      ? 'en-US'
      : 'sv-SE';
};

export const IntlContext = React.createContext<IntlProviderContext>({
  setLocale: (locale: EffectiveLocale) => locale,
});

interface Props {
  children: React.ReactNode;
}

export const IntlProviderWrapper: React.FC<Props> = ({ children }) => {
  const [messages, setMessages] = useState<typeof import('*.json') | null>(
    null,
  );
  const [locale, set] = useState<EffectiveLocale | undefined>(undefined);

  const [updateUserAccountLocale] = useMutation<
    updateUserAccountMutation,
    updateUserAccountMutationVariables
  >(UPDATE_USER_ACCOUNT_MUTATION);

  useEffect(() => {
    const getLocale = async () => {
      let _locale = await getBrowserStoreLocale();

      if (!_locale) {
        _locale = getNearestLocale();
        await setBrowserStoreLocale(_locale);
      }

      set(_locale === 'en-US' ? 'en-US' : 'sv-SE');
    };
    getLocale();
  }, []);

  useEffect(() => {
    const setLang = async () => {
      if (locale) {
        const _result = await messagesFiles[locale];
        setMessages(_result);
      }
    };
    setLang();
  }, [locale]);

  const setLocale = async (_locale: EffectiveLocale, id?: string) => {
    set(_locale);
    await setBrowserStoreLocale(_locale);
    if (id !== undefined) {
      await updateUserAccountLocale({
        variables: { input: { localePreference: _locale, id } },
      });
    }
  };

  const value = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale],
  );

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }} />
      <IntlContext.Provider value={value}>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <IntlProvider
          defaultFormats={formats}
          formats={formats}
          locale={locale ?? 'sv-SE'}
          messages={messages}
        >
          {children}
        </IntlProvider>
      </IntlContext.Provider>
    </>
  );
};

export const useIntlContext = (): IntlProviderContext =>
  useContext(IntlContext);
