import { withinDateRangeExcludeEndDate } from '@frontend/utils';
import {
  FlexSalaryExchangeRequestStatus,
  FlexSalaryExchangeStatus,
} from 'app/apollo/graphql/types';
import { getValidUntilDate } from 'utils/get-valid-until-date';

import { Context } from './types';

interface NudgeTypes<T> {
  editOngoingExchange: T;
  initOngoingExchange: T;
  onetimeExchange: T;
}

interface NudgeSalaryExchange {
  show: NudgeTypes<boolean>;
  // eslint-disable-next-line
  messageValues: NudgeTypes<{ [key: string]: any } | undefined>;
}

interface SalaryExchangeEntitlement {
  applicationDateRange: [string, string | null];
  eligibleForOnetimeExchange: boolean;
  eligibleForOngoingExchange: boolean;
  maxOnetimeDeduction: string;
  maxOngoingDeduction: string;
}

interface SalaryExchange {
  id: string;
  status: FlexSalaryExchangeStatus;
}

interface SalaryExchangeRequest {
  id: string;
  status: FlexSalaryExchangeRequestStatus;
}

interface Params {
  contexts: Context[];
  bonusSalaryExchangeRequests?: SalaryExchangeRequest[] | null;
  salaryExchange?: SalaryExchange | null;
  salaryExchangeEntitlement?: SalaryExchangeEntitlement | null;
  salaryExchangeRequests?: SalaryExchangeRequest[] | null;
}

/**
 * @returns {NudgeSalaryExchange} - Object containing booleans for which
 * nudge types to show as well as message values for each nudge type
 */

export const getSalaryExchangeNudges = (
  params: Params,
): NudgeSalaryExchange => {
  const {
    contexts,
    salaryExchangeEntitlement,
    salaryExchange,
    salaryExchangeRequests,
    bonusSalaryExchangeRequests,
  } = params;

  const hasActiveOrUpcomingOngoingExchange =
    (!!salaryExchange?.status &&
      salaryExchange.status === FlexSalaryExchangeStatus.ACTIVE) ||
    !!salaryExchangeRequests?.some(
      request => request.status === FlexSalaryExchangeRequestStatus.APPROVED,
    );

  if (!salaryExchangeEntitlement) {
    return {
      show: {
        onetimeExchange: false,
        initOngoingExchange: false,
        editOngoingExchange: hasActiveOrUpcomingOngoingExchange,
      },
      messageValues: {
        onetimeExchange: undefined,
        initOngoingExchange: undefined,
        editOngoingExchange: undefined,
      },
    };
  }

  const {
    applicationDateRange,
    eligibleForOngoingExchange,
    eligibleForOnetimeExchange,
    maxOngoingDeduction,
    maxOnetimeDeduction,
  } = salaryExchangeEntitlement;

  const isInApplicationDateRange = applicationDateRange
    ? withinDateRangeExcludeEndDate(
        new Date().toISOString(),
        applicationDateRange,
      )
    : false;

  const hasUpcomingOnetimeExchange = !!bonusSalaryExchangeRequests?.find(
    request => request.status !== FlexSalaryExchangeRequestStatus.REJECTED,
  );

  const canRequestOrTerminateOngoingExchange =
    eligibleForOngoingExchange || hasActiveOrUpcomingOngoingExchange;

  const _nudgeOnetimeExchange =
    eligibleForOnetimeExchange &&
    !hasUpcomingOnetimeExchange &&
    isInApplicationDateRange;

  return {
    show: {
      onetimeExchange:
        _nudgeOnetimeExchange && contexts.includes(Context.PENSION),
      initOngoingExchange:
        canRequestOrTerminateOngoingExchange &&
        !hasActiveOrUpcomingOngoingExchange &&
        !_nudgeOnetimeExchange &&
        contexts.includes(Context.PENSION),
      editOngoingExchange:
        canRequestOrTerminateOngoingExchange &&
        hasActiveOrUpcomingOngoingExchange,
    },
    messageValues: {
      onetimeExchange: {
        max: maxOnetimeDeduction,
        untilDate: new Date(getValidUntilDate(applicationDateRange)),
      },
      initOngoingExchange: {
        max: maxOngoingDeduction,
      },
      editOngoingExchange: undefined,
    },
  };
};
