import { Icon, MenuSection, Separator, SubMenuHeader } from '@frontend/ui';
import { arrowRight, check, swapHoriz } from '@frontend/ui/icons';
import {
  alphabeticallyAscending,
  formatRegistrationNumber,
} from '@frontend/utils';
import { menuMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { MenuItem } from 'components/Menu';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

interface Props {
  setSubMenu: (subMenuContent: React.ReactNode) => void;
}

export const CompanySelectionMenu: React.FC<Props> = ({ setSubMenu }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    currentUser: { currentCompany, accessibleCompanies },
    setCurrentCompany,
  } = useCurrentUser();

  if (!currentCompany) {
    return null;
  }

  const companySubMenu = (
    <>
      <SubMenuHeader
        title={<FormattedMessage {...menuMessages.chooseCompany} />}
        onGoBack={() => setSubMenu(null)}
      />
      {accessibleCompanies
        .sort((a, b) =>
          alphabeticallyAscending(a.company.displayName, b.company.displayName),
        )
        .map((e, index) => (
          <React.Fragment key={e.company.id}>
            <MenuSection>
              <MenuItem
                role="menuitemcheckbox"
                aria-checked={e.company.id === currentCompany.id}
                onClick={() => {
                  setCurrentCompany(e.company.id);
                  history.push(
                    pathname.includes('/admin') || e.roles.length > 0
                      ? '/admin'
                      : '/',
                  );
                }}
                image={{ image: e.company.logoUrl }}
                title={e.company.displayName}
                description={`${formatRegistrationNumber(
                  e.company.registrationNumber,
                )}`}
                disabled={e.company.id === currentCompany.id}
                trailingIcon={
                  e.company.id === currentCompany.id && (
                    <Icon
                      size="medium"
                      icon={check}
                      color="onSurfaceVariant"
                      decorative
                    />
                  )
                }
              />
            </MenuSection>
            {index !== accessibleCompanies.length - 1 && <Separator />}
          </React.Fragment>
        ))}
    </>
  );

  return (
    <MenuItem
      setSubMenu={() => setSubMenu(companySubMenu)}
      title={<FormattedMessage {...menuMessages.chooseCompany} />}
      icon={<Icon size="medium" icon={swapHoriz} decorative />}
      trailingIcon={<Icon icon={arrowRight} decorative />}
    />
  );
};
