import { A } from '@frontend/ui';
import styled from 'styled-components';

const DefaultA = styled(A)`
  color: inherit;
  font-weight: inherit;
  display: block;
  &:hover {
    text-decoration: none;
  }
`;

export default DefaultA;
