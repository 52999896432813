import {
  buttonFocusVisibleAnimationBase,
  LinkButtonWrapper,
  LinkButtonWrapperProps,
} from '@frontend/ui';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import DefaultLink from '../DefaultLink';

export interface LinkButtonProps
  extends Omit<LinkProps, 'children'>,
    LinkButtonWrapperProps {
  disabled?: boolean;
}

const Link = styled(DefaultLink)`
  &:focus-visible {
    border-radius: 6.25rem;
    ${buttonFocusVisibleAnimationBase()}
  }
`;

export const LinkButton: React.FC<LinkButtonProps> = ({
  to,
  replace,
  ...props
}) =>
  props.disabled ? (
    <LinkButtonWrapper {...props} />
  ) : (
    <Link to={to} replace={replace}>
      <LinkButtonWrapper {...props} />
    </Link>
  );
