import {
  Grid,
  GridCell,
  Icon,
  RainbowChart,
  RainbowChartLegend,
} from '@frontend/ui';
import { money } from '@frontend/ui/icons';
import {
  totalCompensationCardQuery,
  totalCompensationCardQueryVariables,
  TotalCompensationId2 as TotalCompensationId,
} from 'app/apollo/graphql/types';
import { totalCompensationMessages as messages } from 'common/messages/total-compensation';
import { FormattedMessage, FormattedPercentInteger } from 'components/formats';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import { mapTotalCompLegend } from 'features/total-compensation/utils/map-total-comp-legend';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { TOTAL_COMPENSATION_CARD_QUERY } from './graphql/queries';
import { getTotalCompensationCardData } from './utils/get-total-compensation-card-data';

interface Props {
  companyId?: string;
  userAccountId?: string;
}

export const TotalCompensationCard: React.FC<Props> = ({
  userAccountId,
  companyId,
}) => {
  const { currentUser } = useCurrentUser();

  const { data } = useQuery<
    totalCompensationCardQuery,
    totalCompensationCardQueryVariables
  >(TOTAL_COMPENSATION_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    skip: !currentUser.currentCompany?.hasTotalCompensation,
    isEmpty: d => !getTotalCompensationCardData(d),
    variables: {
      userAccountId: userAccountId ?? currentUser.userAccountId,
      companyId: companyId ?? currentUser.currentCompany?.id ?? '',
    },
  });

  const totalCompenstaionData = getTotalCompensationCardData(data);

  if (!totalCompenstaionData) {
    return null;
  }

  const { totalCompensation, graphData } = totalCompenstaionData;

  const hasSalaryExchange = totalCompensation.some(
    item => item.key === TotalCompensationId.SALARY_EXCHANGE,
  );

  return (
    <GridCell span={8}>
      <SnapshotCard
        link={{
          to: '/total-compensation',
          label: <FormattedMessage {...messages.showTotalCompensation} />,
        }}
        icon={<Icon icon={money} color="primary" size="medium" decorative />}
        title={<FormattedMessage {...messages.totalCompensation} />}
        figure1={
          <RainbowChart inCard data={graphData}>
            <Grid>
              {graphData.map((e, i) => {
                if (e.percentValue) {
                  return (
                    <GridCell desktop={3} tablet={2} phone={2} key={i}>
                      <RainbowChartLegend
                        colorValue={e.colorValue}
                        label={mapTotalCompLegend({
                          label: e.label,
                          hasSalaryExchange,
                        })}
                        value={
                          <FormattedPercentInteger
                            value={e.percentValue / 1e2}
                          />
                        }
                      />
                    </GridCell>
                  );
                }

                return null;
              })}
            </Grid>
          </RainbowChart>
        }
      />
    </GridCell>
  );
};
