export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M5.04166667,6.70833333 C2.76666667,8.98333333 2.76666667,12.6666667 5.025,14.9416667 C6.25,12.1083333 8.43333333,9.74166667 11.1583333,8.33333333 C8.85,10.2833333 7.23333333,13.0083333 6.66666667,16.1 C8.83332759,17.125 11.5,16.75 13.2916667,14.9583333 C16.1916667,12.0583333 16.6666667,3.33333333 16.6666667,3.33333333 C16.6666667,3.33333333 7.94166667,3.80833333 5.04166667,6.70833333 Z',
        transform: 'translate(-3, -3) scale(1.5)',
      },
    },
  ],
};
