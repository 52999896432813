import gql from 'graphql-tag';

export const COLLECTIVE_AGREEMENT_CARD_QUERY = gql`
  query collectiveAgreementCardQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      collectiveAgreement
      totalCompensation: totalCompensation2 {
        key: id
        category
        annualCompensation
      }
    }
  }
`;
