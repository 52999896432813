import {
  companyDetails,
  costCenterDetails,
  payslipImportDetails,
  plusProfileDetails,
  userAccountDetails,
} from 'common/fragments';
import { pensionInsurance } from 'features/insurances/components/PensionInsurances/PensionInsuranceCard/graphql/fragment';
import { pensionStubItem } from 'features/insurances/components/PensionInsurances/StubItemCard/graphql/fragment';
import { plusSuggestionInstitute } from 'features/pension/plus/components/SuggestionActionTargetCard/graphql/fragment';
import { plusAllocation } from 'features/pension/plus/components/SuggestionAllocationTable/graphql/fragment';
import gql from 'graphql-tag';

export const VIEWER_QUERY = gql`
  query viewerQuery {
    viewer {
      id
      localePreference
    }
  }
`;

/**
 * @deprecated This query should no longer be used.
 *
 * Currently it is used in a number of refetches in the app, and in most of those cases it's probably
 * overfeting quite a bit and used a bit sloppily.
 *
 * Long term, we should remove this query and replace its usages with more fine tuned, slimmed down queries.
 */
export const VIEWER_MEMBERSHIP_QUERY = gql`
  query viewerMembershipQuery {
    viewer {
      ...userAccountDetails
      hasSignedPlus
      hasActivePlus
      insurances(instituteId: "PPM") {
        totalCount
      }
      memberships {
        edges {
          node {
            id
            roles
            company {
              ...companyDetails
            }
            membershipBenefitPackage {
              benefitPackage {
                id
                hasBenefitPortal
              }
              showTotalCompensation
            }
            employments {
              edges {
                node {
                  id
                  period
                }
              }
            }
          }
        }
      }
    }
  }
  ${companyDetails}
  ${userAccountDetails}
`;

export const PLUS_PROFILE_QUERY = gql`
  query plusProfileQuery {
    viewer {
      id
      hasSignedPlus
      hasActivePlus
    }
    plusProfile {
      ...plusProfileDetails
    }
  }
  ${plusProfileDetails}
`;

// TODO: This query and its associated view
// could probably be improved by using suspense
export const PLUS_SUGGESTION_QUERY = gql`
  query plusSuggestionQuery($id: ID!) {
    viewer {
      id
      hasSignedPlus
      hasActivePlus
    }
    plusAdviceHistory(first: 1) {
      edges {
        node {
          id
          suggestions {
            id
            status
            instructions {
              action {
                __typename
              }
            }
          }
        }
      }
    }
    plusSuggestion(id: $id) {
      id
      title
      status
      description
      category
      instructions {
        title
        description
        linkUrl
        linkText
        action {
          targetPath {
            date
            target {
              managementType
              institute {
                ...plusSuggestionInstitute
              }
              allocation {
                ...plusAllocation
              }
            }
          }
        }
      }

      insurances {
        id
        components {
          ...pensionInsurance
        }
      }
      stubs {
        ...pensionStubItem
      }
      advice {
        id
        createdAt
      }
    }
  }
  ${plusSuggestionInstitute}
  ${pensionInsurance}
  ${pensionStubItem}
  ${plusAllocation}
`;

export const ORGANIZATION_OVERVIEW_QUERY = gql`
  query organizationOverviewQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      costCenters {
        edges {
          node {
            ...costCenterDetails
          }
        }
      }
    }
  }
  ${costCenterDetails}
`;

export const PAYSLIP_IMPORT_QUERY = gql`
  query payslipImportQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      name
      payslipImports(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            ...payslipImportDetails
          }
        }
      }
    }
  }
  ${payslipImportDetails}
`;

export const ADMIN_ROUTES_QUERY = gql`
  query adminRoutesQuery($id: ID!, $benefitTypeNames: [String!]) {
    company(id: $id) {
      id
      hasJobOffers
      benefitPackages {
        edges {
          node {
            id
            hasBenefitPortal
          }
        }
      }
      flexBenefits(benefitTypeNames: $benefitTypeNames) {
        edges {
          node {
            id
            content {
              locale
            }
          }
        }
      }
    }
  }
`;

export const VIEWER_PLUS_STATUS_QUERY = gql`
  query viewerPlusStatusQuery {
    viewer {
      id
      hasSignedPlus
      hasActivePlus
    }
  }
`;

export const HAS_PLUS_PROFILE_QUERY = gql`
  query hasPlusProfileQuery {
    viewer {
      id
      hasSignedPlus
      hasActivePlus
    }
    plusProfile {
      userAccountId
    }
  }
`;
