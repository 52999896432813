import { ApolloError } from '@apollo/client';
import { BenefitType } from '@frontend/benefit-types';
import {
  hasAdminMenuContentQuery,
  hasAdminMenuContentQueryVariables,
} from 'app/apollo/graphql/types';
import { useCurrentUser } from 'contexts/current-user';
import { isAdminView } from 'utils/is-admin-view';
import { useQuery } from 'utils/use-query';

import { HAS_ADMIN_MENU_CONTENT_QUERY } from '../graphql';

const benefitTypeNames: BenefitType[] = ['advinans_salary_exchange'];

interface AdminMenuContent {
  error?: ApolloError;
  hasSalaryExchange?: boolean;
  loading?: boolean;
}

export const useHasAdminMenuContent = (): AdminMenuContent => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { data, error, loading } = useQuery<
    hasAdminMenuContentQuery,
    hasAdminMenuContentQueryVariables
  >(HAS_ADMIN_MENU_CONTENT_QUERY, {
    variables: {
      id: currentCompany?.id ?? '',
      benefitTypeNames,
    },
    errorPolicy: 'all',
    skip: !currentCompany || !isAdminView(),
  });

  if (loading) {
    return { loading: true };
  }

  if (!data?.company) {
    return {
      hasSalaryExchange: false,
    };
  }

  const { flexBenefits } = data.company;
  const hasSalaryExchange = !!flexBenefits?.edges?.length;

  return {
    hasSalaryExchange,
    error,
  };
};
