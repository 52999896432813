import { useEffect, useState } from 'react';

const computeIsSmallScreen = (pixelBreakpoint: number) =>
  window.innerWidth < pixelBreakpoint;

export const useIsSmallScreen = (pixelBreakpoint: number): boolean => {
  const [isSmallScreen, setIsSmallScreen] = useState(
    computeIsSmallScreen(pixelBreakpoint),
  );

  useEffect(() => {
    const handleResize = () =>
      setIsSmallScreen(computeIsSmallScreen(pixelBreakpoint));
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [pixelBreakpoint]);

  return isSmallScreen;
};
