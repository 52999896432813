import {
  LegacySalaryExchangeProvider,
  SalaryExchangeProvider,
} from '@frontend/benefit-types';
import {
  FlexMaxOngoingRule,
  FlexSalaryExchangeRequestStatus,
  FlexSalaryExchangeStatus,
} from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const cumulativeSalaryExchangeMessages = defineMessages({
  totalPayment: {
    id: 'cumulativeSalaryExchangeMessages.totalPayment',
    defaultMessage: 'Total inbetalning',
  },
  salaryAfterTaxToday: {
    id: 'cumulativeSalaryExchangeMessages.salaryAfterTaxToday',
    defaultMessage: 'Lön efter skatt idag',
  },
  salaryAfterTaxTodayFootnote: {
    id: 'cumulativeSalaryExchangeMessages.salaryAfterTaxTodayFootnote',
    defaultMessage: 'Vid lön på {monthlySalary, number, ::currency/SEK .}/mån',
  },
  extraContribution: {
    id: 'cumulativeSalaryExchangeMessages.extraContribution',
    defaultMessage: '{boost, number, ::currency/SEK .} extra sparande',
  },
});

export const salaryExchangeIntroMessages = defineMessages({
  header: {
    id: 'salaryExchangeIntroMessages.header',
    defaultMessage: 'Löneväxling',
  },
  preamble: {
    id: 'salaryExchangeIntroMessages.preamble',
    defaultMessage:
      'Genom att växla en del av din lön mot inbetalningar till din tjänstepension kan du spara extra till din pension. Löneväxling kan passa dig som tjänar minst {grossSalaryThreshold, number, ::currency/SEK .} per månad efter löneväxling. För mer information, besök sidan om {link}',
  },
  preambleLinkText: {
    id: 'salaryExchangeIntroMessages.preambleLinkText',
    defaultMessage: 'Löneväxling i förmånsportalen.',
  },
  boost: {
    id: 'salaryExchangeIntroMessages.boost',
    defaultMessage: 'Extra pensionsinbetalning',
  },
  boostDesc: {
    id: 'salaryExchangeIntroMessages.boostDesc',
    defaultMessage:
      'Din arbetsgivare betalar in mer än vad du löneväxlar eftersom pension beskattas lägre än lön',
  },
  lowerTax: {
    id: 'salaryExchangeIntroMessages.lowerTax',
    defaultMessage: 'Låg avkastningsskatt',
  },
  lowerTaxDesc: {
    id: 'salaryExchangeIntroMessages.lowerTaxDesc',
    defaultMessage:
      'Upp till 80 % lägre avkastningsskatt på pension än på investeringssparkonton och kapitalförsäkringar',
  },
  optimizeTax: {
    id: 'salaryExchangeIntroMessages.optimizeTax',
    defaultMessage: 'Optimerad inkomstskatt',
  },
  optimizeTaxDesc: {
    id: 'salaryExchangeIntroMessages.optimizeTaxDesc',
    defaultMessage:
      'Att skjuta upp beskattningen av din inkomst till pensionen kan innebära lägre inkomstskatt',
  },
});

export const salaryExchangeRequestDetailsMessages = defineMessages({
  header: {
    id: 'salaryExchangeRequestDetailsMessages.header',
    defaultMessage: `{salaryExchangeType, select,
      ONETIME {Vad innebär det att bonusväxla {deductionValue, number, ::currency/SEK .}?}
      other {Vad innebär det att löneväxla {deductionValue, number, ::currency/SEK .} per månad?}
    }`,
  },
  amount: {
    id: 'salaryExchangeRequestDetailsMessages.amount',
    defaultMessage: 'Löneväxlingsbelopp',
  },
  amountDesc: {
    id: 'salaryExchangeRequestDetailsMessages.amountDesc',
    defaultMessage: `
    <p>Löneväxlingen är den del av din lön före skatt som du växlar mot extra inbetalningar till din pension.</p>
    <p>Löneväxling innebär att du sänker din lön före skatt med motsvarande belopp. Om du löneväxlar kan andra ersättningar och förmåner påverkas.</p>
    <p>Om du löneväxlar så att din lön före skatt blir lägre än cirka {grossSalaryThreshold, number, ::currency/SEK .} per månad (8,07 inkomstbasbelopp per år) så blir inbetalningarna till din allmänna pension lägre. Du kan också få lägre ersättning om du blir sjuk eller går på föräldraledighet.</p>
    `,
  },
  boost: {
    id: 'salaryExchangeRequestDetailsMessages.boost',
    defaultMessage: 'Extra pensionsinbetalning',
  },
  boostDesc: {
    id: 'salaryExchangeRequestDetailsMessages.boostDesc',
    defaultMessage:
      'Om du löneväxlar {deductionValue, number, ::currency/SEK .} betalar din arbetsgivare in {boost, number, ::currency/SEK .} extra eftersom det är lägre skatt på pension än på lön.',
  },
  itp1Compensation: {
    id: 'salaryExchangeRequestDetailsMessages.itp1Compensation',
    defaultMessage: 'Kompensation för lägre ITP 1',
  },
  itp1CompensationDesc: {
    id: 'salaryExchangeRequestDetailsMessages.itp1CompensationDesc',
    defaultMessage:
      'Din arbetsgivare kompenserar dig för att din vanliga tjänstepension ITP 1 påverkas av löneväxling. Kompensationen betalas in till din löneväxling.',
  },
  premium: {
    id: 'salaryExchangeRequestDetailsMessages.premium',
    defaultMessage: 'Total inbetalning',
  },
  premiumDesc: {
    id: 'salaryExchangeRequestDetailsMessages.premiumDesc',
    defaultMessage:
      'Detta är det totala belopp som din arbetsgivare betalar in till din löneväxling.',
  },
  netSalaryEffect: {
    id: 'salaryExchangeRequestDetailsMessages.netSalaryEffect',
    defaultMessage: 'Lön efter skatt',
  },
  netSalaryEffectDesc: {
    id: 'salaryExchangeRequestDetailsMessages.netSalaryEffectDesc',
    defaultMessage: `
    <p>Att löneväxla innebär att du skjuter upp beskattningen av din inkomst. När du tar ut pengarna kommer de beskattas som inkomst av pension. Men idag sänker du din lön och betalar därför lägre skatt.</p>
    <p>Utifrån en lön på {monthlySalary, number, ::currency/SEK .} per månad före skatt och löneväxling uppskattar vi att effekten på din lön efter skatt är {netSalaryEffect, number, ::currency/SEK .} per månad (med skattetabell {incomeTaxTable}).</p>`,
  },
  goodToKnow: {
    id: 'salaryExchangeRequestDetailsMessages.goodToKnow',
    defaultMessage: 'Bra att veta om löneväxling',
  },
  goodToKnowDesc: {
    id: 'salaryExchangeRequestDetailsMessages.goodToKnowDesc',
    defaultMessage: `
    <p>Löneväxling är ett pensionssparande genom din tjänstepension som ägs av din arbetsgivare. Inbetalningarna blir därför upplåsta. Enligt lag kan du som tidigast ta ut tjänstepension vid 55 års ålder. Kortaste uttagstid är 5 år.</p>
    <p>Löneväxling innebär att du sänker din lön. Om du blir sjuk eller går på föräldraledighet, eller av någon annan anledning får lägre lön, är det viktigt att ändra eller avsluta din löneväxling.</p>`,
  },
});

export const salaryExchangeBenefitMessages = defineMessages({
  deductionValueMaxLabel: {
    id: 'salaryExchangeBenefitMessages.deductionValueMaxLabel',
    description: 'Högsta bruttolöneavdrag i kronor',
    defaultMessage: 'Högsta bruttolöneavdrag i kronor',
  },
  deductionShareMaxLabel: {
    id: 'salaryExchangeBenefitMessages.deductionShareMaxLabel',
    description: 'Högsta bruttolöneavdrag som andel av bruttolön',
    defaultMessage: 'Högsta bruttolöneavdrag i procent',
  },
  annualSalaryAfterExchangeIbbMinLabel: {
    id: 'salaryExchangeBenefitMessages.annualSalaryAfterExchangeIbbMinLabel',
    description: 'Minsta årslön efter löneavdrag',
    defaultMessage: 'Lägsta årslön efter bruttolöneavdrag',
  },
  personTaxBenefitShareLabel: {
    id: 'salaryExchangeBenefitMessages.personTaxBenefitShareLabel',
    description: 'Procentandel till anställd',
    defaultMessage:
      'Andel av skattefördelen (5,76 %) som tillfaller den anställda',
  },
  personTaxBenefitShareTooltip: {
    id: 'salaryExchangeBenefitMessages.personTaxBenefitShareTooltip',
    description: '{extra, number, percent} högre premie',
    defaultMessage: '{extra, number, percent} högre premie',
  },
  salaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.salaryExchangeDeductionValue',
    description: 'Max. bruttolönevadrag:',
    defaultMessage:
      'Max. avdrag: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  oneTimeSalaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.oneTimeSalaryExchangeDeductionValue',
    defaultMessage:
      'Max. avdrag bonuxväxling: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  premiumPerMonth: {
    id: 'salaryExchangeBenefitMessages.premiumPerMonth',
    description: 'Premie (kr/mån)',
    defaultMessage: 'Premie (kr/mån)',
  },
  deduction: {
    id: 'salaryExchangeBenefitMessages.deduction',
    description: 'Avdrag',
    defaultMessage: 'Avdrag',
  },
  premium: {
    id: 'salaryExchangeBenefitMessages.premium',
    description: 'Premie',
    defaultMessage: 'Premie',
  },
  comments: {
    id: 'salaryExchangeBenefitMessages.comments',
    description: 'Kommentar',
    defaultMessage: 'Kommentar',
  },
  collectumAgreementHelperText: {
    id: 'salaryExchangeBenefitMessages.collectumAgreementHelperText',
    description:
      'Helper text for configuring salary exchnage of type collectum agreement',
    defaultMessage:
      'Medarbetare kan välja att löneväxla till extra avsättningar till ITP 1/ITPK. Förvaltningen följer medarbetarens ordinarie val i ITP.',
  },
  informationAboutSalaryExchange: {
    id: 'salaryExchangeBenefitMessages.informationAboutSalaryExchange',
    description: 'Information about salary exchange',
    defaultMessage: 'Information om löneväxling',
  },
  beneficialSaving: {
    id: 'salaryExchangeBenefitMessages.beneficialSaving',
    description: 'A beneficial saving',
    defaultMessage: 'Ett förmånligt sparande',
  },
  pensionTaxBenefitShare: {
    id: 'salaryExchangeBenefitMessages.pensionTaxBenefitShare',
    defaultMessage: 'Andel av skattefördel som tillfaller den anställde',
  },
  automaticApproval: {
    id: 'salaryExchangeBenefitMessages.automaticApproval',
    defaultMessage: 'Godkänn ansökningar automatiskt',
  },
  incomeIbb: {
    id: 'salaryExchangeBenefitMessages.incomeIbb',
    defaultMessage: '{ amount } Inkomstbasbelopp',
  },
});

export const salaryExchangeAdminMessages = defineMessages({
  yearlySalary: {
    id: 'salaryExchangeAdminMessages.yearlySalary',
    description: `app/admin/salary-exchanges - field label - yearly salary`,
    defaultMessage: `Pensionsmedförande årslön`,
  },
  historyHeader: {
    id: 'salaryExchangeAdminMessages.historyHeader',
    description: `Header for salary exchange history`,
    defaultMessage: `Historik för begäran om löneväxling`,
  },
  onetimeHistoryHeader: {
    id: 'salaryExchangeAdminMessages.onetimeHistoryHeader',
    description: `Header for salary exchange history`,
    defaultMessage: `Historik för begäran om bonusväxling`,
  },
  reject: {
    id: 'salaryExchangeAdminMessages.reject',
    description: `Avslå begäran`,
    defaultMessage: `Avslå begäran om löneväxling`,
  },
  rejectUpcoming: {
    id: 'salaryExchangeAdminMessages.rejectUpcoming',
    defaultMessage: `Avsluta nya löneväxlingar`,
  },
  accept: {
    id: 'salaryExchangeAdminMessages.accept',
    description: `Godkänn begäran`,
    defaultMessage: `Godkänn begäran om löneväxling`,
  },
  acceptSubmitSuccessMessage: {
    id: 'salaryExchangeAdminMessages.acceptSubmitSuccessMessage',
    defaultMessage: `{count, plural, =1 {1 begäran} other {# begäranden}} om löneväxling har godkänts.`,
  },
  rejectSubmitSuccessMessage: {
    id: 'salaryExchangeAdminMessages.rejectSubmitSuccessMessage',
    defaultMessage: `{count, plural, =1 {1 begäran} other {# begäranden}} om löneväxling har avslagits.`,
  },
  approveSubmitErrorMessagesTitle: {
    id: 'salaryExchangeAdminMessages.approveSubmitErrorMessagesTitle',
    defaultMessage: 'En eller flera ansökningar kunde inte godkännas',
  },
  approvalAdvinansDesc: {
    id: 'salaryExchangeAdminMessages.approvalAdvinansDesc',
    description: `När löneväxlingen godkänts aktiveras den för den anställde. Avtal om löneväxlingen finns under dokument, den anställde hittar sitt avtal inloggad i sin portal. Glöm inte att justera löneavdraget för den anställde.`,
    defaultMessage: `När löneväxlingen godkänts aktiveras den för den anställde. Avtal om löneväxlingen finns under dokument, den anställde hittar sitt avtal inloggad i sin portal. Glöm inte att justera löneavdraget för den anställde.`,
  },
  rejectSubmitErrorMessagesTitle: {
    id: 'salaryExchangeAdminMessages.rejectSubmitErrorMessagesTitle',
    defaultMessage: 'En eller flera ansökningar kunde inte nekas',
  },
  rejectAdvinansDesc: {
    id: 'salaryExchangeAdminMessages.rejectAdvinansDesc',
    description: `Den anställde får ett meddelande om att begäran nekats och lägger du till en kommentar visas den för den anställde. Det är endast den aktuella begäran som nekas, finns det redan en aktiv löneväxling så påverkas den inte.`,
    defaultMessage: `Den anställde får ett meddelande om att begäran nekats och lägger du till en kommentar visas den för den anställde. Det är endast den aktuella begäran som nekas, finns det redan en aktiv löneväxling så påverkas den inte.`,
  },
  approvalCollectumDesc: {
    id: 'salaryExchangeAdminMessages.approvalCollectumDesc',
    description: `Löneväxlingar genom Collectum kräver månadsvis rapportering av premier. Ni hittar uppdaterad rapportfil i vår tjänst. Filen ska ha skickats in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. Kontrollera datum på {collectumLink}`,
    defaultMessage: `Löneväxlingar genom Collectum kräver månadsvis rapportering av premier. Ni hittar uppdaterad rapportfil i vår tjänst. Filen ska ha skickats in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. Kontrollera datum på {collectumLink}`,
  },
  collectumSiteLinkText: {
    id: 'salaryExchangeAdminMessages.collectumSiteLinkText',
    description: `Link text for link to Collectum home page`,
    defaultMessage: `Collectums hemsida`,
  },
  salaryExchangeReportsDesc: {
    id: 'salaryExchangeAdminMessages.salaryExchangeReportsDesc',
    description: 'Description for salary exchange report table.',
    defaultMessage:
      'Aktiva och avslutade löneväxlingar sammanställs inför varje månad för avstämning mot lönesystemet. I sammanställningen framgår bruttolöneavdrag och premie per person.',
  },
  salaryExchangeReportCollectumDesc: {
    id: 'salaryExchangeAdminMessages.salaryExchangeReportCollectumDesc',
    description:
      'Hanteras löneväxling genom Collectum så ska rapportfilen skickas in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. Läs mer hos Collectum {link}',
    defaultMessage: `Hanteras löneväxling genom Collectum så ska rapportfilen skickas in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. {link}.`,
  },
  readMoreAboutCollectum: {
    id: 'saalryExchangeAdminMessages.readMoreAboutCollectum',
    description: 'Read more about Collectum',
    defaultMessage: ' Läs mer hos Collectum',
  },
  salaryExchangeCapacityTitle: {
    id: 'salaryExchangeAdminMessages.salaryExchangeCapacityTitle',
    description: 'Calculate pension premium capacity',
    defaultMessage: 'Kontrollera utrymme för pensionsavsättningar',
  },
  salaryExchangeCapacityText: {
    id: 'salaryExchangeAdminMessages.salaryExchangeCapacityText',
    description: 'Text for calculating pension premiums',
    defaultMessage: `För er som arbetsgivare är avsättningar till tjänstepension avdragsgillt enligt huvudregeln upp till 35 % av den pensionsmedförande lönen eller max 10 prisbasbelopp. Pensionsmedförande lön är antingen föregående beskattningsår eller innevarande beskattningsår och definieras av all ersättning som är pensionsgrundande. Nedan kan du göra en beräkning givet tjänstepensionsavsättningar och den anställdes pensionsmedförande lön. {link}`,
  },
  readMoreTaxOffice: {
    id: 'salaryExchangeAdminMessages.readMoreTaxOffice',
    description: 'Read more about rules at Skatteverket',
    defaultMessage: 'Läs mer om reglerna hos Skatteverket',
  },
  salaryExchangePremium: {
    id: 'salaryExchangeAdminMessages.salaryExchangePremium',
    description: 'Salary exchange premium',
    defaultMessage: 'Löneväxlingspremie',
  },
  highestSalaryExchangePremium: {
    id: 'salaryExchangeAdminMessages.highestSalaryExchangePremium',
    description: 'Highest salary exchange premium',
    defaultMessage: 'Högsta löneväxlingspremie',
  },
  capacityRemaining: {
    id: 'salaryExchangeAdminMessages.capacityRemaining',
    description: 'Capacity remaining',
    defaultMessage: 'Kvar av utrymme',
  },
  insufficientCapacityMessage: {
    id: 'salaryExchangeAdminMessages.insufficientCapacityMessage',
    description: `The given premiums for pension and insurances gives no room for salary exchange.`,
    defaultMessage: `De angivna premierna för pensionsavsättningar och försäkringar ger inget utrymme för löneväxling.`,
  },
  salaryExchangeWithinRange: {
    id: 'salaryExchangeAdminMessages.salaryExchangeWithinRange',
    description: `The salary exchange premium ({premium}) is within the capacity for pension premiums.`,
    defaultMessage: `Löneväxlingspremien ({premium}) ligger inom det utrymme för pensionsavsättningar som finns.`,
  },
  tooHighSxRequested: {
    id: 'salaryExchangeAdminMessages.tooHighSxRequested',
    description: `The salary exchange premium ({premium}) is higher than the capacity for pension premiums.`,
    defaultMessage: `Löneväxlingspremien ({premium}) ligger över det utrymme för pensionsavsättningar som finns.`,
  },
  occupationalPensionPremiums: {
    id: 'salaryExchangeAdminMessages.occupationalPensionPremiums',
    description: `app/admin/salary-exchange - field label - occupational pension premiums`,
    defaultMessage: `Tjänstepensionspremier`,
  },
  insurances: {
    id: 'salaryExchangeAdminMessages.insurances',
    description: `app/admin/salary-exchange - field label - sickness and premium waiver insurances`,
    defaultMessage: `Sjukförsäkring och premiebefrielseförsäkring`,
  },
  oneTimeDeposits: {
    id: 'salaryExchangeAdminMessages.oneTimeDeposits',
    description: `app/admin/salary-exchange - field label - One time deposits for pension`,
    defaultMessage: `Eventuella engångsavsättningar till pension*`,
  },
  oneTimeDepositsHelperText: {
    id: 'salaryExchangeAdminMessages.oneTimeDepositsHelperText',
    description: `app/admin/salary-exchange - field helper text - *Such as bonus refrain`,
    defaultMessage: `*Exempelvis bonusavstående`,
  },
  salaryExchangeRequestsDesc: {
    id: 'salaryExchangeAdminMessages.salaryExchangeRequestsDesc',
    description: 'The page description of /admin/salary-exchange/requests',
    defaultMessage:
      'Om löneväxlingen godkänts aktiveras den för den anställde. Avtal om löneväxlingen finns under dokument, den anställde hittar sitt avtal inloggad i sin portal. Glöm inte att justera löneavdraget för den anställde.\n\nAnnars får den anställde ett meddelande om att begäran nekats och lägger du till en kommentar visas den för den anställde. Det är endast den aktuella begäran som nekas, finns det redan en aktiv löneväxling så påverkas den inte.',
  },
  activeStatusTooltip: {
    id: 'salaryExchangeAdminMessages.activeStatusTooltip',
    description: 'Deduction is valid from {effectiveDate}',
    defaultMessage: `Löneavdrag gäller från och med {effectiveDate}`,
  },
  inactiveStatusTooltip: {
    id: 'salaryExchangeAdminMessages.inactiveStatusTooltip',
    description: 'Deduction is inactive from {effectiveDate}',
    defaultMessage: `Löneväxling avslutas från och med {effectiveDate}`,
  },
  violatedStatusTooltip: {
    id: 'salaryExchangeAdminMessages.violatedStatusTooltip',
    description: 'Deduction violates the policy',
    defaultMessage: `Löneavdraget strider mot policy`,
  },
  violatedSalaryExchangeLabel: {
    id: 'salaryExchangeAdminMessages.violatedSalaryExchangeLabel',
    defaultMessage: 'Strider mot policy',
  },
  noSalaryExchangeItems: {
    id: 'salaryExchangeAdminMessages.noSalaryExchangeItems',
    description: 'No salary exchange items exists',
    defaultMessage: 'Inga löneväxlingar',
  },
  noSalaryExchangeRequestItems: {
    id: 'salaryExchangeAdminMessages.noSalaryExchangeRequestItems',
    description: 'No salary exchange items exists on admin route',
    defaultMessage: 'Inga löneväxlingar att hantera',
  },
  noSalaryExchangeRequestItemsText: {
    id: 'salaryExchangeAdminMessages.noSalaryExchangeRequestItemsText',
    description: 'No salary exchange items exists on admin route helper text',
    defaultMessage:
      'När det finns löneväxlingar att hantera kommer du se dem här.',
  },
  reviewSalaryExchangeDesc: {
    id: 'salaryExchangeAdminMessages.reviewSalaryExchangeDesc',
    description:
      'There are salary exchanges that no longer follow your salary exchange policy. This may due to the employees have been changed their income, so that the salary exchange deduction is too high.',
    defaultMessage:
      'Det finns löneväxlingar som inte längre följer er löneväxlingspolicy. Detta kan bero på att anställda har fått ändrad inkomst, så att löneavdraget är för högt.',
  },
  terminateSalaryExchangeSubmitError: {
    id: 'salaryExchangeAdminMessages.terminateSalaryExchangeSubmitError',
    description: 'Could not terminate several salary exchanges',
    defaultMessage: 'Det gick inte att avsluta några löneväxlingar',
  },
  confirmApproveTitle: {
    id: 'salaryExchangeAdminMessages.confirmApproveTitle',
    defaultMessage: 'Godkänn löneväxlingar?',
  },
  confirmApprove: {
    id: 'salaryExchangeAdminMessages.confirmApprove',
    defaultMessage:
      'Valda löneväxlingar kommer att godkännas och börjar gälla från och med nästa månad.',
  },
  confirmRejectTitle: {
    id: 'salaryExchangeAdminMessages.confirmRejectTitle',
    defaultMessage: 'Neka löneväxlingar?',
  },
  confirmReject: {
    id: 'salaryExchangeAdminMessages.confirmReject',
    defaultMessage: 'Valda löneväxlingar kommer att nekas.',
  },
  confirmTerminateTitle: {
    id: 'salaryExchangeAdminMessages.confirmTerminateTitle',
    defaultMessage: 'Avsluta löneväxlingar?',
  },
  confirmTerminate: {
    id: 'salaryExchangeAdminMessages.confirmTerminate',
    defaultMessage:
      'Valda löneväxlingar kommer att avslutas från och med nästa månad.',
  },
  autoApproval: {
    id: 'salaryExchangeAdminMessages.autoApproval',
    defaultMessage: 'Hantera ansökningar automatiskt',
  },
  confirmAutoApproval: {
    id: 'salaryExchangeAdminMessages.confirmAutoApproval',
    defaultMessage: `Godkänn ansökningar automatiskt`,
  },
  autoApprovalDesc: {
    id: 'salaryExchangeAdminMessages.autoApprovalDesc',
    defaultMessage: `
    <p>Ansökningar om löneväxling kan godkännas automatiskt om de faller inom arbetsgivarens policy. Utan automatiska godkännanden måste varje ansökan behandlas av en behörig administratör. Automatiska godkännanden innebär att avtal om löneavdrag och extra pensionsinbetalningar träder i kraft så snart en anställd lämnat in en godkänd ansökan.</p>
    <p>Arbetsgivaren kan alltid avsluta löneväxlingar i portalen utan att den anställda behöver ansöka om detta.</p>
    <p>Notera detta gäller för hantering av framtida ansökningar. Eventuella ansökningar om löne- eller bonusväxling som anställda skickat in som just nu väntar på godkännande kommer inte att omfattas av automatisk hantering utan behöver behandlas manuellt.</p>
  `,
  },
  stopSalaryExchange: {
    id: 'salaryExchangeAdminMessages.stopSalaryExchange',
    defaultMessage: `Avsluta {count, plural,
      =1 {löneväxling}
      other {löneväxlingar}
    }`,
  },
});

export const salaryExchangeAdminStatusMessages = defineMessages({
  willBeActive: {
    id: 'salaryExchangeAdminStatusMessages.willBeActive',
    description: 'salary exchange will be active from {effectiveDate}',
    defaultMessage: `Premie {monthlyPremium} kr/mån gäller från {effectiveDate}.`,
  },
});

export const salaryExchangeAgreementMessages = defineMessages({
  agreementTitle: {
    id: 'salaryExchangeAgreementMessages.agreementTitle',
    description: 'salary exchange agreement title',
    defaultMessage: `Avtal om växling av lön till förstärkt tjänstepension`,
  },
  agreementShortTitle: {
    id: 'salaryExchangeAgreementMessages.agreementShortTitle',
    description: 'salary exchange agreement',
    defaultMessage: `Avtal om löneväxling`,
  },
  agreementSubtitle: {
    id: 'salaryExchangeAgreementMessages.agreementSubtitle',
    description: 'salary exchange agreement subtitle',
    defaultMessage: `Gäller som tillägg till den anställdes anställningsavtal`,
  },
  signedBy: {
    id: 'salaryExchangeAgreementMessages.signedBy',
    description:
      'Signature messages by employee and company for a given salary exchange',
    defaultMessage: `Underskrivet av {employeeFirstName} {employeeLastName} med BankID och godkänt av {companyName} via Nordea Nodes portal`,
  },
});

export const salaryExchangeMessages = defineMessages({
  salaryExchanges: {
    id: 'salaryExchange.salaryExchanges',
    defaultMessage: 'Löneväxlingar',
  },
  upcomingSalaryExchanges: {
    id: 'salaryExchange.upcomingSalaryExchanges',
    defaultMessage: 'Nya löneväxlingar',
  },
  upcomingSalaryExchangesDesc: {
    id: 'salaryExchange.upcomingSalaryExchangesDesc',
    defaultMessage: 'Fr.o.m nästa månad',
  },
  upcomingSalaryExchangesEffectiveFrom: {
    id: 'salaryExchange.upcomingSalaryExchangesEffectiveFrom',
    defaultMessage: 'Nya löneväxlingar fr.o.m nästa månad',
  },
  salaryExchangePending: {
    id: 'salaryExchangeMessages.salaryExchangePending',
    defaultMessage: `Din arbetsgivare behandlar din ansökan om löneväxling på {deductionValue, number, ::currency/SEK .}.`,
  },
  onetimeSalaryExchangeRejected: {
    id: 'salaryExchangeMessages.onetimeSalaryExchangeRejected',
    defaultMessage:
      'Din arbetsgivare har nekat din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} som ett engångsbelopp. Kontakta din arbetsgivare för mer information.',
  },
  onetimeSalaryExchangeRejectedWithReason: {
    id: 'salaryExchangeMessages.onetimeSalaryExchangeRejectedWithReason',
    defaultMessage:
      'Din arbetsgivare har nekat din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} som ett engångsbelopp med kommentaren: “{rejectionReason}“. Kontakta din arbetsgivare för mer information.',
  },
  grossSalaryDeduction: {
    id: 'salaryExchangeMessages.grossSalaryDeduction',
    defaultMessage: `Bruttolöneavdrag (kr/mån)`,
  },
  benefitQualifyingIncome: {
    id: 'salaryExchangeMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande lön',
  },
  cancelSalaryExchange: {
    id: 'salaryExchangeMessages.cancelSalaryExchange',
    defaultMessage: `Avsluta löneväxling`,
  },
  cancelSalaryExchangeDesc: {
    id: 'salaryExchangeMessages.cancelSalaryExchangeDesc',
    defaultMessage: 'Jag vill avsluta min löneväxling.',
  },
  cancelSalaryExchangeConfirmTitle: {
    id: 'salaryExchangeMessages.cancelSalaryExchangeConfirmTitle',
    defaultMessage: 'Avsluta löneväxling?',
  },
  cancelSalaryExchangeConfirm: {
    id: 'salaryExchangeMessages.cancelSalaryExchangeConfirm',
    defaultMessage:
      'Du kommer att avsluta din löneväxling från och med nästa månad.',
  },
  cancelSalaryExchangeSuccess: {
    id: 'salaryExchangeMessages.cancelSalaryExchangeSuccess',
    defaultMessage: 'Din löneväxling har avslutats',
  },
  adjustInvalidActiveSX: {
    id: 'salaryExchangeMessages.adjustInvalidActiveSX',
    description:
      'Your salary exchange of {SEK X/month} is higher than what your employers current policy allows. Cancel your salary exchange or apply for a lower amount.',
    defaultMessage:
      'Ditt löneväxlingsbelopp {deductionValue, number, ::currency/SEK .} är högre än vad din arbetsgivares nuvarande policy tillåter. Avsluta din löneväxling eller ansök om ett lägre belopp.',
  },
  violatedRequestDeductionValue: {
    id: 'salaryExchangeMessages.violatedRequestDeductionValue',
    defaultMessage:
      'Ditt ansökta löneväxlingsbelopp på {deductionValue, number, ::currency/SEK .} är högre än vad din arbetsgivares nuvarande policy tillåter. Avbryt din förfrågan eller ansök om ett lägre belopp.',
  },
  pendingRequest: {
    id: 'salaryExchangeMessages.pendingRequest',
    description: 'Pending salary exchange request',
    defaultMessage: 'Pågående ansökan',
  },
  rejectRequest: {
    id: 'salaryExchangeMessages.rejectRequest',
    description: 'Salary exchange request is rejected',
    defaultMessage: 'Begäran nekad',
  },
  deductionValuePerMonth: {
    id: 'salaryExchangeMessages.deductionValuePerMonth',
    description: 'Salary exchange deduction value per month',
    defaultMessage: 'Löneväxlingsbelopp',
  },
  deductionValueOnetime: {
    id: 'salaryExchangeMessages.deductionValueOnetime',
    description: 'Salary exchange deduction value one time',
    defaultMessage: 'Bonusväxling',
  },
  salaryExchangesCount: {
    id: 'salaryExchangeMessages.salaryExchanges',
    description: 'Salary exchanges',
    defaultMessage:
      '{count, number} {count, plural, one {löneväxling} other {löneväxlingar}}',
  },
  salaryExchangeRequestsCount: {
    id: 'salaryExchangeMessages.salaryExchangeRequestsCount',
    description: 'Pending requests',
    defaultMessage:
      '{count, number} {count, plural, one {ansökning} other {ansökningar}}',
  },
  canceledSalaryExchangesCount: {
    id: 'salaryExchangeMessages.canceledSalaryExchangesCount',
    description:
      '{count, number} {count, plural, one {salary exchange canceled} other {salary exchanges canceled}}',
    defaultMessage:
      '{count, number} {count, plural, one {löneväxling avslutad} other {löneväxlingar avslutade}}',
  },
  salaryExchangeDeductionRangeHelperText: {
    id: 'salaryExchangeMessages.salaryExchangeDeductionRangeHelperText',
    description:
      'Enter a deduction between {min} and {max} per month. This is the deduction made from your salary before tax every month.',
    defaultMessage:
      'Ange ett löneavdrag mellan {min, number, ::currency/SEK .} och {max, number, ::currency/SEK .} per månad. Detta är lönen före skatt du avstår varje månad.',
  },
  newRequest: {
    id: 'salaryExchangeMessages.newRequest',
    description: 'New request',
    defaultMessage: 'Ny ansökan',
  },
  editRequest: {
    id: 'salaryExchangeMessages.editRequest',
    description: 'Edit request',
    defaultMessage: 'Ändra ansökan',
  },
  salaryExchangeInfoFootnote: {
    id: 'salaryExchangeMessages.salaryExchangeInfoFootnote',
    description:
      'The Payment is {monthlyPremium} because your occupational pension affects salary exchange. Your own monthly saving with salary exchange is {deductionValue}',
    defaultMessage:
      '* Inbetalningen är {monthlyPremium, number, ::currency/SEK .} eftersom din tjänstepension påverkas',
  },
  confirmSalaryExchange: {
    id: 'salaryExchangeMessages.confirmSalaryExchange',
    description: 'Confirm salary exchange',
    defaultMessage: 'Bekräfta löneväxling',
  },
  confirmOnetimeExchagne: {
    id: 'salaryExchangeMessages.confirmOnetimeExchagne',
    defaultMessage: 'Bekräfta bonusväxling',
  },
  investSalaryExchange: {
    id: 'salaryExchangeMessages.investSalaryExchange',
    description: 'Invest salary exchange',
    defaultMessage: 'Placera löneväxling',
  },
  investSalaryExchangeDesc: {
    id: 'salaryExchangeMessages.investSalaryExchangeDesc',
    description:
      'Choose how to invest your savings. You can switch to a new choice if you apply again.',
    defaultMessage:
      'Välj hur ditt sparande placeras. Du kan göra ett nytt val om du ansöker igen.',
  },
  extraPensionPayments: {
    id: 'salaryExchangeMessages.extraPensionPayments',
    defaultMessage: 'Pensionsinbetalningar',
  },
  pensionPayment: {
    id: 'salaryExchangeMessages.pensionPayment',
    defaultMessage: 'Pensionsinbetalning',
  },
  cancelRequest: {
    id: 'salaryExchangeMessages.cancelRequest',
    description: 'Cancel request',
    defaultMessage: 'Avbryt ansökan',
  },
  cancelRequestConfirm: {
    id: 'salaryExchangeMessages.cancelRequestConfirm',
    description: 'Are you sure to cancel the request?',
    defaultMessage: `Vill du avbryta din ansökan om ny löneväxling? {hasActiveSX, select,
        true {Din nuvarande löneväxling kommer inte påverkas.}
        false {}
        other {}
      }`,
  },
  cancelRequestSuccess: {
    id: 'salaryExchangeMessages.cancelRequestSuccess',
    description: 'Your salary exchange request has been canceled.',
    defaultMessage: 'Ansökan har avbryts',
  },
  onetimeSalaryExchanges: {
    id: 'salaryExchangeMessages.onetimeSalaryExchanges',
    defaultMessage: 'Bonusväxlingar',
  },
  applyOnetimeSalaryExchange: {
    id: 'salaryExchangeMessages.applyOnetimeSalaryExchange',
    defaultMessage: `Gör bonusväxling`,
  },
  activeOnetimeSalaryExchange: {
    id: 'salaryExchangeMessages.activeOnetimeSalaryExchange',
    defaultMessage: 'Bonusväxling godkänd',
  },
  activeOnetimeSalaryExchangeDesc: {
    id: 'salaryExchangeMessages.activeOnetimeSalaryExchangeDesc',
    defaultMessage: `
    Din ansökan om att växla {deductionValue, number, ::currency/SEK .} av din bonus på {onetimeSalaryAvailable, number, ::currency/SEK .} är nu godkänd och kommer att genomföras. Du kan ändra beloppet genom att göra en ny ansökan innan {endOfRange, date, ::dMMMMyyyy}.
    `,
  },
  adjustOnetimeSalaryExchange: {
    id: 'salaryExchangeMessages.adjustOnetimeSalaryExchange',
    defaultMessage: 'Ändra bonusväxling',
  },
  activeOnetimeSalaryExchangeAltDesc: {
    id: 'salaryExchangeMessages.activeOnetimeSalaryExchangeAltDesc',
    defaultMessage: `Din ansökan om att växla {deductionValue, number, ::currency/SEK .} av din bonus är nu godkänd och kommer att genomföras.`,
  },
  onetimeSalaryExchangeDesc: {
    id: 'salaryExchangeMessages.onetimeSalaryExchangeDesc',
    defaultMessage: `
    Du har fått en bonus på {onetimeSalaryAvailable, number, ::currency/SEK .}. Fram till och med den {date, date, ::dMMMMyyyy} kan du löneväxla upp till {deductionValue, number, ::currency/SEK .} som ett engångsbelopp.
    `,
  },
  cancelOnetimeSalaryExchange: {
    id: 'salaryExchangeMessages.cancelOnetimeSalaryExchange',
    defaultMessage: 'Avbryt bonusväxling',
  },
  outsideApplicationDateRangeInfo: {
    id: 'salaryExchangeMessages.outsideApplicationDateRangeInfo',
    defaultMessage: `Just nu kan du inte ansöka om löneväxling.
    {hasEndOfRange, select,
     true { Nästa ansökningsperiod för löneväxling är {startOfRange, date, ::dMMMMyyyy} till och med {endOfRange, date, ::dMMMMyyyy}.}
     other { Nästa ansökningsperiod för löneväxling startar {startOfRange, date, ::dMMMMyyyy}.}
    }`,
  },
  salaryExchangeConfigTitle: {
    id: 'salaryExchangeMessages.salaryExchangeConfigTitle',
    defaultMessage: `{salaryExchangeType, select,
      ONETIME {Hur mycket vill du bonusväxla?}
      other {Hur mycket vill du löneväxla?}
    }`,
  },
  changeOrEndSalaryExchange: {
    id: 'salaryExchangeMessages.changeOrEndSalaryExchange',
    defaultMessage: 'Ändra eller avsluta löneväxling',
  },
  endSalaryExchange: {
    id: 'salaryExchangeMessages.endSalaryExchange',
    defaultMessage: 'Avsluta löneväxling',
  },
  salaryExchangeRequestHeader: {
    id: 'salaryExchangeMessages.salaryExchangeRequestHeader',
    defaultMessage: 'Hur mycket vill du löneväxla?',
  },
  changeSalaryExchange: {
    id: 'salaryExchangeMessages.changeSalaryExchange',
    defaultMessage: 'Ändra löneväxling',
  },
  portfolioSectionHeader: {
    id: 'salaryExchangeMessages.portfolioSectionHeader',
    defaultMessage: `{count, plural,
      =1 {Din löneväxling}
      other {Dina löneväxlingar}}
      `,
  },
});

export const salaryExchangeRequestMessages = defineMessages({
  requested: {
    id: 'salaryExchangeRequestMessages.requested',
    defaultMessage:
      'Din arbetsgivare granskar din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} per månad.',
  },
  rejected: {
    id: 'salaryExchangeRequestMessages.rejected',
    defaultMessage: `Din arbetsgivare har nekat din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} per månad{hasRejectionReason, select,
      true { med anledningen: ”{rejectionReason}”}
      other {}
    }. Du kan ansöka på nytt eller kontakta din arbetsgivare.`,
  },
  accepted: {
    id: 'salaryExchangeRequestMessages.accepted',
    defaultMessage:
      'Din arbetsgivare har godkänt din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} från och med {effectiveDate, date, ::MMMMyyyy}. Du kan fortfarande ändra eller avbryta din ansökan.',
  },
  acceptedNotEligible: {
    id: 'salaryExchangeRequestMessages.acceptedNotEligible',
    defaultMessage:
      'Din arbetsgivare har godkänt din ansökan om att löneväxla {deductionValue, number, ::currency/SEK .} från och med {effectiveDate, date, ::MMMMyyyy}.',
  },
  outsideApplicationDateRange: {
    id: 'salaryExchangeRequestMessages.outsideApplicationDateRange',
    defaultMessage:
      'Just nu kan du inte ansöka om löneväxling. Nästa ansökningsperiod för löneväxling är {startOfRange, date, ::dMMMMyyyy} till och med {endOfRange, date, ::dMMMMyyyy}.',
  },
  confirmOnetimeDescription: {
    id: 'salaryExchangeRequestMessages.confirmOnetimeDescription',
    defaultMessage:
      'Godkänns din begäran sker avdraget på din kommande bonusutbetalning. Du kan inte ändra belopp på din bonusväxling efter att den blivit godkänd av din arbetsgivare.',
  },
  confirmOngoingDescription: {
    id: 'salaryExchangeRequestMessages.confirmOngoingDescription',
    defaultMessage:
      'Du kan ändra belopp eller avsluta din löneväxling när du vill.',
  },
});

export const createSalaryExchangeMessages = defineMessages({
  confirmLabel: {
    id: 'createSalaryExchangeMessages.confirmLabel',
    description:
      'I confirm that I have read, understood and agree to the above',
    defaultMessage:
      'Jag intygar att jag läst, förstått och godkänner ovanstående',
  },
  requestedSalaryExchange: {
    id: 'createSalaryExchangeMessages.requestedSalaryExchange',
    description: 'Requested salary exchange',
    defaultMessage: `
    {isOngoingSalaryExchange, select,
      true {Löneväxlingsbelopp}
      false {Bonusväxling}
     other {}
    }
    `,
  },
  requestSalaryExchangeTitle: {
    id: 'createSalaryExchangeMessages.requestSalaryExchangeTitle',
    description: 'Request salary exchange',
    defaultMessage: `Ansök om löneväxling`,
  },
});

export const salaryExchangeRequestStatusMessages =
  defineMessages<FlexSalaryExchangeRequestStatus>({
    APPROVED: {
      id: 'salaryExchangeRequestStatusMessages.APPROVED',
      defaultMessage: 'Accepterad',
    },
    CANCELLED: {
      id: 'salaryExchangeRequestStatusMessages.CANCELLED',
      defaultMessage: 'Avbruten',
    },
    REJECTED: {
      id: 'salaryExchangeRequestStatusMessages.REJECTED',
      defaultMessage: 'Nekad',
    },
    REQUESTED: {
      id: 'salaryExchangeRequestStatusMessages.REQUESTED',
      defaultMessage: 'Ansökt',
    },
  });

export const requestSalaryExchangeNudgeMessages = defineMessages({
  title: {
    id: 'requestSalaryExchangeNudgeMessages.title',
    defaultMessage: 'Börja löneväxla',
  },
  body: {
    id: 'requestSalaryExchangeNudgeMessages.body',
    defaultMessage:
      'Det är enkelt att sätta igång, bestäm belopp och välj din förvaltning. Godkänner din arbetsgivare din ansökan innan den 25:e i månaden så kommer ditt löneväxling och löneavdrag att börja gälla från och med månaden därpå.',
  },
  ctaLabel: {
    id: 'requestSalaryExchangeNudgeMessages.ctaLabel',
    defaultMessage: 'Börja löneväxla',
  },
});

export const salaryExchangeProviderMessages =
  defineMessages<LegacySalaryExchangeProvider>({
    advinans: {
      id: 'salaryExchangeProviderMessages.advinans',
      defaultMessage: 'Advinans Autopilot',
    },
    nordnet: {
      id: 'salaryExchangeProviderMessages.nordnet',
      defaultMessage: 'Nordnet Tjänstepension',
    },
    lf: {
      id: 'salaryExchangeProviderMessages.lf',
      defaultMessage: 'Länsförsäkringar',
    },
    collectum: {
      id: 'salaryExchangeProviderMessages.collectum',
      defaultMessage: 'Collectum Tjänstepension ITP',
    },
    nordea_fund: {
      id: 'salaryExchangeProviderMessages.nordea_fund',
      defaultMessage: 'Nordea Tjänstepension Fond',
    },
    nordea_trad: {
      id: 'salaryExchangeProviderMessages.nordea_trad',
      defaultMessage: 'Nordea Tjänstepension Garanti',
    },
  });

export const salaryExchangeProviderDescriptionMessages =
  defineMessages<SalaryExchangeProvider>({
    collectum: {
      id: 'salaryExchangeProviderDescriptionMessages.collectum',
      defaultMessage:
        'Premierna placeras enligt ditt nuvarande val hos Collectum',
    },
    nordnet: {
      id: 'salaryExchangeProviderDescriptionMessages.nordnet',
      defaultMessage:
        'Depåförsäkring där du själv investerar i aktier, fonder och andra värdepapper',
    },
    nordea_fund: {
      id: 'salaryExchangeProviderDescriptionMessages.nordea_fund',
      defaultMessage:
        'Fondförsäkring där Nordea Pensionsportfölj är entrelösning',
    },
    nordea_trad: {
      id: 'salaryExchangeProviderDescriptionMessages.nordea_trad',
      defaultMessage:
        'Traditionellt förvaltad försäkring där en del av din pension är garanterad',
    },
  });

export const salaryExchangeStatusMessages =
  defineMessages<FlexSalaryExchangeStatus>({
    ACTIVE: {
      id: 'salaryExchangeStatusMessages.ACTIVE',
      defaultMessage: 'Aktiv',
    },
    INACTIVE: {
      id: 'salaryExchangeStatusMessages.INACTIVE',
      defaultMessage: 'Ej aktiv fr.o.m. {effectiveDate}',
    },
  });

export const salaryExchangeMaxOngoingRuleMessages =
  defineMessages<FlexMaxOngoingRule>({
    AVAILABLE_INCOME_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.AVAILABLE_INCOME_RULE',
      defaultMessage:
        'Ditt individuella maxbelopp för löneväxling är {max} per månad för att din pensionsgrundande lön efter löneväxling ska vara minst {incomeMin} på kalenderårsbasis. Om du växlar ett högre belopp går du miste om avsättningar till allmän pension.',
    },
    BASE_SALARY_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.BASE_SALARY_RULE',
      defaultMessage:
        'Ditt individuella maxbelopp för löneväxling är {max} per månad för att din pensionsgrundande lön efter löneväxling ska vara minst {incomeMin} på kalenderårsbasis. Om du växlar ett högre belopp går du miste om avsättningar till allmän pension.',
    },
    ONGOING_DEDUCTION_SHARE_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.ONGOING_DEDUCTION_SHARE_RULE',
      defaultMessage:
        'Din arbetsgivare tillåter dig att löneväxla upp till {max} per månad.',
    },
    ONGOING_MAXIMUM_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.ONGOING_MAXIMUM_RULE',
      defaultMessage:
        'Din arbetsgivare tillåter dig att löneväxla upp till {max} per månad.',
    },
    ONGOING_MINIMUM_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.ONGOING_MINIMUM_RULE',
      defaultMessage:
        'Din arbetsgivare tillåter dig att löneväxla upp till {max} per månad.',
    },
    TOTAL_EXCHANGE_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.TOTAL_EXCHANGE_RULE',
      defaultMessage:
        'Du kan löneväxla upp till {max} per månad under resten av {effectiveDate, date, ::y} eftersom din arbetsgivare tillåter dig att löneväxla upp till {totalMax} per år.',
    },
    TOTAL_INCOME_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.TOTAL_INCOME_RULE',
      defaultMessage:
        'Ditt individuella maxbelopp för löneväxling är {max} per månad för att din pensionsgrundande lön efter löneväxling ska vara minst {incomeMin} på kalenderårsbasis. Om du växlar ett högre belopp går du miste om avsättningar till allmän pension.',
    },
    TOTAL_PENSION_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.TOTAL_PENSION_RULE',
      defaultMessage:
        'Ditt individuella maxbelopp för löneväxling är {max} per månad eftersom dina totala pensionsinbetalningar får vara högst {pensionMaxPbb} per år.',
    },
    TOTAL_PENSION_TO_SALARY_RULE: {
      id: 'salaryExchangeMaxOngoingRuleMessages.TOTAL_PENSION_TO_SALARY_RULE',
      defaultMessage:
        'Ditt individuella maxbelopp för löneväxling är {max} per månad eftersom dina totala pensionsinbetalningar får vara högst {pensionToSalaryMax, number, ::percent} av din pensionsgrundande årslön.',
    },
  });
