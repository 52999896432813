import { bodyLargeCSS, bodyMediumCSS, media, PageLede } from '@frontend/ui';
import styled from 'styled-components';

export const AuthLede = styled(PageLede)`
  ${bodyLargeCSS};
  ${media.lessThan('tablet')`
    ${bodyMediumCSS};
  `}
  margin-bottom: 1rem;
  text-align: center;
`;
