import { ContentContainer } from '@frontend/ui';
import styled from 'styled-components';

export const AUTH_FORM_WRAPPER_PADDING = 1.5;

export const AuthFormWrapper = styled(ContentContainer)`
  width: 100%;
  box-sizing: border-box;
  max-width: 43.75rem;
  padding: ${AUTH_FORM_WRAPPER_PADDING}rem;
  margin: 0 auto;
`;
