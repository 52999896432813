import { ApolloError } from '@apollo/client';
import { EmptyState } from 'components/EmptyState';
import { stripParentSegments, useRouteTitle } from 'containers/Route';
import { Page } from 'features/page';
import React from 'react';

interface Props {
  parentLink: string;
  children?: React.ReactNode;
  error?: ApolloError | string;
  errors?: ApolloError[];
  title?: React.ReactNode;
}

export const EmptyStatePage: React.FC<Props> = props => {
  const title = useRouteTitle();

  return (
    <Page
      title={props.title ?? stripParentSegments(title)}
      parentLink={props.parentLink ?? '/'}
      trackPageOnUnmount={false}
    >
      <EmptyState {...props} />
    </Page>
  );
};
