import {
  Button,
  Card,
  NewsCardLayout,
  NewsCardLayoutProps,
} from '@frontend/ui';
import { internalNewsMessages } from 'common/messages/internal-news';
import { CardLink } from 'components/CardLink';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { FormattedDate } from 'react-intl';
import styled from 'styled-components';

interface LinkItem {
  label: React.ReactNode;
  to: string;
}

interface Props extends NewsCardLayoutProps {
  link: LinkItem;
  date?: string;
  important?: boolean;
}

const ThreeLineDescription = styled.div`
  overflow: hidden;
  height: 4.5rem;
`;

export const NewsCard: React.FC<Props> = ({
  date,
  description,
  important,
  link,
  ...props
}) => {
  const { to, label } = link;

  return (
    <Card>
      <CardLink to={to}>
        <NewsCardLayout
          tagLabel={
            important && (
              <FormattedMessage {...internalNewsMessages.important} />
            )
          }
          comment={date && <FormattedDate value={date} />}
          description={
            <ThreeLineDescription>{description}</ThreeLineDescription>
          }
          {...props}
          actions={<Button text>{label}</Button>}
        />
      </CardLink>
    </Card>
  );
};
