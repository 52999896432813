import { EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { commonMessages } from 'common/messages';
import { economicProfileMessages } from 'common/messages/economic-profile';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { FormikWizard, SubmissionError } from 'containers/FormikWizard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { useSubmit } from './lib/use-submit';
import {
  EconomyBalance,
  validationSchema as economyBalanceValidation,
} from './pages/EconomyBalance';
import {
  EconomyMonthly,
  validationSchema as economyMonthlyValidation,
} from './pages/EconomyMonthly';

const pages = (intl: IntlShape) => [
  {
    offWhite: true,
    page: EconomyMonthly,
    validationSchema: () => economyMonthlyValidation(intl),
  },
  {
    offWhite: true,
    page: EconomyBalance,
    validationSchema: () => economyBalanceValidation(intl),
  },
];

export interface FormValues extends SubmissionError {
  assets?: string | null;
  liabilities?: string | null;
  monthlyExpenses?: string | null;
  monthlyIncome?: string | null;
}

export const EconomicProfile: React.FC<RouteComponentProps> = props => {
  const intl = useIntl();
  const {
    currentUser: { economicProfile },
  } = useCurrentUser();

  const { submit } = useSubmit(props);

  return (
    <FormikWizard<FormValues>
      title={<FormattedMessage {...economicProfileMessages.header} />}
      baseUrl={EMPLOYEE_ROOT_PATHS.economicProfile}
      parentLink={EMPLOYEE_ROOT_PATHS.index}
      initialValues={{
        assets: economicProfile?.assets,
        liabilities: economicProfile?.liabilities,
        monthlyIncome: economicProfile?.monthlyIncome,
        monthlyExpenses: economicProfile?.monthlyExpenses,
      }}
      submitLabel={<FormattedMessage {...commonMessages.continue} />}
      onSubmit={submit}
      pages={pages(intl)}
    />
  );
};
