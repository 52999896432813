import { totalCompensationDetails } from 'common/fragments';
import gql from 'graphql-tag';

export const TOTAL_COMPENSATION_CARD_QUERY = gql`
  query totalCompensationCardQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      monthlySalary
      totalCompensation: totalCompensation2 {
        ...totalCompensationDetails
      }
    }
  }
  ${totalCompensationDetails}
`;
