import {
  bodyLargeCSS,
  defaultTheme,
  Grid,
  GridCell,
  headlineSmallCSS,
} from '@frontend/ui';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Title = styled.span`
  ${headlineSmallCSS};
  margin-bottom: 1rem;
`;

const Lede = styled.span`
  ${bodyLargeCSS};
  margin-bottom: 1rem;
`;

const Body = styled.div`
  ${bodyLargeCSS};
  margin-bottom: 1.5rem;

  > div:last-child {
    margin-bottom: 0;
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Illustration = styled.img`
  width: 100%;
`;

interface Props {
  actions: React.ReactNode;
  illustration: string;
  lede: React.ReactNode;
  title: React.ReactNode;
  body?: React.ReactNode;
}

export const HeroCard: React.FC<Props> = ({
  title,
  lede,
  body,
  illustration,
  actions,
}) => {
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.desktop * 16);

  return (
    <Grid>
      <GridCell desktop={7} tablet={8}>
        <ContentWrapper>
          <Title>{title}</Title>
          <Lede>{lede}</Lede>
          {!!body && <Body>{body}</Body>}
          {actions}
        </ContentWrapper>
      </GridCell>
      {!isSmallScreen && <GridCell desktop={1} />}
      {!isSmallScreen && (
        <GridCell desktop={4}>
          <IllustrationWrapper>
            <Illustration src={illustration} alt="" role="presentation" />
          </IllustrationWrapper>
        </GridCell>
      )}
    </Grid>
  );
};
