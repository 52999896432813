import { bodySmallCSS, Grid, GridCell, labelLargeCSS } from '@frontend/ui';
import EXTERNAL_URLS from 'app/lib/external-urls';
import { footerMessages } from 'common/messages/footer';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import styled from 'styled-components';

import { FooterA } from '../../components/FooterA';

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  ${bodySmallCSS}

  > li {
    margin-bottom: 1rem;
  }
`;

const ListHeader = styled.div`
  ${labelLargeCSS}
  margin-bottom: 1rem;

  > * span {
    color: ${p => p.theme.onSurface};
  }
`;

export const Contact: React.FC = () => (
  <Grid>
    <GridCell desktop={4} tablet={3} phone={4}>
      <ListHeader>
        <FormattedMessage {...footerMessages.aboutNordeaNode} />
      </ListHeader>
      <Ul>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeFaq}>
            <FormattedMessage {...footerMessages.commonQuestions} />
          </FooterA>
        </li>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeEnvPolicy}>
            <FormattedMessage {...footerMessages.environmentPolicy} />
          </FooterA>
        </li>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeSecurity}>
            <FormattedMessage {...footerMessages.safetyWork} />
          </FooterA>
        </li>
      </Ul>
    </GridCell>
    <GridCell desktop={4} tablet={3} phone={4}>
      <ListHeader>
        <FormattedMessage {...footerMessages.law} />
      </ListHeader>
      <Ul>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeForms}>
            <FormattedMessage {...footerMessages.formsAndAgreements} />
          </FooterA>
        </li>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeUserTerms}>
            <FormattedMessage {...footerMessages.termsAndConditions} />
          </FooterA>
        </li>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodeCookies}>
            <FormattedMessage {...footerMessages.cookies} />
          </FooterA>
        </li>
        <li>
          <FooterA href={EXTERNAL_URLS.nordeaNodePersonalData}>
            <FormattedMessage {...footerMessages.personalData} />
          </FooterA>
        </li>
      </Ul>
    </GridCell>
    <GridCell desktop={4} tablet={3} phone={4}>
      <ListHeader>
        <FormattedMessage {...footerMessages.contact} />
      </ListHeader>
      <Ul>
        <li>
          <FormattedMessage
            {...footerMessages.emailContact}
            values={{
              email: (
                <FooterA href="mailto:support@nordeanode.se">
                  support@nordeanode.se
                </FooterA>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage
            {...footerMessages.phoneContact}
            values={{
              phone: <FooterA href="tel:02122030">021 - 22 030</FooterA>,
            }}
          />
        </li>
        <li>
          <FormattedMessage {...footerMessages.openingHours} />
        </li>
      </Ul>
    </GridCell>
  </Grid>
);
