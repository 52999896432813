import { PAYMENT_MODEL } from 'features/benefits/presentation/embedded/PersonalAdviceCard/utils/constants';
import { defineMessages } from 'react-intl';

export const personalAdviceInfoMessages = defineMessages({
  presentationCardTitle: {
    id: 'personalAdviceInfoMessages.presentationCardTitle',
    defaultMessage: 'Din förmån',
  },
  priceLabel: {
    id: 'personalAdviceInfoMessages.priceLabel',
    defaultMessage: 'Ditt pris',
  },
  pricePerMeetingSuffix: {
    id: 'personalAdviceInfoMessages.pricePerMeetingSuffix',
    defaultMessage: '/möte',
  },
  latestAdvisoryMeetingEmptyState: {
    id: 'personalAdviceInfoMessages.latestAdvisoryMeetingEmptyState',
    defaultMessage: 'Ingen tidigare mötesbokning',
  },
  latestAdvisoryMeetingLabel: {
    id: 'personalAdviceInfoMessages.latestAdvisoryMeetingLabel',
    defaultMessage: 'Ditt senaste möte',
  },
  earliestNextAdvisoryMeetingLabel: {
    id: 'personalAdviceInfoMessages.earliestNextAdvisoryMeetingLabel',
    defaultMessage: 'Du kan boka tidigast',
  },
  upcomingAdvisoryMeetingLabel: {
    id: 'personalAdviceInfoMessages.upcomingAdvisoryMeetingLabel',
    defaultMessage: 'Ditt bokade möte',
  },
  editBookingCta: {
    id: 'personalAdviceInfoMessages.editBookingCta',
    defaultMessage: 'Ändra mötesbokning',
  },
  bookCta: {
    id: 'personalAdviceInfoMessages.bookCta',
    defaultMessage: 'Boka möte',
  },
  meetingFrequencySuffix: {
    id: 'personalAdviceInfoMessages.meetingFrequencySuffix',
    defaultMessage: `{count, plural,
      =0 {}
      one { varje månad}
      two { varannan månad}
      other { var #:e månad}
    }`,
  },
  tableHeader: {
    id: 'personalAdviceInfoMessages.tableHeader',
    defaultMessage: 'Dina rådgivningsmöten',
  },
  dashboardCardTitle: {
    id: 'personalAdviceInfoMessages.dashboardCardTitle',
    defaultMessage: 'Personlig rådgivning',
  },
});

export const personalAdvicePaymentDescriptionMessages =
  defineMessages<PAYMENT_MODEL>({
    EMPLOYER_PAYS_MONTHLY: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYER_PAYS_MONTHLY',
      defaultMessage:
        'Kostnaden för rådgivning betalas av din arbetsgivare. {availability}',
    },
    EMPLOYER_PAYS_PER_MEETING: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYER_PAYS_PER_MEETING_LIMITED',
      defaultMessage:
        'Kostnaden för rådgivning betalas av din arbetsgivare. {availability}',
    },
    EMPLOYEE_HAS_ACCESS_TO_SINGLE_MEETING: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_HAS_ACCESS_TO_SINGLE_MEETING',
      defaultMessage:
        'Du har möjlighet att boka ett möte som betalas av din arbetsgivare.',
    },
    EMPLOYEE_HAS_ACCESS_TO_SINGLE_MEETING_USED: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_HAS_ACCESS_TO_SINGLE_MEETING_USED',
      defaultMessage:
        'Du har redan genomfört ditt rådgivningsmöte som betalas av din arbetsgivare.',
    },
    EMPLOYEE_PAYS_MONTHLY_ENABLED: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_PAYS_MONTLY_ENABLED',
      defaultMessage:
        'Rådgivningen betalas genom en avgift som dras av din månatliga insättning till tjänstepensionen. {availability} Om du inte har behov av personlig rådgivning kan du kontakta kundtjänst för att ta bort förmånen.',
    },
    EMPLOYEE_PAYS_MONTHLY_ENABLED_FREE_PERIOD: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_PAYS_MONTHLY_ENABLED_FREE_PERIOD',
      defaultMessage:
        'Du har möjlighet att boka rådgivning utan kostnad fram till om med {limitingDate, date, ::yyyyMMdd}. Därefter betalas rådgivningen av dig som anställd genom en avgift på {fee, number, ::currency/SEK .}/månad som dras av din månatliga insättning till tjänstepensionen.',
    },
    EMPLOYEE_PAYS_MONTHLY_DISABLED: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_PAYS_MONTLY_DISABLED',
      defaultMessage:
        'Du har valt att ta bort förmånen personlig rådgivning och avgiften dras inte längre från din månatliga insättning till tjänstepensionen. Kontakta kundtjänst om du vill ha tillgång till personlig rådgivning igen.',
    },
    EMPLOYEE_PAYS_PER_MEETING: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_PAYS_PER_MEETING',
      defaultMessage:
        'Kostnaden för rådgivning betalas av dig som anställd genom ett engångsbelopp per möte.',
    },
    EMPLOYEE_PAYS_PER_MEETING_FREE_PERIOD: {
      id: 'personalAdvicePaymentDescriptionMessages.EMPLOYEE_PAYS_PER_MEETING_FREE_PERIOD',
      defaultMessage:
        'Du har möjlighet att boka rådgivning utan kostnad fram till om med {limitingDate, date, ::yyyyMMdd}. Därefter betalas rådgivningen av dig som anställd genom ett engångsbelopp per möte på {fee, number, ::currency/SEK .}.',
    },
    INCL_IN_CAPITAL_FEE: {
      id: 'personalAdvicePaymentDescriptionMessages.INCL_IN_CAPITAL_FEE',
      defaultMessage:
        'Personlig rådgivning ingår i din tjänstepension som betalas av din arbetsgivare. {availability}',
    },
  });

export const personalAdviceMeetingFrequencyMessages = defineMessages({
  UNLIMITED: {
    id: 'personalAdviceMeetingFrequencyMessages.UNLIMITED',
    defaultMessage: 'Du har fri tillgång till rådgivningsmöten.',
  },
  LIMITED: {
    id: 'personalAdviceMeetingFrequencyMessages.LIMITED',
    defaultMessage: 'Du har möjlighet att boka ett möte {frequency}.',
  },
});
