import gql from 'graphql-tag';

export const HAS_ADMIN_MENU_CONTENT_QUERY = gql`
  query hasAdminMenuContentQuery($id: ID!, $benefitTypeNames: [String!]) {
    company(id: $id) {
      id
      hasJobOffers
      flexBenefits(benefitTypeNames: $benefitTypeNames) {
        edges {
          node {
            id
            content {
              locale
            }
          }
        }
      }
    }
  }
`;
