import gql from 'graphql-tag';

export const LOGIN_POLLING_QUERY = gql`
  query loginPollingQuery($ticketId: ID!) {
    loginTicket(ticketId: $ticketId) {
      ... on BankIdTicketProgress {
        progressStatus: status
        qrData
      }
      ... on BankIdTicketFailure {
        failureStatus: status
      }
      ... on LoginTicketComplete {
        token
      }
    }
  }
`;
