import { pensionCardQuery_Query } from 'app/apollo/graphql/types';

import { isPensionEntitlement } from './is-pension-entitlement';

interface PensionCardData {
  /**
   * The type of pension benefit
   */
  benefitType: string;
  /**
   * The current month's pension premium
   */
  pensionPremium: string;
  /**
   * The next month's pension premium if it exists
   */
  nextPensionPremium?: string | null;
  /**
   * Toggles the visibility of the next month's pension premium,
   * which is dependant on the state of the users' entitlement for the next month
   */
  showNextPensionPremium?: boolean;
}

/**
 * Retrieves the relevant pension card data from the query response
 * or returns null if the user has no access to pension.
 */
export const getPensionCardData = (
  data?: pensionCardQuery_Query,
  totalCompensation?: boolean,
): PensionCardData | null => {
  if (!data?.entitlements) {
    return null;
  }

  if (
    totalCompensation &&
    !data?.company?.totalCompensationConfiguration?.pension
  ) {
    return null;
  }

  const entitlement = data?.entitlements?.edges?.[0]?.node;

  const pensionEntitlement =
    entitlement && isPensionEntitlement(entitlement) ? entitlement : undefined;

  const pensionPremium = pensionEntitlement?.netPremium;

  if (!pensionPremium || pensionEntitlement?.status !== 'ACTIVE') {
    return null;
  }

  const nextMonthEntitlement = data?.entitlementsOnDate?.edges?.[0]?.node;
  const nextMonthPensionEntitlement =
    nextMonthEntitlement && isPensionEntitlement(nextMonthEntitlement)
      ? nextMonthEntitlement
      : undefined;

  const nextPensionPremium = nextMonthPensionEntitlement?.netPremium;

  const showNextPensionPremium =
    nextMonthPensionEntitlement?.status === 'ACTIVE' &&
    !!nextPensionPremium &&
    nextPensionPremium !== pensionPremium;

  return {
    benefitType: entitlement.benefit.type,
    pensionPremium,
    nextPensionPremium,
    showNextPensionPremium,
  };
};
