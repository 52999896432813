export const ADMIN_ROOT_PATHS = {
  benefitPackages: '/admin/benefits/benefit-packages',
  benefits: '/admin/benefits',
  company: '/admin/company',
  employees: '/admin/employees',
  index: `/admin`,
  internalNews: '/admin/internal-news',
  invoices: '/admin/invoices',
  jobOffers: '/admin/job-offers',
  payslips: '/admin/payslips',
  reports: '/admin/reports',
  salaryExchanges: '/admin/salary-exchanges',
};

export const EMPLOYEE_ROOT_PATHS = {
  benefits: '/benefits',
  connectedServices: '/connected-services',
  economicProfile: '/economic-profile',
  index: '/',
  insurances: '/insurances',
  internalNews: '/internal-news',
  payslips: '/payslips',
  pension: '/pension',
  pensionTransfer: '/pension-transfer',
  settings: '/settings',
  totalCompensation: '/total-compensation',
  upgradeFri: '/upgrade-fri',
};
