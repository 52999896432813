import { ApolloError } from '@apollo/client';
import {
  ButtonLayout,
  ContentContainer,
  EmptyState as EmptyStateUI,
  Subsection,
} from '@frontend/ui';
import { commonMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { GraphQlError, GraphQlErrors } from 'components/GraphQlError';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';

const isFormattedError = (error: ApolloError | string): error is string =>
  typeof error === 'string';

interface Props {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  error?: ApolloError | string;
  errors?: ApolloError[];
  title?: React.ReactNode;
}

export const EmptyState: React.FC<Props> = ({
  title,
  children,
  actions,
  error,
  errors,
}) => {
  const formattedError = errors?.length ? (
    <GraphQlErrors errors={errors} />
  ) : error ? (
    isFormattedError(error) ? (
      <NotificationCard type="error">{error}</NotificationCard>
    ) : (
      <GraphQlError error={error} />
    )
  ) : undefined;

  return (
    <>
      {formattedError && <ContentContainer>{formattedError}</ContentContainer>}
      <EmptyStateUI
        title={
          title ?? <FormattedMessage {...commonMessages.EmptyState404Message} />
        }
      >
        <Subsection>{children}</Subsection>
        {actions && <ButtonLayout align="center">{actions}</ButtonLayout>}
      </EmptyStateUI>
    </>
  );
};
