import { Grid, GridCell, Section, SectionHeader } from '@frontend/ui';
import {
  internalPostsSectionQuery,
  internalPostsSectionQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { internalNewsMessages } from 'common/messages/internal-news';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import { NewsCard } from 'components/NewsCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { INTERNAL_POSTS_SECTION_QUERY } from './graphql/queries';

export const InternalPostsSection: React.FC = () => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { data } = useQuery<
    internalPostsSectionQuery,
    internalPostsSectionQueryVariables
  >(INTERNAL_POSTS_SECTION_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    skip: !currentCompany,
    variables: { companyId: currentCompany?.id ?? '' },
    // This query must not affect the empty state of the page.
    // Ideally the component/query composition should be different
    // to avoid having to pass this hard-coded `isEmpty` function.
    isEmpty: () => true,
  });

  if (!data?.company) {
    return null;
  }

  const { internalPosts } = data.company;

  const posts = internalPosts?.edges.slice(0, 3);

  if (!posts?.length) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        actions={
          <LinkButton text to="/internal-news">
            <FormattedMessage {...commonMessages.showAll} />
          </LinkButton>
        }
      >
        <FormattedMessage {...internalNewsMessages.news} />
      </SectionHeader>
      <Grid>
        {posts.map(post => (
          <GridCell key={post.node.id} span={4}>
            <NewsCard
              link={{
                to: `/internal-news/${post.node.id}`,
                label: <FormattedMessage {...commonMessages.readMore} />,
              }}
              title={post.node.title}
              description={post.node.description}
              image={post.node.image ? post.node.image : undefined}
              date={post.node.publishedAt}
              important={post.node.important}
            />
          </GridCell>
        ))}
      </Grid>
    </Section>
  );
};
