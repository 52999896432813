import { media } from '@frontend/ui';
import styled from 'styled-components';

export const AUTH_CONTAINER_WIDTH = 33.5;

interface Props {
  fullViewHeight: number;
}

export const AuthContainer = styled.div<Props>`
  justify-content: space-between;
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: ${p => `${p.fullViewHeight}px`};
  width: ${AUTH_CONTAINER_WIDTH}rem;

  ${media.lessThan('desktop')`
    width: 100%;
  `}
`;
