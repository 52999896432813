export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z',
      },
    },
  ],
};
