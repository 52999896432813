import { cardLinkCss } from '@frontend/ui';
import React from 'react';
import styled from 'styled-components';

import { PlainButton } from '../PlainButton';

interface LinkProps {
  fullWidth?: boolean;
  hoverDisabled?: boolean;
}

const ScrollLink = styled(PlainButton)<LinkProps>`
  ${p => p.fullWidth && 'width: 100%;'}
  ${p => !p.hoverDisabled && cardLinkCss}
`;

const APPBAR_WITH_TABS_OFFSET = 100;
const PADDING_OFFSET = 16;

export const scrollTop = () => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch {
    window.scrollTo(0, 0);
  }
};

export const scrollTo = (querySelector: string | number) => {
  if (typeof querySelector === 'string') {
    const el = document.querySelector(querySelector) as HTMLElement;

    if (el) {
      window.scroll({
        top: el.offsetTop - APPBAR_WITH_TABS_OFFSET - PADDING_OFFSET,
        left: 0,
        behavior: 'smooth',
      });
    }
  } else if (typeof querySelector === 'number') {
    window.scroll({ top: querySelector, left: 0, behavior: 'smooth' });
  }
};

interface Props {
  children: React.ReactNode;
  to: string;
  fullWidth?: boolean;
  hoverDisabled?: boolean;
}

export const LinkScrollTo: React.FC<Props> = ({
  to,
  fullWidth,
  hoverDisabled,
  children,
}) => (
  <ScrollLink
    fullWidth={fullWidth}
    hoverDisabled={hoverDisabled}
    onClick={() => scrollTo(to)}
  >
    {children}
  </ScrollLink>
);
