import { ApolloError } from '@apollo/client';
import { alphabeticallyAscending } from '@frontend/utils';
import {
  companyRemunerationTypesQuery,
  companyRemunerationTypesQuery_company_Company_remunerationTypes_RemunerationTypeConnection_edges_RemunerationTypeEdge_node_RemunerationType as remunerationTypeDetails,
  companyRemunerationTypesQueryVariables,
} from 'app/apollo/graphql/types';
import { useCurrentUser } from 'contexts/current-user';
import { COMPANY_REMUNERATION_TYPES_QUERY } from 'features/account-plan/graphql/queries';
import { useQuery } from 'utils/use-query';

interface RemunerationType {
  id: string;
  name: string | null;
}

interface Edge<N> {
  node: N;
}

export const formatRemunerationTypes = <T extends RemunerationType>(
  edges: readonly Edge<T>[] | undefined,
): T[] =>
  edges
    ?.map(({ node }) => node)
    ?.sort((a, b) => alphabeticallyAscending(a.id, b.id)) ?? [];

interface RemunerationTypes {
  loading: boolean;
  remunerationTypes: readonly remunerationTypeDetails[];
  error?: ApolloError;
}

interface Args {
  suspend?: boolean;
}

export const useRemunerationTypes = (args?: Args): RemunerationTypes => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { loading, error, data } = useQuery<
    companyRemunerationTypesQuery,
    companyRemunerationTypesQueryVariables
  >(COMPANY_REMUNERATION_TYPES_QUERY, {
    skip: !currentCompany?.id,
    variables: { companyId: currentCompany?.id ?? '' },
    errorPolicy: 'all',
    suspend: args?.suspend,
  });

  const remunerationTypes = formatRemunerationTypes(
    data?.company?.remunerationTypes?.edges,
  );

  return {
    loading,
    remunerationTypes,
    error,
  };
};
