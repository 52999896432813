import { bodyMediumCSS, Card, CardContent, titleMediumCSS } from '@frontend/ui';
import { CardLink } from 'components/CardLink';
import {
  ActionType,
  ExternalLink,
  ILLUSTRATION_MAP,
  isExternal,
} from 'containers/Discover';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  text-align: center;
  height: 100%;
  box-shadow:
    0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.08),
    0 0.1875rem 0.25rem 0 rgba(0, 0, 0, 0.04),
    0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.08);
  border: none;
  margin-right: 0.75rem;
  margin-top: 0.125rem;
`;

const ActionIllustration = styled.img`
  height: 5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const Title = styled.div`
  ${titleMediumCSS};
  padding-bottom: 0.5rem;
`;

const Description = styled.div`
  ${bodyMediumCSS};
`;

interface Props {
  children: React.ReactNode;
  to: LinkProps['to'] | ExternalLink;
}

const Link: React.FC<Props> = ({ to, children, ...props }) => (
  <CardLink
    to={!isExternal(to) ? to : undefined}
    href={isExternal(to) ? to.href : undefined}
    {...props}
  >
    {children}
  </CardLink>
);

interface IllustrationCardProps {
  action: ActionType;
  description: React.ReactNode;
  link: LinkProps['to'] | ExternalLink;
  title: React.ReactNode;
}

export const IllustrationCard: React.FC<IllustrationCardProps> = ({
  link,
  title,
  description,
  action,
}) => (
  <StyledCard>
    <Link to={link}>
      <CardContent>
        <ActionIllustration src={ILLUSTRATION_MAP[action]} alt="" />
        <Title>{title}</Title>
        <Description>{description}</Description>
      </CardContent>
    </Link>
  </StyledCard>
);
