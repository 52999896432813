import { ContentContainer, Grid, Section as _Section } from '@frontend/ui';
import { commonMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { Discover } from 'containers/Discover';
import { Suspense } from 'containers/Suspense';
import { FeatureType, useCurrentUser } from 'contexts/current-user';
import { CollectiveAgreementCard } from 'features/dashboard/collective-agreement-card';
import { CompanyCarCard } from 'features/dashboard/company-car-card';
import { FitnessContributionCard } from 'features/dashboard/fitness-contribution-card';
import { InsuranceCard } from 'features/dashboard/insurance-card';
import { InternalPostsSection } from 'features/dashboard/internal-posts';
import { Loading } from 'features/dashboard/loading';
import { PensionCard } from 'features/dashboard/pension-card';
import { PersonalAdviceCard } from 'features/dashboard/personal-advice-card';
import { SalaryExchangeCard } from 'features/dashboard/salary-exchange-card';
import { TotalCompensationCard } from 'features/dashboard/total-compensation-card';
import { VacationCard } from 'features/dashboard/vacation-card';
import { useFeaturePreview } from 'features/feature-preview';
import { Page } from 'features/page';
import { Card as PayslipCard } from 'features/payslip/components/Card';
import { TransferMeetingBanner } from 'features/pension/transfer/components/TransferMeetingBanner';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

interface SectionProps {
  hide: boolean;
}

const Section = styled(_Section)<SectionProps>`
  ${p => p.hide && 'display: none'};
`;

export const IndexRoute: React.FC<
  RouteComponentProps
> = routeComponentProps => {
  useFeaturePreview({ feature: FeatureType.ONBOARDING_TOUR });
  useFeaturePreview({
    feature: FeatureType.SETTINGS_TOUR,
    prerequisite: FeatureType.ONBOARDING_TOUR,
  });

  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const givenName = currentCompany?.membershipGivenName;

  return (
    <Page
      title={
        <FormattedMessage
          {...commonMessages.greeting}
          values={{ hasGivenName: !!givenName, givenName }}
        />
      }
    >
      <Suspense batch fallback={<Loading />}>
        {({ errors, isEmpty }) => (
          <>
            <TransferMeetingBanner {...routeComponentProps} />
            <ContentContainer>
              {errors && <GraphQlErrors errors={errors} />}
              <Section hide={isEmpty()}>
                <Grid>
                  <TotalCompensationCard />
                  <PensionCard />
                  <CollectiveAgreementCard />
                  <InsuranceCard />
                  <FitnessContributionCard />
                  <SalaryExchangeCard />
                  <PersonalAdviceCard />
                  <PayslipCard />
                  <VacationCard />
                  <CompanyCarCard />
                </Grid>
              </Section>
              <InternalPostsSection />
              <Discover {...routeComponentProps} />
            </ContentContainer>
          </>
        )}
      </Suspense>
    </Page>
  );
};
