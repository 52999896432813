const EXTERNAL_URLS = {
  ap7: 'https://www.pensionsmyndigheten.se/forsta-din-pension/valj-och-byt-fonder/ap7-safa-och-statens-ovriga-sparalternativ-for-premiepensionen',
  collectum: 'https://www.collectum.se/',
  advinansPlusTerms:
    'https://docs.nordeanode.se/node/plus-terms-2024-10-09.pdf',
  skatteverketPensionPremiums:
    'https://www4.skatteverket.se/rattsligvagledning/edition/2019.3/339027.html',
  purchaseInformation:
    'https://docs.nordeanode.se/node/precontract-information-2024-10-09.pdf',
  advinansFriAgreementLink:
    'https://docs.nordeanode.se/node/information-service-terms-2024-02-01.pdf',
  nordeaNodeFaq: 'https://nordeanode.freshdesk.com/',
  nordeaNodeEnvPolicy: 'https://nordeanode.se/legal-information/miljopolicy/',
  nordeaNodeSecurity: 'https://nordeanode.se/legal-information/sakerhet/',
  nordeaNodeForms: 'https://nordeanode.se/blanketter',
  nordeaNodeUserTerms:
    'https://nordeanode.se/legal-information/anvandarvillkor/',
  nordeaNodeCookies: 'https://nordeanode.se/legal-information/cookies/',
  nordeaNodePersonalData:
    'https://nordeanode.se/legal-information/personuppgifter/',
  ie: 'https://www.microsoft.com/sv-se/download/internet-explorer-11-for-windows-7-details.aspx',
  edge: 'https://support.microsoft.com/sv-se/help/4027667/windows-update-windows-10',
  investmentSustainabilityFaq:
    'https://nordeanode.freshdesk.com/sv-SE/support/solutions/articles/77000558230-nordea-nodes-principer-f%C3%B6r-h%C3%A5llbara-investeringar',
  pensionTransferAgreement:
    'https://docs.nordeanode.se/node/transfer-advice-terms-2024-10-09.pdf',
  advinansMovieTicketAgreement:
    'https://docs.nordeanode.se/node/movie-ticket-precontract-terms-2024-02-01.pdf',
  nordeaPensionsPortfolj:
    'https://www.nordea.se/privat/produkter/pension/nordea-pensionsportfolj.html',
  nordeaTradPortfolj:
    'https://www.nordea.se/foretag/produkter/pension/tjanstepension-garanti.html',
  epassiBikeStandardPolicyHref: 'https://epassibike.se/pages/policy',
  epassiBikePartners:
    'https://www.epassi.se/sv/anstalld/formanscykel/partnernatverk',
  epassiLogin: 'https://services.epassi.se/ui/login',
};

export default EXTERNAL_URLS;
