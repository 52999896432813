import { ApolloError } from '@apollo/client';
import { stripSearchParams } from '@frontend/utils';
import {
  pensionTransferMeetingQuery,
  pensionTransferMeetingQueryVariables,
} from 'app/apollo/graphql/types';
import { PENSION_TRANSFER_MEETING_QUERY } from 'common/queries/pension-transfer';
import qs from 'query-string';
import { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import { useQuery } from 'utils/use-query';

interface Props {
  error?: ApolloError;
  loading?: boolean;
  scheduledTime?: string | null;
  schedulingLink?: string | null;
}

export const usePensionTransferMeeting = (args: {
  userAccountId: string;
  routeComponentProps?: RouteComponentProps;
  skip?: boolean;
}): Props => {
  const { userAccountId, routeComponentProps, skip } = args;

  const parsedScheduledTime = useRef<string | null>();

  const { data, loading, error, updateQuery } = useQuery<
    pensionTransferMeetingQuery,
    pensionTransferMeetingQueryVariables
  >(PENSION_TRANSFER_MEETING_QUERY, {
    errorPolicy: 'all',
    skip,
    suspend: 'no-fallback',
    variables: {
      userAccountId,
    },
  });

  /* XXX: The external booking service (Calendly) redirects to the app dashboard
  with event data as query parameters after booking submission. We use the
  event start time query param to modify the PENSION_TRANSFER_MEETING_QUERY cache
  in frontend as our backend service might not receive updated Calendly event data
  for up to 20 sec. */

  const onBookingSuccess = () => {
    if (routeComponentProps) {
      const { history, location } = routeComponentProps;
      const { event_start_time } = qs.parse(location.search);

      if (event_start_time) {
        parsedScheduledTime.current = event_start_time;
        stripSearchParams(history, location);
      }
    }
  };

  useEffect(() => {
    onBookingSuccess();
  }, []);

  const writeScheduledTimeToCache = (_scheduledTime: string) => {
    updateQuery<pensionTransferMeetingQuery>(_data => ({
      userAccount: {
        __typename: 'UserAccount',
        id: userAccountId,
        pensionTransferMeeting: {
          __typename: 'PensionTransferMeeting',
          schedulingLink: _data?.userAccount ?? null,
          scheduledTime: _scheduledTime,
        },
      },
    }));
  };

  useEffect(() => {
    if (data?.userAccount && parsedScheduledTime.current) {
      writeScheduledTimeToCache(parsedScheduledTime.current);
      parsedScheduledTime.current = null;
    }
  }, [data]);

  if (loading) {
    return { loading };
  }

  if (!data?.userAccount) {
    return { error };
  }

  const schedulingLink = null;
  const scheduledTime = null;

  return {
    error,
    schedulingLink,
    scheduledTime,
  };
};
