export default {
  children: [
    {
      name: 'polygon',
      attribs: {
        fill: 'currentColor',
        points: '1,11 1,21 6,21 6,15 10,15 10,21 15,21 15,11 8,6',
      },
    },
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M10,3v1.97l7,5V11h2v2h-2v2h2v2h-2v4h6V3H10z M19,9h-2V7h2V9z',
      },
    },
  ],
};
