import { loginMessages } from 'common/messages/login';
import { FormattedMessage } from 'components/formats';
import React from 'react';

// Nordea Node started authentication (considered progress)
export const AUTH = 'AUTH';

// Progress Status as in BankID
export const OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION';
export const NO_CLIENT = 'NO_CLIENT';
export const STARTED = 'STARTED';
export const USER_SIGN = 'USER_SIGN';
export const COMPLETE = 'COMPLETE';

// Exceptions as in BankID (client visible, others are INTERNAL_ERROR)
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const CLIENT_ERR = 'CLIENT_ERR';
export const EXPIRED_TRANSACTION = 'EXPIRED_TRANSACTION';
export const CERTIFICATE_ERR = 'CERTIFICATE_ERR';
export const USER_CANCEL = 'USER_CANCEL';
export const CANCELLED = 'CANCELLED';
export const START_FAILED = 'START_FAILED';
export const ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS';

export const SANCTIONED_ERROR = 'SANCTIONED_ERROR';

// HTTP Error
export const NO_RESPONSE = 'NO_RESPONSE';

// Interval (in ms) to poll BankID for status
export const POLLING_INTERVAL = 2000;

export const FORM_NAME = 'login';

const bankIdProgressStatusTypes = [
  'GENERAL',
  'INIT',
  'NO_CLIENT',
  'OUTSTANDING_TRANSACTION',
  'STARTED',
  'USER_SIGN',
  'PENDING',
  'PROCESSING',
] as const;

export type BankIdProgressStatus = (typeof bankIdProgressStatusTypes)[number];

export const isBankIdProgressStatus = (
  status?: string,
): status is BankIdProgressStatus =>
  status != null &&
  bankIdProgressStatusTypes.includes(status as BankIdProgressStatus);

export type BankIdProgressFailure =
  | 'GENERAL'
  | 'CANCELLED'
  | 'CERTIFICATE_ERR'
  | 'CLIENT_ERR'
  | 'EXPIRED_TRANSACTION'
  | 'USER_UNDER_AGE_18'
  | 'USER_CANCEL'
  | 'COMPLETE'
  | 'ALREADY_IN_PROGRESS'
  | 'START_FAILED'
  | 'INTERNAL_ERROR'
  | 'SPAR_LOOKUP_FAILED'
  | 'USER_NOT_FOUND';

export const PROGRESS_STATUS: {
  [k in BankIdProgressStatus]: JSX.Element | string;
} = {
  GENERAL: <FormattedMessage {...loginMessages.generalProgress} />,
  INIT: <FormattedMessage {...loginMessages.generalProgress} />,
  NO_CLIENT: <FormattedMessage {...loginMessages.generalProgress} />,
  PENDING: <FormattedMessage {...loginMessages.generalProgress} />,
  PROCESSING: <FormattedMessage {...loginMessages.generalProgress} />,
  OUTSTANDING_TRANSACTION: (
    <FormattedMessage {...loginMessages.generalProgress} />
  ),
  STARTED: <FormattedMessage {...loginMessages.started} />,
  USER_SIGN: <FormattedMessage {...loginMessages.userSign} />,
};

export const FAILURE_STATUS: {
  [k in BankIdProgressFailure]: JSX.Element | string;
} = {
  GENERAL: <FormattedMessage {...loginMessages.generalFailure} />,
  CANCELLED: <FormattedMessage {...loginMessages.cancelled} />,
  CERTIFICATE_ERR: <FormattedMessage {...loginMessages.certificateErr} />,
  CLIENT_ERR: <FormattedMessage {...loginMessages.clientErr} />,
  EXPIRED_TRANSACTION: (
    <FormattedMessage {...loginMessages.expiredTransaction} />
  ),
  USER_CANCEL: <FormattedMessage {...loginMessages.userCancel} />,
  COMPLETE: <FormattedMessage {...loginMessages.generalFailure} />,
  USER_UNDER_AGE_18: <FormattedMessage {...loginMessages.underageFailure} />,
  ALREADY_IN_PROGRESS: (
    <FormattedMessage {...loginMessages.alreadyInProgress} />
  ),
  START_FAILED: <FormattedMessage {...loginMessages.generalFailure} />,
  SPAR_LOOKUP_FAILED: <FormattedMessage {...loginMessages.sparLookupFailure} />,
  INTERNAL_ERROR: <FormattedMessage {...loginMessages.generalFailure} />,
  USER_NOT_FOUND: <FormattedMessage {...loginMessages.userNotFound} />,
};
