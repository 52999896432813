import { companyDetails, userAccountDetails } from 'common/fragments';
import gql from 'graphql-tag';

export const CURRENT_USER_CONTEXT_QUERY = gql`
  query currentUserContextQuery {
    viewer {
      ...userAccountDetails
      insurances(instituteId: "PPM") {
        totalCount
      }
      memberships {
        edges {
          node {
            id
            birthdate
            givenName
            lastName
            roles
            company {
              ...companyDetails
            }
            membershipBenefitPackage {
              benefitPackage {
                id
              }
              showTotalCompensation
            }
            employments {
              edges {
                node {
                  id
                  period
                }
              }
            }
          }
        }
      }
    }
  }
  ${companyDetails}
  ${userAccountDetails}
`;
