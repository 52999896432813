import { Icon } from '@frontend/ui';
import { shield } from '@frontend/ui/icons';
import {
  insuranceCardQuery,
  insuranceCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { dashboardMessages } from 'common/messages/dashboard';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import { benefitTypeNames } from 'features/benefits/utils/types';
import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'utils/use-query';

import { InsuranceItem, InsuranceType } from './components/InsuranceItems';
import { INSURANCE_CARD_QUERY } from './graphql/queries';
import { getInsuranceCardData } from './utils/get-insurance-card-data';

const FigureContainer = styled.div`
  > :first-child {
    margin-bottom: 1rem;
  }
`;

export const InsuranceCard: React.FC = () => {
  const {
    currentUser: { currentCompany, userAccountId },
  } = useCurrentUser();

  const { data } = useQuery<insuranceCardQuery, insuranceCardQueryVariables>(
    INSURANCE_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      isEmpty: d => !getInsuranceCardData(d),
      variables: {
        userAccountId,
        entitlementsCompanyId: currentCompany?.id,
        membershipCompanyId: currentCompany?.id ?? '',
        benefitTypeNames: benefitTypeNames.slice(),
        hasMembership: !!currentCompany,
      },
    },
  );

  const insuranceCardData = getInsuranceCardData(data);

  if (!insuranceCardData) {
    return null;
  }

  const { insurances } = insuranceCardData;

  const insurancesList: React.ReactNode[] = [];

  if (insurances.life) {
    insurancesList.push(
      <InsuranceItem key="insurance-item-life" type={InsuranceType.LIFE} />,
    );
  }

  if (insurances.accident) {
    insurancesList.push(
      <InsuranceItem
        key="insurance-item-accident"
        type={InsuranceType.ACCIDENT}
      />,
    );
  }

  if (insurances.sickness) {
    insurancesList.push(
      <InsuranceItem
        key="insurance-item-sickness"
        type={InsuranceType.SICKNESS}
      />,
    );
  }

  if (insurances.health) {
    insurancesList.push(
      <InsuranceItem key="insurance-item-health" type={InsuranceType.HEALTH} />,
    );
  }

  return (
    <CardGridCell>
      <SnapshotCard
        link={{
          to: '/benefits/insurances',
          label: <FormattedMessage {...dashboardMessages.showInsurances} />,
        }}
        icon={<Icon icon={shield} color="primary" size="medium" decorative />}
        title={<FormattedMessage {...commonMessages.insurance} />}
        figure1={
          <FigureContainer>{insurancesList.slice(0, 2)}</FigureContainer>
        }
        figure2={
          insurancesList.length > 2 && (
            <FigureContainer>{insurancesList.slice(2, 4)}</FigureContainer>
          )
        }
      />
    </CardGridCell>
  );
};
