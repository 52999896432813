import {
  DatePickerField,
  NumberField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import { ReadOnlyField } from '@frontend/ui';
import { getMostRecentEmployment, group } from '@frontend/utils';
import {
  editSalaryQuery,
  editSalaryQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'common/messages';
import {
  FormattedCurrency,
  FormattedMessage,
  IntlShape,
  useIntl,
} from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import { useCurrentUser } from 'contexts/current-user';
import { formatRemunerationTypes } from 'features/account-plan/remunerations/utils/use-remuneration-types';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React, { useEffect } from 'react';
import {
  MONTHLY_SALARY_REMUNERATION_TYPE_ID,
  UnitCodeOptions,
  unitCodeSuffixMessages,
} from 'utils/constants';
import { formatRemunerationSuffix } from 'utils/format-remuneration-suffix';
import { useQuery } from 'utils/use-query';
import { isValidMonth } from 'validations';
import * as Yup from 'yup';

import { FormValues } from '../..';
import { EDIT_SALARY_QUERY } from '../../graphql/queries';

const getRemunerations = (data?: editSalaryQuery) => {
  const latestEmployment = data?.membership?.employment
    ? getMostRecentEmployment(data.membership.employment)
    : undefined;

  return latestEmployment?.remuneration ?? [];
};

export const enterDetailsValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    amount: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid date',
        intl.formatMessage(validationMessages.invalidMonth),
        value => isValidMonth(value),
      ),
    remunerationType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface EnterDetailsFormValues {
  amount: string;
  effectiveDate: string;
  remunerationType: string;
}

export const enterDetailsInitialValues: EnterDetailsFormValues = {
  amount: '',
  effectiveDate: '',
  remunerationType: '',
};

export const EnterDetailsHeader: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <FormattedMessage
      {...adminShortcutMessages.newSalary}
      values={{
        employee: values.userAccount?.label,
      }}
    />
  );
};

export const EnterDetailsBody: React.FC = () => {
  const intl = useIntl();
  const { locale } = useIntlContext();

  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { setFieldValue, values } = useFormikContext<FormValues>();

  const companyId = currentCompany?.id;
  const userAccountId = values.userAccount?.value;

  const { data, loading, error } = useQuery<
    editSalaryQuery,
    editSalaryQueryVariables
  >(EDIT_SALARY_QUERY, {
    errorPolicy: 'all',
    skip: !values.userAccount?.value,
    variables: {
      companyId: companyId ?? '',
      userAccountId: userAccountId ?? '',
    },
  });

  const latestRemunerationsByType = group({
    itemsOf: getRemunerations(data),
    by: r => r.type.id,
  }).map(([current]) => current);

  const remunerationTypes = formatRemunerationTypes(
    data?.company?.remunerationTypes?.edges,
  );

  const options = remunerationTypes?.map(({ id, name }) => ({
    value: id,
    label: `${id} ${name ?? ''}`,
  }));

  const selectedRemunerationType = remunerationTypes?.find(
    ({ id }) => id === values.remunerationType,
  );

  const latestRemunerationOfSelectedType = latestRemunerationsByType.find(
    ({ type }) => type.id === values.remunerationType,
  );

  // Initial value on the select field result in a strikethrough for the floating label,
  // Most likely because the option isn't available when the value is set.
  // This is a workaround to avoid that.
  useEffect(() => {
    if (options.length && !values.remunerationType) {
      setFieldValue('remunerationType', MONTHLY_SALARY_REMUNERATION_TYPE_ID);
    }
  }, [options, setFieldValue, values.remunerationType]);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} inModal />}
      <p>
        <FormattedMessage {...adminShortcutMessages.newSalaryDescription} />
      </p>
      <SelectField
        dense
        disabled={loading}
        fixed
        label={<FormattedMessage {...adminShortcutMessages.remunerationType} />}
        name="remunerationType"
        options={options}
        required
      />
      {selectedRemunerationType && latestRemunerationOfSelectedType && (
        <ReadOnlyField
          label={<FormattedMessage {...adminShortcutMessages.currentSalary} />}
          value={
            <FormattedMessage
              {...adminShortcutMessages.currentSalaryValue}
              values={{
                value: (
                  <FormattedCurrency
                    after={
                      selectedRemunerationType.unitCode &&
                      selectedRemunerationType.unitCode !==
                        UnitCodeOptions.LS ? (
                        <>
                          /
                          <FormattedMessage
                            select={selectedRemunerationType.unitCode}
                            messages={unitCodeSuffixMessages}
                          />
                        </>
                      ) : null
                    }
                    currency={selectedRemunerationType.currency ?? ''}
                    noSuffix={!selectedRemunerationType.currency}
                    value={latestRemunerationOfSelectedType.value}
                  />
                ),
                effectiveDate: new Date(latestRemunerationOfSelectedType.from),
              }}
            />
          }
        />
      )}
      <NumberField
        affix={
          selectedRemunerationType
            ? formatRemunerationSuffix(
                intl,
                selectedRemunerationType.currency,
                selectedRemunerationType.unitCode,
              )
            : undefined
        }
        decimalScale={0}
        dense
        label={<FormattedMessage {...adminShortcutMessages.newSalaryLabel} />}
        locale={locale}
        name="amount"
        required
      />
      <DatePickerField
        fixed
        dense
        name="effectiveDate"
        type="month"
        label={<FormattedMessage {...commonMessages.effectiveDate} />}
        required
      />
    </>
  );
};
