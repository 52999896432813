import {
  MutationFunction,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import { stripSearchParams } from '@frontend/utils';
import {
  cancelLoginMutation,
  cancelLoginMutationVariables,
  loginMutation,
  loginMutationVariables,
  loginPollingQuery,
  loginPollingQueryVariables,
} from 'app/apollo/graphql/types';
import { CANCEL_LOGIN_MUTATION, LOGIN_MUTATION } from 'common/mutations';
import { LOGIN_POLLING_QUERY } from 'common/queries/log-in';
import { initGA } from 'contexts/google/analytics';
import { allowed, THIRD_PARTY_COOKIES } from 'features/cookie-banner';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router';
import { useQuery } from 'utils/use-query';

import { BankIdProgressFailure } from '../common/constants';
import { createSession } from './lib/create-session';
import { getErrorMessageFromGraphQlError } from './lib/get-error-message-from-graphql-error';
import { signBankIdWithSameDevice } from './lib/sign-bankid-with-same-device';

interface Args {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  onSignSuccess: () => void;
  initialTicketId?: string;
}
export interface SignWithSameDeviceProps {
  autoStartToken: string;
  ticketId: string;
}

interface LoginObject {
  cancelLogin: () => Promise<void>;
  loading: boolean;
  signWithSameDevice: (props?: SignWithSameDeviceProps) => void;
  startLogin: MutationFunction<loginMutation, loginMutationVariables>;
  error?: string;
  qrCode?: string;
  sessionToken?: string;
}

interface Error {
  invalidTicketError?: BankIdProgressFailure | string;
  pollingDataError?: BankIdProgressFailure | string;
  sessionError?: BankIdProgressFailure | string;
}

export const useLogin = ({
  initialTicketId,
  onSignSuccess,
  history,
  location,
}: Args): LoginObject => {
  const [ticketId, setTicketId] = useState<string | undefined>(initialTicketId);
  const [{ sessionError, invalidTicketError, pollingDataError }, setError] =
    useState<Error>({});

  const [cookies] = useCookies([THIRD_PARTY_COOKIES]);

  const [_cancelLogin] = useMutation<
    cancelLoginMutation,
    cancelLoginMutationVariables
  >(CANCEL_LOGIN_MUTATION);

  const [_startLogin, { data: loginData, error: mutationError }] = useMutation<
    loginMutation,
    loginMutationVariables
  >(LOGIN_MUTATION, {
    errorPolicy: 'all',
    onCompleted: ({ login }) => {
      if (!login) {
        return null;
      }

      const { ticketId: _ticketId } = login;
      return setTicketId(_ticketId);
    },
  });

  const startLogin = (
    input: MutationFunctionOptions<loginMutation, loginMutationVariables>,
  ) => {
    setError(() => ({}));
    return _startLogin(input);
  };

  const { data, error: pollingError } = useQuery<
    loginPollingQuery,
    loginPollingQueryVariables
  >(LOGIN_POLLING_QUERY, {
    skip: !ticketId,
    variables: { ticketId: ticketId ?? '' },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    pollInterval: ticketId ? 1000 : undefined,
  });

  /* If LOGIN_POLLING_QUERY doesn't return any errors but the
    loginTicket response is null, treat it like an error and
    stop the polling.
  */
  useEffect(() => {
    if (ticketId && data && !data.loginTicket) {
      setError(_error => ({ ..._error, invalidTicketError: 'GENERAL' }));
      setTicketId(undefined);
      stripSearchParams(history, location, ['ticketId']);
    }
  }, [ticketId, data, location, history]);

  const cancelLogin = async () => {
    if (!ticketId) {
      return;
    }
    try {
      await _cancelLogin({
        variables: { input: { ticketId } },
      });
    } finally {
      setTicketId(undefined);
      stripSearchParams(history, location, ['ticketId']);
    }
  };

  const loginTicket = data?.loginTicket;

  useEffect(() => {
    if (loginTicket?.__typename === 'BankIdTicketFailure') {
      setError(_error => ({
        ..._error,
        pollingDataError: loginTicket.failureStatus,
      }));
      setTicketId(undefined);
    }
  }, [loginTicket]);

  const loading =
    !!ticketId ||
    loginTicket?.__typename === 'BankIdTicketProgress' ||
    loginTicket?.__typename === 'LoginTicketComplete';

  const autoStartToken = loginData?.login?.autoStartToken;

  const qrCode =
    loginTicket?.__typename === 'BankIdTicketProgress'
      ? loginTicket.qrData ?? loginData?.login?.qrData
      : loginData?.login?.qrData;

  const sessionToken =
    loginTicket?.__typename === 'LoginTicketComplete'
      ? loginTicket.token
      : undefined;

  const error =
    pollingDataError ??
    (pollingError
      ? getErrorMessageFromGraphQlError(pollingError.graphQLErrors)
      : mutationError
        ? getErrorMessageFromGraphQlError(mutationError.graphQLErrors)
        : sessionError ?? invalidTicketError);

  const createSessionFromToken = useCallback(
    async (token: string): Promise<void> => {
      try {
        await createSession(token, onSignSuccess);
      } catch (e) {
        setError(_error => ({
          ..._error,
          sessionError: e.message || 'GENERAL',
        }));
      } finally {
        initGA(allowed(cookies.adv_third_party_cookies));
        setTicketId(undefined);
      }
    },
    [cookies, onSignSuccess],
  );

  useEffect(() => {
    if (sessionToken) {
      createSessionFromToken(sessionToken);
    }
  }, [sessionToken, createSessionFromToken]);

  /* If this is called right after `startLogin`, always pass
  `autoStartToken` and `ticketId` as function parameters as the
  data values in this hook might not be available yet!
   */
  const signWithSameDevice = (params?: SignWithSameDeviceProps) => {
    signBankIdWithSameDevice({
      autoStartToken: params?.autoStartToken ?? autoStartToken,
      ticketId: params?.ticketId ?? ticketId ?? '',
      history,
      location,
    });
  };

  return {
    startLogin,
    cancelLogin,
    loading,
    signWithSameDevice,
    qrCode,
    sessionToken,
    error,
  };
};
