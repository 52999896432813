import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { schedule, wavingHand } from '@frontend/ui/icons';
import { featurePreviewMessages } from 'common/messages/feature-preview';
import { FormattedMessage } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useFeaturePreview } from 'features/feature-preview';
import React from 'react';
import { sleep } from 'utils/sleep';

export const Step1 = () => {
  const { next, dismiss, snooze } = useFeaturePreview();
  const { setIsDrawerOpen } = useNavigationContext();

  return (
    <Modal isOpen size="small">
      <ModalHeader icon={wavingHand}>
        <FormattedMessage {...featurePreviewMessages.step1Header} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...featurePreviewMessages.step1Body} />
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button text onClick={snooze} icon={schedule}>
            <FormattedMessage {...featurePreviewMessages.snoozeAction} />
          </Button>
          <ButtonLayout align="right">
            <Button text onClick={dismiss}>
              <FormattedMessage
                {...featurePreviewMessages.step1SecondaryAction}
              />
            </Button>
            <Button
              text
              onClick={async () => {
                setIsDrawerOpen(true);
                // This sleep ensures that the navigation drawer
                // is allowed to expand fully before the tooltip is rendered.
                // Without this, the tooltip will be positioned relative to
                // to the hidden menu drawer item.
                await sleep(250);

                next?.();
              }}
            >
              <FormattedMessage
                {...featurePreviewMessages.step1PrimaryAction}
              />
            </Button>
          </ButtonLayout>
        </div>
      </ModalFooter>
    </Modal>
  );
};
