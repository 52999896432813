import { ActivatePlusContext } from 'features/plus/lib/activate-plus-context';
import { PlusStatus } from 'utils/advinans-plus-status';

import { Context } from './types';

interface NudgePlusAdvice {
  nudgePlusAdvice: boolean;
  nudgePlusContext?: ActivatePlusContext;
}

export interface Data {
  insuranceAnalysis?: {
    id: string;
  } | null;
  plusAdviceHistory?: {
    edges: ReadonlyArray<{
      node: {
        id: string;
      };
    }>;
  } | null;
}

export const getNudgePlusAdvice = <T extends Data>(
  contexts: Context[],
  advinansPlusStatus: PlusStatus,
  data?: T,
): NudgePlusAdvice => {
  const hasPlusBenefit =
    advinansPlusStatus === PlusStatus.FULL_ACCESS ||
    advinansPlusStatus === PlusStatus.NEEDS_SIGNATURE;

  const nudgeRiskInsuranceAdvice =
    hasPlusBenefit &&
    !data?.insuranceAnalysis &&
    contexts.includes(Context.INSURANCES);

  const nudgePensionAdvice =
    hasPlusBenefit &&
    !data?.plusAdviceHistory?.edges.length &&
    contexts.includes(Context.EXTERNAL_PENSION_INSURANCE);

  const nudgePlusAdvice = nudgeRiskInsuranceAdvice || nudgePensionAdvice;

  const nudgePlusContext: ActivatePlusContext | undefined = nudgePlusAdvice
    ? nudgePensionAdvice
      ? 'pension'
      : 'risk-insurances'
    : undefined;

  return {
    nudgePlusAdvice,
    nudgePlusContext,
  };
};
