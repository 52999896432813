import { NumberField } from '@frontend/formik';
import { WizardContentCard } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import { suffixMessages, validationMessages } from 'common/messages';
import { economicProfileMessages } from 'common/messages/economic-profile';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { SideSheetSection } from 'components/SideSheet';
import { PageProps } from 'containers/FormikWizard';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import { WithSideSheet } from 'containers/WithSideSheet';
import React from 'react';
import * as Yup from 'yup';

import { FormValues } from '../..';
import {
  EconomicProfile,
  getEconomicProfileRatios,
} from '../../lib/get-economic-profile-ratio';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    liabilities: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .nullable(),
    assets: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .nullable(),
  });

type Props = FormValues & PageProps<FormValues>;

export const EconomyBalance: React.FC<Props> = ({
  errors,
  assets,
  direction,
  liabilities,
  monthlyIncome,
  monthlyExpenses,
}) => {
  const getDebtRatioWarning = (profile: EconomicProfile) => {
    const ratios = getEconomicProfileRatios(profile);

    if (ratios.debtRatio && ratios.debtRatio > 6) {
      return <FormattedMessage {...economicProfileMessages.debtRatioWarning} />;
    }

    return undefined;
  };

  const warning = getDebtRatioWarning({
    assets: toNumber(assets) ?? null,
    liabilities: toNumber(liabilities) ?? null,
    monthlyIncome: toNumber(monthlyIncome) ?? null,
    monthlyExpenses: toNumber(monthlyExpenses) ?? null,
  });

  const sideSheetContent = (
    <>
      <SideSheetSection>
        <FormattedMessage {...economicProfileMessages.riskSideSheet} />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage
          {...economicProfileMessages.economyBalanceSideSheetOne}
        />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage
          {...economicProfileMessages.economyBalanceSideSheetTwo}
        />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage {...economicProfileMessages.advinansDataPolicy} />
      </SideSheetSection>
    </>
  );

  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  return (
    <WithSideSheet help={sideSheetContent}>
      {({ helpButton }) => (
        <WizardContentCard
          slideFrom={direction}
          header={
            <FormattedMessage
              {...economicProfileMessages.economyBalanceTitle}
            />
          }
          description={
            <FormattedMessage {...economicProfileMessages.economyBalanceDesc} />
          }
          helpButton={helpButton}
        >
          <NumberField
            dense
            name="assets"
            label={
              <FormattedMessage {...economicProfileMessages.assetsLabel} />
            }
            affix={formatMessage(suffixMessages.kr)}
            helperText={
              <FormattedMessage {...economicProfileMessages.assetsText} />
            }
            decimalScale={0}
            required
            locale={locale}
          />
          <NumberField
            dense
            name="liabilities"
            label={
              <FormattedMessage {...economicProfileMessages.liabilitiesLabel} />
            }
            affix={formatMessage(suffixMessages.kr)}
            helperText={
              <FormattedMessage {...economicProfileMessages.liabilitiesText} />
            }
            decimalScale={0}
            required
            locale={locale}
          />
          {!!warning && (
            <NotificationCard type="warning">{warning}</NotificationCard>
          )}
          {!!errors?.submissionError && (
            <NotificationCard type="error">
              {errors.submissionError}
            </NotificationCard>
          )}
        </WizardContentCard>
      )}
    </WithSideSheet>
  );
};
