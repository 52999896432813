import { NaturalPersonIdentifier } from '@advinans/belt-natural-person-identifier';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import startOfMonth from 'date-fns/startOfMonth';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/min';
import _isEmail from 'validator/lib/isEmail';

/**
 * List of valid email domains.
 */
const VALID_EMAIL_DOMAINS = [
  '.uk',
  '.tech',
  '.se',
  '.ro',
  '.pl',
  '.pe',
  '.org',
  '.nu',
  '.net',
  '.me',
  '.life',
  '.law',
  '.io',
  '.ie',
  '.fr',
  '.football',
  '.fi',
  '.eu',
  '.es',
  '.energy',
  '.dev',
  '.de',
  '.cz',
  '.com',
  '.br',
  '.ac',
  '.ai',
  '.dk',
  '.no',
];

/**
 * Checks is belongs to a, by us, valid email domain.
 */
export const isValidEmailDomain = (value?: string): boolean =>
  !!value && VALID_EMAIL_DOMAINS.some(domain => value.endsWith(domain));

/**
 * Checks if provided email is valid and ensures local part of
 * email only consists of English characters.
 *
 * @param value - email value
 * @returns {boolean} - Returns true if email is valid.
 */
export const isEmail = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  return (
    _isEmail(value, { allow_utf8_local_part: false }) &&
    isValidEmailDomain(value)
  );
};

export const isValidNaturalPersonIdentifier = (value): boolean => {
  try {
    NaturalPersonIdentifier.FromString(value);
  } catch {
    return false;
  }

  return true;
};

// Normalizers
export const normalizeWhiteSpace = (
  val?: string | null,
): string | null | undefined => {
  if (val) {
    const trimed = val.trim();
    return trimed.length === 0 ? null : trimed;
  }
  return val;
};

// eslint-disable-next-line
export const monthToDate = (value: unknown): any => {
  if (typeof value === 'string') {
    // Matches format YYYY-MM
    const dateFormatRegEx = /^\d{4}-\d{2}$/;
    if (isValid(new Date(value)) && value.match(dateFormatRegEx) != null) {
      return format(startOfMonth(new Date(value)), 'yyyy-MM-dd');
    }
  }

  return value;
};

const DEFAULT_REGION = 'SE';

// Parse the raw value as a phone number object, or fail with null
function parsePhoneRaw(value: string): PhoneNumber | null {
  if (!value) {
    return null;
  }

  const parsedNumber = parsePhoneNumberFromString(value, DEFAULT_REGION);

  return parsedNumber || null;
}

export const isValidPhoneNumber = (value): boolean => {
  const parsed = parsePhoneRaw(value);

  if (parsed === null || value.length > 13) {
    return false;
  }

  return true;
};

export const isValidDate = (value: unknown): boolean => {
  if (value === undefined || value === null || value === '') {
    return false;
  }

  if (typeof value === 'string' && isValid(new Date(value))) {
    return true;
  }

  return false;
};

export const isValidMonth = (value: unknown): boolean => {
  if (value === undefined || value === null || value === '') {
    return false;
  }

  if (typeof value === 'string') {
    // Matches format YYYY-MM
    const dateFormatRegEx = /^\d{4}-\d{2}$/;
    if (isValid(new Date(value)) && value.match(dateFormatRegEx) != null) {
      return true;
    }
  }

  return false;
};
