import { Status } from 'features/assert-work-capability/components/FcfwStatus';
import { defineMessages } from 'react-intl';

import { commonMessages } from './';

export const employmentMessages = defineMessages({
  noEmployments: {
    id: 'employmentMessages.noEmployments',
    description: 'No employments exists',
    defaultMessage: 'Det finnas inga anställningar att visa',
  },
  employmentStartDate: {
    id: 'employmentMessages.employmentStartDate',
    description: 'Employment start date',
    defaultMessage: 'Anställningsdatum',
  },
  employmentEndDate: {
    id: 'employmentMessages.employmentEndDate',
    description: 'Employment end date',
    defaultMessage: 'Avgångsdatum',
  },
  addEmployment: {
    id: 'employmentMessages.addEmployment',
    description: 'Add employment',
    defaultMessage: 'Lägg till anställning',
  },
  editEmployment: {
    id: 'employmentMessages.editEmployment',
    description: 'Edit employment',
    defaultMessage: 'Ändra anställning ',
  },
  addEmploymentForName: {
    id: 'employmentMessages.addEmploymentForName',
    description:
      'Message saying you can add employment for an employee, takes {employeeName} as value.',
    defaultMessage: 'Lägg till anställning för {employeeName}',
  },
  editEmploymentForName: {
    id: 'employmentMessages.editEmploymentForName',
    description:
      'Message saying you can edit employment for an employee, takes {employeeName} as value.',
    defaultMessage: 'Ändra anställning för {employeeName}',
  },
  absenceAlternatives: {
    id: 'employmentMessages.absenceAlternatives',
    defaultMessage: 'Alternativ för frånvaro',
  },
  removeAbsence: {
    id: 'employmentMessages.removeAbsence',
    defaultMessage: 'Ta bort frånvaro',
  },
  editAbsence: {
    id: 'employmentMessages.editAbsence',
    defaultMessage: 'Redigera frånvaro',
  },
  absenceRemovalSuccessful: {
    id: 'employmentMessages.absenceRemovalSuccessful',
    defaultMessage: 'Frånvaron togs bort',
  },
  absenceRemovalFailure: {
    id: 'employmentMessages.absenceRemovalFailure',
    defaultMessage: 'Frånvaron kunde inte tas bort',
  },
  confirmDeleteEmploymentActivityTitle: {
    id: 'employmentMessages.confirmDeleteEmploymentActivityTitle',
    defaultMessage: 'Ta bort frånvaro?',
  },
  confirmDeleteEmploymentActivity: {
    id: 'employmentMessages.confirmDeleteEmploymentActivity',
    description: 'Confirm message shown before deleting an employment activity',
    defaultMessage: `{type, select,
      OFF_DUTY {Tjänstledigheten}
      PARENTAL_LEAVE {Föräldraledigheten}
      other {Frånvaron}
    } kommer att tas bort och den anställdes försäkringsskydd kan påverkas.`,
  },
  addEmploymentActivity: {
    id: 'employmentMessages.addEmploymentActivity',
    description: 'Add employment activity',
    defaultMessage: 'Lägg till frånvaro',
  },
  addEmploymentActivityForName: {
    id: 'employmentMessages.addEmploymentActivityForName',
    description:
      'Message saying you can add employment activity for an employee, takes {employeeName} as value',
    defaultMessage: 'Lägg till frånvaro för {employeeName}',
  },
  editEmploymentActivityForName: {
    id: 'employmentMessages.editEmploymentActivityForName',
    description:
      'Message saying you can edit employment activity for an employee, takes {employeeName} and {type} as values',
    defaultMessage: `Ändra {type, select,
      OFF_DUTY {tjänstledighet}
      PARENTAL_LEAVE {föräldraledighet}
      other {frånvaron}
    } för {employeeName}`,
  },
  employmentActivity: {
    id: 'employmentMessages.employmentActivity',
    description: 'Employment activity',
    defaultMessage: 'Frånvaro',
  },
  employmentActivityRate: {
    id: 'employmentMessages.employmentActivityRate',
    description: 'Employment activity rate',
    defaultMessage: 'Frånvaro omfattning',
  },
  employmentActivityRateLabel: {
    id: 'employmentMessages.employmentActivityRateLabel',
    description: 'Employment activity rate: {rate}',
    defaultMessage: 'Frånvaro omfattning: {rate}',
  },
  employmentActivityEffectiveDate: {
    id: 'employmentMessages.employmentActivityEffectiveDate',
    description: 'Employment activity effective date',
    defaultMessage: 'Frånvaro gäller fr.o.m.',
  },
  employmentActivityEffectiveUntil: {
    id: 'employmentMessages.employmentActivityEffectiveUntil',
    description: 'Employment activity effective until',
    defaultMessage: 'Frånvaro gäller t.o.m.',
  },
  noEmploymentActivities: {
    id: 'employmentMessages.noEmploymentActivities',
    description: 'No employment activities for this employment',
    defaultMessage: 'Det finns ingen frånvaro kopplad till denna anställning',
  },
  employmentActivityType: {
    id: 'employmentMessages.employmentActivityType',
    description: 'Employment activity type',
    defaultMessage: 'Typ av frånvaro',
  },
  deleteEmployment: {
    id: 'employmentMessages.deleteEmployment',
    defaultMessage: 'Ta bort anställning',
  },
  deleteEmploymentForName: {
    id: 'employmentMessages.deleteEmploymentForName',
    defaultMessage: 'Ta bort anställning för {employeeName}',
  },
  deleteEmploymentDesc: {
    id: 'employmentMessages.deleteEmploymentDesc',
    defaultMessage:
      'Anställningen kommer att tas bort. Den anställde kommer mista rätten till förmåner för denna anställning. Vill du fortsätta?',
  },
  deleteEmploymentSuccess: {
    id: 'formSubmitMessages.deleteEmploymentSuccess',
    defaultMessage: 'Anställningen har tagits bort',
  },
  deleteEmploymentWarning: {
    id: 'employmentMessages.deleteEmploymentWarning',
    defaultMessage:
      'Medarbetaren kommer anses anställd under minst en dag och kan därför fortfarande ha rätt till försäkringar och förmåner. Om du vill avsluta anställningen från start använd "{deleteEmploymentMsg}".',
  },
  contactToDeleteEmployment: {
    id: 'employmentMessages.contactToDeleteEmployment',
    defaultMessage:
      'Medarbetaren kommer anses anställd under minst en dag och kan därför fortfarande ha rätt till försäkringar och förmåner. Om du vill avsluta anställningen från start så behöver du kontakta kundtjänst.',
  },
});

export const workInformationMessages = defineMessages({
  ownerLabel: {
    id: 'workInformationMessages.ownerLabel',
    description: 'Position',
    defaultMessage: 'Ägarposition',
  },
  owner: {
    id: 'workInformationMessages.owner',
    description: 'Owns at least a third of company stock',
    defaultMessage: 'Äger minst en tredjedel av företagets aktier',
  },
  personStatusLabel: {
    id: 'workInformationMessages.personStatusLabel',
    description: 'Status',
    defaultMessage: 'Anställningsstatus',
  },
  collectiveAgreementLabel: {
    id: 'workInformationMessages.collectiveAgreementLabel',
    description: 'Kollektivavtal',
    defaultMessage: 'Kollektivavtal',
  },
  employeeNumberLabel: {
    id: 'workInformationMessages.employeeNumberLabel',
    description: 'Anställningsnummer',
    defaultMessage: 'Anställningsnummer',
  },
  vacationDaysPerYearLabel: {
    id: 'workInformationMessages.vacationDaysPerYearLabel',
    description: 'Semesterrätt',
    defaultMessage: 'Semesterrätt',
  },
  vacationDaysSavedLabel: {
    id: 'workInformationMessages.vacationDaysSavedLabel',
    description: 'Sparade semesterdagar',
    defaultMessage: 'Sparade semesterdagar',
  },
  vacationDaysPaidLabel: {
    id: 'workInformationMessages.vacationDaysPaidLabel',
    description: 'Betalda semesterdagar',
    defaultMessage: 'Betalda semesterdagar',
  },
  companyCarMonthlyValueLabel: {
    id: 'workInformationMessages.companyCarMonthlyValueLabel',
    description: 'Förmånsvärde tjänstebil',
    defaultMessage: 'Förmånsvärde tjänstebil',
  },
  fitnessContributionBalanceLabel: {
    id: 'workInformationMessages.fitnessContributionBalanceLabel',
    description: 'Fitness contribution balance',
    defaultMessage: 'Friskvårdssaldo',
  },
  fitnessContributionBalanceHelperText: {
    id: 'workInformationMessages.fitnessContributionBalanceHelperText',
    description: 'Fitness contribution balance helper text',
    defaultMessage: 'Friskvårdssaldo visas i den anställdas förmånsportal',
  },
  fitnessContributionBalanceDescText: {
    id: 'workInformationMessages.fitnessContributionBalanceDescText',
    description: 'Fitness contribution balance description text',
    defaultMessage:
      'Om ni har friskvård via Epassi, kontrolleras friskvårdssaldo av er friskvårdspolicy.',
  },
  vacationDaysUnpaidLabel: {
    id: 'workInformationMessages.vacationDaysUnpaidLabel',
    description: 'Obetalda semesterdagar',
    defaultMessage: 'Obetalda semesterdagar',
  },
  vacationDaysAdvanceLabel: {
    id: 'workInformationMessages.vacationDaysAdvanceLabel',
    description: 'Vacation days in advance',
    defaultMessage: 'Förskottssemester',
  },
  employerContributionShareLabel: {
    id: 'workInformationMessages.employerContributionShareLabel',
    description: 'Arbetsgivaravgifter (%)',
    defaultMessage: 'Arbetsgivaravgifter (%)',
  },
  incomeTaxTableLabel: {
    id: 'workInformationMessages.incomeTaxTableLabel',
    description: 'Skattetabell',
    defaultMessage: 'Skattetabell',
  },
  costCenterLabel: {
    id: 'workInformationMessages.costCenterLabel',
    description: 'Kostnadsställe',
    defaultMessage: 'Kostnadsställe',
  },
  currentCostCenter: {
    id: 'workInformationMessages.currentCostCenter',
    description: 'Tidigare kostnadsställe',
    defaultMessage: 'Nuvarande kostnadsställe',
  },
  newCostCenter: {
    id: 'workInformationMessages.newCostCenter',
    description: 'Nytt kostnadsställe',
    defaultMessage: 'Välj nytt kostnadsställe',
  },
  monthlyDeposit: {
    id: 'workInformationMessages.monthlyDeposit',
    description: 'Månadsavsättning',
    defaultMessage: 'Månadsavsättning',
  },
  currentMonthlySalary: {
    id: 'workInformationMessages.currentMonthlySalary',
    description: 'Nuvarande lön (från {effectiveDate})',
    defaultMessage: 'Nuvarande lön (fr.o.m. {effectiveDate})',
  },
  latestUpdatedSalaryLabel: {
    id: 'workInformationMessages.latestUpdatedSalaryLabel',
    description: 'Latest salary update',
    defaultMessage: 'Senaste löneändring',
  },
  latestUpdatedSalaryDescription: {
    id: 'workInformationMessages.latestUpdatedSalaryDescription',
    description: '{salary} starts from {effectiveMonth}',
    defaultMessage: '{salary} från och med {effectiveMonth}',
  },
  currentMonthlyPensionPremium: {
    id: 'workInformationMessages.currentMonthlyPensionPremium',
    description: 'Current pension premium (from {effectiveDate})',
    defaultMessage: 'Nuvarande pensionspremie (fr.o.m. {effectiveDate})',
  },
  newMonthlyPensionPremium: {
    id: 'workInformationMessages.newMonthlyPensionPremium',
    description: 'New pension premium',
    defaultMessage: 'Ange ny pensionspremie',
  },
  currentStatus: {
    id: 'workInformationMessages.currentStatus',
    description: 'Nuvarande status',
    defaultMessage: 'Nuvarande status',
  },
  newStatus: {
    id: 'workInformationMessages.newStatus',
    description: 'Ny status',
    defaultMessage: 'Välj ny status',
  },
  personalDetails: {
    id: 'workInformationMessages.personalDetails',
    description: 'Personuppgifter',
    defaultMessage: 'Personuppgifter',
  },
  employmentDetails: {
    id: 'workInformationMessages.employmentDetails',
    description: 'Anställningsuppgifter',
    defaultMessage: 'Anställningsuppgifter',
  },
  superAdmin: {
    id: 'workInformationMessages.superAdmin',
    description: 'Super administrator',
    defaultMessage: 'Huvudadministratör',
  },
  HRAdmin: {
    id: 'workInformationMessages.HRAdmin',
    description: 'HR administrator',
    defaultMessage: 'HR-administratör',
  },
  HRAdminDescription: {
    id: 'workInformationMessages.HRAdminDescription',
    description: 'HR administrator description',
    defaultMessage:
      'HR-administratörer kan hantera anställda och deras uppgifter (inklusive löne- och förmånsuppgifter)',
  },
  contentAdmin: {
    id: 'workInformationMessages.contentAdmin',
    description: 'Content Kommunikatör',
    defaultMessage: 'Kommunikatör',
  },
  contentAdminDescription: {
    id: 'workInformationMessages.contentAdminDescription',
    description: 'HR administrator description',
    defaultMessage:
      'Kommunikatörer kan hantera företagets egna förmåner och artiklar men kan inte se uppgifter om några anställda',
  },
  membershipRole: {
    id: 'workInformationMessages.membershipRole',
    description: 'membership role',
    defaultMessage: 'Systemrättigheter',
  },
  informationAboutYourPension: {
    id: 'workInformationMessages.informationAboutYourPension',
    description: 'Information about your pension',
    defaultMessage: 'Information om din pension',
  },
  emailInsuranceWarning: {
    id: 'workInformationMessages.emailInsuranceWarning',
    description:
      'Insurance warning message showing if membership is missing email',
    defaultMessage:
      'E-post måste fyllas i för att riskförsäkringar ska tecknas',
  },
  remunerationInsuranceWarning: {
    id: 'workInformationMessages.remunerationInsuranceWarning',
    defaultMessage:
      '{remunerationType} måste fyllas i för att sjukförsäkring ska tecknas',
  },
  financeAdmin: {
    id: 'workInformationMessages.financeAdmin',
    defaultMessage: 'Ekonomiadministratör',
  },
  financeAdminDescr: {
    id: 'workInformationMessages.financeAdminDescr',
    defaultMessage:
      'Ekonomiadministratörer kan hantera fakturor, bokföringsfiler och finansiell information men har inte tillgång till uppgifter om anställdas löner eller förmåner',
  },
  salaryAndOtherBenefits: {
    id: 'workInformationMessages.salaryAndOtherBenefits',
    description: 'Salary and other benefits',
    defaultMessage: 'Lön och andra ersättningar',
  },
  salaryAndOtherBenefitsDescription: {
    id: 'workInformationMessages.salaryAndOtherBenefitsDescription',
    description: 'Salary and other benefits description',
    defaultMessage:
      'Lön och andra ersättningar är de ersättningar som anställda får från sin arbetsgivare. Olika löner och ersättningar kan ligga till grund för olika förmåner som ålderspension och sjukförsäkring. Lön och andra ersättningar anges månadsvis. För att ändra vilka lönearter som är konfigurerade för ert företag, gå till <accountPlanHref>Kontoplan under Företagsinställningar</accountPlanHref>.',
  },
  noAdministrators: {
    id: 'workInformationMessages.noAdministrators',
    defaultMessage: 'Inga administratörer',
  },
  payrollElement: {
    id: 'workInformationMessages.payrollElement',
    defaultMessage: 'Löneart',
  },
  dateSpan: {
    id: 'workInformationMessages.dateSpan',
    defaultMessage: 'Datering',
  },
  newRemuneration: {
    id: 'workInformationMessages.newRemuneration',
    defaultMessage: 'Ny {remunerationName}',
  },
  dateLowerBound: {
    id: 'workInformationMessages.dateLowerBound',
    defaultMessage: '{lowerBound, date, ::yyyyMMdd} - ',
  },
  dateUpperBound: {
    id: 'workInformationMessages.dateUpperBound',
    defaultMessage: '{upperBound, date, ::yyyyMMdd}',
  },
  editRemunerationTitle: {
    id: 'workInformationMessages.editRemunerationTitle',
    defaultMessage: 'Ny lön eller ersättning',
  },
  editRemunerationDesc: {
    id: 'workInformationMessages.editRemunerationDesc',
    defaultMessage:
      'Lön och andra ersättningar anges månadsvis. En ny lön eller ersättning gäller från och med den första dagen i månaden.',
  },
  latestChangeLabel: {
    id: 'workInformationMessages.latestChangeLabel',
    defaultMessage: 'Senaste ändring',
  },
  latestChangeDesc: {
    id: 'workInformationMessages.latestChangeDesc',
    defaultMessage: '{value} från och med {effectiveDate, date, ::yMMMM }',
  },
  editRemunerationSuccess: {
    id: 'workInformationMessages.editRemunerationSuccess',
    defaultMessage: 'Ändringarna har sparats',
  },
});

export const workCapabilityMessages = defineMessages({
  fullWorkCapability: {
    id: 'workCapabilityMessages.fullWorkCapability',
    description: 'Fullt arbetsför',
    defaultMessage: 'Fullt arbetsför',
  },
  confirmFullWorkCapability: {
    id: 'workCapabilityMessages.confirmFullWorkCapability',
    description: 'Confirm full work capability',
    defaultMessage: 'Intyga fullt arbetsför',
  },
  confirmFullWorkCapabilityFor: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityFor',
    description: 'Confirm full work capability for {name}',
    defaultMessage: 'Intyga att {name} är fullt arbetsför',
  },
  confirmFullWorkCapabilityLede: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityLede',
    description: 'Confirm full work capability employee lede',
    defaultMessage:
      'För att teckna eller ändra försäkringar hos Euro Accident, måste du intyga att den anställde är fullt arbetsför. Oriktiga uppgifter kan påverka försäkringens giltighet.',
  },
  confirmFullWorkCapabilityEmployeesLede: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityEmployeesLede',
    description: 'Confirm full work capability employees lede',
    defaultMessage:
      'För att teckna eller ändra försäkringar hos Euro Accident måste du intyga fullt arbetsför. Oriktiga uppgifter kan påverka försäkringars giltighet.',
  },
  employeesToConfirmFullWorkCapability: {
    id: 'workCapabilityMessages.employeesToConfirmFullWorkCapability',
    description: 'Employees to confirm full work capability',
    defaultMessage: 'Anställda att intyga',
  },
  fullyCapableForWorkInfoTitle: {
    id: 'workCapabilityMessages.fullyCapableForWorkInfoTitle',
    description: 'Fully capable for work info title',
    defaultMessage: 'Vad är fullt arbetsför?',
  },
  assertWorkCapabilitySuccess: {
    id: 'workCapabilityMessages.assertWorkCapabilitySuccess',
    description:
      'Success shown when asserting fully capable for work is successul',
    defaultMessage: 'Intygande av fullt arbetsför lyckades',
  },
  noEmployeesToAssertWorkCapability: {
    id: 'workCapabilityMessages.noEmployeesToAssertWorkCapability',
    description:
      'Empty state message shown when there is no employees to assert work capability for',
    defaultMessage: 'Inga anställda behöver intygas',
  },
  effectiveDateHelperText: {
    id: 'workCapabilityMessages.effectiveDateHelperText',
    description:
      'Helper text for selecting an effective date, uses {firstDate} for first possible effective date',
    defaultMessage:
      'Intyga fullt arbetsför den {firstDate} eller ett senare datum',
  },
  certificationOfFullWorkCapability: {
    id: 'workCapabilityMessages.certificationOfFullWorkCapability',
    defaultMessage: 'Intygande om fullt arbetsför',
  },
  certificationDate: {
    id: 'workCapabilityMessages.certificationDate',
    defaultMessage: 'Intygat för datum',
  },
});

export const effectiveDateStatusMessages = defineMessages<Status>({
  INSURANCE_WARNING: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.INSURANCE_WARNING',
    defaultMessage: 'Skjut upp ändringar',
  },
  NOT_COMPLETE: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.NOT_COMPLETE',
    defaultMessage: 'Några ändringar kommer kräva ett nytt intygande',
  },
  COMPLETE: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.COMPLETE',
    defaultMessage: 'Alla ändringar kommer genomföras',
  },
});

export const employeePensionMessages = defineMessages({
  deduction: {
    id: 'employeePensionMessages.deduction',
    description: 'Bruttolöneavdrag',
    defaultMessage: 'Bruttolöneavdrag',
  },
  premium: commonMessages.premium,
  provider: {
    id: 'employeePensionMessages.provider',
    description: 'Löneväxling via',
    defaultMessage: 'Via',
  },
  alertMessage: {
    id: 'employeePensionMessages.alertMessage',
    description: 'Den anställda har en förfrågan om löneväxling.',
    defaultMessage: 'Den anställda har en förfrågan om löneväxling.',
  },
  alertButton: {
    id: 'employeePensionMessages.alertButton',
    description: 'go to tasks',
    defaultMessage: 'Visa löneväxlingar',
  },
  premiumNeedsUpdate: {
    id: 'employeePensionMessages.premiumNeedsUpdate',
    description:
      'In order to activate pension for this employee, please update pension premium.',
    defaultMessage: `Ska den anställde ha insättningar till pension behöver du ange vilken månatlig premie som ska gälla.`,
  },
  nordnetPension: {
    id: 'employeePensionMessages.nordnetPension',
    description: 'Occupational pension via Nordnet',
    defaultMessage: 'Ålderspension via Nordnet',
  },
});

export const employeeFormMessages = defineMessages({
  addEmployee: {
    id: 'employeeFormMessages.addEmployee',
    description: 'Lägg till anställd',
    defaultMessage: 'Lägg till anställd',
  },
  exportEmployees: {
    id: 'employeeFormMessages.exportEmployees',
    description: 'Export employees',
    defaultMessage: 'Exportera anställda',
  },
  addEmployeeDescription: {
    id: 'employeeFormMessages.addEmployeeDescription',
    description:
      'Lorem ipsum dolor sit amet, at fugit essent nam, mea ad nisl epicuri,eum impetus petentium erroribus ne. Id putent blandit repudiare pri,\n    ad partem facilisi eum. No sea adhuc forensibus contentiones, est ei\n    labore mediocritatem. Eam soleat suscipiantur in, sea ut nemore\n    fastidii similique.\n    ',
    defaultMessage:
      'Information om den anställda hämtas automatiskt från Statens personadressregister (SPAR). Den anställda får automatiskt tillgång till sin förmånsgrupps nuvarande och framtida förmåner.',
  },
  noPensionBenefits: {
    id: 'employeeFormMessages.noPensionBenefits',
    description: "This employee doesn't have any pension benefit",
    defaultMessage: 'Finns inga pensionsförsäkringar att visa',
  },
  noPensionBenefitsText: {
    id: 'employeeFormMessages.noPensionBenefitsText',
    description: "This employee doesn't have any pension benefit help text",
    defaultMessage:
      'När den anställde har en aktiv tjänstepension (exempelvis löneväxling) så kommer det att visas här.',
  },
  noInsuranceBenefits: {
    id: 'employeeFormMessages.noInsuranceBenefits',
    description: "This employee doesn't have any insurance benefit",
    defaultMessage: 'Det finns inga riskförsäkringar att visa',
  },
  noInsuranceBenefitsText: {
    id: 'employeeFormMessages.noInsuranceBenefitsText',
    description: "This employee doesn't have any insurance benefit helper text",
    defaultMessage:
      'När medarbetare läggs till i en förmånsgrupp med riskförsäkringar så kommer de att visas här.',
  },
  noStatus: {
    id: 'employeeFormMessages.noStatus',
    description:
      'Empty state message when a membership does not have any status',
    defaultMessage: `Inga händelser`,
  },
  editContactInformationTitle: {
    id: 'employeeFormMessages.editContactInformationTitle',
    description: 'Ändra {name}',
    defaultMessage: 'Ändra {name}',
  },
  editContactInformationDescription: {
    id: 'employeeFormMessages.editContactInformationDescription',
    defaultMessage:
      'Vi hämtar automatiskt information om den anställdas namn och adress från Statens personadressregister (SPAR).',
  },
  addEmployeeBenefitPackagesDescription: {
    id: 'employeeFormMessages.addEmployeeBenefitPackagesDescription',
    description:
      'Den anställda kommer erbjudas alla nuvarande och framtida förmåner som hör till förmånsgruppen. Förmåner som inte behöver aktiveras av den anställda aktiveras automatiskt.',
    defaultMessage:
      'Den anställda får tillgång till förmånsgruppens nuvarande och framtida förmåner',
  },
  changeEmployeeBenefitPackagesDescription: {
    id: 'employeeFormMessages.changeEmployeeBenefitPackagesDescription',
    description:
      'Vid byte av förmånsgrupp avslutas nuvarande förmåner. Den anställda får istället tillgång till den nya förmånsgruppens förmåner. Var uppmärksam på att detta kan leda till att riskförsäkringar avslutas för den anställda.',
    defaultMessage:
      'Vid byte av förmånsgrupp avslutas nuvarande förmåner. Den anställda får istället tillgång till den nya förmånsgruppens förmåner. Var uppmärksam på att detta kan leda till att riskförsäkringar avslutas för den anställda.',
  },
  updateEmployeeSalaryTitle: {
    id: 'employeeFormMessages.updateEmployeeSalaryTitle',
    description: 'Ändra lön för {name}',
    defaultMessage: 'Ändra lön för {name}',
  },
  updateEmployeeSalaryDescription: {
    id: 'employeeFormMessages.updateEmployeeSalaryDescription',
    description:
      'The salary updates from the first of the month. Salary can be changed from the earliest registered one.',
    defaultMessage:
      'Ändring av lön gäller från och med den första i månaden. Lön kan ändras tidigast från och med redan registrerad lön.',
  },
  updateEmployeePensionPremiumDescription: {
    id: 'employeeFormMessages.updateEmployeePensionPremiumDescription',
    description: 'Description in modal for updating pension premium value.',
    defaultMessage:
      'Ändring av pensionspremie gäller från och med den första i månaden. Du kan ändra premie upp till en månad framåt och tre månader bakåt i tiden.',
  },
  addEmployeeStatus: {
    id: 'employeeFormMessages.addEmployeeStatus',
    description: 'Add membership status',
    defaultMessage: 'Lägg till status',
  },
  updateEmployeeStatus: {
    id: 'employeeFormMessages.updateEmployeeStatus',
    description: 'Ändra status',
    defaultMessage: 'Ändra status',
  },
  updateEmployeeStatusForTitle: {
    id: 'employeeFormMessages.updateEmployeeStatusForTitle',
    description: 'Ändra status för {name}',
    defaultMessage: 'Ändra status för {name}',
  },
  updateEmployeeWorkCapabilityDescriptiion: {
    id: 'employeeFormMessages.updateEmployeeWorkCapabilityDescriptiion',
    description:
      'Anställda kan endast anslutas till Euro Accidents riskförsäkringar om de är fullt arbetsföra. Med fullt arbetsför meas att den anställdas arbete kan fullgöras utan att inskränkningar och att sjuklön från arbetsgivaren eller ersättning från Föräkringskassan inte utbetalas. Det är arbetsgivaren som intygar att den anställda är fullt arbetsför.',
    defaultMessage:
      'Endast fullt arbetsföra anställda kan anslutas till riskförsäkringar. Arbetsgivaren intygar att den anställda är fullt arbetsför.',
  },
  noEmployeeMatch: {
    id: 'employeeFormMessages.noEmployeeMatch',
    description: 'No employees match the search',
    defaultMessage: 'Inga personer matchar sökningen',
  },
  updateRole: {
    id: 'employeeFormMessages.updateRole',
    description: 'update membershp role',
    defaultMessage: 'Ändra systemrättigheter',
  },
  updateRoleDescription: {
    id: 'employeeFormMessages.updateRoleDescription',
    description: 'The description of updating membership role.',
    defaultMessage:
      'För att ge andra tillgång att sköta företaget, kan du tilldela vissa systemrättigheter till andra administratörer. För att byta huvudadministratör, kontakta kundtjänst.',
  },
  confirmSubmitAddEmployee: {
    id: 'employeeFormMessages.confirmSubmitAddEmployee',
    description:
      'Label for checkbox to confirm all details is correct when adding an employee',
    defaultMessage: `Härmed intygar jag att samtliga uppgifter enligt ovan är sanningsenliga och korrekta. Jag bekräftar att ovanstående anställd ska omfattas av de avtal som arbetsgivaren ingått med Nordea Node AB, och i förekommande fall med med de försäkringsbolag som avtal ingåtts med genom Nordea Nodes Portal.`,
  },
  confirmImport: {
    id: 'employeeFormMessages.confirmImport',
    description: 'Confirm import',
    defaultMessage: 'Bekräfta import',
  },
  confirmWelcomeEmails: {
    id: 'employeeFormMessages.confirmWelcomeEmails',
    description: 'Skip welcome emails',
    defaultMessage: 'Skicka e-post',
  },
  confirmSkipWelcomeEmails: {
    id: 'employeeFormMessages.confirmSkipWelcomeEmails',
    description:
      'Label for checkbox to confirm whether welcome emails should be sent or not',
    defaultMessage: `Skicka e-post med information om Nordea Node till nyanställda`,
  },
  confirmSendWelcomeEmail: {
    id: 'employeeFormMessages.confirmSendWelcomeEmail',
    description:
      'Label for checkbox to confirm whether welcome emails should be sent or not',
    defaultMessage: `Skicka e-post med information om Nordea Node`,
  },
  confirmSubmitImportEmployees: {
    id: 'employeeFormMessages.confirmSubmitImportEmployees',
    description:
      'Label for checkbox to confirm all details is correct when importing employees',
    defaultMessage: `Härmed intygar jag att samtliga uppgifter är sanningsenliga och korrekta. Jag bekräftar att anställda i förekommande fall ska omfattas av de tjänstepensionsförsäkringar och riskförsäkringar som bolaget ingått avtal med genom Nordea Node AB.`,
  },
  effectiveDateStatusHelperText: {
    id: 'employeeFormMessages.effectiveDateStatusHelperText',
    description: 'Helper text for effective date when handling statuses',
    defaultMessage: 'Startdatum för denna händelse',
  },
  effectiveUntilStatusHelperText: {
    id: 'employeeFormMessages.effectiveUntilStatusHelperText',
    description: 'Helper text for effective until when handling statuses',
    defaultMessage: 'Slutdatum för denna händelse',
  },
  membershipStatusExtentHelperText: {
    id: 'employeeFormMessages.membershipStatusExtentHelperText',
    description: 'Helper text for extent when handling statuses',
    defaultMessage:
      'För deltidsanställningar, ange andelen av en heltidstjänst',
  },
  hideTerminatedEmployees: {
    id: 'employeeFormMessages.hideTerminatedEmployees',
    defaultMessage: 'Aktiv anställning',
  },
  confirmSwitchBackageTitle: {
    id: 'employeeFormMessages.confirmSwitchBackageTitle',
    defaultMessage: 'Byta förmånsgrupp?',
  },
  confirmSwitchBackage: {
    id: 'employeeFormMessages.confirmSwitchBackage',
    defaultMessage:
      'Förmånsgruppen för den anställda kommer att uppdateras till {backageName} fr.o.m. {effectiveDate}.',
  },
  confirmUpdateSalary: {
    id: 'employeeFormMessages.confirmUpdateSalary',
    defaultMessage:
      'Lön kommer att ändras till {monthlySalary} fr.o.m {effectiveMonth} för den anställda. Är du säker?',
  },
  nidOptOut: {
    id: 'employeeFormMessages.nidOptOut',
    defaultMessage:
      'Lägg till anställd utan svenskt personnummer eller samordningsnummer',
  },
  birthdate: {
    id: 'employeeFormMessages.birthdate',
    defaultMessage: 'Födelsedatum',
  },
  sex: {
    id: 'employeeFormMessages.sex',
    defaultMessage: 'Kön',
  },
  address: {
    id: 'employeeFormMessages.address',
    defaultMessage: 'Gatuadress',
  },
  city: {
    id: 'employeeFormMessages.city',
    defaultMessage: 'Stad',
  },
  female: {
    id: 'employeeFormMessages.female',
    defaultMessage: 'Kvinna',
  },
  male: {
    id: 'employeeFormMessages.male',
    defaultMessage: 'Man',
  },
  nidDescription: {
    id: 'employeeFormMessages.nidDescription',
    defaultMessage:
      'Du kan lägga till fler identifierare. Vi har stöd för personnummer, samordningsnummer och A-nummer.',
  },
  addNid: {
    id: 'employeeFormMessages.addNid',
    defaultMessage: 'Lägg till identifierare',
  },
  employeeIsUnder18: {
    id: 'employeeFormMessages.employeeIsUnder18',
    defaultMessage: 'Den anställde är under 18 år',
  },
  employeeIsOver62: {
    id: 'employeeFormMessages.employeeIsOver62',
    defaultMessage: 'Den anställde är över 62 år',
  },
  confirmEmployeeAgeTitle: {
    id: 'employeeFormMessages.confirmEmployeeAgeTitle',
    defaultMessage: 'Har korrekt ålder angivits?',
  },
  confirmEmployeeIsUnder18: {
    id: 'employeeFormMessages.confirmEmployeeIsUnder18',
    defaultMessage: 'Bekräfta att den anställde är under 18 år.',
  },
  confirmEmployeeIsOver62: {
    id: 'employeeFormMessages.confirmEmployeeIsOver62',
    defaultMessage: 'Bekräfta att den anställde är över 62 år.',
  },
});

export const membershipEventHistoryMessages = defineMessages({
  eventHistory: {
    id: 'membershipEventHistoryMessages.eventHistory',
    defaultMessage: 'Händelsehistorik',
  },
  event: {
    id: 'membershipEventHistoryMessages.event',
    defaultMessage: 'Händelse',
  },
  reportingDate: {
    id: 'membershipEventHistoryMessages.reportingDate',
    defaultMessage: 'Rapporteringsdatum',
  },
  source: {
    id: 'membershipEventHistoryMessages.source',
    defaultMessage: 'Källa',
  },
  author: {
    id: 'membershipEventHistoryMessages.author',
    defaultMessage: 'Aktör',
  },
  fileImport: {
    id: 'membershipEventHistoryMessages.fileImport',
    defaultMessage: 'Filimport',
  },
  portal: {
    id: 'membershipEventHistoryMessages.portal',
    defaultMessage: 'Portal',
  },
});
