import gql from 'graphql-tag';

export const TERMINATE_EMPLOYMENT_MUTATION = gql`
  mutation terminateEmploymentMutation($input: UpdateEmploymentInput!) {
    updateEmployment(input: $input) {
      employment {
        id
        period
      }
    }
  }
`;
