import {
  bodySmallCSS,
  headlineMediumCSS,
  headlineSmallCSS,
  media,
} from '@frontend/ui';
import styled from 'styled-components';

export const SignUpHeadline = styled.h1`
  ${headlineSmallCSS};
  margin: 1.5rem 0 0.5rem 0;

  ${media.lessThan('desktop')`
    ${headlineMediumCSS};
  `}
`;

export const PrivacyPolicy = styled.div`
  ${bodySmallCSS}
  margin-bottom: 1rem;
  text-align: center;
`;
