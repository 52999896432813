import { BenefitType } from '@frontend/benefit-types';
import { Figure, Icon } from '@frontend/ui';
import { beachAccess } from '@frontend/ui/icons';
import { startOfNextMonth } from '@frontend/utils';
import {
  pensionCardQuery,
  pensionCardQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/admin/employees/employee';
import { commonMessages } from 'common/messages';
import { benefitTypesMessages } from 'common/messages/benefits';
import { dashboardMessages } from 'common/messages/dashboard';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from 'utils/use-query';

import { PENSION_CARD_QUERY } from './graphql/queries';
import { getPensionCardData } from './utils/get-pension-card-data';

const benefitTypeNames: BenefitType[] = [
  'advinans_occupational_pension',
  'employers_pension',
];

type MatchParams = Partial<EmployeeRouteMatchParams>;

const isAdminView = (params: MatchParams): params is EmployeeRouteMatchParams =>
  'userAccountId' in params;

interface Props {
  notLinked?: boolean;
  totalCompensation?: boolean;
}

export const PensionCard: React.FC<Props> = ({
  notLinked,
  totalCompensation,
}) => {
  const { currentUser } = useCurrentUser();
  const { params } = useRouteMatch<MatchParams>();
  const userAccountId = isAdminView(params)
    ? params.userAccountId
    : currentUser.userAccountId;
  const companyId = currentUser.currentCompany?.id ?? '';

  const nextMonthStartDate = startOfNextMonth();

  const { data } = useQuery<pensionCardQuery, pensionCardQueryVariables>(
    PENSION_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      skip: !companyId,
      isEmpty: d => !getPensionCardData(d, totalCompensation),
      variables: {
        userAccountId,
        companyId,
        benefitTypeNames,
        date: nextMonthStartDate,
      },
    },
  );

  const pensionCardData = getPensionCardData(data, totalCompensation);

  if (!pensionCardData) {
    return null;
  }

  const {
    benefitType,
    pensionPremium,
    nextPensionPremium,
    showNextPensionPremium,
  } = pensionCardData;

  const link = notLinked
    ? undefined
    : {
        to: '/pension/benefits/pension',
        label: <FormattedMessage {...commonMessages.showBenefit} />,
      };

  return (
    <CardGridCell>
      <SnapshotCard
        link={link}
        icon={
          <Icon icon={beachAccess} color="primary" size="medium" decorative />
        }
        title={
          <FormattedMessage
            select={benefitType}
            messages={benefitTypesMessages}
          />
        }
        figure1={
          <Figure
            size="medium"
            title={
              <FormattedCurrency
                value={pensionPremium}
                currency="SEK"
                after="PER_MONTH"
              />
            }
            description={
              <FormattedMessage {...dashboardMessages.currentPremium} />
            }
          />
        }
        figure2={
          showNextPensionPremium && nextPensionPremium ? (
            <Figure
              size="medium"
              title={
                <FormattedCurrency
                  value={nextPensionPremium}
                  currency="SEK"
                  after="PER_MONTH"
                />
              }
              description={
                <FormattedMessage {...dashboardMessages.fromNextMonth} />
              }
            />
          ) : null
        }
      />
    </CardGridCell>
  );
};
