import { Button, ButtonLayout } from '@frontend/ui';
import { event } from '@frontend/ui/icons';
import DefaultA from 'components/DefaultA';
import { FormattedMessage } from 'components/formats';
import { useCurrentUser } from 'contexts/current-user';
import { useBanner } from 'features/banner';
import { pensionTransferMessages } from 'features/pension-transfer/messages';
import React, { useLayoutEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import { usePensionTransferMeeting } from '../../lib/use-pension-transfer-meeting';
import { AddToCalendar } from '../AddToCalendarButton';

export const TransferMeetingBanner: React.FC<
  RouteComponentProps
> = routeComponentProps => {
  const { setBanner } = useBanner();
  const {
    currentUser: { userAccountId },
  } = useCurrentUser();
  const { schedulingLink, scheduledTime } = usePensionTransferMeeting({
    userAccountId,
    routeComponentProps,
  });

  const bannerMessage = scheduledTime
    ? pensionTransferMessages.bookingConfirmationBanner
    : pensionTransferMessages.bookMeetingBanner;

  const actionMessage = scheduledTime
    ? pensionTransferMessages.editBooking
    : pensionTransferMessages.bookMeeting;

  useLayoutEffect(() => {
    if (schedulingLink) {
      setBanner({
        message: (
          <FormattedMessage
            {...bannerMessage}
            values={
              scheduledTime
                ? { scheduledTime: new Date(scheduledTime) }
                : undefined
            }
          />
        ),
        icon: event,
        actions: (
          <ButtonLayout>
            <DefaultA href={schedulingLink}>
              <Button text>
                <FormattedMessage {...actionMessage} />
              </Button>
            </DefaultA>
            {scheduledTime && <AddToCalendar scheduledTime={scheduledTime} />}
          </ButtonLayout>
        ),
      });
    }
  }, [schedulingLink]);

  return null;
};
