import gql from 'graphql-tag';

export const EDIT_SALARY_MUTATION = gql`
  mutation editSalaryMutation($input: AddCustomItemInput) {
    addCustomItem(input: $input) {
      remuneration {
        from
        value: price
        currency
      }
    }
  }
`;
