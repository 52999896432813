import { defaultTheme } from '@frontend/ui';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getCssVariableValue } from 'utils/get-css-variable-value';

export const WithPwaMetaThemeColor: React.FC = () => {
  if (!navigator.standalone) {
    return null;
  }

  return (
    <Helmet>
      <meta
        name="theme-color"
        content={getCssVariableValue(defaultTheme.surface2)}
      />
    </Helmet>
  );
};
