import gql from 'graphql-tag';

export const SELECT_EMPLOYEE_QUERY = gql`
  query selectEmployeeQuery($companyId: ID!, $search: String!) {
    company(id: $companyId) {
      id
      memberships(search: $search) {
        edges {
          node {
            id
            givenName
            lastName
            naturalPersonIdentifiers
            userAccountId
          }
        }
      }
    }
  }
`;
