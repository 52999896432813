import { defineMessages } from 'react-intl';

export const dashboardMessages = defineMessages({
  showCompanyCar: {
    id: 'dashboardMessages.showCompanyCar',
    description: 'Show company car',
    defaultMessage: 'Visa bilförmån',
  },
  showWellnessBenefit: {
    id: 'dashboardMessages.showWellnessBenefit',
    description: 'Show wellness benefit',
    defaultMessage: 'Hitta friskvård',
  },
  showInsurances: {
    id: 'dashboardMessages.showInsurances',
    description: 'Show insurances',
    defaultMessage: 'Visa försäkringar',
  },
  showSalaryExchange: {
    id: 'dashboardMessages.showSalaryExchange',
    description: 'Show salary exchange',
    defaultMessage: 'Visa löneväxling',
  },
  showVacation: {
    id: 'dashboardMessages.showVacation',
    description: 'Show vacation',
    defaultMessage: 'Visa semester',
  },
  showPayslip: {
    id: 'dashboardMessages.showPayslip',
    description: 'Show payslip',
    defaultMessage: 'Visa lönespecifikation',
  },
  currentPremium: {
    id: 'dashboardMessages.currentPremium',
    defaultMessage: 'Nuvarande inbetalning',
  },
  collectiveAgreementPremiumPerMonth: {
    id: 'dashboardMessages.collectiveAgreementPremiumPerMonth',
    defaultMessage: 'Inbetalning per månad ({collectiveAgreement})',
  },
  fromNextMonth: {
    id: 'dashboardMessages.fromNextMonth',
    defaultMessage: 'Från nästa månad',
  },
});
