import { gql } from 'graphql-tag';

export const plusAllocation = gql`
  fragment plusAllocation on PlusTargetAllocationItem {
    name
    share
    internalCode
    ongoingCharge
    fund {
      id
      name
      type
      riskIndicator
      providerHoldingCompany
    }
  }
`;
