import { MutationFunction } from '@apollo/client';
import {
  loginMutation,
  loginMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { loginMessages } from 'common/messages/login';
import { BankIdButtonLabel } from 'components/BankIdButtonLabel';
import { FormattedMessage } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import React, { useState } from 'react';

import { SignWithSameDeviceProps } from '../../use-login';

interface Props {
  cancelLogin: () => void;
  loading: boolean;
  startLogin: MutationFunction<loginMutation, loginMutationVariables>;
  signWithSameDevice?: (props?: SignWithSameDeviceProps) => void;
}

export const LoginFormSection: React.FC<Props> = ({
  startLogin,
  cancelLogin,
  loading,
  signWithSameDevice,
}) => {
  const [initLoginLoading, setInitLoginLoading] = useState(false);
  const handleSubmit = async () => {
    setInitLoginLoading(true);
    const res = await startLogin({
      variables: {
        input: {},
      },
    });
    const autoStartToken = res.data?.login?.autoStartToken;
    const ticketId = res.data?.login?.ticketId;
    if (autoStartToken && ticketId) {
      signWithSameDevice?.({ autoStartToken, ticketId });
    }
    setInitLoginLoading(false);
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <FullWidthButton
        onClick={handleSubmit}
        loading={initLoginLoading || loading}
        filled
        extended
      >
        <BankIdButtonLabel label={loginMessages.identifyWithBankId} />
      </FullWidthButton>
      {loading && (
        <FullWidthButton
          style={{
            marginTop: '0.75rem',
          }}
          onClick={cancelLogin}
          text
          extended
        >
          <FormattedMessage {...commonMessages.cancel} />
        </FullWidthButton>
      )}
    </div>
  );
};
