import { isOnIOSDevice } from '@frontend/utils';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';

/**
 * @function signBankIdWithSameDevice Constructs a URL to the BankID application, with valid
 * redirection back to the Nordea Node app, to which the user is linked.
 *
 * The redirect link back to the Nordea Node application is set to null for non-iOS devices. For
 * iOS devices the current location of the application and the `ticketId` of the ongoing signing,
 * is properly encoded and passed to BankID.
 */

const CRIOS_URL = 'googlechrome://';

interface Props {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  ticketId: string;
  autoStartToken?: string;
}

export const signBankIdWithSameDevice = ({
  ticketId,
  autoStartToken,
  history: { replace },
  location,
}: Props): void => {
  const rootPath = window.location.origin + window.location.pathname;
  const currentSearch = qs.parse(location.search);
  const search = qs.stringify({ ...currentSearch, ticketId });
  const { state } = location;

  const isOnIOS = isOnIOSDevice();
  const isPwa = !!navigator.standalone;
  const isBrowserOnIOS = isOnIOS && !isPwa;
  const isChromeOnIOS = isOnIOS && navigator.userAgent.indexOf('CriOS') > -1;

  if (isBrowserOnIOS) {
    replace({ search, state });
  }

  if (autoStartToken) {
    window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=${
      isBrowserOnIOS
        ? isChromeOnIOS
          ? CRIOS_URL
          : encodeURIComponent(`${rootPath}?${search}#`)
        : null
    }`;
    return;
  }

  window.location.href = `bankid:///?redirect=${
    isBrowserOnIOS
      ? isChromeOnIOS
        ? CRIOS_URL
        : encodeURIComponent(`${rootPath}?${search}#`)
      : null
  }`;
};
