import {
  BaseAmountType,
  LegacyPensionProvider,
  SalaryExchangeProvider,
} from '@frontend/benefit-types';
import { defaultTheme } from '@frontend/ui';
import {
  ActivityLevel,
  CollectiveAgreement,
  FlexSalaryExchangeProvider,
  InsuranceComponentStatus,
  InsuranceManagementType,
  InsurancePremiumStatus,
  InvoiceSupplier,
  MembershipRole,
} from 'app/apollo/graphql/types';
import {
  activityLevelMessages,
  insuranceManagementTypeMessages,
  suffixMessages,
} from 'common/messages';
import { workInformationMessages } from 'common/messages/employees';
import {
  AKAPKL_PAGES,
  AKAPKR_PAGES,
  CollectiveAgreementPage,
  ITP_PAGES,
  ITP1_PAGES,
  KAPKL_PAGES,
  PA_16_1,
  PA_16_2,
  PAKFS_PAGES,
  SAFLO_PAGES,
} from 'features/contentful/lib/contentful-entry-id';
import { ValidAgreementType } from 'features/pension/lib/get-collective-agreement-type-from-url';
import { defineMessages, MessageDescriptor } from 'react-intl';

export const COLLECTIVE_AGREEMENT_PAGE_ID: Record<
  ValidAgreementType,
  CollectiveAgreementPage | null
> = {
  ITP: ITP_PAGES,
  ITP1: ITP1_PAGES,
  SAFLO: SAFLO_PAGES,
  KAPKL: KAPKL_PAGES,
  AKAPKL: AKAPKL_PAGES,
  AKAPKR: AKAPKR_PAGES,
  PAKFS09: PAKFS_PAGES,
  PA161: PA_16_1,
  PA162: PA_16_2,
};

export const ACTIVITY_LEVEL: Record<ActivityLevel, MessageDescriptor> = {
  NOT_ACTIVE: activityLevelMessages.activityLevelNotActive,
  ACTIVE: activityLevelMessages.activityLevelActive,
};

export const ACTIVITY_LEVEL_DESCRIPTION: Record<
  ActivityLevel,
  MessageDescriptor
> = {
  NOT_ACTIVE: activityLevelMessages.activityLevelNotActiveDescription,
  ACTIVE: activityLevelMessages.activityLevelActiveDescription,
};

export const INSURANCE_MANAGEMENT_TYPE: Record<
  InsuranceManagementType,
  MessageDescriptor
> = {
  DEPOSITORY: insuranceManagementTypeMessages.depository,
  UNIT: insuranceManagementTypeMessages.unit,
  TRADITIONAL: insuranceManagementTypeMessages.traditional,
};

const messages = defineMessages({
  AFG: { id: 'country.AFG', defaultMessage: 'Afghanistan' },
  ALB: { id: 'country.ALB', defaultMessage: 'Albanien' },
  DZA: { id: 'country.DZA', defaultMessage: 'Algeriet' },
  VIR: { id: 'country.VIR', defaultMessage: 'Amerikanska Jungfruöarna' },
  ASM: { id: 'country.ASM', defaultMessage: 'Amerikanska Samoa' },
  AND: { id: 'country.AND', defaultMessage: 'Andorra' },
  AGO: { id: 'country.AGO', defaultMessage: 'Angola' },
  AIA: { id: 'country.AIA', defaultMessage: 'Anguilla' },
  ATA: { id: 'country.ATA', defaultMessage: 'Antarktis' },
  ATG: { id: 'country.ATG', defaultMessage: 'Antigua och Barbuda' },
  ARG: { id: 'country.ARG', defaultMessage: 'Argentina' },
  ARM: { id: 'country.ARM', defaultMessage: 'Armenien' },
  ABW: { id: 'country.ABW', defaultMessage: 'Aruba' },
  AUS: { id: 'country.AUS', defaultMessage: 'Australien' },
  AZE: { id: 'country.AZE', defaultMessage: 'Azerbajdzjan' },
  BHS: { id: 'country.BHS', defaultMessage: 'Bahamas' },
  BHR: { id: 'country.BHR', defaultMessage: 'Bahrain' },
  BGD: { id: 'country.BGD', defaultMessage: 'Bangladesh' },
  BRB: { id: 'country.BRB', defaultMessage: 'Barbados' },
  BEL: { id: 'country.BEL', defaultMessage: 'Belgien' },
  BLZ: { id: 'country.BLZ', defaultMessage: 'Belize' },
  BEN: { id: 'country.BEN', defaultMessage: 'Benin' },
  BMU: { id: 'country.BMU', defaultMessage: 'Bermuda' },
  BTN: { id: 'country.BTN', defaultMessage: 'Bhutan' },
  BOL: { id: 'country.BOL', defaultMessage: 'Bolivia' },
  BIH: { id: 'country.BIH', defaultMessage: 'Bosnien och Hercegovina' },
  BWA: { id: 'country.BWA', defaultMessage: 'Botswana' },
  BVT: { id: 'country.BVT', defaultMessage: 'Bouvetön' },
  BRA: { id: 'country.BRA', defaultMessage: 'Brasilien' },
  BRN: { id: 'country.BRN', defaultMessage: 'Brunei' },
  BGR: { id: 'country.BGR', defaultMessage: 'Bulgarien' },
  BFA: { id: 'country.BFA', defaultMessage: 'Burkina Faso' },
  MMR: { id: 'country.MMR', defaultMessage: 'Burma' },
  BDI: { id: 'country.BDI', defaultMessage: 'Burundi' },
  KYM: { id: 'country.KYM', defaultMessage: 'Caymanöarna' },
  CAF: { id: 'country.CAF', defaultMessage: 'Centralafrikanska republiken' },
  CHL: { id: 'country.CHL', defaultMessage: 'Chile' },
  COL: { id: 'country.COL', defaultMessage: 'Colombia' },
  COK: { id: 'country.COK', defaultMessage: 'Cooköarna' },
  CRI: { id: 'country.CRI', defaultMessage: 'Costa Rica' },
  CYP: { id: 'country.CYP', defaultMessage: 'Cypern' },
  DNK: { id: 'country.DNK', defaultMessage: 'Danmark' },
  COD: { id: 'country.COD', defaultMessage: 'Demokratiska republiken Kongo' },
  DJI: { id: 'country.DJI', defaultMessage: 'Djibouti' },
  DMA: { id: 'country.DMA', defaultMessage: 'Dominica' },
  DOM: { id: 'country.DOM', defaultMessage: 'Dominikanska republiken' },
  ECU: { id: 'country.ECU', defaultMessage: 'Ecuador' },
  EGY: { id: 'country.EGY', defaultMessage: 'Egypten' },
  GNQ: { id: 'country.GNQ', defaultMessage: 'Ekvatorialguinea' },
  SLV: { id: 'country.SLV', defaultMessage: 'El Salvador' },
  CIV: { id: 'country.CIV', defaultMessage: 'Elfenbenskusten' },
  ERI: { id: 'country.ERI', defaultMessage: 'Eritrea' },
  EST: { id: 'country.EST', defaultMessage: 'Estland' },
  ETH: { id: 'country.ETH', defaultMessage: 'Etiopien' },
  FLK: { id: 'country.FLK', defaultMessage: 'Falklandsöarna' },
  FJI: { id: 'country.FJI', defaultMessage: 'Fiji' },
  PHL: { id: 'country.PHL', defaultMessage: 'Filippinerna' },
  FIN: { id: 'country.FIN', defaultMessage: 'Finland' },
  FRA: { id: 'country.FRA', defaultMessage: 'Frankrike' },
  GUF: { id: 'country.GUF', defaultMessage: 'Franska Guyana' },
  PYF: { id: 'country.PYF', defaultMessage: 'Franska Polynesien' },
  FRO: { id: 'country.FRO', defaultMessage: 'Färöarna' },
  ARE: { id: 'country.ARE', defaultMessage: 'Förenade Arabemiraten' },
  GAB: { id: 'country.GAB', defaultMessage: 'Gabon' },
  GMB: { id: 'country.GMB', defaultMessage: 'Gambia' },
  GEO: { id: 'country.GEO', defaultMessage: 'Georgien' },
  GHA: { id: 'country.GHA', defaultMessage: 'Ghana' },
  GIB: { id: 'country.GIB', defaultMessage: 'Gibraltar' },
  GRC: { id: 'country.GRC', defaultMessage: 'Grekland' },
  GRD: { id: 'country.GRD', defaultMessage: 'Grenada' },
  GRL: { id: 'country.GRL', defaultMessage: 'Grönland' },
  GLP: { id: 'country.GLP', defaultMessage: 'Guadeloupe' },
  GUM: { id: 'country.GUM', defaultMessage: 'Guam' },
  GTM: { id: 'country.GTM', defaultMessage: 'Guatemala' },
  GGY: { id: 'country.GGY', defaultMessage: 'Guernsey' },
  GIN: { id: 'country.GIN', defaultMessage: 'Guinea' },
  GNB: { id: 'country.GNB', defaultMessage: 'Guinea Bissau' },
  GUY: { id: 'country.GUY', defaultMessage: 'Guyana' },
  HTI: { id: 'country.HTI', defaultMessage: 'Haiti' },
  HND: { id: 'country.HND', defaultMessage: 'Honduras' },
  HKG: { id: 'country.HKG', defaultMessage: 'Hongkong' },
  IND: { id: 'country.IND', defaultMessage: 'Indien' },
  IDN: { id: 'country.IDN', defaultMessage: 'Indonesien' },
  IRQ: { id: 'country.IRQ', defaultMessage: 'Irak' },
  IRN: { id: 'country.IRN', defaultMessage: 'Iran' },
  IRL: { id: 'country.IRL', defaultMessage: 'Irland' },
  ISL: { id: 'country.ISL', defaultMessage: 'Island' },
  IMN: { id: 'country.IMN', defaultMessage: 'Isle of Man' },
  ISR: { id: 'country.ISR', defaultMessage: 'Israel' },
  ITA: { id: 'country.ITA', defaultMessage: 'Italien' },
  JAM: { id: 'country.JAM', defaultMessage: 'Jamaica' },
  JPN: { id: 'country.JPN', defaultMessage: 'Japan' },
  YEM: { id: 'country.YEM', defaultMessage: 'Jemen' },
  JEY: { id: 'country.JEY', defaultMessage: 'Jersey' },
  JOR: { id: 'country.JOR', defaultMessage: 'Jordanien' },
  CXR: { id: 'country.CXR', defaultMessage: 'Julön' },
  KHM: { id: 'country.KHM', defaultMessage: 'Kambodja' },
  CMR: { id: 'country.CMR', defaultMessage: 'Kamerun' },
  CAN: { id: 'country.CAN', defaultMessage: 'Kanada' },
  CPV: { id: 'country.CPV', defaultMessage: 'Kap Verde' },
  KAZ: { id: 'country.KAZ', defaultMessage: 'Kazakstan' },
  KEN: { id: 'country.KEN', defaultMessage: 'Kenya' },
  CHN: { id: 'country.CHN', defaultMessage: 'Kina' },
  KGZ: { id: 'country.KGZ', defaultMessage: 'Kirgizistan' },
  KIR: { id: 'country.KIR', defaultMessage: 'Kiribati' },
  CCK: { id: 'country.CCK', defaultMessage: 'Kokosöarna' },
  COM: { id: 'country.COM', defaultMessage: 'Komorerna' },
  COG: { id: 'country.COG', defaultMessage: 'Kongo-Brazzaville' },
  HRV: { id: 'country.HRV', defaultMessage: 'Kroatien' },
  CUB: { id: 'country.CUB', defaultMessage: 'Kuba' },
  KWT: { id: 'country.KWT', defaultMessage: 'Kuwait' },
  LAO: { id: 'country.LAO', defaultMessage: 'Laos' },
  LSO: { id: 'country.LSO', defaultMessage: 'Lesotho' },
  LVA: { id: 'country.LVA', defaultMessage: 'Lettland' },
  LBN: { id: 'country.LBN', defaultMessage: 'Libanon' },
  LBR: { id: 'country.LBR', defaultMessage: 'Liberia' },
  LBY: { id: 'country.LBY', defaultMessage: 'Libyen' },
  LIE: { id: 'country.LIE', defaultMessage: 'Liechtenstein' },
  LTU: { id: 'country.LTU', defaultMessage: 'Litauen' },
  LUX: { id: 'country.LUX', defaultMessage: 'Luxemburg' },
  MAC: { id: 'country.MAC', defaultMessage: 'Macau' },
  MDG: { id: 'country.MDG', defaultMessage: 'Madagaskar' },
  MKD: { id: 'country.MKD', defaultMessage: 'Makedonien' },
  MWI: { id: 'country.MWI', defaultMessage: 'Malawi' },
  MYS: { id: 'country.MYS', defaultMessage: 'Malaysia' },
  MDV: { id: 'country.MDV', defaultMessage: 'Maldiverna' },
  MLI: { id: 'country.MLI', defaultMessage: 'Mali' },
  MLT: { id: 'country.MLT', defaultMessage: 'Malta' },
  MAR: { id: 'country.MAR', defaultMessage: 'Marocko' },
  MHL: { id: 'country.MHL', defaultMessage: 'Marshallöarna' },
  MTQ: { id: 'country.MTQ', defaultMessage: 'Martinique' },
  MRT: { id: 'country.MRT', defaultMessage: 'Mauretanien' },
  MUS: { id: 'country.MUS', defaultMessage: 'Mauritius' },
  MYT: { id: 'country.MYT', defaultMessage: 'Mayotte' },
  MEX: { id: 'country.MEX', defaultMessage: 'Mexiko' },
  FSM: { id: 'country.FSM', defaultMessage: 'Mikronesiska federationen' },
  MDA: { id: 'country.MDA', defaultMessage: 'Moldavien' },
  MCO: { id: 'country.MCO', defaultMessage: 'Monaco' },
  MNG: { id: 'country.MNG', defaultMessage: 'Mongoliet' },
  MNE: { id: 'country.MNE', defaultMessage: 'Montenegro' },
  MSR: { id: 'country.MSR', defaultMessage: 'Montserrat' },
  MOZ: { id: 'country.MOZ', defaultMessage: 'Moçambique' },
  NAM: { id: 'country.NAM', defaultMessage: 'Namibia' },
  NRU: { id: 'country.NRU', defaultMessage: 'Nauru' },
  NLD: { id: 'country.NLD', defaultMessage: 'Nederländerna' },
  ANT: { id: 'country.ANT', defaultMessage: 'Nederländska Antillerna' },
  NPL: { id: 'country.NPL', defaultMessage: 'Nepal' },
  NIC: { id: 'country.NIC', defaultMessage: 'Nicaragua' },
  NER: { id: 'country.NER', defaultMessage: 'Niger' },
  NGA: { id: 'country.NGA', defaultMessage: 'Nigeria' },
  NIU: { id: 'country.NIU', defaultMessage: 'Niue' },
  PRK: { id: 'country.PRK', defaultMessage: 'Nordkorea' },
  MNP: { id: 'country.MNP', defaultMessage: 'Nordmarianerna' },
  NFK: { id: 'country.NFK', defaultMessage: 'Norfolkön' },
  NOR: { id: 'country.NOR', defaultMessage: 'Norge' },
  NCL: { id: 'country.NCL', defaultMessage: 'Nya Kaledonien' },
  NZL: { id: 'country.NZL', defaultMessage: 'Nya Zeeland' },
  OMN: { id: 'country.OMN', defaultMessage: 'Oman' },
  PAK: { id: 'country.PAK', defaultMessage: 'Pakistan' },
  PLW: { id: 'country.PLW', defaultMessage: 'Palau' },
  PAN: { id: 'country.PAN', defaultMessage: 'Panama' },
  PNG: { id: 'country.PNG', defaultMessage: 'Papua Nya Guinea' },
  PRY: { id: 'country.PRY', defaultMessage: 'Paraguay' },
  PER: { id: 'country.PER', defaultMessage: 'Peru' },
  PCN: { id: 'country.PCN', defaultMessage: 'Pitcairnöarna' },
  POL: { id: 'country.POL', defaultMessage: 'Polen' },
  PRT: { id: 'country.PRT', defaultMessage: 'Portugal' },
  PRI: { id: 'country.PRI', defaultMessage: 'Puerto Rico' },
  QAT: { id: 'country.QAT', defaultMessage: 'Qatar' },
  REU: { id: 'country.REU', defaultMessage: 'Réunion' },
  ROU: { id: 'country.ROU', defaultMessage: 'Rumänien' },
  RWA: { id: 'country.RWA', defaultMessage: 'Rwanda' },
  RUS: { id: 'country.RUS', defaultMessage: 'Ryssland' },
  KNA: { id: 'country.KNA', defaultMessage: 'Saint Kitts och Nevis' },
  LCA: { id: 'country.LCA', defaultMessage: 'Saint Lucia' },
  VCT: { id: 'country.VCT', defaultMessage: 'Saint Vincent och Grenadinerna' },
  BLM: { id: 'country.BLM', defaultMessage: 'Saint-Barthélemy' },
  SPM: { id: 'country.SPM', defaultMessage: 'Saint-Pierre och Miquelon' },
  SLB: { id: 'country.SLB', defaultMessage: 'Salomonöarna' },
  WSM: { id: 'country.WSM', defaultMessage: 'Samoa' },
  SMR: { id: 'country.SMR', defaultMessage: 'San Marino' },
  SHN: { id: 'country.SHN', defaultMessage: 'Sankta Helena' },
  STP: { id: 'country.STP', defaultMessage: 'São Tomé och Príncipe' },
  SAU: { id: 'country.SAU', defaultMessage: 'Saudiarabien' },
  CHE: { id: 'country.CHE', defaultMessage: 'Schweiz' },
  SEN: { id: 'country.SEN', defaultMessage: 'Senegal' },
  SRB: { id: 'country.SRB', defaultMessage: 'Serbien' },
  SYC: { id: 'country.SYC', defaultMessage: 'Seychellerna' },
  SLE: { id: 'country.SLE', defaultMessage: 'Sierra Leone' },
  SGP: { id: 'country.SGP', defaultMessage: 'Singapore' },
  SVK: { id: 'country.SVK', defaultMessage: 'Slovakien' },
  SVN: { id: 'country.SVN', defaultMessage: 'Slovenien' },
  SOM: { id: 'country.SOM', defaultMessage: 'Somalia' },
  ESP: { id: 'country.ESP', defaultMessage: 'Spanien' },
  LKA: { id: 'country.LKA', defaultMessage: 'Sri Lanka' },
  GBR: { id: 'country.GBR', defaultMessage: 'Storbritannien' },
  SDN: { id: 'country.SDN', defaultMessage: 'Sudan' },
  SUR: { id: 'country.SUR', defaultMessage: 'Surinam' },
  SWE: { id: 'country.SWE', defaultMessage: 'Sverige' },
  SWZ: { id: 'country.SWZ', defaultMessage: 'Swaziland' },
  ZAF: { id: 'country.ZAF', defaultMessage: 'Sydafrika' },
  KOR: { id: 'country.KOR', defaultMessage: 'Sydkorea' },
  SYR: { id: 'country.SYR', defaultMessage: 'Syrien' },
  TJK: { id: 'country.TJK', defaultMessage: 'Tadzjikistan' },
  TWN: { id: 'country.TWN', defaultMessage: 'Taiwan' },
  TZA: { id: 'country.TZA', defaultMessage: 'Tanzania' },
  TCD: { id: 'country.TCD', defaultMessage: 'Tchad' },
  THA: { id: 'country.THA', defaultMessage: 'Thailand' },
  CZE: { id: 'country.CZE', defaultMessage: 'Tjeckien' },
  TGO: { id: 'country.TGO', defaultMessage: 'Togo' },
  TKL: { id: 'country.TKL', defaultMessage: 'Tokelauöarna' },
  TON: { id: 'country.TON', defaultMessage: 'Tonga' },
  TTO: { id: 'country.TTO', defaultMessage: 'Trinidad och Tobago' },
  TUN: { id: 'country.TUN', defaultMessage: 'Tunisien' },
  TUR: { id: 'country.TUR', defaultMessage: 'Turkiet' },
  TKM: { id: 'country.TKM', defaultMessage: 'Turkmenistan' },
  TCA: { id: 'country.TCA', defaultMessage: 'Turks- och Caicosöarna' },
  TUV: { id: 'country.TUV', defaultMessage: 'Tuvalu' },
  DEU: { id: 'country.DEU', defaultMessage: 'Tyskland' },
  UGA: { id: 'country.UGA', defaultMessage: 'Uganda' },
  UKR: { id: 'country.UKR', defaultMessage: 'Ukraina' },
  HUN: { id: 'country.HUN', defaultMessage: 'Ungern' },
  URY: { id: 'country.URY', defaultMessage: 'Uruguay' },
  USA: { id: 'country.USA', defaultMessage: 'USA' },
  UZB: { id: 'country.UZB', defaultMessage: 'Uzbekistan' },
  VUT: { id: 'country.VUT', defaultMessage: 'Vanuatu' },
  VAT: { id: 'country.VAT', defaultMessage: 'Vatikanstaten' },
  VEN: { id: 'country.VEN', defaultMessage: 'Venezuela' },
  VNM: { id: 'country.VNM', defaultMessage: 'Vietnam' },
  BLR: { id: 'country.BLR', defaultMessage: 'Vitryssland' },
  ESH: { id: 'country.ESH', defaultMessage: 'Västsahara' },
  ZMB: { id: 'country.ZMB', defaultMessage: 'Zambia' },
  ZWE: { id: 'country.ZWE', defaultMessage: 'Zimbabwe' },
  AUT: { id: 'country.AUT', defaultMessage: 'Österrike' },
  TLS: { id: 'country.TLS', defaultMessage: 'Östtimor' },
});
export const CITIZENSHIP_OPTIONS = [
  { value: 'SWE', label: messages.SWE },
  { value: 'AFG', label: messages.AFG },
  { value: 'ALB', label: messages.ALB },
  { value: 'DZA', label: messages.DZA },
  { value: 'VIR', label: messages.VIR },
  { value: 'ASM', label: messages.ASM },
  { value: 'AND', label: messages.AND },
  { value: 'AGO', label: messages.AGO },
  { value: 'AIA', label: messages.AIA },
  { value: 'ATA', label: messages.ATA },
  { value: 'ATG', label: messages.ATG },
  { value: 'ARG', label: messages.ARG },
  { value: 'ARM', label: messages.ARM },
  { value: 'ABW', label: messages.ABW },
  { value: 'AUS', label: messages.AUS },
  { value: 'AZE', label: messages.AZE },
  { value: 'BHS', label: messages.BHS },
  { value: 'BHR', label: messages.BHR },
  { value: 'BGD', label: messages.BGD },
  { value: 'BRB', label: messages.BRB },
  { value: 'BEL', label: messages.BEL },
  { value: 'BLZ', label: messages.BLZ },
  { value: 'BEN', label: messages.BEN },
  { value: 'BMU', label: messages.BMU },
  { value: 'BTN', label: messages.BTN },
  { value: 'BOL', label: messages.BOL },
  { value: 'BIH', label: messages.BIH },
  { value: 'BWA', label: messages.BWA },
  { value: 'BVT', label: messages.BVT },
  { value: 'BRA', label: messages.BRA },
  { value: 'BRN', label: messages.BRN },
  { value: 'BGR', label: messages.BGR },
  { value: 'BFA', label: messages.BFA },
  { value: 'MMR', label: messages.MMR },
  { value: 'BDI', label: messages.BDI },
  { value: 'KYM', label: messages.KYM },
  { value: 'CAF', label: messages.CAF },
  { value: 'CHL', label: messages.CHL },
  { value: 'COL', label: messages.COL },
  { value: 'COK', label: messages.COK },
  { value: 'CRI', label: messages.CRI },
  { value: 'CYP', label: messages.CYP },
  { value: 'DNK', label: messages.DNK },
  { value: 'COD', label: messages.COD },
  { value: 'DJI', label: messages.DJI },
  { value: 'DMA', label: messages.DMA },
  { value: 'DOM', label: messages.DOM },
  { value: 'ECU', label: messages.ECU },
  { value: 'EGY', label: messages.EGY },
  { value: 'GNQ', label: messages.GNQ },
  { value: 'SLV', label: messages.SLV },
  { value: 'CIV', label: messages.CIV },
  { value: 'ERI', label: messages.ERI },
  { value: 'EST', label: messages.EST },
  { value: 'ETH', label: messages.ETH },
  { value: 'FLK', label: messages.FLK },
  { value: 'FJI', label: messages.FJI },
  { value: 'PHL', label: messages.PHL },
  { value: 'FIN', label: messages.FIN },
  { value: 'FRA', label: messages.FRA },
  { value: 'GUF', label: messages.GUF },
  { value: 'PYF', label: messages.PYF },
  { value: 'FRO', label: messages.FRO },
  { value: 'ARE', label: messages.ARE },
  { value: 'GAB', label: messages.GAB },
  { value: 'GMB', label: messages.GMB },
  { value: 'GEO', label: messages.GEO },
  { value: 'GHA', label: messages.GHA },
  { value: 'GIB', label: messages.GIB },
  { value: 'GRC', label: messages.GRC },
  { value: 'GRD', label: messages.GRD },
  { value: 'GRL', label: messages.GRL },
  { value: 'GLP', label: messages.GLP },
  { value: 'GUM', label: messages.GUM },
  { value: 'GTM', label: messages.GTM },
  { value: 'GGY', label: messages.GGY },
  { value: 'GIN', label: messages.GIN },
  { value: 'GNB', label: messages.GNB },
  { value: 'GUY', label: messages.GUY },
  { value: 'HTI', label: messages.HTI },
  { value: 'HND', label: messages.HND },
  { value: 'HKG', label: messages.HKG },
  { value: 'IND', label: messages.IND },
  { value: 'IDN', label: messages.IDN },
  { value: 'IRQ', label: messages.IRQ },
  { value: 'IRN', label: messages.IRN },
  { value: 'IRL', label: messages.IRL },
  { value: 'ISL', label: messages.ISL },
  { value: 'IMN', label: messages.IMN },
  { value: 'ISR', label: messages.ISR },
  { value: 'ITA', label: messages.ITA },
  { value: 'JAM', label: messages.JAM },
  { value: 'JPN', label: messages.JPN },
  { value: 'YEM', label: messages.YEM },
  { value: 'JEY', label: messages.JEY },
  { value: 'JOR', label: messages.JOR },
  { value: 'CXR', label: messages.CXR },
  { value: 'KHM', label: messages.KHM },
  { value: 'CMR', label: messages.CMR },
  { value: 'CAN', label: messages.CAN },
  { value: 'CPV', label: messages.CPV },
  { value: 'KAZ', label: messages.KAZ },
  { value: 'KEN', label: messages.KEN },
  { value: 'CHN', label: messages.CHN },
  { value: 'KGZ', label: messages.KGZ },
  { value: 'KIR', label: messages.KIR },
  { value: 'CCK', label: messages.CCK },
  { value: 'COM', label: messages.COM },
  { value: 'COG', label: messages.COG },
  { value: 'HRV', label: messages.HRV },
  { value: 'CUB', label: messages.CUB },
  { value: 'KWT', label: messages.KWT },
  { value: 'LAO', label: messages.LAO },
  { value: 'LSO', label: messages.LSO },
  { value: 'LVA', label: messages.LVA },
  { value: 'LBN', label: messages.LBN },
  { value: 'LBR', label: messages.LBR },
  { value: 'LBY', label: messages.LBY },
  { value: 'LIE', label: messages.LIE },
  { value: 'LTU', label: messages.LTU },
  { value: 'LUX', label: messages.LUX },
  { value: 'MAC', label: messages.MAC },
  { value: 'MDG', label: messages.MDG },
  { value: 'MKD', label: messages.MKD },
  { value: 'MWI', label: messages.MWI },
  { value: 'MYS', label: messages.MYS },
  { value: 'MDV', label: messages.MDV },
  { value: 'MLI', label: messages.MLI },
  { value: 'MLT', label: messages.MLT },
  { value: 'MAR', label: messages.MAR },
  { value: 'MHL', label: messages.MHL },
  { value: 'MTQ', label: messages.MTQ },
  { value: 'MRT', label: messages.MRT },
  { value: 'MUS', label: messages.MUS },
  { value: 'MYT', label: messages.MYT },
  { value: 'MEX', label: messages.MEX },
  { value: 'FSM', label: messages.FSM },
  { value: 'MDA', label: messages.MDA },
  { value: 'MCO', label: messages.MCO },
  { value: 'MNG', label: messages.MNG },
  { value: 'MNE', label: messages.MNE },
  { value: 'MSR', label: messages.MSR },
  { value: 'MOZ', label: messages.MOZ },
  { value: 'NAM', label: messages.NAM },
  { value: 'NRU', label: messages.NRU },
  { value: 'NLD', label: messages.NLD },
  { value: 'ANT', label: messages.ANT },
  { value: 'NPL', label: messages.NPL },
  { value: 'NIC', label: messages.NIC },
  { value: 'NER', label: messages.NER },
  { value: 'NGA', label: messages.NGA },
  { value: 'NIU', label: messages.NIU },
  { value: 'PRK', label: messages.PRK },
  { value: 'MNP', label: messages.MNP },
  { value: 'NFK', label: messages.NFK },
  { value: 'NOR', label: messages.NOR },
  { value: 'NCL', label: messages.NCL },
  { value: 'NZL', label: messages.NZL },
  { value: 'OMN', label: messages.OMN },
  { value: 'PAK', label: messages.PAK },
  { value: 'PLW', label: messages.PLW },
  { value: 'PAN', label: messages.PAN },
  { value: 'PNG', label: messages.PNG },
  { value: 'PRY', label: messages.PRY },
  { value: 'PER', label: messages.PER },
  { value: 'PCN', label: messages.PCN },
  { value: 'POL', label: messages.POL },
  { value: 'PRT', label: messages.PRT },
  { value: 'PRI', label: messages.PRI },
  { value: 'QAT', label: messages.QAT },
  { value: 'REU', label: messages.REU },
  { value: 'ROU', label: messages.ROU },
  { value: 'RWA', label: messages.RWA },
  { value: 'RUS', label: messages.RUS },
  { value: 'KNA', label: messages.KNA },
  { value: 'LCA', label: messages.LCA },
  { value: 'VCT', label: messages.VCT },
  { value: 'BLM', label: messages.BLM },
  { value: 'SPM', label: messages.SPM },
  { value: 'SLB', label: messages.SLB },
  { value: 'WSM', label: messages.WSM },
  { value: 'SMR', label: messages.SMR },
  { value: 'SHN', label: messages.SHN },
  { value: 'STP', label: messages.STP },
  { value: 'SAU', label: messages.SAU },
  { value: 'CHE', label: messages.CHE },
  { value: 'SEN', label: messages.SEN },
  { value: 'SRB', label: messages.SRB },
  { value: 'SYC', label: messages.SYC },
  { value: 'SLE', label: messages.SLE },
  { value: 'SGP', label: messages.SGP },
  { value: 'SVK', label: messages.SVK },
  { value: 'SVN', label: messages.SVN },
  { value: 'SOM', label: messages.SOM },
  { value: 'ESP', label: messages.ESP },
  { value: 'LKA', label: messages.LKA },
  { value: 'GBR', label: messages.GBR },
  { value: 'SDN', label: messages.SDN },
  { value: 'SUR', label: messages.SUR },
  { value: 'SWZ', label: messages.SWZ },
  { value: 'ZAF', label: messages.ZAF },
  { value: 'KOR', label: messages.KOR },
  { value: 'SYR', label: messages.SYR },
  { value: 'TJK', label: messages.TJK },
  { value: 'TWN', label: messages.TWN },
  { value: 'TZA', label: messages.TZA },
  { value: 'TCD', label: messages.TCD },
  { value: 'THA', label: messages.THA },
  { value: 'CZE', label: messages.CZE },
  { value: 'TGO', label: messages.TGO },
  { value: 'TKL', label: messages.TKL },
  { value: 'TON', label: messages.TON },
  { value: 'TTO', label: messages.TTO },
  { value: 'TUN', label: messages.TUN },
  { value: 'TUR', label: messages.TUR },
  { value: 'TKM', label: messages.TKM },
  { value: 'TCA', label: messages.TCA },
  { value: 'TUV', label: messages.TUV },
  { value: 'DEU', label: messages.DEU },
  { value: 'UGA', label: messages.UGA },
  { value: 'UKR', label: messages.UKR },
  { value: 'HUN', label: messages.HUN },
  { value: 'URY', label: messages.URY },
  { value: 'USA', label: messages.USA },
  { value: 'UZB', label: messages.UZB },
  { value: 'VUT', label: messages.VUT },
  { value: 'VAT', label: messages.VAT },
  { value: 'VEN', label: messages.VEN },
  { value: 'VNM', label: messages.VNM },
  { value: 'BLR', label: messages.BLR },
  { value: 'ESH', label: messages.ESH },
  { value: 'ZMB', label: messages.ZMB },
  { value: 'ZWE', label: messages.ZWE },
  { value: 'AUT', label: messages.AUT },
  { value: 'TLS', label: messages.TLS },
];

export type FundRiskLevel = 'LOW' | 'MEDIUM' | 'HIGH';

export const POLL_INTERVAL = 500;
export const CRAWL_POLL_INTERVAL = 1500;

export const EMPLOYEE_ROLE: Record<MembershipRole, MessageDescriptor> = {
  SUPER: workInformationMessages.superAdmin,
  HR: workInformationMessages.HRAdmin,
  CONTENT: workInformationMessages.contentAdmin,
  FINANCE: workInformationMessages.financeAdmin,
};

export const COLLECTIVE_AGREEMENT_LABEL: Record<CollectiveAgreement, string> = {
  ITP1: 'ITP 1',
  ITP: 'ITP 2',
  SAFLO: 'SAF-LO',
  KAPKL: 'KAP-KL',
  AKAPKL: 'AKAP-KL',
  AKAPKR: 'AKAP-KR',
  PA161: 'PA 16 avdelning 1',
  PA162: 'PA 16 avdelning 2',
  PAKFS09: 'PA-KFS 09',
  BTP1: 'BTP 1',
  BTP1X: 'BTP 1, hängavtal',
  BTP: 'BTP 2',
  FTP1: 'FTP 1',
  FTP: 'FTP 2',
  KTP1: 'KTP 1',
  KTP: 'KTP 2',
  KAP: 'KAP (KFO-LO)',
  AIP: 'AIP',
  PPA13: 'PPA 13',
};

export const SALARY_EXCHANGE_TYPED_PROVIDER: Record<
  SalaryExchangeProvider,
  FlexSalaryExchangeProvider
> = {
  nordnet: FlexSalaryExchangeProvider.nordnet,
  collectum: FlexSalaryExchangeProvider.collectum,
  nordea_fund: FlexSalaryExchangeProvider.nordea_fund,
  nordea_trad: FlexSalaryExchangeProvider.nordea_trad,
};

export const PENSION_PROVIDER: Record<LegacyPensionProvider, string> = {
  advinans: 'Advinans Autopilot',
  nordnet: 'Nordnet',
  lf: 'Länsförsäkringar',
  nordea_fund: 'Nordea',
  nordea_trad: 'Nordea',
};

export const SALARY_EXCHANGE_PROVIDER: Record<SalaryExchangeProvider, string> =
  {
    nordnet: 'Nordnet',
    collectum: 'ITP',
    nordea_fund: 'Nordea',
    nordea_trad: 'Nordea',
  };

export const MEMBERSHIP_BENEFIT_STATUS_COLOR: Record<
  InsurancePremiumStatus,
  string
> = {
  ACTIVE: 'green',
  ACTIVE_WAIVER: 'green',
  COMPLETE: 'outline',
  INACTIVE: 'outline',
  OPEN: 'green',
  PENDING_ACTIVATION: 'orange',
};

export const MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR: Record<
  InsuranceComponentStatus,
  string
> = {
  NEW: 'orange',
  ACTIVE: 'green',
  NOT_SPECIFIED: 'error',
  PAID_UP: 'outline',
  PAID_OUT: 'outline',
  PAYING_OUT: 'green',
};

export const MEMBERSHIP_INSURANCE_PREMIUM_STATUS: Record<
  InsuranceComponentStatus,
  InsurancePremiumStatus | undefined
> = {
  NEW: InsurancePremiumStatus.PENDING_ACTIVATION,
  ACTIVE: InsurancePremiumStatus.ACTIVE,
  NOT_SPECIFIED: undefined,
  PAID_UP: InsurancePremiumStatus.INACTIVE,
  PAID_OUT: InsurancePremiumStatus.INACTIVE,
  PAYING_OUT: InsurancePremiumStatus.ACTIVE,
};

export const BASE_AMOUNT_TYPE: Record<BaseAmountType, string> = {
  PBB: 'PBB',
  IBB: 'IBB',
};

export const FUTUR_PENSION = 'Futur Pension';
export const NORDNET = 'Nordnet';

export const translatePBB = Pbb => `${Pbb.replace('PBB_', '')} PBB`;
export const translatePBBLevel = Pbb => `${Pbb.replace('PBB', '')} PBB`;

export const DEFAULT_EMPLOYER_CONTRIBUTIONS_SHARE = 0.3142;

export const DEFAULT_RESULT_PER_PAGE = 50;
export const DEFAULT_RESULT_PER_PAGE_SMALL = 10;
export const DEFAULT_RESULT_PER_PAGE_XSMALL = 5;

export const baseDeduction = 19247;
export const marginalTaxSalaryBP1 = 37942;
export const marginalTaxSalaryBP2 = 55192;
export const municipalIncomeTaxRate = 0.32;
export const stateIncomeTaxBP1 = 0.2;
export const stateIncomeTaxBP2 = 0.25;

// /* Remove it once BE implements a flag for collectively agreed occupational pension */
export const COLLECTIVELY_AGREED_PRODUCT_CODE = {
  AKAPKL: 'AKAPKL',
  AKAPKR: 'AKAPKR',
  BTP: 'BTP',
  BTP10: 'BTP10',
  BTP10B: 'BTP10B',
  BTP1T: 'BTP1T',
  BTP1V: 'BTP1V',
  BTPKE: 'BTPKE',
  BTPKU: 'BTPKU',
  FTP: 'FTP',
  FTP1: 'FTP1',
  FTP10: 'FTP10',
  FTP10B: 'FTP10B',
  FTP2: 'FTP2',
  FTPKE: 'FTPKE',
  FTPKU: 'FTPKU',
  ITP: 'ITP',
  ITPS: 'ITPS',
  ITP1: 'ITP1',
  ITP10: 'ITP10',
  ITP10B: 'ITP10B',
  ITP2: 'ITP2',
  ITPK: 'ITPK',
  ITPKE: 'ITPKE',
  ITPKU: 'ITPKU',
  ITPP: 'ITPP',
  KAP: 'KAP',
  KAPKL: 'KAPKL',
  KAPKLK: 'KAPKLK',
  KAPKL10: 'KAPKL10',
  KTP: 'KTP',
  KTPKE: 'KTPKE',
  KTPKU: 'KTPKU',
  KTP1: 'KTP1',
  KTP10: 'KTP10',
  KTP10B: 'KTP10B',
  PA03: 'PA03',
  PA03K: 'PA03K',
  PA03I: 'PA03I',
  PA03E: 'PA03E',
  PA03P: 'PA03P',
  PA03AIP: 'PA03AIP',
  PA161: 'PA161',
  PA161V: 'PA161V',
  PA162: 'PA162',
  PA162I: 'PA162I',
  PA16AVD2: 'PA16AVD2',
  PAKFS: 'PAKFS',
  PAKFS09: 'PAKFS09',
  PAKFSK: 'PAKFSK',
  PAKFS10: 'PAKFS10',
  PAKFS10B: 'PAKFS10B',
  PARFVS: 'PARFVS',
  PFA: 'PFA',
  PFAIPR: 'PFAIPR',
  PFAK: 'PFAK',
  PPA07: 'PPA07',
  PPAK: 'PPAK',
  PPA13: 'PPA13',
  SAFLO: 'SAFLO',
  SAFLOSTP: 'SAFLOSTP',
  GTP: 'GTP',
  K06: 'K06',
  K0610B: 'K0610B',
  KAPKLIPR: 'KAPKLIPR',
  TPA18: 'TPA18',
  PA91: 'PA91',
  PA91F: 'PA91F',
  PA161E: 'PA161E',
  PA161F: 'PA161F',
  PA161O: 'PA161O',
  PA162E: 'PA162E',
  PA162K: 'PA162K',
  PAKFS09IPR: 'PAKFS09IPR',
};

export type CollectivelyAgreedProductCode =
  keyof typeof COLLECTIVELY_AGREED_PRODUCT_CODE;

type StaticBenefitUrl =
  | 'filmstaden'
  | 'rabatt-forsakringar'
  | 'personal-advice';

export enum StaticBenefitType {
  'filmstaden' = 'filmstaden',
  'forsakringar' = 'euro_accident_voluntary_group',
  'personalAdvice' = 'nordea_node_personal_advice',
}

export const STATIC_BENEFIT_URL_TYPE_MAP: Record<
  StaticBenefitUrl,
  StaticBenefitType
> = {
  filmstaden: StaticBenefitType.filmstaden,
  'rabatt-forsakringar': StaticBenefitType.forsakringar,
  'personal-advice': StaticBenefitType.personalAdvice,
};

export const CUSTOM_BENEFIT_TYPE_NAME = 'custom';

export enum InsuranceTypeCode {
  REPAYMENT_COVER = 'ÅBS',
}

export enum PensionTransferStatus {
  IN_TRANSIT = 'IN TRANSIT',
}

export const SMALL_SCREEN_RESOLUTION = defaultTheme.breakpoints.desktopHD * 16;

export const MOBILE_SCREEN_RESOLUTION =
  defaultTheme.breakpoints.tablet * 16 - 1;

export const NORDEA_PENSION_PORTFOLIO_NAME = 'Nordea Pensionsportfölj';
export const NORDEA_PENSION_PORTFOLIO_ID = 'NLPSE0000013';
export const NORDEA_TRAD_PORTFOLIO_ID = 'NLPSE0000020';

export const APP_ID = 'app';

export const DEFAULT_RETIREMENT_AGE = 65;

export const INVOICE_SUPPLIER: Record<
  Exclude<InvoiceSupplier, InvoiceSupplier.ACTIWAY | InvoiceSupplier.EDENRED>,
  string
> = {
  ADVINANS: 'Nordea Node',
  EURO_ACCIDENT: 'Euro Accident',
  FUTUR: 'Futur Pension',
  LF: 'Länsförsäkringar',
  NORDNET: 'Nordnet',
  SL: 'SL',
  NLP: 'Nordea',
};

export const MONTHLY_SALARY_REMUNERATION_TYPE_ID = '11100';

export enum UnitCodeOptions {
  ANN = 'ANN',
  LS = 'LS',
  MON = 'MON',
}

export const unitCodeMessages: Record<UnitCodeOptions, MessageDescriptor> = {
  [UnitCodeOptions.MON]: suffixMessages.monthly,
  [UnitCodeOptions.ANN]: suffixMessages.annually,
  [UnitCodeOptions.LS]: suffixMessages.lumpSum,
};

export const unitCodeSuffixMessages: Omit<
  Record<UnitCodeOptions, MessageDescriptor>,
  'LS'
> = {
  [UnitCodeOptions.MON]: suffixMessages.months,
  [UnitCodeOptions.ANN]: suffixMessages.years,
};

export const INVOICE_SUPPLIERS: Record<InvoiceSupplier, string> = {
  ACTIWAY: 'Actiway AB',
  FUTUR: 'Danica Pension Försäkringsaktiebolag (publ)',
  EDENRED: 'Edenred',
  EURO_ACCIDENT: 'Euro Accident',
  LF: 'Länsförsäkringar Fondliv Försäkrings AB',
  NLP: 'Nordea Livförsäkring Sverige AB (publ)',
  ADVINANS: 'Nordea Node AB',
  NORDNET: 'Nordnet Pensionsförsäkring AB',
  SL: 'SL',
};

export const CHART_ANIMATION_DURATION = 250;
