import { defineMessages } from 'react-intl';

export const loginMessages = defineMessages({
  generalProgress: {
    id: 'loginMessages.generalProgress',
    description: 'Inloggning eller signering pågår',
    defaultMessage: 'Starta BankID-appen',
  },
  generalFailure: {
    id: 'loginMessages.generalFailure',
    description: 'Okänt fel. Försök igen.',
    defaultMessage: 'Åtgärden avbruten. Försök igen.',
  },
  startFailed: {
    id: 'loginMessages.startFailed',
    description: 'Okänt fel. Försök igen.',
    defaultMessage:
      'BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
  },
  alreadyInProgress: {
    id: 'loginMessages.alreadyInProgress',
    description: 'Okänt fel. Försök igen.',
    defaultMessage:
      'En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.',
  },
  underageFailure: {
    id: 'loginMessages.underageFailure',
    description: 'User under age 18',
    defaultMessage: 'Du måste vara minst 18 år för att använda våra tjänster.',
  },
  sparLookupFailure: {
    id: 'loginMessages.sparLookupFailure',
    description: 'User not found in SPAR',
    defaultMessage:
      'Ditt konto kan inte skapas eftersom vi inte kan bekräfta din identitet automatiskt. För att gå vidare, kontakta Nordea Nodes kundtjänst.',
  },
  signWithBankId: {
    id: 'loginMessages.signWithBankId',
    description: 'Skriv under med BankID',
    defaultMessage: 'Skriv under med BankID',
  },
  complete: {
    id: 'loginMessages.complete',
    description: 'Du skickas nu vidare',
    defaultMessage: 'Du skickas nu vidare',
  },
  started: {
    id: 'loginMessages.started',
    description: 'Söker efter BankID',
    defaultMessage: 'Söker efter BankID',
  },
  userSign: {
    id: 'loginMessages.userSign',
    description: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera',
    defaultMessage:
      'Skriv in din säkerhetskod i BankID-appen och välj Legitimera eller Skriv under',
  },
  cancelled: {
    id: 'loginMessages.cancelled',
    description: 'Åtgärden avbruten. Försök igen.',
    defaultMessage: 'Åtgärden avbruten. Försök igen.',
  },
  certificateErr: {
    id: 'loginMessages.certificateErr',
    description:
      'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.',
    defaultMessage:
      'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.',
  },
  clientErr: {
    id: 'loginMessages.clientErr',
    description: 'Okänt fel. Uppdatera BankID-appen och försök igen.',
    defaultMessage: 'Okänt fel. Uppdatera BankID-appen och försök igen.',
  },
  expiredTransaction: {
    id: 'loginMessages.expiredTransaction',
    description:
      'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.',
    defaultMessage:
      'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.',
  },
  userCancel: {
    id: 'loginMessages.userCancel',
    description: 'Åtgärden avbruten',
    defaultMessage: 'Åtgärden avbruten',
  },
  loginTitle: {
    id: 'loginMessages.loginTitle',
    description: 'Login',
    defaultMessage: 'Logga in',
  },
  welcomeToNordeaNode: {
    id: 'loginMessages.welcomeToNordeaNode',
    defaultMessage: 'Välkommen till Nordea Node',
  },
  loginIntroduction: {
    id: 'loginMessages.loginIntroduction',
    defaultMessage:
      'Logga in genom att identifiera dig med BankID. Du kan logga in med e-post i stället om din arbetsgivare tillåter det.',
  },
  identifyWithBankId: {
    id: 'loginMessages.identifyWithBankId',
    description: 'Submit login',
    defaultMessage: 'Identifiera dig med BankID',
  },
  signWithOtherDevice: {
    id: 'common.signWithOtherDevice',
    description: 'Sign with other device',
    defaultMessage: 'Använd BankID på en annan enhet',
  },
  loggedOut: {
    id: 'loginMessages.loggedOut',
    description: 'Du har loggat ut',
    defaultMessage: 'Du har loggat ut',
  },
  personalIdentityNumberHelperText: {
    id: 'loginMessages.personalIdentityNumberHelperText',
    description: '10 eller 12 siffror',
    defaultMessage: '10 eller 12 siffror',
  },
  logoutError: {
    id: 'loginMessages.logoutError',
    description:
      'Det blev ett fel när du skulle logga ut. Ladda om sidan och kontakta\n    kundtjänst om felet kvarstår.',
    defaultMessage: 'Åtgärden misslyckades. Försök igen.',
  },
  loggingOut: {
    id: 'loginMessages.loggingOut',
    description: 'Loggar ut...',
    defaultMessage: 'Loggar ut…',
  },
  signInSameDeviceText: {
    id: 'loginMessages.signInSameDeviceText',
    description: 'Login with BankID on same device',
    defaultMessage: 'BankID på denna enhet',
  },
  signInOtherDeviceText: {
    id: 'loginMessages.signInOtherDeviceText',
    description: 'Login with BankID on other device',
    defaultMessage: 'BankID på en annan enhet',
  },
  userNotFound: {
    id: 'loginMessages.userNotFound',
    description: 'app/login - user not found',
    defaultMessage:
      'Vi kan inte hitta ditt konto. För att använda tjänsten, ta kontakt med din arbetsgivare.',
  },
  inactivityMessage: {
    id: 'loginMessages.inactivityMessage',
    description: 'app/login',
    defaultMessage: 'Du har blivit utloggad på grund av inaktivitet.',
  },
  rememberCredentials: {
    id: 'loginMessages.rememberCredentials',
    defaultMessage: 'Kom ihåg inloggningsuppgifter',
  },
  emailLoginDescription: {
    id: 'loginMessages.emailLoginDescription',
    defaultMessage:
      'Om din arbetsgivare tillåter det kan du logga in genom att få en länk till din e-post. För att komma åt vissa funktioner måste du dock använda BankID.',
  },
  sendLink: {
    id: 'loginMessages.sendLink',
    defaultMessage: 'Skicka länk',
  },
  emailLogin: {
    id: 'loginMessages.emailLogin',
    defaultMessage: 'Logga in med e-post',
  },
  bankIdLogin: {
    id: 'loginMessages.bankIdLogin',
    defaultMessage: 'Logga in med BankID istället',
  },
  checkYourEmail: {
    id: 'loginMessages.checkYourEmail',
    defaultMessage: 'En inloggningslänk har skickats till din e-post.',
  },
  invalidEmail: {
    id: 'loginMessages.invalidEmail',
    defaultMessage:
      'Du kan inte logga in med den här e-postadressen. Använd BankID istället.',
  },
  expiredLink: {
    id: 'loginMessages.expiredLink',
    defaultMessage: 'Länkens giltighetstid har gått ut. Försök igen.',
  },
  agreeToPrivacyPolicy: {
    id: 'loginMessages.agreeToPrivacyPolicy',
    defaultMessage:
      'Genom att fortsätta godkänner du <privacyPolicyHref>Nordea Nodes policy för behandling av personuppgifter</privacyPolicyHref>.',
  },
});
