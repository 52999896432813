/* eslint-disable no-restricted-imports */
import {
  IconButton,
  SideSheet as SideSheetUI,
  SideSheetBody,
  SideSheetContentContainer,
  SideSheetFooter,
  SideSheetHeader,
  SideSheetSection,
  SideSheetSubtitle,
} from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import { a11yMessages } from 'common/messages/a11y';
import { useIntl } from 'components/formats';
import React from 'react';
import { createPortal } from 'react-dom';
import { APP_ID } from 'utils/constants';

interface Props extends Pick<React.HTMLProps<HTMLElement>, 'id'> {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: React.ReactNode;
  footer?: React.ReactNode;
  onClosed?: () => void;
  zIndex?: number;
}

export const SideSheet: React.FC<Props> = ({
  title,
  onClosed,
  open,
  children,
  footer,
  setOpen,
  zIndex,
  id,
}) => {
  const { formatMessage } = useIntl();

  const appRoot = document.getElementById(APP_ID);

  if (!appRoot) {
    return null;
  }

  return createPortal(
    <SideSheetUI
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClosed}
      zIndex={zIndex}
      id={id}
    >
      <SideSheetHeader
        action={
          <IconButton
            icon={clear}
            size="small"
            color="primary"
            label={formatMessage(a11yMessages.close)}
            onClick={() => setOpen(false)}
          />
        }
        title={title}
      />
      <SideSheetBody>{children}</SideSheetBody>
      {!!footer && <SideSheetFooter>{footer}</SideSheetFooter>}
    </SideSheetUI>,
    appRoot,
  );
};

export { SideSheetContentContainer, SideSheetSection, SideSheetSubtitle };
