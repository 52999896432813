/**
 * Enum describing a user's digital advice access status
 * @readonly
 * @enum {string} - FULL_ACCESS - Benefit exists and digital advice is activated with signature
 * - NEEDS_SIGNATURE - Benefit exists but digital advice has not been activated
 * - NO_ACCESS - Benefit does not exist
 */

export enum PlusStatus {
  FULL_ACCESS = 'FULL_ACCESS',
  NEEDS_SIGNATURE = 'NEEDS_SIGNATURE',
  NO_ACCESS = 'NO_ACCESS',
}

interface Viewer {
  hasActivePlus: boolean | null;
  hasSignedPlus: boolean;
}

export const getAdvinansPlusStatus = (viewer: Viewer) => {
  const { hasActivePlus, hasSignedPlus } = viewer;

  if (hasActivePlus && hasSignedPlus) {
    return PlusStatus.FULL_ACCESS;
  }

  if (hasActivePlus) {
    return PlusStatus.NEEDS_SIGNATURE;
  }

  return PlusStatus.NO_ACCESS;
};
