// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select:focus::-ms-value {
  background: #f8f8f8; /* THIS LINE REMOVES THE BLUE BACKGROUND */
  color: #484848; /* the -ms-value element defaults to white text, so a different font colour is needed for text to be visible */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/ie11.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAE,0CAA0C;EAC/D,cAAc,EAAE,8GAA8G;AAChI","sourcesContent":["select:focus::-ms-value {\n  background: #f8f8f8; /* THIS LINE REMOVES THE BLUE BACKGROUND */\n  color: #484848; /* the -ms-value element defaults to white text, so a different font colour is needed for text to be visible */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
