import { useMutation } from '@apollo/client';
import { client } from 'app/apollo/client';
import {
  AddEmploymentActivityInput,
  registerAbsenceMutation,
  registerAbsenceMutationVariables,
} from 'app/apollo/graphql/types';
import { useCurrentUser } from 'contexts/current-user';

import { FormValues } from '..';
import { REGISTER_ABSENCE_MUTATION } from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
}

type Submit = (values: FormValues) => Promise<void>;

export const useSubmit = ({ onCompleted }: Params) => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const [registerAbsence, { error: submissionError }] = useMutation<
    registerAbsenceMutation,
    registerAbsenceMutationVariables
  >(REGISTER_ABSENCE_MUTATION);

  const submit: Submit = async (values: FormValues) => {
    try {
      // These values are guaranteed through form validation
      if (!currentCompany || !values.employment || !values.absenceType) {
        return;
      }

      const input: AddEmploymentActivityInput = {
        effectiveDate: values.effectiveDate,
        employmentId: values.employment.value.employmentId,
        type: values.absenceType,
      };

      await registerAbsence({
        variables: { input },
      });

      client.cache.evict({
        id: `Employment:${values.employment.value.employmentId}`,
        fieldName: 'activities',
      });
      client.cache.evict({
        id: `CompanyAnalytics:${currentCompany?.id}`,
        fieldName: 'numberOfEmployeesWithOffDutyAbsence',
      });
      client.cache.evict({
        id: `CompanyAnalytics:${currentCompany?.id}`,
        fieldName: 'numberOfEmployeesWithParentalLeaveAbsence',
      });
      client.cache.gc();
      onCompleted();
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
