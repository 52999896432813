import { Step } from 'features/feature-preview';

/**
 * Calculates the number of active steps for the onboarding tour journey.
 * As steps are only relevant for the appearing 'rich tooltips' 1 is
 * subtracted to account for the first modal step.
 *
 * @param steps the steps of the onboarding tour
 * @returns the number of steps which are marked with show: true
 */
export const getActiveStepCount = (steps: Step[]): number =>
  steps.filter(({ show }) => show).length - 1;
