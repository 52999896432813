import { EffectiveLocale } from 'containers/IntlProviderWrapper';
import contentfulClient from 'features/contentful/lib/contentful-client';
import ContentfulContext, {
  ContenfulEntry,
  ContentfulGetEntry,
  ContentfulStore,
} from 'features/contentful/lib/contentful-context';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

class ContentfulProvider extends React.Component<Props, ContentfulStore> {
  private getEntry: ContentfulGetEntry;

  constructor(props) {
    super(props);

    this.getEntry = async (entryId, locale) => {
      const emptyEntry = {
        data: undefined,
        error: false,
        loading: false,
        queryFired: false,
      };

      const { entries } = this.state;

      if (!entries[locale][entryId]) {
        this.updateEntry(
          entryId,
          locale,
          {
            ...emptyEntry,
          },
          this.fireQueries.bind(this, locale),
        );
      } else {
        this.fireQueries(locale);
      }
    };

    this.state = {
      entries: { 'sv-SE': {}, 'en-US': {} },
      getEntry: this.getEntry,
    };
  }

  private fireQueries = (locale: EffectiveLocale) => {
    const notFiredEntries = Object.values(this.state.entries[locale]).filter(
      a => !a.queryFired,
    );

    notFiredEntries.forEach(entry => this.fetchEntry(entry.id, locale));
  };

  private updateEntry = (
    entryId: string,
    locale: EffectiveLocale,
    update: Partial<ContenfulEntry>,
    callback?: () => void,
  ) => {
    this.setState(
      state => ({
        ...state,
        entries: {
          ...state.entries,
          [locale]: {
            ...state.entries[locale],
            ...{
              [entryId]: {
                ...(state.entries[locale][entryId] ?? { id: entryId }),
                ...update,
              },
            },
          },
        },
      }),
      callback,
    );
  };

  private fetchEntry = async (entryId: string, locale: EffectiveLocale) => {
    this.updateEntry(entryId, locale, {
      error: false,
      loading: true,
      queryFired: true,
    });

    try {
      const result = await contentfulClient.getEntry(entryId, { locale });

      this.updateEntry(entryId, locale, {
        loading: false,
        data: result,
      });
    } catch {
      this.updateEntry(entryId, locale, {
        loading: false,
        error: true,
      });
    }
  };

  public render() {
    return (
      <ContentfulContext.Provider value={this.state}>
        {this.props.children}
      </ContentfulContext.Provider>
    );
  }
}

export default ContentfulProvider;
