import gql from 'graphql-tag';

export const isNordeaOccupationalPensionDetails = gql`
  fragment isNordeaOccupationalPensionDetails on Insurance {
    id
    brokeredByAdvinans
    components {
      id
    }
    institute {
      id
    }
  }
`;
