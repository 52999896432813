import { useMutation } from '@apollo/client';
import { client } from 'app/apollo/client';
import {
  changeBenefitPackageMutation,
  changeBenefitPackageMutationVariables,
  UpdateMembershipBenefitPackageInput,
} from 'app/apollo/graphql/types';
import { useCurrentUser } from 'contexts/current-user';
import { refetchFcfwRequests } from 'features/assert-work-capability/utils/refetch-fcfw-requests';
import { MEMBERSHIP_BENEFIT_PACKAGE_QUERY } from 'features/employees/employee/benefit-package/graphql/queries';
import { benefitTypeNames } from 'features/employees/employee/notifications';
import { EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY } from 'features/employees/employee/notifications/graphql/queries';

import { FormValues } from '..';
import { CHANGE_BENEFIT_PACKAGE_MUTATION } from '../graphql/mutations';

interface Params {
  onCompleted: () => void;
}

type Submit = (values: FormValues) => Promise<void>;

export const useSubmit = ({ onCompleted }: Params) => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const [changeBenefitPackage, { error: submissionError }] = useMutation<
    changeBenefitPackageMutation,
    changeBenefitPackageMutationVariables
  >(CHANGE_BENEFIT_PACKAGE_MUTATION);

  const submit: Submit = async (values: FormValues) => {
    try {
      // UserAccount is guaranteed through form validation
      if (!currentCompany || !values.userAccount) {
        return;
      }

      const input: UpdateMembershipBenefitPackageInput = {
        benefitPackageId: values.benefitPackage,
        companyId: currentCompany.id,
        effectiveDate: values.effectiveDate,
        userAccountId: values.userAccount.value,
      };

      await changeBenefitPackage({
        refetchQueries: [
          {
            query: MEMBERSHIP_BENEFIT_PACKAGE_QUERY,
            variables: {
              userAccountId: values.userAccount.value,
              companyId: currentCompany?.id ?? '',
            },
          },
          {
            query: EMPLOYEE_OVERVIEW_NOTIFICATIONS_QUERY,
            variables: {
              userAccountId: values.userAccount.value,
              benefitTypeNames,
              companyId: currentCompany?.id ?? '',
            },
          },
        ],
        variables: { input },
      });

      client.cache.evict({
        id: `Company: ${currentCompany?.id}`,
        fieldName: 'memberships',
      });
      client.cache.gc();

      await refetchFcfwRequests(currentCompany.id);
      onCompleted();
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
