import { WizardContainer, WizardFooter, WizardGrid } from '@frontend/ui';
import { Modal } from 'components/Modal';
// eslint-disable-next-line no-restricted-imports
import { WizardHeader } from 'components/WizardHeader';
import React from 'react';

interface BasicProps {
  children: React.ReactNode;
  title: React.ReactNode;
  actions?: React.ReactNode;
  fullBleed?: boolean;
  headerActions?: React.ReactNode;
  offWhite?: boolean;
  progress?: number;
  withAnimation?: boolean;
  /**
   * Wraps the wizard modal content in a Formik form.
   * Note: Wrapping only the `children` in a Formik form is insufficient,
   * as it covers only the wizard's body, not the `actions`.
   * The `Form` element cannot wrap `SimpleWizard` directly because the wizard
   * is a modal and is rendered outside the form in the DOM.
   */
  withForm?: (children: React.ReactNode) => React.ReactNode;
}

interface PropsWithOnModalClose extends BasicProps {
  onModalClose: () => void;
  parentLink?: never;
}

interface PropsWithParentLink extends BasicProps {
  parentLink: string;
  onModalClose?: never;
}

type Props = PropsWithParentLink | PropsWithOnModalClose;

export const SimpleWizard: React.FC<Props> = ({
  children,
  title,
  parentLink,
  actions,
  progress,
  fullBleed,
  headerActions,
  offWhite,
  onModalClose,
  withAnimation,
  withForm,
}) => {
  const commonHeaderProps = {
    title,
    progress,
    actions: headerActions,
  };

  const headerProps = onModalClose
    ? { ...commonHeaderProps, onModalClose }
    : parentLink
      ? { ...commonHeaderProps, parentLink }
      : null;

  const render = withForm ?? ((_children: React.ReactNode) => _children);

  return (
    <Modal size="full-window" withAnimation={withAnimation}>
      {render(
        <>
          {!!headerProps && <WizardHeader {...headerProps} />}
          <WizardContainer offWhite={offWhite}>
            {fullBleed ? children : <WizardGrid>{children}</WizardGrid>}
          </WizardContainer>
          {actions && (
            <WizardFooter
              noOffsetLeft={fullBleed}
              noOffsetRight={fullBleed}
              actions={actions}
            />
          )}
        </>,
      )}
    </Modal>
  );
};
