import { A, defaultTheme } from '@frontend/ui';
import { viewerQuery } from 'app/apollo/graphql/types';
import EXTERNAL_URLS from 'app/lib/external-urls';
import { useFullViewHeight } from 'app/lib/use-full-view-height';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import { loginMessages } from 'common/messages/login';
import { VIEWER_QUERY } from 'common/queries';
import { FlexWrapper } from 'components/FlexWrapper';
import { FormattedMessage } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import { AuthMedia } from 'features/auth/common/AuthMedia';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { useQuery } from 'utils/use-query';

import { AuthContainer } from '../common/AuthContainer';
import { AuthFooter } from '../common/AuthFooter';
import { AuthFormWrapper } from '../common/AuthFormWrapper';
import { AuthLede } from '../common/AuthLede';
import { AuthTitle } from '../common/AuthTitle';
import { LanguageSelect } from '../common/LanguageSelect';
import { PrivacyPolicy } from './components';
import { LoginNotificationCard } from './components/LoginNotificationCard';
import { LoginProgressSection } from './components/LoginProgressSection';
import { EmailLoginFormSection } from './containers/EmailLoginFormSection';
import { LoginFormSection } from './containers/LoginFormSection';
import { useLogin } from './use-login';

interface OuterProps {
  onSignSuccess: () => void;
}

interface State {
  displayQrOnMobile: boolean;
  emailLogin: boolean;
  initLoginLoading: boolean;
}

type Props = RouteComponentProps & OuterProps;

export const Login: React.FC<Props> = ({
  location,
  history,
  onSignSuccess,
}) => {
  const { state } = location;
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.desktop * 16);
  const fullViewHeight = useFullViewHeight();

  const [{ emailLogin, displayQrOnMobile, initLoginLoading }, setState] =
    useState<State>({
      emailLogin: false,
      displayQrOnMobile: false,
      initLoginLoading: false,
    });

  const displayQrCode = isSmallScreen ? displayQrOnMobile : true;

  const {
    error: invalidTokenError,
    ticketId: initialTicketId,
    next,
  } = qs.parse(location.search);

  const { data, loading: viewerLoading } = useQuery<viewerQuery>(VIEWER_QUERY);
  const { setLocale } = useIntlContext();
  const {
    startLogin,
    cancelLogin,
    loading,
    signWithSameDevice,
    qrCode,
    error,
  } = useLogin({
    initialTicketId,
    onSignSuccess,
    history,
    location,
  });

  /* If a login session is cancelled, reset loading screen to default */
  useEffect(() => {
    if (!loading) {
      setState(_state => ({ ..._state, displayQrOnMobile: false }));
    }
  }, [loading]);

  // Should we have a search parameter redirecting to
  // logout redirect to /login without search params
  // as this next url will cause immediate logout
  if (next && (next === '/logout' || next === 'logout')) {
    return <Redirect to="/login" />;
  }

  if (!data || viewerLoading) {
    return null;
  }

  const { viewer } = data;

  if (viewer) {
    setLocale(viewer?.localePreference === 'en-US' ? 'en-US' : 'sv-SE');

    return next && next !== '/' ? (
      <Redirect to={next} />
    ) : (
      <Redirect to={{ pathname: '/', state }} />
    );
  }

  const isLinkExpired = invalidTokenError === 'invalid_token';

  return (
    <FlexWrapper>
      <AuthContainer fullViewHeight={fullViewHeight}>
        <LanguageSelect />
        <AuthFormWrapper>
          {loading && displayQrCode && !!qrCode ? (
            <LoginProgressSection
              cancelLogin={cancelLogin}
              qrCode={qrCode}
              signWithSameDevice={signWithSameDevice}
            />
          ) : (
            <>
              {emailLogin ? (
                <>
                  <AuthTitle>
                    <FormattedMessage {...loginMessages.emailLogin} />
                  </AuthTitle>
                  <AuthLede>
                    <FormattedMessage
                      {...loginMessages.emailLoginDescription}
                    />
                  </AuthLede>
                  <EmailLoginFormSection
                    isSmallScreen={isSmallScreen}
                    isLinkExpired={isLinkExpired}
                  />
                </>
              ) : (
                <>
                  <AuthTitle>
                    <FormattedMessage {...loginMessages.welcomeToNordeaNode} />
                  </AuthTitle>
                  <AuthLede>
                    <FormattedMessage {...loginMessages.loginIntroduction} />
                  </AuthLede>
                  <LoginNotificationCard
                    error={error}
                    isLinkExpired={isLinkExpired}
                  />
                  <LoginFormSection
                    startLogin={startLogin}
                    cancelLogin={cancelLogin}
                    loading={loading}
                    signWithSameDevice={
                      !displayQrCode ? signWithSameDevice : undefined
                    }
                  />
                </>
              )}
              <PrivacyPolicy>
                <FormattedMessage
                  {...loginMessages.agreeToPrivacyPolicy}
                  values={{
                    // eslint-disable-next-line
                    privacyPolicyHref: chunk => (
                      <A
                        href={EXTERNAL_URLS.nordeaNodePersonalData}
                        target="_blank"
                      >
                        {chunk}
                      </A>
                    ),
                  }}
                />
              </PrivacyPolicy>
              {emailLogin ? (
                <FullWidthButton
                  extended
                  text
                  onClick={() =>
                    setState(prev => ({ ...prev, emailLogin: false }))
                  }
                >
                  <FormattedMessage {...loginMessages.bankIdLogin} />
                </FullWidthButton>
              ) : (
                !loading && (
                  <FullWidthButton
                    extended
                    text
                    onClick={() =>
                      setState(prev => ({ ...prev, emailLogin: true }))
                    }
                    style={{ marginTop: '0.75rem' }}
                  >
                    <FormattedMessage {...loginMessages.emailLogin} />
                  </FullWidthButton>
                )
              )}
            </>
          )}
          {!displayQrCode && !emailLogin && (
            <FullWidthButton
              extended
              loading={initLoginLoading}
              style={{
                marginTop: '0.5rem',
              }}
              onClick={async () => {
                setState(_state => ({ ..._state, initLoginLoading: true }));
                await startLogin({ variables: { input: {} } });
                setState(_state => ({
                  ..._state,
                  initLoginLoading: false,
                  displayQrOnMobile: true,
                }));
              }}
              text
            >
              <FormattedMessage {...loginMessages.signWithOtherDevice} />
            </FullWidthButton>
          )}
        </AuthFormWrapper>
        <AuthFooter />
      </AuthContainer>
      {!isSmallScreen && <AuthMedia />}
    </FlexWrapper>
  );
};
