import gql from 'graphql-tag';

export const VIEWER_ECONOMIC_PROFILE_QUERY = gql`
  query viewerEconomicProfileQuery {
    viewer {
      id
      economicProfile {
        assets
        liabilities
        monthlyIncome
        monthlyExpenses
      }
    }
  }
`;
