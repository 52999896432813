import { isNordeaOccupationalPensionDetails } from 'common/fragments/insurances';
import gql from 'graphql-tag';

export const pensionInsurance = gql`
  fragment pensionInsurance on InsuranceComponent {
    id
    name
    taxCategory
    typeCode
    detailedTypeCode
    insuranceNumber
    insurance {
      id
      ...isNordeaOccupationalPensionDetails
      name
      asOf
      policyholder
      policyholderName
      transferable
      pensionTransferStatus
      collectivelyAgreedPensionPlan
      collectivelyAgreedPensionPlanPart
      effectiveDate
      nextPremiumPayment {
        value
        currency
      }
      institute {
        name
        id
      }
      plusSuggestion {
        id
        status
        instructions {
          action {
            __typename
          }
        }
      }
    }
    ... on InsuranceComponentRetirement {
      capital {
        id
        managementType
        capitalReport {
          value {
            value
            currency
          }
          returnsEarned {
            value
            currency
          }
        }
      }
    }
  }
  ${isNordeaOccupationalPensionDetails}
`;
