import { getIsFeaturePreviewSnoozed } from 'app/browser-store';
import { FeatureType, useCurrentUser } from 'contexts/current-user';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import styled, { css } from 'styled-components';

import { reducer } from './reducer';
import { getAdminShortcutsTourSteps } from './utils/get-admin-shortcuts-tour-steps';
import { getOnboardingTourSteps } from './utils/get-onboarding-tour-steps';
import { getSettingsTourSteps } from './utils/get-settings-tour-steps';
import { isFinished } from './utils/is-finished';

const disableScrollCss = css`
  position: fixed;
  inset: 0;
  user-select: none;
  pointer-events: none;
`;

interface DisableScrollOverlayProps {
  disableScroll: boolean;
}

const ScrollDisablingOverlay = styled.div<DisableScrollOverlayProps>`
  ${p => p.disableScroll && disableScrollCss};
`;

export interface Step {
  node: React.FC;
  show?: boolean;
}

interface FeaturePreviewContext {
  complete: () => void;
  dismiss: () => void;
  init: (feature: FeatureType) => void;
  next: () => void;
  snooze: () => void;
  steps: Step[];
  activeIndex?: number;
}

const FeaturePreviewContext = React.createContext<FeaturePreviewContext>({
  init: () => undefined,
  next: () => undefined,
  complete: () => undefined,
  dismiss: () => undefined,
  snooze: () => undefined,
  activeIndex: undefined,
  steps: [],
});

interface Props {
  children: React.ReactNode;
}

export const FeaturePreviewProvider: React.FC<Props> = ({ children }) => {
  const currentUser = useCurrentUser();
  const { setAppData } = currentUser;

  const [{ activeFeature, activeIndex, features }, dispatch] = useReducer(
    reducer,
    {
      setAppData,
      features: {
        ONBOARDING_TOUR: getOnboardingTourSteps(currentUser),
        SETTINGS_TOUR: getSettingsTourSteps(),
        ADMIN_SHORTCUTS_TOUR: getAdminShortcutsTourSteps(),
      },
    },
  );

  const init = (feature: FeatureType) => dispatch({ type: 'INIT', feature });
  const next = () => {
    dispatch({
      type: 'STEP',
    });
  };

  const complete = () => {
    dispatch({
      type: 'COMPLETE',
    });
  };

  const dismiss = () => {
    dispatch({
      type: 'DISMISS',
    });
  };

  const snooze = () => {
    dispatch({
      type: 'SNOOZE',
    });
  };

  const value: FeaturePreviewContext = useMemo(
    () => ({
      init,
      next,
      complete,
      dismiss,
      activeIndex,
      steps: activeFeature ? features[activeFeature] : [],
      snooze,
    }),
    [activeFeature, activeIndex],
  );

  const activeFeatureSteps = activeFeature
    ? features[activeFeature].filter(step => step.show)
    : null;
  const ActiveNode =
    activeIndex != null && activeFeatureSteps
      ? activeFeatureSteps[activeIndex]?.node
      : null;

  return (
    <FeaturePreviewContext.Provider value={value}>
      <>
        {ActiveNode && <ActiveNode />}
        <ScrollDisablingOverlay disableScroll={!!ActiveNode}>
          {children}
        </ScrollDisablingOverlay>
      </>
    </FeaturePreviewContext.Provider>
  );
};

export const useFeaturePreviewContext = (): FeaturePreviewContext =>
  useContext(FeaturePreviewContext);
interface FeaturePreviewArgs {
  feature: FeatureType;
  prerequisite?: FeatureType;
}

/**
 * Returns the feature preview context and invokes the
 * init callback of the feature parameter if passed
 */
export const useFeaturePreview = (
  args?: FeaturePreviewArgs,
): FeaturePreviewContext => {
  const { feature, prerequisite } = args ?? {};

  const featureContext = useFeaturePreviewContext();
  const { init } = featureContext;

  const {
    currentUser: { appData },
  } = useCurrentUser();

  const prerequisiteStatus =
    prerequisite && appData ? appData[prerequisite] : undefined;

  const initializeFeature = async () => {
    if (!feature) {
      return;
    }

    const isSnoozed = await getIsFeaturePreviewSnoozed(feature);

    if (prerequisite && !isFinished(prerequisiteStatus)) {
      return;
    }

    const status = appData ? appData[feature] : undefined;

    if (!isSnoozed && !isFinished(status)) {
      init(feature);
    }
  };

  useEffect(() => {
    initializeFeature();
  }, []);

  return featureContext;
};
