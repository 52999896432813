import { DatePickerField, useFormikContext } from '@frontend/formik';
import { SearchableSelectOption } from '@frontend/ui';
import { commonMessages, validationMessages } from 'common/messages';
import { FormattedMessage, IntlShape } from 'components/formats';
import { isAfter } from 'date-fns';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React from 'react';
import * as Yup from 'yup';

import {
  Employment,
  NO_EMPLOYMENT,
  SelectEmployeeEmploymentField,
} from '../../../select-employee-employment';
import { FormValues } from '../..';

export interface EnterDetailsFormValues {
  effectiveDate: string;
  employment: SearchableSelectOption<Employment> | null;
}

export const enterDetailsValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveDate: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'is after start date',
        test: (value, context) =>
          isAfter(
            new Date(value),
            new Date(context.parent.employment.value.effectiveDate),
          )
            ? true
            : context.createError({
                message: intl.formatMessage(
                  validationMessages.isAfterStartDate,
                  {
                    startDate: context.parent.employment.value.effectiveDate,
                  },
                ),
              }),
      }),
    employment: Yup.object()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'has employment',
        message: intl.formatMessage(validationMessages.noEmployments),
        test: (option: SearchableSelectOption<Employment>) =>
          option.value.employmentId !== NO_EMPLOYMENT,
      }),
  });

export const enterDetailsInitialValues: EnterDetailsFormValues = {
  effectiveDate: '',
  employment: null,
};

export const EnterDetailsHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.terminateEmployment} />
);

export const EnterDetailsBody: React.FC = () => {
  const { values } = useFormikContext<FormValues>();

  const startDate = values.employment?.value.effectiveDate;

  return (
    <>
      <SelectEmployeeEmploymentField inModal name="employment" required />
      <DatePickerField
        fixed
        dense
        min={startDate || undefined}
        name="effectiveDate"
        label={<FormattedMessage {...commonMessages.effectiveDate} />}
        required
      />
    </>
  );
};
