import gql from 'graphql-tag';

export const SELECT_EMPLOYEE_EMPLOYMENT_QUERY = gql`
  query selectEmployeeEmploymentQuery($companyId: ID!, $search: String!) {
    company(id: $companyId) {
      id
      memberships(search: $search) {
        edges {
          node {
            id
            employments {
              edges {
                node {
                  id
                  period
                }
              }
            }
            givenName
            lastName
            naturalPersonIdentifiers
          }
        }
      }
    }
  }
`;
