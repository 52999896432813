import { defineMessages } from 'react-intl';

export const internalNewsMessages = defineMessages({
  all: {
    id: 'internalNewsMessages.all',
    defaultMessage: 'Alla',
  },
  createNews: {
    id: 'internalNewsMessages.createNews',
    description: 'Skapa artikel',
    defaultMessage: 'Skapa artikel',
  },
  editNews: {
    id: 'internalNewsMessages.editNews',
    description: 'Edit article',
    defaultMessage: 'Redigera artikel',
  },
  deleteNews: {
    id: 'internalNewsMessages.deleteNews',
    description: 'Delete news',
    defaultMessage: 'Ta bort artikel',
  },
  noInternalNews: {
    id: 'internalNewsMessages.noInternalNews',
    description: 'No internal posts to view',
    defaultMessage: 'Det finns inga artiklar att visa',
  },
  noInternalNewsText: {
    id: 'internalNewsMessages.noInternalNewsText',
    description: 'Du har inga artiklar',
    defaultMessage: 'När du har skapat artiklar kommer de att visas här.',
  },
  gotoNewsLink: {
    id: 'internalNewsMessages.gotoNewsLink',
    description: 'Gå till artiklar',
    defaultMessage: 'Gå till artiklar',
  },
  news: {
    id: 'internalNewsMessages.news',
    description: 'Artiklar',
    defaultMessage: 'Artiklar',
  },
  allTheNews: {
    id: 'internalNewsMessages.allTheNews',
    description: 'Alla artiklar',
    defaultMessage: 'Alla artiklar',
  },
  important: {
    id: 'internalNewsMessages.important',
    description: 'Important',
    defaultMessage: 'Viktigt',
  },
  importantNews: {
    id: 'internalNewsMessages.importantNews',
    description: 'Viktiga artiklar',
    defaultMessage: 'Viktiga artiklar',
  },
  latestNews: {
    id: 'internalNewsMessages.latestNews',
    description: 'Senaste artiklar',
    defaultMessage: 'Senaste artiklar',
  },
  searchArticles: {
    id: 'internalNewsMessages.searchArticles',
    description: 'Search articles',
    defaultMessage: 'Sök artiklar',
  },
  noArticlesMatch: {
    id: 'internalNewsMessages.noArticlesMatch',
    description: 'No articles matching search',
    defaultMessage: 'Inga artiklar matchar sökningen',
  },
});

export const internalNewsFormMessages = defineMessages({
  title: {
    id: 'internalNewsFormMessages.title',
    description: 'Artikelrubrik',
    defaultMessage: 'Rubrik',
  },
  description: {
    id: 'internalNewsFormMessages.description',
    description: 'Texting / Kort beskrivning',
    defaultMessage: 'Ingress',
  },
  body: {
    id: 'internalNewsFormMessages.body',
    description: 'Skriv erbjudande detaljer här ',
    defaultMessage: 'Brödtext',
  },
  detail: {
    id: 'internalNewsFormMessages.detail',
    description: 'Detaljer',
    defaultMessage: 'Detaljer',
  },
  selectFiles: {
    id: 'internalNewsFormMessages.selectFiles',
    defaultMessage: 'Välj filer',
  },
  attachDocuments: {
    id: 'internalNewsFormMessages.attachDocuments',
    description: 'Bifoga dokument',
    defaultMessage: 'Bifoga filer',
  },
  markAsImportant: {
    id: 'internalNewsFormMessages.markAsImportant',
    description: 'Markera som viktigt',
    defaultMessage: 'Markera som “Viktigt”',
  },
  tags: {
    id: 'internalNewsFormMessages.tags',
    description: 'Tags',
    defaultMessage: 'Kategorier',
  },
  publicationStatus: {
    id: 'internalNewsFormMessages.publicationStatus',
    description: 'Publikation status',
    defaultMessage: 'Synlighet',
  },
  published: {
    id: 'internalNewsFormMessages.published',
    description: 'Publicerat',
    defaultMessage: 'Publicerad',
  },
  private: {
    id: 'internalNewsFormMessages.private',
    description: 'Privat',
    defaultMessage: 'Ej publicerad',
  },
  benefitPackagesDescription: {
    id: 'internalNewsFormMessages.benefitPackagesDescription',
    description: 'Description benefit packages in news form',
    defaultMessage:
      'Ska artikeln endast vara tillgängligt för vissa förmånsgrupper väljer du dessa här, annars är de tillgängliga för alla.',
  },
  organizationalUnitsDescription: {
    id: 'internalNewsFormMessages.organizationalUnitsDescription',
    description: 'Description organizational units in news form',
    defaultMessage:
      'Ska artikeln endast vara tillgängligt för vissa avdelningar väljer du dessa här, annars är de tillgängliga för alla.',
  },
  removedOrgUnitPlaceholder: {
    id: 'internalNewsFormMessages.removedOrgUnitPlaceholder',
    description:
      'Placeholder name for organizational units that no longer exist',
    defaultMessage: 'Borttagen avdelning',
  },
  publicationDate: {
    id: 'internalNewsFormMessages.publicationDate',
    description: 'Publication date',
    defaultMessage: 'Publiceringsdatum',
  },
  publicationDateHelperText: {
    id: 'internalNewsFormMessages.publicationDateHelperText',
    description: 'Articles are sorted by the publication date',
    defaultMessage: 'Artiklar sorteras fallande efter publiceringsdatum',
  },
  addImage: {
    id: 'internalNewsFormMessages.addImage',
    defaultMessage: 'Välj bild',
  },
  editImage: {
    id: 'internalNewsFormMessages.editImage',
    defaultMessage: 'Ändra bild',
  },
});
