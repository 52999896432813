import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers, TextField } from '@frontend/formik';
import {
  loginMutation,
  loginMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'common/messages';
import { loginMessages } from 'common/messages/login';
import { LOGIN_MUTATION } from 'common/mutations';
import { VIEWER_QUERY } from 'common/queries';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import { NotificationCard } from 'components/NotificationCard';
import { initGA } from 'contexts/google/analytics';
import { allowed, THIRD_PARTY_COOKIES } from 'features/cookie-banner';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';

interface FormValues {
  email: string;
  submissionError?: string;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .email()
      .required(intl.formatMessage(validationMessages.mandatoryField)),
  });

interface Props {
  isLinkExpired: boolean;
  isSmallScreen: boolean;
}

export const EmailLoginFormSection: React.FC<Props> = ({
  isSmallScreen,
  isLinkExpired,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [cookies] = useCookies([THIRD_PARTY_COOKIES]);
  const [login] = useMutation<loginMutation, loginMutationVariables>(
    LOGIN_MUTATION,
    {
      refetchQueries: [
        {
          query: VIEWER_QUERY,
        },
      ],
    },
  );

  const [completed, setCompleted] = useState(false);

  const submit = async (
    formValues: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      await login({ variables: { input: { email: formValues.email } } });
      initGA(allowed(cookies.adv_third_party_cookies));
      setCompleted(true);
    } catch (error) {
      helpers.setErrors({ submissionError: error?.message });
    }
  };

  return (
    <div style={{ marginBottom: '1rem' }}>
      <Formik<FormValues>
        validationSchema={validationSchema(intl)}
        initialValues={{ email: '' }}
        isInitialValid={false}
        onSubmit={submit}
      >
        {({ errors, isValid, isSubmitting }) => {
          const notification = completed ? (
            <NotificationCard type="success">
              <FormattedMessage {...loginMessages.checkYourEmail} />
            </NotificationCard>
          ) : errors?.submissionError ? (
            <NotificationCard type="error">
              {errors.submissionError}
            </NotificationCard>
          ) : isLinkExpired ? (
            <NotificationCard type="warning">
              <FormattedMessage {...loginMessages.expiredLink} />
            </NotificationCard>
          ) : null;

          return (
            <Form>
              {notification}
              <TextField
                name="email"
                type="email"
                label={formatMessage(commonMessages.email)}
                dense={isSmallScreen}
                required
              />

              <FullWidthButton
                style={{ marginTop: '0.5rem' }}
                type="submit"
                filled
                disabled={!isValid || completed || isSubmitting}
                extended
              >
                <FormattedMessage {...loginMessages.sendLink} />
              </FullWidthButton>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
