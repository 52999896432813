import {
  totalCompensationCardQuery_Query,
  totalCompensationDetails,
} from 'app/apollo/graphql/types';
import {
  aggregateTotalCompChartData,
  GraphDataItem,
} from 'features/total-compensation/utils/aggregate-total-comp-chart-data';

interface TotalCompensationCardData {
  graphData: GraphDataItem[];
  totalCompensation: readonly totalCompensationDetails[];
}

/**
 * Retrieves the relevant total compensation data from the query response
 * or returns null if the user does not have access to total compensation.
 */
export const getTotalCompensationCardData = (
  data?: totalCompensationCardQuery_Query,
): TotalCompensationCardData | null => {
  if (!data?.membership) {
    return null;
  }

  const { totalCompensation, monthlySalary } = data.membership;

  if (!monthlySalary) {
    return null;
  }

  const { graphData } = aggregateTotalCompChartData(totalCompensation);

  if (!graphData.length) {
    return null;
  }

  return { graphData, totalCompensation };
};
