import { useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import addMeeting from 'assets/illustrations/addMeeting.svg';
import { commonMessages } from 'common/messages';
import { employmentStatusMessages } from 'common/messages/status';
import { FormattedMessage } from 'components/formats';
import { afterFadeout, Modal, ModalBody, ModalHeader } from 'components/Modal';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { FormValues } from '..';

export const RegisterAbsenceSuccessModal = () => {
  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search);

  const isOpen = 'register-absence-success' in search;

  const { values, resetForm } = useFormikContext<FormValues>();

  const handleClose = () => {
    stripSearchParams(history, location, ['register-absence-success']);
    afterFadeout(resetForm);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} size="medium">
      <ModalHeader illustration={addMeeting}>
        <FormattedMessage {...adminShortcutMessages.registerAbsenceSuccess} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          {...adminShortcutMessages.registerAbsenceSuccessDescription}
          values={{
            absenceType: (
              <FormattedMessage
                messages={employmentStatusMessages}
                select={values.absenceType}
              />
            ),
            employee: values.employment?.label,
            effectiveDate: new Date(values.effectiveDate),
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button onClick={handleClose} text>
            <FormattedMessage {...commonMessages.close} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

export const getRegisterAbsenceSuccessLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'register-absence-success',
});
