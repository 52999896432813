import { formSubmitMessages } from 'common/messages';
import { IntlShape } from 'components/formats';
import { GraphQLError } from 'graphql';

/**
 * Retrieves a human readable error message to be used in form submissions
 *
 * @param intl
 * @param errors
 */
export const formatMutationErrors = (
  intl: IntlShape,
  errors?: readonly GraphQLError[],
): Error => {
  if (errors) {
    return new Error(errors.reduce((acc, e) => `${acc + e.message}\n`, ''));
  }

  return new Error(intl.formatMessage(formSubmitMessages.frontendSubmitError));
};
