import gql from 'graphql-tag';

const salaryExchange = gql`
  fragment salaryExchange on FlexSalaryExchange {
    id
    status
  }
`;

const salaryExchangeRequests = gql`
  fragment salaryExchangeRequests on FlexSalaryExchangeRequestConnection {
    edges {
      node {
        id
        status
      }
    }
  }
`;

const salaryExchangeEntitlement = gql`
  fragment salaryExchangeEntitlement on FlexEntitlementConnection {
    edges {
      node {
        userAccountId
        companyId
        ... on FlexSalaryExchangeEntitlement {
          applicationDateRange
          eligibleForOngoingExchange
          eligibleForOnetimeExchange
          maxOngoingDeduction
          maxOnetimeDeduction
        }
      }
    }
  }
`;

const pensionTransferMeeting = gql`
  fragment pensionTransferMeeting on UserAccount {
    id
  }
`;

export const DISCOVER_SECTION_QUERY = gql`
  query discoverSectionQuery(
    $membershipCompanyId: ID!
    $userAccountId: ID!
    $includeSalaryExchange: Boolean!
    $salaryExchangeBenefitType: [String!]
    $includePensionTransferMeeting: Boolean!
    $includeAdvinansPlus: Boolean!
    $includeVoluntaryGroupBenefit: Boolean!
    $voluntaryGroupBenefitType: [String!]
    $entitlementsCompanyId: ID
  ) {
    viewer {
      id
      hasSignedPlus
      hasActivePlus
    }
    salaryExchangeEntitlement: entitlements(
      userAccountId: $userAccountId
      companyId: $entitlementsCompanyId
      benefitTypeNames: $salaryExchangeBenefitType
      showAll: true
    ) @include(if: $includeSalaryExchange) {
      ...salaryExchangeEntitlement
    }
    salaryExchange(
      userAccountId: $userAccountId
      companyId: $membershipCompanyId
    ) @include(if: $includeSalaryExchange) {
      ...salaryExchange
    }
    salaryExchangeRequests(
      userAccountId: $userAccountId
      companyId: $membershipCompanyId
    ) @include(if: $includeSalaryExchange) {
      ...salaryExchangeRequests
    }
    bonusSalaryExchangeRequests(
      userAccountId: $userAccountId
      companyId: $membershipCompanyId
    ) @include(if: $includeSalaryExchange) {
      ...salaryExchangeRequests
    }
    viewerPensionTransferMeeting: viewer
      @include(if: $includePensionTransferMeeting) {
      ...pensionTransferMeeting
    }
    insuranceAnalysis @include(if: $includeAdvinansPlus) {
      id
    }
    plusAdviceHistory(userAccountId: $userAccountId, first: 1)
      @include(if: $includeAdvinansPlus) {
      edges {
        node {
          id
        }
      }
    }
    voluntaryGroupEntitlement: entitlements(
      companyId: $entitlementsCompanyId
      userAccountId: $userAccountId
      benefitTypeNames: $voluntaryGroupBenefitType
    ) @include(if: $includeVoluntaryGroupBenefit) {
      edges {
        node {
          benefit {
            id
            content {
              locale
            }
          }
        }
      }
    }
  }
  ${salaryExchange}
  ${salaryExchangeRequests}
  ${salaryExchangeEntitlement}
  ${pensionTransferMeeting}
`;
