import { Button, ButtonLayout, RichTooltip } from '@frontend/ui';
import { featurePreviewMessages } from 'common/messages/feature-preview';
import { FormattedMessage } from 'components/formats';
import { useFeaturePreview } from 'features/feature-preview';
import { FEATURE_PREVIEW_SETTINGS_PARENT_ID } from 'features/layout/app-bar/user-menu';
import React, { useEffect, useState } from 'react';

export const Step1 = () => {
  const { complete } = useFeaturePreview();
  const [parent, setParent] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const _parent = document.getElementById(
        FEATURE_PREVIEW_SETTINGS_PARENT_ID,
      );

      if (_parent && !parent) {
        setParent(_parent);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => observer.disconnect();
  }, []);

  if (!parent) {
    return null;
  }

  return (
    <RichTooltip
      position="bottomLeft"
      title={
        <FormattedMessage {...featurePreviewMessages.settingsTourHeader} />
      }
      description={
        <FormattedMessage {...featurePreviewMessages.settingsTourBody} />
      }
      parentId={FEATURE_PREVIEW_SETTINGS_PARENT_ID}
      actions={
        <ButtonLayout align="right">
          <Button text onClick={complete}>
            <FormattedMessage {...featurePreviewMessages.settingsTourAction} />
          </Button>
        </ButtonLayout>
      }
    />
  );
};
