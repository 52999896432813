import gql from 'graphql-tag';

export const PERSONAL_ADVICE_DASHBOARD_CARD_QUERY = gql`
  query personalAdviceDashboardCardQuery(
    $userAccountId: ID!
    $companyId: ID!
    $benefitTypeNames: [String!]
  ) {
    entitlements(
      userAccountId: $userAccountId
      companyId: $companyId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          ... on FlexPersonalAdviceEntitlement {
            allowedToHaveMeeting
            benefit {
              id
              type
              content {
                locale
              }
              configuration {
                data
              }
            }
            bookedMeeting {
              time
              link
            }
            earliestNextMeetingDate
            latestMeetingDate
          }
        }
      }
    }
  }
`;
