export const getTopDomain = () => {
  const testCookie = 'weird_get_top_level_domain=cookie';
  const hostname = document.location.hostname.split('.');
  for (let i = hostname.length - 1; i >= 0; i -= 1) {
    const h = hostname.slice(i).join('.');
    document.cookie = `${testCookie};domain=.${h};`;
    if (document.cookie.indexOf(testCookie) > -1) {
      document.cookie = `${
        testCookie.split('=')[0]
      }=;domain=.${h};expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      return h;
    }
  }
  return undefined;
};
