import { Form, Formik, TextField } from '@frontend/formik';
import { A, Button, WizardContentCard } from '@frontend/ui';
import {
  AuthenticationMethod,
  upgradeFriOverlayQuery,
  upgradeFriOverlayQueryVariables,
} from 'app/apollo/graphql/types';
import EXTERNAL_URLS from 'app/lib/external-urls';
import { EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { commonMessages, validationMessages } from 'common/messages';
import { upgradeFriMessages } from 'common/messages/upgrade-fri';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import { useNavigationStackUtils } from 'containers/NavigationDrawer/lib/use-navigation-stack-utils';
import { useCurrentUser } from 'contexts/current-user';
import { useSubmit } from 'features/upgrade-fri/submit';
import qs from 'query-string';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'utils/use-query';
import { isEmail } from 'validations';
import * as Yup from 'yup';

import { UPGRADE_FRI_OVERLAY_QUERY } from './graphql/queries';
import { UpgradeSessionModal } from './UpgradeSessionModal';

const FriHref = chunk => (
  <A href={EXTERNAL_URLS.advinansFriAgreementLink} target="_blank">
    {chunk}
  </A>
);

const PrivacyPolicyHref = chunk => (
  <A href={EXTERNAL_URLS.nordeaNodePersonalData} target="_blank">
    {chunk}
  </A>
);

export interface FormValues {
  email: string;
  submissionError?: string;
}

const upgradeFriValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      ),
  });

export const UpgradeFriOverlay: React.FC<RouteComponentProps> = props => {
  const intl = useIntl();
  const { history, location } = props;
  const { push } = history;
  const { peek } = useNavigationStackUtils();
  const {
    currentUser: { userAccountId, isFriSignedWithEmail, currentCompany, email },
  } = useCurrentUser();

  const { data, loading, error, refetch } = useQuery<
    upgradeFriOverlayQuery,
    upgradeFriOverlayQueryVariables
  >(UPGRADE_FRI_OVERLAY_QUERY, {
    skip: !userAccountId || !currentCompany?.id,
    variables: {
      userAccountId: userAccountId ?? '',
      companyId: currentCompany?.id ?? '',
    },
    errorPolicy: 'all',
  });

  const { next } = qs.parse(location.search);

  const { submit, submissionError } = useSubmit();

  if (isFriSignedWithEmail) {
    return <Redirect to={{ state: location.state, pathname: next ?? '/' }} />;
  }

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.session) {
    return (
      <EmptyStatePage error={error} parentLink={EMPLOYEE_ROOT_PATHS.index} />
    );
  }

  const isEmailLogin =
    data.session.authenticationMethod === AuthenticationMethod.EMAIL;

  const existingEmail = email || data.membership?.email;

  const parentLink = '/';

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  return isEmailLogin ? (
    <UpgradeSessionModal onSignSuccess={refetch} {...props} />
  ) : (
    <Formik<FormValues>
      initialValues={{
        email: existingEmail ?? '',
      }}
      validateOnMount
      validationSchema={upgradeFriValidationSchema(intl)}
      onSubmit={submit}
    >
      {({ isValid, isSubmitting }) => (
        <SimpleWizard
          offWhite
          title={<FormattedMessage {...upgradeFriMessages.wizardHeader} />}
          parentLink={parentLink}
          actions={
            <>
              <Button
                text
                onClick={() => {
                  const path = peek();
                  push(path ?? parentLink);
                }}
              >
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button
                type="submit"
                filled
                loading={isSubmitting}
                disabled={!isValid}
              >
                <FormattedMessage {...commonMessages.continue} />
              </Button>
            </>
          }
          withForm={withForm}
        >
          <WizardContentCard
            header={<FormattedMessage {...upgradeFriMessages.contentHeader} />}
            description={
              <FormattedMessage {...upgradeFriMessages.contentLede} />
            }
          >
            {(error || submissionError) && (
              <GraphQlError error={error ?? submissionError} />
            )}
            <TextField
              type="email"
              name="email"
              autoComplete="email"
              label={<FormattedMessage {...commonMessages.email} />}
              helperText={
                <FormattedMessage
                  {...upgradeFriMessages.emailFieldHelperText}
                />
              }
              dense
              required
            />
            <FormattedMessage
              {...upgradeFriMessages.acceptFriTerm}
              values={{
                friHref: FriHref,
                privacyPolicyHref: PrivacyPolicyHref,
              }}
            />
          </WizardContentCard>
        </SimpleWizard>
      )}
    </Formik>
  );
};
