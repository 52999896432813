import { getUserMenuItems } from 'containers/NavigationDrawer/lib/get-user-menu-items';
import { CurrentUser } from 'contexts/current-user';

import { Step } from '..';
import { Step1 } from '../features/onboarding-tour/step1';
import { Step2 } from '../features/onboarding-tour/step2';
import { Step3 } from '../features/onboarding-tour/step3';
import { Step4 } from '../features/onboarding-tour/step4';
import { Step5 } from '../features/onboarding-tour/step5';
import { Step6 } from '../features/onboarding-tour/step6';
import { Step7 } from '../features/onboarding-tour/step7';
import { Step8 } from '../features/onboarding-tour/step8';

export const getOnboardingTourSteps = (currentUser: CurrentUser): Step[] => {
  const menuItems = getUserMenuItems(currentUser);

  return [
    { node: Step1, show: true },
    { node: Step2, show: menuItems.home.show || menuItems.companyHome.show },
    {
      node: Step3,
      show: menuItems.companyBenefits.show,
    },
    { node: Step4, show: menuItems.pension.show },
    { node: Step5, show: menuItems.insurances.show },
    { node: Step6, show: menuItems.totalCompensation.show },
    { node: Step7, show: menuItems.internalNews.show },
    {
      node: Step8,
      show: true,
    },
  ];
};
