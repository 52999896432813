import { Link } from 'components/Links/Link';
import styled from 'styled-components';

export const linkCss = `
color: inherit;
font-weight: inherit;

&:hover {
  text-decoration: inherit;
}
`;

const DefaultLink = styled(Link)`
  ${linkCss}
`;

export default DefaultLink;
