import { IconContent, Tab, TabBar } from '@frontend/ui';
import { ThemeColor } from '@frontend/ui/theme';
import { FormattedMessage } from 'components/formats';
import React, { useEffect, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

export interface RoutedTab {
  title: MessageDescriptor;
  url: string;
  color?: ThemeColor;
  // XXX: investigate if this flag is still really needed.
  // Viktor thinks maybe not... and it seems to be causing
  // bugs in conjuction with the effect hook in this component.
  includesSubRoutes?: boolean;
  trailingIcon?: IconContent;
}

function getActiveIndex(pathname: string, tabs: RoutedTab[]): number {
  return tabs.findIndex(tab =>
    tab.includesSubRoutes
      ? pathname.indexOf(tab.url) > -1
      : tab.url === pathname,
  );
}

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -1px;
`;

interface Props {
  tabs: RoutedTab[];
}

export const RoutedTabBar: React.FC<Props> = ({ tabs }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [activeIndex, setIndex] = useState(getActiveIndex(pathname, tabs));

  useEffect(() => {
    const _activeIndex = getActiveIndex(pathname, tabs);
    if (_activeIndex > -1) {
      setIndex(_activeIndex);
    }
  }, [pathname]);

  if (!tabs.length) {
    return null;
  }

  const activate = (i: number) => {
    if (i !== activeIndex) {
      push(tabs[i].url);
    }
  };

  return (
    <Wrapper>
      <TabBar activeIndex={activeIndex}>
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            color={tab.color}
            onClick={() => activate(i)}
            trailingIcon={tab.trailingIcon}
          >
            <FormattedMessage {...tab.title} />
          </Tab>
        ))}
      </TabBar>
    </Wrapper>
  );
};
