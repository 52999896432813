export interface EconomicProfile {
  assets?: number | null;
  liabilities?: number | null;
  monthlyExpenses?: number | null;
  monthlyIncome?: number | null;
}

export const getEconomicProfileRatios = (profile: EconomicProfile) => {
  let debtRatio: number | null = null;
  let spendingRatio: number | null = null;
  const { monthlyExpenses, monthlyIncome, liabilities } = profile;
  if (monthlyExpenses && monthlyIncome) {
    spendingRatio = monthlyExpenses / monthlyIncome;
  }
  if (liabilities && monthlyIncome) {
    debtRatio = liabilities / (monthlyIncome * 12);
  }

  return { debtRatio, spendingRatio };
};
