import {
  currentUserContextQuery_viewer_UserAccount_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Membership,
  MembershipRole,
} from 'app/apollo/graphql/types';

import { CurrentUserCompany } from '../';

export const getCompanyInfo = (params: {
  accessibleCompanies: Membership[];
  companyId: string;
}): CurrentUserCompany | undefined => {
  const { accessibleCompanies, companyId } = params;
  const selectedMembership = accessibleCompanies.find(
    e => e.company.id === companyId,
  );

  if (!selectedMembership) {
    return undefined;
  }

  const {
    birthdate: membershipBirthdate,
    company,
    givenName: membershipGivenName,
    lastName: membershipLastName,
    roles,
    membershipBenefitPackage,
  } = selectedMembership;

  const isSuperAdmin = roles.includes(MembershipRole.SUPER);
  const isHRAdmin = isSuperAdmin || roles.includes(MembershipRole.HR);
  const isContentAdmin = isSuperAdmin || roles.includes(MembershipRole.CONTENT);
  const isFinanceAdmin = isSuperAdmin || roles.includes(MembershipRole.FINANCE);

  const hasTotalCompensation =
    !!membershipBenefitPackage?.showTotalCompensation;

  return {
    id: company.id,
    displayName: company.displayName,
    registrationNumber: company.registrationNumber,
    logoUrl: company.logoUrl || undefined,
    isSuperAdmin,
    isHRAdmin,
    isContentAdmin,
    isFinanceAdmin,
    hasJobOffers: company.hasJobOffers,
    isAdmin: isSuperAdmin || isHRAdmin || isContentAdmin || isFinanceAdmin,
    hasTotalCompensation,
    managedBy: company.managedBy,
    membershipBirthdate,
    membershipGivenName,
    membershipLastName,
  };
};
