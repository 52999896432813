import { payslipOverviewDetails } from 'common/fragments';
import gql from 'graphql-tag';

export const PAYSLIP_CARD_QUERY = gql`
  query payslipCardQuery {
    payslips(first: 1) {
      edges {
        node {
          ...payslipOverviewDetails
        }
      }
    }
  }
  ${payslipOverviewDetails}
`;
