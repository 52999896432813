import {
  personalAdviceDashboardCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement as PersonalAdviceEntitlement,
  personalAdviceDashboardCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexPersonalAdviceEntitlement_bookedMeeting_Meeting as Meeting,
  personalAdviceDashboardCardQuery_Query,
} from 'app/apollo/graphql/types';
import isAfter from 'date-fns/isAfter';

import { isPersonalAdviceEntitlement } from './is-personal-advice-entitlement';

interface PersonalAdviceCardData {
  /**
   * The benefit id for for the personal advice benefit
   */
  benefitId: string;
  /**
   * The booked personal advice meeting
   */
  bookedMeeting: Meeting | null;
  /**
   * The earliest date on which the user can book a new meeting
   */
  earliestNextMeetingDate: string | null;
  /**
   * The entitlement for the personal advice benefit
   */
  entitlement: PersonalAdviceEntitlement;
  /**
   * Designates whether the user has an upcoming meeting
   */
  hasUpcomingMeeting: boolean;
  /**
   * The date of the upcoming or latest personal advice meeting
   */
  upcomingOrLatestMeetingDate: string | null;
}

/**
 * Retrieves the relevant personal advice card data from the query response
 * or returns null if the user has no access to personal advice.
 */
export const getPersonalAdviceCardData = (
  data?: personalAdviceDashboardCardQuery_Query,
): PersonalAdviceCardData | null => {
  const entitlement = data?.entitlements?.edges?.[0]?.node;

  if (!entitlement || !isPersonalAdviceEntitlement(entitlement)) {
    return null;
  }

  const { bookedMeeting, benefit } = entitlement;
  const benefitId = benefit.id;
  const upcomingOrLatestMeetingDate =
    bookedMeeting?.time ?? entitlement.latestMeetingDate;
  const hasUpcomingMeeting = !!bookedMeeting;

  const earliestNextMeetingDate =
    entitlement.earliestNextMeetingDate &&
    isAfter(new Date(entitlement.earliestNextMeetingDate), new Date())
      ? entitlement.earliestNextMeetingDate
      : null;

  return {
    benefitId,
    entitlement,
    bookedMeeting,
    upcomingOrLatestMeetingDate,
    hasUpcomingMeeting,
    earliestNextMeetingDate,
  };
};
