import { loginMessages } from 'common/messages/login';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { FAILURE_STATUS } from 'features/auth/common/constants';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

interface OuterProps {
  isLinkExpired: boolean;
  error?: string;
}

interface LocationState {
  loggedOut?: boolean;
  message?: string;
}

type Props = OuterProps;

export const LoginNotificationCard: React.FC<Props> = ({
  error,
  isLinkExpired,
}) => {
  const { replace } = useHistory();
  const location = useLocation<LocationState>();

  const [loggedOutMessage, setLoggedOutMessage] =
    useState<React.ReactNode>(undefined);

  useEffect(() => {
    if (location?.state) {
      const { loggedOut, message, ...state } = location.state;
      if (loggedOut) {
        setLoggedOutMessage(
          message ?? <FormattedMessage {...loginMessages.loggedOut} />,
        );
        replace({ ...location, state });
      }
    }
  }, [location?.state?.loggedOut]);

  if (error) {
    return (
      <NotificationCard type="error">
        {error && (FAILURE_STATUS[error] || error)}
      </NotificationCard>
    );
  }

  if (loggedOutMessage) {
    return <NotificationCard type="info">{loggedOutMessage}</NotificationCard>;
  }

  if (isLinkExpired) {
    return (
      <NotificationCard type="warning">
        <FormattedMessage {...loginMessages.expiredLink} />
      </NotificationCard>
    );
  }

  return null;
};
