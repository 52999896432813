import { Logo } from '@frontend/ui';
import { menuMessages } from 'common/messages';
import { useIntl } from 'components/formats';
import { NavLink } from 'components/Links/NavLink';
import React from 'react';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  align-self: center;
  display: inline-block;
`;

const LogoHolder = styled.div`
  max-height: calc(4.5rem - 2px);

  svg {
    display: block;
  }
`;

const PortalLogo: React.FC = () => {
  const intl = useIntl();

  return (
    <StyledNavLink aria-label={intl.formatMessage(menuMessages.home)} to="/">
      <LogoHolder>
        <Logo width={130} height={26} />
      </LogoHolder>
    </StyledNavLink>
  );
};

export default PortalLogo;
