import { BenefitType } from '@frontend/benefit-types';
import { AnchorButton, ButtonLayout, Section } from '@frontend/ui';
import {
  discoverSectionQuery,
  discoverSectionQueryVariables,
} from 'app/apollo/graphql/types';
import advinansPlus from 'assets/illustrations/accountAggregationSuccess.svg';
import bookMeeting from 'assets/illustrations/addMeeting.svg';
import salaryExchange from 'assets/illustrations/coinsSweep.svg';
import insuranceDiscount from 'assets/illustrations/insurance.svg';
import optimizePension from 'assets/illustrations/puzzle.svg';
import bonusExchange from 'assets/illustrations/redirect.svg';
import {
  heroBodyMessages,
  heroLedeMessages,
  heroPrimaryLinkMessage,
  heroSecondaryLinkMessage,
  heroTitleMessages,
} from 'common/messages/discover';
import { FormattedMessage } from 'components/formats';
import { HeroCard } from 'components/HeroCard';
import { LinkButton } from 'components/LinkButton';
import { ActionsCarousel } from 'containers/ActionsCarousel';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LinkProps } from 'react-router-dom';
import { StaticBenefitType } from 'utils/constants';
import { useQuery } from 'utils/use-query';

import { DISCOVER_SECTION_QUERY } from './graphql/queries';
import { getFilteredActions } from './utils/get-filtered-actions';
import { Context } from './utils/types';

export interface ExternalLink {
  href: string;
}

const salaryExchangeBenefitType: BenefitType[] = ['advinans_salary_exchange'];

export const isExternal = (
  link: LinkProps['to'] | ExternalLink,
): link is ExternalLink => (typeof link === 'string' ? false : 'href' in link);

export type ActionType =
  | 'bonusExchange'
  | 'salaryExchange'
  | 'changeSx'
  | 'optimizePension'
  | 'bookTransferMeeting'
  | 'advinansPlus'
  | 'insuranceDiscount';

export const ILLUSTRATION_MAP: Record<ActionType, string> = {
  bonusExchange,
  salaryExchange,
  changeSx: salaryExchange,
  optimizePension,
  bookTransferMeeting: bookMeeting,
  advinansPlus,
  insuranceDiscount,
};

const allContexts: Context[] = Object.values(Context);

interface Props extends RouteComponentProps {
  contexts?: Context[];
  noActions?: boolean;
}

export const Discover: React.FC<Props> = ({
  contexts = allContexts,
  noActions,
  location,
}) => {
  const {
    currentUser: {
      userAccountId,
      poa: { effective: isPoaEffective },
      isPpmConnected,
      currentCompany,
      isFriSignedWithEmail,
    },
  } = useCurrentUser();

  const { data } = useQuery<
    discoverSectionQuery,
    discoverSectionQueryVariables
  >(DISCOVER_SECTION_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      membershipCompanyId: currentCompany?.id ?? '',
      entitlementsCompanyId: currentCompany?.id,
      includeVoluntaryGroupBenefit: contexts.includes(Context.INSURANCES),
      voluntaryGroupBenefitType: [StaticBenefitType.forsakringar],
      includeSalaryExchange:
        contexts.some(context => Context.PENSION === context) &&
        !!currentCompany?.id,
      salaryExchangeBenefitType,
      includePensionTransferMeeting: contexts.some(context =>
        [Context.PENSION, Context.TRANSFERABLE_PENSION_INSURANCE].includes(
          context,
        ),
      ),
      includeAdvinansPlus: contexts.some(context =>
        [Context.INSURANCES, Context.EXTERNAL_PENSION_INSURANCE].includes(
          context,
        ),
      ),
    },
    // This query must not affect the empty state of the page.
    // Ideally the component/query composition should be different
    // to avoid having to pass this hard-coded `isEmpty` function.
    isEmpty: () => true,
  });

  const filteredActions = getFilteredActions({
    contexts,
    data,
    isFriSignedWithEmail,
    isPoaEffective,
    isPpmConnected,
    pathname: location.pathname,
  });

  if (!filteredActions?.length) {
    return null;
  }

  const hero = filteredActions.find(action => action.hasHero);

  return (
    <>
      <Section>
        {hero && (
          <HeroCard
            title={
              <FormattedMessage
                select={hero.action}
                messages={heroTitleMessages}
              />
            }
            lede={
              <FormattedMessage
                select={hero.action}
                messages={heroLedeMessages}
              />
            }
            body={
              heroBodyMessages[hero.action] && (
                <FormattedMessage
                  select={hero.action}
                  messages={heroBodyMessages}
                />
              )
            }
            illustration={ILLUSTRATION_MAP[hero.action]}
            actions={
              <ButtonLayout>
                {isExternal(hero.primaryLink) ? (
                  <AnchorButton href={hero.primaryLink.href} filled>
                    <FormattedMessage
                      select={hero.action}
                      messages={heroPrimaryLinkMessage}
                    />
                  </AnchorButton>
                ) : (
                  <LinkButton filled to={hero.primaryLink}>
                    <FormattedMessage
                      select={hero.action}
                      messages={heroPrimaryLinkMessage}
                    />
                  </LinkButton>
                )}
                {hero.secondaryLink && (
                  <AnchorButton href={hero.secondaryLink} target="_blank" text>
                    <FormattedMessage
                      select={hero.action}
                      messages={heroSecondaryLinkMessage}
                    />
                  </AnchorButton>
                )}
              </ButtonLayout>
            }
          />
        )}
      </Section>
      {!noActions && (
        <Section>
          <ActionsCarousel actions={filteredActions} />
        </Section>
      )}
    </>
  );
};
