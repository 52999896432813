import { TransitionOverlay } from 'components/TransitionOverlay';
import { useTransitionOverlay } from 'containers/TransitionOverlayProvider';
import React from 'react';

interface TransitionCenterProps {
  pathname: string;
}

export const TransitionCenter: React.FC<TransitionCenterProps> = ({
  pathname,
}) => {
  const { transition } = useTransitionOverlay();

  if (!transition) {
    return null;
  }

  return <TransitionOverlay {...transition} pathname={pathname} />;
};
