import {
  AnchorWithIconLayout,
  ASet,
  ASetItem,
  ChipSet,
  IconButton,
  MODAL_DATA_ROLE,
} from '@frontend/ui';
import {
  event,
  loyalty,
  money,
  person,
  personalInjury,
  shortcut,
  upload,
} from '@frontend/ui/icons';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, useIntl } from 'components/formats';
import { Link } from 'components/Links/Link';
import {
  SideSheet,
  SideSheetSection,
  SideSheetSubtitle,
} from 'components/SideSheet';
import { FeatureType } from 'contexts/current-user';
import { useFeaturePreview } from 'features/feature-preview';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { MOBILE_SCREEN_RESOLUTION } from 'utils/constants';

import {
  ChangeBenefitPackageModal,
  getChangeBenefitPackageLink,
} from './change-benefit-package';
import { EditSalaryModal, getEditSalaryLink } from './edit-salary';
import { adminShortcutMessages } from './messages';
import {
  getRegisterAbsenceLink,
  RegisterAbsenceModal,
} from './register-absence';
import {
  getTerminateEmploymentLink,
  TerminateEmploymentModal,
} from './terminate-employment';

export const FEATURE_PREVIEW_ADMIN_SHORTCUTS_PARENT_ID =
  'feature-preview-admin-shortcuts-parent-id';

export const AdminShortcuts: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isSmallScreen = useIsSmallScreen(MOBILE_SCREEN_RESOLUTION);
  const { formatMessage } = useIntl();
  const location = useLocation();

  useFeaturePreview({
    feature: FeatureType.ADMIN_SHORTCUTS_TOUR,
  });

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          id={FEATURE_PREVIEW_ADMIN_SHORTCUTS_PARENT_ID}
          icon={shortcut}
          label={formatMessage(adminShortcutMessages.shortcuts)}
          onClick={() => setIsOpen(true)}
        />
      ) : (
        <ChipSet id={FEATURE_PREVIEW_ADMIN_SHORTCUTS_PARENT_ID}>
          <AssistChip
            leadingIcon={shortcut}
            onClick={() => setIsOpen(true)}
            text={<FormattedMessage {...adminShortcutMessages.shortcuts} />}
          />
        </ChipSet>
      )}
      <SideSheet
        onClosed={() => {
          /**
           * By default, MDC Drawer restores focus to the menu button that opened it upon closing.
           * When we close the SideSheet because a modal has opened, we want to restore focus to the modal.
           */
          const element = document.querySelector(
            `[data-role=${MODAL_DATA_ROLE}]`,
          );
          if (element instanceof HTMLElement) {
            element.focus();
          }
        }}
        setOpen={setIsOpen}
        open={isOpen}
        title={<FormattedMessage {...adminShortcutMessages.shortcuts} />}
      >
        <SideSheetSection>
          <SideSheetSubtitle>
            <FormattedMessage {...adminShortcutMessages.actions} />
          </SideSheetSubtitle>
          <ASet>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to={getEditSalaryLink(location)}>
                <AnchorWithIconLayout icon={money}>
                  <FormattedMessage {...adminShortcutMessages.editSalary} />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to={getChangeBenefitPackageLink(location)}>
                <AnchorWithIconLayout icon={loyalty}>
                  <FormattedMessage
                    {...adminShortcutMessages.changeBenefitPackage}
                  />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to={getRegisterAbsenceLink(location)}>
                <AnchorWithIconLayout icon={personalInjury}>
                  <FormattedMessage
                    {...adminShortcutMessages.registerAbsence}
                  />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to={getTerminateEmploymentLink(location)}>
                <AnchorWithIconLayout icon={event}>
                  <FormattedMessage
                    {...adminShortcutMessages.terminateEmployment}
                  />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
          </ASet>
        </SideSheetSection>
        <SideSheetSection>
          <SideSheetSubtitle>
            <FormattedMessage {...adminShortcutMessages.links} />
          </SideSheetSubtitle>
          <ASet>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to="/admin/employees/add">
                <AnchorWithIconLayout icon={person}>
                  <FormattedMessage {...adminShortcutMessages.addEmployee} />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
            <ASetItem onClick={() => setIsOpen(false)}>
              <Link to="/admin/employees/import">
                <AnchorWithIconLayout icon={upload}>
                  <FormattedMessage
                    {...adminShortcutMessages.importEmployees}
                  />
                </AnchorWithIconLayout>
              </Link>
            </ASetItem>
          </ASet>
        </SideSheetSection>
      </SideSheet>
      <EditSalaryModal />
      <TerminateEmploymentModal />
      <RegisterAbsenceModal />
      <ChangeBenefitPackageModal />
    </>
  );
};
