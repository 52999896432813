import { Main } from '@frontend/ui';
import { NavigationBar } from 'components/NavigationBar';
import { NavigationRail } from 'components/NavigationRail';
import { NavigationDrawer } from 'containers/NavigationDrawer';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import React from 'react';
import styled from 'styled-components';

import { AppBar } from './app-bar';
import { Footer } from './footer';

interface OffsetContainerProps {
  navigationOffset: number;
}

const OffsetContainer = styled.div<OffsetContainerProps>`
  margin-left: ${p => p.navigationOffset}px;
`;

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  const { navigationOffset } = useNavigationContext();

  return (
    <>
      <AppBar />
      <NavigationRail />
      <NavigationDrawer />
      <NavigationBar />
      <OffsetContainer navigationOffset={navigationOffset}>
        <Main>{children}</Main>
        <Footer />
      </OffsetContainer>
    </>
  );
};
