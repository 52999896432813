export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M8 18h1.5v-2h2v-1.5h-2v-2H8v2H6V16h2Zm5-.75h5v-1.5h-5Zm0-2.5h5v-1.5h-5Zm1.1-3.8 1.4-1.4 1.4 1.4 1.05-1.05-1.4-1.45 1.4-1.4L16.9 6l-1.4 1.4L14.1 6l-1.05 1.05 1.4 1.4-1.4 1.45ZM6.25 9.2h5V7.7h-5ZM5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Z',
      },
    },
  ],
};
