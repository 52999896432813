import { Figure, Icon } from '@frontend/ui';
import { money } from '@frontend/ui/icons';
import { payslipCardQuery } from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { dashboardMessages } from 'common/messages/dashboard';
import { totalCompensationMessages } from 'common/messages/total-compensation';
import { FormattedMessage } from 'components/formats';
import { FormattedLongDate } from 'components/FormattedLongDate';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { PAYSLIP_CARD_QUERY } from './graphql/queries';
import { getPayslipCardData } from './utils/get-payslip-card-data';

export const Card: React.FC = () => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();
  const { data } = useQuery<payslipCardQuery>(PAYSLIP_CARD_QUERY, {
    suspend: true,
    isEmpty: p => !getPayslipCardData(p),
    errorPolicy: 'all',
  });

  const payslipCardData = getPayslipCardData(data);

  if (!payslipCardData) {
    return null;
  }

  const { payslip: latestPayslip } = payslipCardData;

  const to = currentCompany?.hasTotalCompensation
    ? '/total-compensation/payslips'
    : '/payslips';

  return (
    <CardGridCell>
      <SnapshotCard
        link={{
          to,
          label: <FormattedMessage {...dashboardMessages.showPayslip} />,
        }}
        icon={<Icon icon={money} color="primary" size="medium" decorative />}
        title={<FormattedMessage {...commonMessages.payslips} />}
        figure1={
          <Figure
            size="medium"
            title={
              <FormattedLongDate
                value={latestPayslip.paidAt || latestPayslip.importDate}
              />
            }
            description={
              <FormattedMessage {...totalCompensationMessages.latestPayslip} />
            }
          />
        }
      />
    </CardGridCell>
  );
};
