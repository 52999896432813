export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'm21 12.22c0-5.49-4.26-9.22-9-9.22-4.69 0-9 3.65-9 9.28-0.6 0.34-1 0.98-1 1.72v2c0 1.1 0.9 2 2 2h1v-6.1c0-3.87 3.13-7 7-7s7 3.13 7 7v7.1h-8v2h8c1.1 0 2-0.9 2-2v-1.22c0.59-0.31 1-0.92 1-1.64v-2.3c0-0.7-0.41-1.31-1-1.62z',
      },
    },
    {
      name: 'circle',
      attribs: {
        fill: 'currentColor',
        cx: '9',
        cy: '13',
        r: '1',
      },
    },
    {
      name: 'circle',
      attribs: {
        fill: 'currentColor',
        cx: '15',
        cy: '13',
        r: '1',
      },
    },
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'm18 11.03c-0.48-2.85-2.96-5.03-5.95-5.03-3.03 0-6.29 2.51-6.03 6.45 2.47-1.01 4.33-3.21 4.86-5.89 1.31 2.63 4 4.44 7.12 4.47z',
      },
    },
  ],
};
