import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router';

export const UserSettingsRedirects: React.FC<RouteComponentProps> = ({
  match,
}) => (
  <Switch>
    <Redirect from={`${match.url}/documents`} to="/settings/documents" />
    <Redirect exact from={match.url} to="/settings" />
  </Switch>
);
