import gql from 'graphql-tag';

export const CHANGE_BENEFIT_PACKAGE_QUERY = gql`
  query changeBenefitPackageQuery($companyId: ID!, $userAccountId: ID!) {
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      membershipBenefitPackage {
        benefitPackage {
          id
          name
        }
        effectiveDate
      }
    }
  }
`;
