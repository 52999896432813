import { FlexEpassiBikeCodeStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const offerCommonMessages = defineMessages({
  campaignCode: {
    id: 'offerCommonMessages.campaignCode',
    defaultMessage: 'Kampanjkod',
  },
  categories: {
    id: 'offerCommonMessages.categories',
    defaultMessage: 'Kategorier',
  },
  copyCode: {
    id: 'offerCommonMessages.copyCode',
    defaultMessage: 'kopiera kod',
  },
  customOffer: {
    id: 'offerCommonMessages.customOffer',
    defaultMessage: '{companyName}-förmåner',
  },
  filter: {
    id: 'offerCommonMessages.filter',
    defaultMessage: `{count, plural,
    =0 {Alla filter}
    other {Alla filter (#)}
  }`,
  },
  toOfferLink: {
    id: 'offerCommonMessages.toOfferLink',
    description: 'Till erbjudandet',
    defaultMessage: 'Till erbjudandet',
  },
  title: {
    id: 'offerCommonMessages.title',
    description: 'Erbjudande',
    defaultMessage: 'Erbjudande',
  },
  offerUrl: {
    id: 'offerCommonMessages.offerUrl',
    description: 'Url to web',
    defaultMessage: 'Address till webbplats',
  },
  searchOffers: {
    id: 'offerCommonMessages.searchOffers',
    description: 'Label used on benefit offer search field',
    defaultMessage: 'Sök bland förmåner och erbjudanden',
  },
  noBenefitsFound: {
    id: 'offerCommonMessages.noBenefitsFound',
    defaultMessage: 'Inga förmåner matchar sökningen',
  },
});

export const offerFormMessages = defineMessages({
  title: {
    id: 'offerFormMessages.title',
    description: 'Rubrik för erbjudande',
    defaultMessage: 'Rubrik',
  },
  addSupplier: {
    id: 'offerFormMessages.addSupplier',
    description: 'Lägg till leverantör',
    defaultMessage: 'Lägg till leverantör',
  },
  validTo: {
    id: 'offerFormMessages.validTo',
    description: '"Utgångsdatum"',
    defaultMessage: 'Giltig t.o.m.',
  },
  visibilityDescription: {
    id: 'offerFormMessages.visibilityDescription',
    description: 'Visibility description',
    defaultMessage:
      'Om ni vill gömma erbjudandet från förmånsportalen, välj bort Visa i förmånsportal.',
  },
  visibilityLabel: {
    id: 'offerFormMessages.visibilityLabel',
    description: 'Visibility label',
    defaultMessage: 'Visa i förmånsportal',
  },
  submit: {
    id: 'offerFormMessages.submit',
    description: 'Skapa erbjudande',
    defaultMessage: 'Spara',
  },
  organizationalUnitsDescription: {
    id: 'offerFormMessages.organizationalUnitsDescription',
    description: 'Description organizational units in news form',
    defaultMessage:
      'Ska erbjudandet endast vara tillgängligt för vissa avdelningar väljer du dessa här, annars är de tillgängliga för alla.',
  },
});

export const movieTicketOfferMessages = defineMessages({
  formTitle: {
    id: 'movieTicketOfferMessages.formTitle',
    defaultMessage: 'Varukorg',
  },
  cardTitle: {
    id: 'movieTicketOfferMessages.cardTitle',
    defaultMessage: 'Biobiljett - En traditionell bioupplevelse',
  },
  cardDescription: {
    id: 'movieTicketOfferMessages.cardDescription',
    defaultMessage: 'Nordea Nodes pris: {amount, number, ::currency/SEK .}',
  },
  totalAmountLabel: {
    id: 'movieTicketOfferMessages.totalAmountLabel',
    defaultMessage: 'Totalbelopp',
  },
  confirmMessage: {
    id: 'movieTicketOfferMessages.confirmMessage',
    defaultMessage:
      'Vid köp av biljetter godkänner du <termsLink>våra villkor</termsLink>',
  },
  buyButton: {
    id: 'movieTicketOfferMessages.buyButton',
    defaultMessage: 'Köp biljetter',
  },
  emailFieldHelperText: {
    id: 'movieTicketOfferMessages.emailFieldHelperText',
    defaultMessage:
      'Dina biljetter kommer att skickas till den här e-postadressen',
  },
  successTitle: {
    id: 'movieTicketOfferMessages.successTitle',
    defaultMessage: 'Dina biobiljetter är på väg!',
  },
  successDescription: {
    id: 'movieTicketOfferMessages.successDescription',
    defaultMessage: `Biljetterna hittar du som unika värdekoder under förmånssidan och de skickas även till din mail {email}, kom ihåg att kolla skräposten. Du anger koderna vid köp av biobiljetter på Filmstadens hemsida. Trevlig bioupplevelse!`,
  },
  outOfTicketsMessage: {
    id: 'movieTicketOfferMessages.outOfTicketsMessage',
    defaultMessage:
      'Vi har tyvärr inte tillräckligt med biljetter på lager. Fler är på väg. Vänligen försök med ett mindre antal eller prova igen igenom inom ett par dagar.',
  },
  yourTicketsTitle: {
    id: 'movieTicketOfferMessages.yourTicketsTitle',
    defaultMessage: 'Dina biljetter',
  },
  ticketCodes: {
    id: 'movieTicketOfferMessages.ticketCodes',
    defaultMessage: 'Biljettkoder',
  },
  purchaseDate: {
    id: 'movieTicketOfferMessages.purchaseDate',
    defaultMessage: 'Köpdatum',
  },
  validUntil: offerFormMessages.validTo,
});

export const epassiBikeMessages = defineMessages({
  informationTitle: {
    id: 'epassiBikeMessages.informationTitle',
    defaultMessage: 'Övrig information',
  },
  grossSalaryDeductionHeader: {
    id: 'epassiBikeMessages.grossSalaryDeduction',
    defaultMessage: 'Bruttlöneavdrags påverkan på andra förmåner',
  },
  grossSalaryDeductionDescription: {
    id: 'epassiBikeMessages.grossSalaryDeductionDescription',
    defaultMessage: `
      <p>Att leasa en cykel via bruttolöneavdrag påverkar andra förmåner.</p>
      <ul>
        <li>
          <b>Ersättning från föräldrapenning och sjukpenning</b>
          <p>Om din årslön efter bruttolöneavdrag understiger 10 prisbasbelopp (motsvarar en årslön om {pbb10, number, ::currency/SEK .} eller en genomsnittlig månadslön om {pbb10Monthly, number, ::currency/SEK .} enligt {year} års nivå).</p>
        </li>
        <li>
          <b>Avsättning till allmän pension</b>
          <p>Om din årslön efter bruttolöneavdrag understiger {incomeLimitIbb, number} inkomstbasbelopp (motsvarar en årslön om {yearlyThreshold, number, ::currency/SEK .} eller en genomsnittlig månadslön om {monthlyThreshold, number, ::currency/SEK .} enligt {year} års nivå).</p>
        </li>
        <li>
          <b>Kollektivavtalade pensions- och försäkringsförmåner</b>
          <p>Påverkas beroende på avtal oavsett lönenivå (se din arbetsgivares policy för mer information).</p>  
        </li>
      </ul>
    `,
  },
  cancelLeaseHeader: {
    id: 'epassiBikeMessages.cancelLeaseHeader',
    defaultMessage: 'Avbryta pågående leasing',
  },
  cancelLeaseDescription: {
    id: 'epassiBikeMessages.cancelLeaseDescription',
    defaultMessage: `
      <p>Om du vill eller behöver avsluta leasingen under en pågående leasingperiod finns två alternativ. För detaljer, se din arbetsgivares policy/villkor. Observera att du själv behöver höra av dig till Epassi för att avbryta din leasing.</p>
      <ol>
        <li>
          <b>Lämna tillbaka cykeln</b>
          <p>Genom att nyttja en avbrottsförsäkring som innebär att du betalar 2 500 kr via privat faktura för att avsluta hyreskontraktet.</p>
        </li>
        <li>
          <b>Behålla cykeln</b>
          <p>Genom att betala återstående leasingavgifter via din lön och restvärdet via privat faktura. Om din lön inte räcker eller om du föredrar kan du betala beloppet via privat faktura.</p>
        </li>
      </ol>
    `,
  },
  employmentTerminationOrAbsenseHeader: {
    id: 'epassiBikeMessages.employmentTerminationOrAbsenseHeader',
    defaultMessage: 'Vid avslutad anställning eller frånvaro',
  },
  employmentTerminationOrAbsenseDescription: {
    id: 'epassiBikeMessages.employmentTerminationOrAbsenseDescription',
    defaultMessage: `
      <p>Om din anställning avslutas eller om du blir sjukskriven, tjänstledig, föräldraledig eller på annat vis långtidsfrånvarande utan att uppbära tillräcklig lön för att fortsätta leasingen finns följande två alternativ. För detaljer, se din arbetsgivares policy/villkor.</p>
      <ol>
        <li>
          <b>Lämna tillbaka cykeln</b>
          <p>Genom att nyttja en avbrottsförsäkring som innebär att du betalar 2 500 kr via privat faktura för att avsluta hyreskontraktet.</p>
        </li>
        <li>
          <b>Behålla cykeln </b>
          <p>Genom att betala återstående leasingavgifter via din lön och restvärdet via privat faktura. Observera att du själv behöver höra av dig till Epassi senast 1 månad innan du slutar/din frånvaro inleds för att du ska kunna behålla cykeln och dra återstående leasingavgifter via din lön. Om din lön inte räcker eller om du föredrar kan du betala beloppet via privat faktura.</p>
        </li>
      </ol>
    `,
  },
  endOfLeaseHeader: {
    id: 'epassiBikeMessages.endOfLeaseHeader',
    defaultMessage: 'Vid leasingperiodens slut',
  },
  endOfLeaseDescription: {
    id: 'epassiBikeMessages.endOfLeaseDescription',
    defaultMessage:
      'Efter din leasingperiod har du möjlighet att köpa loss din förmånscykel, du behöver bara betala restvärdet så är cykeln din. Om du inte önskar att behålla cykeln så lämnar du tillbaka cykeln till Epassi i gott skick.',
  },
  handlingOfPersonalDataHeader: {
    id: 'epassiBikeMessages.handlingOfPersonalDataHeader',
    defaultMessage: 'Hantering av personuppgifter',
  },
  handlingOfPersonalDataDescription: {
    id: 'epassiBikeMessages.handlingOfPersonalDataDescription',
    defaultMessage:
      'Genom beställning av cykel är du som anställd medveten om att Epassi, aktuell cykelleverantör och finansbolag, behandlar dina personuppgifter. Så snart samtliga parter har fullgjort sina förpliktelser enligt avtalet raderas samtliga personuppgifter.',
  },
  cardHeader: {
    id: 'epassiBikeMessages.cardHeader',
    defaultMessage: 'Din EpassiBIKE-kod',
  },
  orderCode: {
    id: 'epassiBikeMessages.orderCode',
    defaultMessage: 'Beställ kod',
  },
  downloadCode: {
    id: 'epassiBikeMessages.downloadCode',
    defaultMessage: 'Hämta kod',
  },
  yourPersonalCode: {
    id: 'epassiBikeMessages.yourPersonalCode',
    defaultMessage: 'Din personliga EpassiBIKE-kod',
  },
  codeOnTheWay: {
    id: 'epassiBikeMessages.codeOnTheWay',
    defaultMessage: 'Din kod är påväg',
  },
  terms: {
    id: 'epassiBikeMessages.terms',
    defaultMessage: `
    <p>När du hämtat din kod kan du se den i Epassis portal/app nästa morgon. Genom att hämta koden intygar jag att:</p>
    <ul>
      <li>Jag har läst och godkänt min arbetsgivares policy/villkor.</li>
      <li>Jag förstår att bruttolöneavdrag kan påverka sociala förmåner och kollektivavtalad tjänstepension.</li>
      <li>Jag är betalningsansvarig för min cykelförmån enligt villkoren, även vid avslutad anställning och frånvaro.</li>
      <li>Jag är ansvarig för underhåll, service och reparation av cykeln under leasingperioden.</li>
      <li>Jag förstår att utrustning eller tillbehör som adderas efter den ursprungliga beställningen bekostas privat och kommer inte vara en del av förmånen.</li>
      <li>Jag själv måste höra av mig till Epassi vid avslutad anställning eller frånvaro för att bestämma hur leasingen ska hanteras.</li>
    </ul>
    `,
  },
  whereToFindCode: {
    id: 'epassiBikeMessages.whereToFindCode',
    defaultMessage:
      'Du hittar din personliga kod i Epassis portal redan imorgon. Så länge kan du börja utforska utbudet av cyklar!',
  },
  close: {
    id: 'epassiBikeMessages.close',
    defaultMessage: 'Stäng',
  },
  seeBicycles: {
    id: 'epassiBikeMessages.seeBicycles',
    defaultMessage: 'Se utbud av cyklar',
  },
  agreeToTerms: {
    id: 'epassiBikeMessages.agreeToTerms',
    defaultMessage: 'Jag har läst och godkänner ovan',
  },
});

export const epassiBikeCodeStatusMessages =
  defineMessages<FlexEpassiBikeCodeStatus>({
    NOT_ORDERED: {
      id: 'epassiBikeCodeStatusMessages.NOT_ORDERED',
      defaultMessage: 'Ej beställd',
    },
    CODE_ON_WAY: {
      id: 'epassiBikeCodeStatusMessages.CODE_ON_WAY',
      defaultMessage: 'Kod påväg',
    },
    CODE_ARRIVED: {
      id: 'epassiBikeCodeStatusMessages.CODE_ARRIVED',
      defaultMessage: 'Tillgänglig via Epassi',
    },
  });
