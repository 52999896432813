import { useFormikObserver } from '@frontend/formik';
import {
  AppBar as AppBarUi,
  AppBarContent,
  AppBarMeta,
  AppBarTitle,
  IconButton,
} from '@frontend/ui';
import { arrowBack, menu, menuOpen } from '@frontend/ui/icons';
import { formMessages } from 'common/messages';
import { a11yMessages } from 'common/messages/a11y';
import { useIntl } from 'components/formats';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { useAppBarNavigationStack } from 'containers/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { useConfirm } from 'contexts/confirmation';
import { useCurrentUser } from 'contexts/current-user';
import { AdminShortcuts } from 'features/admin-shortcuts';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isAdminView } from 'utils/is-admin-view';

import CustomCompanyLogo from '../CustomCompanyLogo';
import { SkipToMainLink } from '../SkipToMainLink';
import { UserMenu } from './user-menu';

export const AppBar: React.FC = () => {
  const { pathname, state } = useLocation();
  const { hasDirtyFields } = useFormikObserver();
  const { confirm } = useConfirm();
  const { formatMessage } = useIntl();
  const {
    isDrawerOpen,
    page: { parentLink },
    setIsDetailScreen,
    setIsDrawerOpen,
  } = useNavigationContext();

  const { peek, push } = useAppBarNavigationStack();

  useEffect(() => {
    setIsDetailScreen(!!parentLink);
  }, [pathname]);

  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  return (
    <AppBarUi>
      <SkipToMainLink />
      <AppBarContent>
        <>
          {!parentLink && (
            <IconButton
              icon={isDrawerOpen ? menuOpen : menu}
              label={formatMessage(a11yMessages.menu)}
              onClick={() => setIsDrawerOpen(_isDrawerOpen => !_isDrawerOpen)}
              aria-expanded={!!isDrawerOpen}
              aria-controls="navigation-drawer"
            />
          )}
          {parentLink && (
            <IconButton
              icon={arrowBack}
              label={formatMessage(a11yMessages.backward)}
              onClick={async () => {
                const link = peek();

                if (!hasDirtyFields) {
                  push(link ?? parentLink, state);
                  return;
                }

                const confirmation = await confirm({
                  title: formatMessage(formMessages.confirmLeaveFormTitle),
                  description: formatMessage(
                    formMessages.confirmLeaveFormDescription,
                  ),
                });

                if (confirmation) {
                  push(link ?? parentLink, state);
                }
              }}
            />
          )}
          {currentCompany?.logoUrl && !isAdminView() ? (
            <CustomCompanyLogo
              imageUrl={currentCompany.logoUrl}
              alt={currentCompany.displayName}
            />
          ) : currentCompany?.displayName ? (
            <AppBarTitle>{currentCompany.displayName}</AppBarTitle>
          ) : null}
        </>
        <AppBarMeta>
          {isAdminView() && currentCompany?.isHRAdmin && <AdminShortcuts />}
          <UserMenu />
        </AppBarMeta>
      </AppBarContent>
    </AppBarUi>
  );
};
