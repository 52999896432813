import { useFormikContext } from '@frontend/formik';
import { employmentStatusMessages } from 'common/messages/status';
import { FormattedMessage } from 'components/formats';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React from 'react';

import { FormValues } from '../..';

export const ConfirmHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.confirmRegisterAbsence} />
);

export const ConfirmBody: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <p>
      <FormattedMessage
        {...adminShortcutMessages.confirmRegisterAbsenceDescription}
        values={{
          absenceType: (
            <FormattedMessage
              messages={employmentStatusMessages}
              select={values.absenceType}
            />
          ),
          employee: values.employment?.label,
          effectiveDate: new Date(values.effectiveDate),
        }}
      />
    </p>
  );
};
