import { BenefitType } from '@frontend/benefit-types';
import { Figure, Icon } from '@frontend/ui';
import { swapHorizontalCircle } from '@frontend/ui/icons';
import {
  FlexSalaryExchangeRequestStatus,
  salaryExchangeCardQuery,
  salaryExchangeCardQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/admin/employees/employee';
import { commonBenefitMessages } from 'common/messages/benefits';
import { dashboardMessages } from 'common/messages/dashboard';
import { workInformationMessages } from 'common/messages/employees';
import { salaryExchangeBenefitMessages } from 'common/messages/salary-exchange';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { LinkItem, SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from 'utils/use-query';

import { SALARY_EXCHANGE_CARD_QUERY } from './graphql/queries';
import { getSalaryExchangeCardData } from './utils/get-salary-exchange-card-data';

const benefitTypeNames: BenefitType[] = ['advinans_salary_exchange'];

interface CardProps {
  figureDescription: React.ReactNode;
  figureTitle: React.ReactNode;
  link?: LinkItem;
}

const Card: React.FC<CardProps> = ({
  link,
  figureTitle,
  figureDescription,
}) => (
  <CardGridCell>
    <SnapshotCard
      link={link}
      icon={
        <Icon
          icon={swapHorizontalCircle}
          color="primary"
          size="medium"
          decorative
        />
      }
      title={<FormattedMessage {...commonBenefitMessages.salaryExchange} />}
      figure1={
        <Figure
          size="medium"
          title={figureTitle}
          description={figureDescription}
        />
      }
    />
  </CardGridCell>
);

type MatchParams = Partial<EmployeeRouteMatchParams>;

const isAdminView = (params: MatchParams): params is EmployeeRouteMatchParams =>
  'userAccountId' in params;

interface Props {
  notLinked?: boolean;
  totalCompensation?: boolean;
}

export const SalaryExchangeCard: React.FC<Props> = ({
  notLinked,
  totalCompensation,
}) => {
  const { currentUser } = useCurrentUser();
  const { params } = useRouteMatch<MatchParams>();
  const userAccountId = isAdminView(params)
    ? params.userAccountId
    : currentUser.userAccountId;
  const companyId = currentUser.currentCompany?.id ?? '';

  const { data } = useQuery<
    salaryExchangeCardQuery,
    salaryExchangeCardQueryVariables
  >(SALARY_EXCHANGE_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    skip: !companyId,
    isEmpty: d => !getSalaryExchangeCardData(d, totalCompensation),
    variables: {
      userAccountId,
      companyId,
      benefitTypeNames,
      requestStatuses: [FlexSalaryExchangeRequestStatus.APPROVED],
    },
  });

  const salaryExchangeCardData = getSalaryExchangeCardData(
    data,
    totalCompensation,
  );

  if (!salaryExchangeCardData) {
    return null;
  }

  const { monthlyPremium, benefitId } = salaryExchangeCardData;

  if (monthlyPremium) {
    return (
      <Card
        figureTitle={
          <FormattedCurrency value={monthlyPremium} currency="SEK" />
        }
        figureDescription={
          <FormattedMessage {...workInformationMessages.monthlyDeposit} />
        }
        link={
          notLinked
            ? undefined
            : {
                to: '/pension/salary-exchange',
                label: (
                  <FormattedMessage {...dashboardMessages.showSalaryExchange} />
                ),
              }
        }
      />
    );
  }

  return (
    <Card
      figureTitle={
        <FormattedMessage {...salaryExchangeBenefitMessages.beneficialSaving} />
      }
      figureDescription={
        <FormattedMessage
          {...salaryExchangeBenefitMessages.informationAboutSalaryExchange}
        />
      }
      link={
        notLinked
          ? undefined
          : {
              to: `/benefits/${benefitId}`,
              label: (
                <FormattedMessage {...dashboardMessages.showSalaryExchange} />
              ),
            }
      }
    />
  );
};
