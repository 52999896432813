import { vacationCardQuery_Query } from 'app/apollo/graphql/types';

interface VacationCardData {
  vacationDaysTotalValue: number;
  vacationDaysAdvance?: number | null;
  vacationDaysPaid?: number | null;
  vacationDaysPerYear?: number | null;
  vacationDaysSaved?: number | null;
  vacationDaysUnpaid?: number | null;
}

/**
 * Retrieves the relevant vacation card data from the query response
 * or returns null if the user does not have vacation configured.
 */
export const getVacationCardData = (
  data?: vacationCardQuery_Query,
  totalCompensation?: boolean,
): VacationCardData | null => {
  if (!data?.membership) {
    return null;
  }

  if (
    totalCompensation &&
    !data.company?.totalCompensationConfiguration?.vacation
  ) {
    return null;
  }

  const {
    vacationDaysPaid,
    vacationDaysSaved,
    vacationDaysUnpaid,
    vacationDaysAdvance,
    vacationDaysPerYear,
  } = data.membership;

  const vacationDaysTotalValue =
    (vacationDaysPaid || 0) +
    (vacationDaysUnpaid || 0) +
    (vacationDaysSaved || 0) +
    (vacationDaysAdvance || 0);

  if (!vacationDaysTotalValue && !vacationDaysPerYear) {
    return null;
  }

  return {
    vacationDaysPaid,
    vacationDaysSaved,
    vacationDaysUnpaid,
    vacationDaysAdvance,
    vacationDaysPerYear,
    vacationDaysTotalValue,
  };
};
