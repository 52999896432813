import { isNullOrUndefined } from '@frontend/utils';
import { ADMIN_ROOT_PATHS, EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useNavigationContext } from '../containers/NavigationContext';
import {
  NavigationStack,
  useNavigationStackUtils,
} from './use-navigation-stack-utils';

const TOP_LEVEL_ROUTES = [
  ...Object.values(EMPLOYEE_ROOT_PATHS),
  ...Object.values(ADMIN_ROOT_PATHS),
];

interface NavigationStackProps {
  trackPageOnUnmount?: boolean;
}

interface AppBarNavigationStack extends Omit<NavigationStack, 'push'> {
  push: (path: string, state: unknown) => void;
}

/**
 * Re-exports the stack peek function provided by useNavigationStackUtils,
 * and adds wrapper to history.push for detecting changes on nav stack.
 *
 * Also adds observer strategy for tracking route manipulations on the
 * navigation stack.
 */
export const useAppBarNavigationStack = (
  props?: NavigationStackProps,
): AppBarNavigationStack => {
  const { trackPageOnUnmount = false } = props ?? {};
  const { pathname } = useLocation();
  const history = useHistory();

  const path = useRef(pathname);
  // Denotes if this hook is unmounted due to a browser pop
  // event (backwards navigation), in which case the current
  // pathname should not be tracked.
  const isNavBack = useRef(false);
  const trackPageOnUnmountRef = useRef(trackPageOnUnmount);
  const { peek, push: pushToStack } = useNavigationStackUtils();
  const {
    browseHistory: { pop, clear, stack },
  } = useNavigationContext();

  const push = (_path: string, state: unknown) => {
    isNavBack.current = true;
    history.push(_path, state);
  };

  const setIsNavBack = () => {
    isNavBack.current = true;
  };

  // If the current top item on the stack is the same route
  // as is currently mounted, then we pop it from the stack
  // to ensure that the user isn't pushed back to current
  // route upon exiting/navigating backward from a child screen
  // or a full window modal.
  //
  // Ideally this hook shouldn't need a second subscription to the
  // route stack, though since the 'popstate' event listener doesn't
  // always fire, this extra subscription help to circumvent issues
  // with accidentally pushing pathnames of routes that are being
  // unmounted which shouldn't have occured due to a backwards navigation
  useEffect(() => {
    path.current = pathname;
    if (!!stack.length && path.current === stack[0]) {
      pop();
    }
  }, [pathname, stack]);

  useEffect(() => {
    trackPageOnUnmountRef.current = trackPageOnUnmount;
  }, [trackPageOnUnmount]);

  useEffect(() => {
    window.addEventListener('popstate', setIsNavBack);

    if (!isNullOrUndefined(path.current)) {
      const isTopLevelDestination = TOP_LEVEL_ROUTES.includes(path.current);

      if (isTopLevelDestination) {
        clear();
      }
    }

    return () => {
      if (path.current && trackPageOnUnmountRef.current && !isNavBack.current) {
        pushToStack(path.current);
      }

      window.removeEventListener('popstate', setIsNavBack);
    };
  }, []);

  return { peek, push };
};
