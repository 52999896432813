export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M12 17.65 8.25 21.4q-.575.575-1.4.575t-1.4-.575L2.6 18.55q-.575-.575-.575-1.4t.575-1.4L6.35 12 2.6 8.25q-.575-.575-.575-1.4t.575-1.4L5.45 2.6q.575-.575 1.4-.575t1.4.575L12 6.35l3.75-3.75q.575-.575 1.4-.575t1.4.575l2.85 2.85q.575.575.575 1.4t-.575 1.4L17.65 12l3.75 3.75q.575.575.575 1.4t-.575 1.4l-2.85 2.85q-.575.575-1.4.575t-1.4-.575ZM12 11q.425 0 .713-.288Q13 10.425 13 10t-.287-.713Q12.425 9 12 9t-.712.287Q11 9.575 11 10t.288.712Q11.575 11 12 11Zm-4.25-.4 2.85-2.85L6.85 4 4 6.85ZM10 13q.425 0 .713-.288Q11 12.425 11 12t-.287-.713Q10.425 11 10 11t-.712.287Q9 11.575 9 12t.288.712Q9.575 13 10 13Zm2 2q.425 0 .713-.288Q13 14.425 13 14t-.287-.713Q12.425 13 12 13t-.712.287Q11 13.575 11 14t.288.712Q11.575 15 12 15Zm2-2q.425 0 .713-.288Q15 12.425 15 12t-.287-.713Q14.425 11 14 11t-.712.287Q13 11.575 13 12t.288.712Q13.575 13 14 13Zm-.6 3.25L17.15 20 20 17.15l-3.75-3.75ZM8.475 8.475Zm7.05 7.05Z',
      },
    },
  ],
};
