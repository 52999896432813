import { BenefitType } from '@frontend/benefit-types';
import {
  pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node as Entitlement,
  pensionCardQuery_entitlements_FlexEntitlementConnection_edges_FlexEntitlementEdge_node_FlexOccupationalPensionEntitlement as OccupationalPensionEntitlement,
} from 'app/apollo/graphql/types';

const advinansOccupationalPensionType: BenefitType =
  'advinans_occupational_pension';

const employersPensionType: BenefitType = 'employers_pension';

export const isPensionEntitlement = (
  entitlement: Entitlement,
): entitlement is OccupationalPensionEntitlement =>
  [advinansOccupationalPensionType, employersPensionType].some(
    type => type === entitlement.benefit.type,
  );
