interface ICollectiveAgreementPage {
  pension: string;
  sickness: string;
  tgl: string;
}

export interface TfaCollectiveAgreementPage extends ICollectiveAgreementPage {
  tfa: string;
}

export interface PsaCollectiveAgreementPage extends ICollectiveAgreementPage {
  psa: string;
}

export type CollectiveAgreementPage =
  | PsaCollectiveAgreementPage
  | TfaCollectiveAgreementPage;

export const ITP_PAGES: TfaCollectiveAgreementPage = {
  pension: '2893tCYJ1LdzBM58PhPvdh',
  tgl: '5OliZsT114pivfPB5P8fUJ',
  sickness: 'nrdeeV5Nm91BPUZpABrk8',
  tfa: 'Ao9QaM0AaQpdtfKP1yBUl',
};

export const ITP1_PAGES: TfaCollectiveAgreementPage = {
  pension: 'xmWno4OTHINP6GGhnuRhi',
  tgl: '5OliZsT114pivfPB5P8fUJ',
  sickness: 'nrdeeV5Nm91BPUZpABrk8',
  tfa: 'Ao9QaM0AaQpdtfKP1yBUl',
};

export const KAPKL_PAGES: TfaCollectiveAgreementPage = {
  pension: '5prcLlQGyWfkLDTYZaLXwb',
  tgl: '4UzIE8G41oniiCxOHGlW0a',
  sickness: '4iyZrcMtbtfi3KGw3JjRcS',
  tfa: '6D3Gmzi0tQHelMtzooHsJ3',
};

export const AKAPKL_PAGES: TfaCollectiveAgreementPage = {
  pension: '3d6zQ2PXevsP0wdW4c5q9B',
  tgl: '4UzIE8G41oniiCxOHGlW0a',
  sickness: '4iyZrcMtbtfi3KGw3JjRcS',
  tfa: '6D3Gmzi0tQHelMtzooHsJ3',
};

export const AKAPKR_PAGES: TfaCollectiveAgreementPage = {
  pension: '24ZAGZRKJJLJV6QkPTkTGY',
  tgl: '4UzIE8G41oniiCxOHGlW0a',
  sickness: '4iyZrcMtbtfi3KGw3JjRcS',
  tfa: '6D3Gmzi0tQHelMtzooHsJ3',
};

export const SAFLO_PAGES: TfaCollectiveAgreementPage = {
  pension: '4RXBnkxh9tGHC2a3cKEdVu',
  tgl: 'CE0uKH30A4RREa05qiLd4',
  sickness: '65bSe6C9nwMIjVJZbjZNcc',
  tfa: '27aGGotCDpJqbBrqc5z4YB',
};

export const PAKFS_PAGES: TfaCollectiveAgreementPage = {
  pension: '53LIp0Hph0aQhOAC3fDC0N',
  tgl: '4gO0i63iNExrjWL8DHxaRp',
  sickness: '6fZjfXmkwZahBZw9mMc54w',
  tfa: '6gJOzKpFv9UYfMKfzaKYmP',
};

export const PA_16_1: PsaCollectiveAgreementPage = {
  pension: '1sVYK3nQc2MbFtI8X77TsG',
  tgl: '63V7IqtrTJCkrrQjBX7krn',
  sickness: '4fdB8aeqdYxrGFF4l888oh',
  psa: '5vXGHOy1suqYQ45NJAmuRV',
};

export const PA_16_2: PsaCollectiveAgreementPage = {
  pension: '5gB5zR8txMPDhJuPFcDZjh',
  tgl: '63V7IqtrTJCkrrQjBX7krn',
  sickness: '4fdB8aeqdYxrGFF4l888oh',
  psa: '5vXGHOy1suqYQ45NJAmuRV',
};

export const ADVINANS_PAGES = {
  salaryExchange: '40dIf4qda715tfdJXXTxGK',
  pension: '7AjSDcBpEkG8a2IOyTIG3d',
};

export const NORDNET_PAGES = {
  pension: '2WWhE2a2JL6nde30l70y91',
};

export const LF_PAGES = {
  pension: '18fhXOaw1vioDa3EpULS80',
};

export const NORDEA_PAGES = {
  pension: '1RFyZMdRbHeYALEIViI8q7',
};

export const STUB_ITEM_PAGES = {
  incomePension: 'VN3ghdQiSUocjNrCWQnyE',
  ppmPension: 'QzyrYY91LTfI1xAEMxA7M',
  itp1Pension: '1Al47CS0xMs3E1zfSnYTga',
  itpkPension: '6f0YECIXAqTzrDW5uTC66f',
  safloPension: '3IBUtQTHU9cDrOmSlxQ6lN',
  kapklPension: '2zwaV5XxSTeAl91n7bTlih',
  akapklPension: '1wO3mqpa13uyPxAEy16cOi',
  akapkrPension: '5CSmmNz7SDUC0jKT7DjR0Y',
  pakfsPension: '3f4xmcU9gh6EEtqExl3HHv',
};

export const CONTENTFUL_ENTRY_ID = {
  salaryExchangeAgreement: '1lSpkMzaRu8mcIEC8I2eK0',
  plusWhitePaper: '5hQ994eAziOAW9sVgYnvCD',
};
