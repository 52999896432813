import { useFormikContext } from '@frontend/formik';
import { toNumber } from '@frontend/utils';
import { FormattedMessage, FormattedNumber } from 'components/formats';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React from 'react';

import { FormValues } from '../..';

export const ConfirmHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.confirmEditSalary} />
);

export const ConfirmBody: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <p>
      <FormattedMessage
        {...adminShortcutMessages.confirmEditSalaryDescription}
        values={{
          employee: values.userAccount?.label,
          value: <FormattedNumber value={toNumber(values.amount) ?? 0} />,
          effectiveDate: new Date(values.effectiveDate),
        }}
      />
    </p>
  );
};
