import { PremiumMatrix } from '@frontend/benefit-types';

import { PENSION_PREMIUM_CALCULATION_METHODS_NODE } from '../benefit/pension/constants';

/**
 * This array lists the personal advice meeting frequency options available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES = [
  'ONE_ADVICE_MEETING_LIMIT',
  '0',
  '12',
  '24',
  '36',
  '48',
  '60',
] as const;

// Personal advice
export type PersonalAdviceMeetingFrequencyOptionValue =
  (typeof PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES)[number];
export type PensionConfigurationPremiumMatrix = NonNullable<PremiumMatrix>;
// Pension premium calculation method
export type PensionPremiumCalculationMethod =
  (typeof PENSION_PREMIUM_CALCULATION_METHODS_NODE)[number];

export enum AgreementType {
  EURO_ACCIDENT_GRUPP = 'EURO_ACCIDENT_GRUPP',
  EURO_ACCIDENT_TJP = 'EURO_ACCIDENT_TJP',
}

export const benefitTypeNames = [
  'euro_accident_accident',
  'euro_accident_life',
  'euro_accident_plansjuk',
  'euro_accident_privataccess',
  'euro_accident_tgl',
] as const;

type RiskInsuranceBenefitTypeName = (typeof benefitTypeNames)[number];

export const isRiskInsuranceTypeName = (
  type: string,
): type is RiskInsuranceBenefitTypeName =>
  ([...benefitTypeNames] as string[]).includes(type);
