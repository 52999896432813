import { A, AProps } from '@frontend/ui';
import React from 'react';
import styled from 'styled-components';

const StyledA = styled(A)`
  color: ${p => p.theme.primary};
  display: inline-block;
  &:hover {
    text-decoration: none;
    color: ${p => p.theme.primary};
    border-bottom: 0.0625rem solid ${p => p.theme.primary};
    margin-bottom: -0.0625rem;
  }
`;

interface Props extends AProps {
  href: string;
}

export const FooterA: React.FC<Props> = ({ href, children }) => (
  <StyledA target="_blank" href={href}>
    {children}
  </StyledA>
);
