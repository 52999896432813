import { useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import { toMoney } from '@frontend/utils';
import {
  EconomicProfileInput,
  updateEconomicProfileMutation,
  updateEconomicProfileMutationVariables,
} from 'app/apollo/graphql/types';
import { EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { UPDATE_ECONOMIC_PROFILE_MUTATION } from 'common/mutations';
import { useIntl } from 'components/formats';
import { useCurrentUser } from 'contexts/current-user';
import qs from 'query-string';
import { RouteComponentProps } from 'react-router';
import { formatMutationErrors } from 'utils/format-mutation-errors';

import { FormValues } from '..';
import { VIEWER_ECONOMIC_PROFILE_QUERY } from '../graphql/queries';

interface Submit {
  submit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => Promise<void>;
}

export const useSubmit = (props: RouteComponentProps): Submit => {
  const intl = useIntl();
  const {
    currentUser: { userAccountId },
  } = useCurrentUser();
  const {
    location: { search, state },
    history: { push },
  } = props;

  const [updateEconomicProfile] = useMutation<
    updateEconomicProfileMutation,
    updateEconomicProfileMutationVariables
  >(UPDATE_ECONOMIC_PROFILE_MUTATION, {
    // This refetch query is needed for the case when viewer.economicProfile
    // is null, which should be the case then a user is filling it in for the
    // first time. Then we need to run this to make sure that we rehydrate
    // the current user context.
    refetchQueries: [{ query: VIEWER_ECONOMIC_PROFILE_QUERY }],
    awaitRefetchQueries: true,
  });

  const submit = async (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const { next, deviate } = qs.parse(search);
    const { setErrors } = formikHelpers;

    try {
      const { assets, monthlyIncome, monthlyExpenses, liabilities } = values;

      const economicProfileInput: EconomicProfileInput = {
        userAccountId,
        assets: toMoney(assets),
        monthlyIncome: toMoney(monthlyIncome),
        monthlyExpenses: toMoney(monthlyExpenses),
        liabilities: toMoney(liabilities),
      };

      const economicProfileRes = await updateEconomicProfile({
        variables: { input: economicProfileInput },
      });

      if (
        !economicProfileRes.data?.updateEconomicProfile?.economicProfile ||
        !!economicProfileRes.errors
      ) {
        throw formatMutationErrors(intl, economicProfileRes.errors);
      }

      push({
        pathname: next ?? EMPLOYEE_ROOT_PATHS.index,
        search: qs.stringify({ deviate: deviate === 'true' }),
        state,
      });
    } catch (error) {
      setErrors({ submissionError: error.message });
    }
  };

  return { submit };
};
