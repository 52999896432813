import { defineMessages } from 'react-intl';

export const adminShortcutMessages = defineMessages({
  absenceType: {
    id: 'adminShortcutMessages.absenceType',
    defaultMessage: 'Typ av frånvaro',
  },
  actions: {
    id: 'adminShortcutMessages.actions',
    defaultMessage: 'Snabbåtgärder',
  },
  addEmployee: {
    id: 'adminShortcutMessages.addEmployee',
    defaultMessage: 'Lägg till anställd',
  },
  changeBenefitPackage: {
    id: 'adminShortcutMessages.changeBenefitPackage',
    defaultMessage: 'Byt förmånsgrupp',
  },
  changeBenefitPackageForEmployee: {
    id: 'adminShortcutMessages.changeBenefitPackageForEmployee',
    defaultMessage: 'Byt förmånsgrupp för {employee}',
  },
  changeBenefitPackageSuccess: {
    id: 'adminShortcutMessages.changeBenefitPackageSuccess',
    defaultMessage: 'Ny förmånsgrupp registrerad',
  },
  changeBenefitPackageSuccessDescription: {
    id: 'adminShortcutMessages.changeBenefitPackageSuccessDescription',
    defaultMessage:
      'Ny förmånsgrupp, {benefitPackage}, är nu registrerad för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  confirmChangeBenefitPackage: {
    id: 'adminShortcutMessages.confirmChangeBenefitPackage',
    defaultMessage: 'Bekräfta byte av förmånsgrupp',
  },
  confirmChangeBenefitPackageDescription: {
    id: 'adminShortcutMessages.confirmChangeBenefitPackageDescription',
    defaultMessage:
      'Byt förmånsgrupp från {currentBenefitPackage} till {newBenefitPackage} för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  confirmChangeBenefitPackageNoCurrentDescription: {
    id: 'adminShortcutMessages.confirmChangeBenefitPackageNoCurrentDescription',
    defaultMessage:
      'Byt förmånsgrupp till {newBenefitPackage} för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  confirmEditSalary: {
    id: 'adminShortcutMessages.confirmEditSalary',
    defaultMessage: 'Bekräfta löneändring',
  },
  confirmEditSalaryDescription: {
    id: 'adminShortcutMessages.confirmEditSalaryDescription',
    defaultMessage:
      'Ändra lön för {employee} till {value} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  confirmRegisterAbsence: {
    id: 'adminShortcutMessages.confirmRegisterAbsence',
    defaultMessage: 'Bekräfta frånvaro',
  },
  confirmRegisterAbsenceDescription: {
    id: 'adminShortcutMessages.confirmRegisterAbsenceDescription',
    defaultMessage:
      'Registrera {absenceType} för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  confirmTerminateEmployment: {
    id: 'adminShortcutMessages.confirmTerminateEmployment',
    defaultMessage: 'Bekräfta avslutad anställning',
  },
  confirmTerminateEmploymentDescription: {
    id: 'adminShortcutMessages.confirmTerminateEmploymentDescription',
    defaultMessage:
      'Avsluta anställning för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}. Eventuella försäkringar och förmåner kommer att avslutas.',
  },
  currentBenefitPackage: {
    id: 'adminShortcutMessages.currentBenefitPackage',
    defaultMessage: 'Nuvarande förmånsgrupp',
  },
  currentSalary: {
    id: 'adminShortcutMessages.currentSalary',
    defaultMessage: 'Nuvarande lön eller ersättning',
  },
  currentSalaryValue: {
    id: 'adminShortcutMessages.currentSalaryValue',
    defaultMessage: '{value} fr.o.m {effectiveDate, date, ::yMMMM }',
  },
  registerAbsence: {
    id: 'adminShortcutMessages.registerAbsence',
    defaultMessage: 'Registrera frånvaro',
  },
  editSalary: {
    id: 'adminShortcutMessages.editSalary',
    defaultMessage: 'Ändra eller ange lön',
  },
  editSalarySuccess: {
    id: 'adminShortcutMessages.editSalarySuccess',
    defaultMessage: 'Löneändring genomförd',
  },
  editSalarySuccessDescription: {
    id: 'adminShortcutMessages.editSalarySuccessDescription',
    defaultMessage:
      'En ny lön är nu registrerad för {employee} på {value} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
  firstNameLastName: {
    id: 'adminShortcutMessages.firstNameLastName',
    defaultMessage: 'Förnamn Efternamn',
  },
  importEmployees: {
    id: 'adminShortcutMessages.importEmployees',
    defaultMessage: 'Importera anställda',
  },
  links: {
    id: 'adminShortcutMessages.links',
    defaultMessage: 'Snabblänkar',
  },
  newBenefitPackage: {
    id: 'adminShortcutMessages.newBenefitPackage',
    defaultMessage: 'Ny förmånsgrupp',
  },
  newSalary: {
    id: 'adminShortcutMessages.newSalary',
    defaultMessage: 'Ny lön för {employee}',
  },
  newSalaryDescription: {
    id: 'adminShortcutMessages.newSalaryDescription',
    defaultMessage:
      'Ny lön eller ersättning gäller från och med den första dagen i månaden.',
  },
  newSalaryLabel: {
    id: 'adminShortcutMessages.newSalaryLabel',
    defaultMessage: 'Ny lön',
  },
  registerAbsenceSuccess: {
    id: 'adminShortcutMessages.registerAbsenceSuccess',
    defaultMessage: 'Frånvaro registrerad',
  },
  registerAbsenceSuccessDescription: {
    id: 'adminShortcutMessages.registerAbsenceSuccessDescription',
    defaultMessage:
      '{absenceType} är nu registrerad för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}.',
  },
  remunerationType: {
    id: 'adminShortcutMessages.remunerationType',
    defaultMessage: 'Löneart',
  },
  shortcuts: {
    id: 'adminShortcutMessages.shortcuts',
    defaultMessage: 'Genvägar',
  },
  terminateEmployment: {
    id: 'adminShortcutMessages.terminateEmployment',
    defaultMessage: 'Avsluta anställning',
  },
  terminateEmploymentSuccess: {
    id: 'adminShortcutMessages.terminateEmploymentSuccess',
    defaultMessage: 'Avslutad anställning registrerad',
  },
  terminateEmploymentSuccessDescription: {
    id: 'adminShortcutMessages.terminateEmploymentSuccessDescription',
    defaultMessage:
      'Avslutad anställning är nu registrerad för {employee} fr.o.m {effectiveDate, date, ::yyyyMMdd}',
  },
});
