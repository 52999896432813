import { defineMessages } from 'react-intl';

export const cookieMessages = defineMessages({
  description: {
    id: 'cookieMessages.description',
    description: 'Cookie description',
    defaultMessage:
      'Vi använder kakor för analysera trafik och förbättra vår tjänst. Om du tillåter kakor, delas information om ditt besök med våra analyspartners. Läs mer {link}.',
  },
  linkText: {
    id: 'cookieMessages.linkText',
    description: 'Cookie link text',
    defaultMessage: 'om användning av kakor i vår policy',
  },
  allowButtonText: {
    id: 'cookieMessages.allowButtonText',
    description: 'Cookie allow button text',
    defaultMessage: 'Tillåt kakor',
  },
  blockButtonText: {
    id: 'cookieMessages.blockButtonText',
    description: 'Cookie block button text',
    defaultMessage: 'Tillåt inte kakor',
  },
});
