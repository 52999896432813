import gql from 'graphql-tag';

export const INSURANCE_CARD_QUERY = gql`
  query insuranceCardQuery(
    $userAccountId: ID!
    $entitlementsCompanyId: ID
    $membershipCompanyId: ID!
    $benefitTypeNames: [String!]
    $hasMembership: Boolean!
  ) {
    membership(userAccountId: $userAccountId, companyId: $membershipCompanyId)
      @include(if: $hasMembership) {
      id
      collectiveAgreement
    }
    entitlements(
      userAccountId: $userAccountId
      companyId: $entitlementsCompanyId
      benefitTypeNames: $benefitTypeNames
      showAll: true
    ) {
      edges {
        node {
          benefit {
            id
            type
            content {
              locale
            }
          }
        }
      }
    }
  }
`;
