import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { commonMessages, signMessages } from 'common/messages';
import { loginMessages } from 'common/messages/login';
import { upgradeFriMessages } from 'common/messages/upgrade-fri';
import { FormattedMessage, useIntl } from 'components/formats';
import { FullWidthButton } from 'components/FullWidthButton';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { FAILURE_STATUS } from 'features/auth/common/constants';
import { useLogin } from 'features/auth/login/use-login';
import {
  CenteredQRCode,
  QrCodeHelperText,
} from 'features/sign-graphql/bank-id-sign-modal';
import qs from 'query-string';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  onSignSuccess: () => void;
}

export const UpgradeSessionModal: React.FC<Props> = ({
  history,
  location,
  onSignSuccess,
}) => {
  const { formatMessage } = useIntl();
  const [isBankIdModalOpen, setIsBankIdModalOpen] = useState<boolean>(false);
  const [initSignLoading, setInitSignLoading] = useState<boolean>(false);
  const [signLoading, setSignLoading] = useState<boolean>(false);

  const { ticketId: initialTicketId } = qs.parse(location.search);

  const { startLogin, cancelLogin, signWithSameDevice, qrCode, error } =
    useLogin({
      onSignSuccess,
      history,
      location,
      initialTicketId,
    });

  return (
    <>
      <Modal isOpen={!isBankIdModalOpen}>
        <ModalHeader>
          <FormattedMessage {...upgradeFriMessages.updateSessionHeader} />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage {...upgradeFriMessages.updateSessionDesc} />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={() => history.goBack()}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button
              text
              loading={initSignLoading}
              onClick={async () => {
                setInitSignLoading(true);
                await startLogin({
                  variables: {
                    input: {},
                  },
                });
                setInitSignLoading(false);
                setIsBankIdModalOpen(true);
              }}
            >
              <FormattedMessage {...upgradeFriMessages.identifyWithBankId} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isBankIdModalOpen}
        zIndex={99}
        size="small"
        contentLabel={formatMessage(signMessages.signBankId)}
        withQrCode={!!qrCode}
      >
        <ModalBody>
          {!!qrCode && (
            <>
              <CenteredQRCode value={qrCode} />
              <QrCodeHelperText>
                <FormattedMessage {...loginMessages.generalProgress} />
              </QrCodeHelperText>
            </>
          )}
          {error && (
            <NotificationCard inModal type="error">
              {FAILURE_STATUS[error] || error}
            </NotificationCard>
          )}
        </ModalBody>
        <ModalFooter>
          <FullWidthButton
            loading={signLoading}
            onClick={() => {
              signWithSameDevice();
              setSignLoading(true);
            }}
            style={{ marginBottom: '1rem' }}
          >
            <FormattedMessage {...commonMessages.openBankIdSameDevice} />
          </FullWidthButton>
          <FullWidthButton
            danger
            text
            onClick={() => {
              cancelLogin();
              setSignLoading(false);
              setIsBankIdModalOpen(false);
            }}
          >
            <FormattedMessage {...commonMessages.cancel} />
          </FullWidthButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
