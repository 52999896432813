import { Figure, Icon } from '@frontend/ui';
import { directionsCar } from '@frontend/ui/icons';
import {
  companyCarCardQuery,
  companyCarCardQueryVariables,
} from 'app/apollo/graphql/types';
import { dashboardMessages } from 'common/messages/dashboard';
import { totalCompensationMessages } from 'common/messages/total-compensation';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { LinkItem, SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { COMPANY_CAR_CARD_QUERY } from './graphql/queries';
import { getCompanyCarCardData } from './utils/get-company-car-card-data';

interface CompanyCarCardComponentProps {
  companyCarValue: string | number;
  link?: LinkItem;
}

export const CompanyCarCardComponent: React.FC<
  CompanyCarCardComponentProps
> = ({ companyCarValue, link }) => (
  <SnapshotCard
    link={link}
    icon={
      <Icon icon={directionsCar} color="primary" size="medium" decorative />
    }
    title={<FormattedMessage {...totalCompensationMessages.companyCar} />}
    figure1={
      <Figure
        size="medium"
        title={<FormattedCurrency value={companyCarValue} currency="SEK" />}
        description={
          <FormattedMessage {...totalCompensationMessages.benefitValue} />
        }
      />
    }
  />
);

export const CompanyCarCard: React.FC = () => {
  const {
    currentUser: { currentCompany, userAccountId },
  } = useCurrentUser();

  const { data } = useQuery<companyCarCardQuery, companyCarCardQueryVariables>(
    COMPANY_CAR_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      skip: !currentCompany,
      isEmpty: d => !getCompanyCarCardData(d),
      variables: {
        userAccountId,
        companyId: currentCompany?.id ?? '',
      },
    },
  );

  const companyCarCardData = getCompanyCarCardData(data);

  if (!companyCarCardData) {
    return null;
  }

  const { companyCarMonthlyValue } = companyCarCardData;

  return (
    <CardGridCell>
      <CompanyCarCardComponent
        companyCarValue={companyCarMonthlyValue}
        link={{
          to: '/total-compensation',
          label: <FormattedMessage {...dashboardMessages.showCompanyCar} />,
        }}
      />
    </CardGridCell>
  );
};
