import { Scalars } from 'app/apollo/graphql/types';
import sub from 'date-fns/sub';

import { parseToISODate } from './parse-to-iso-date';

export const getValidUntilDate = (
  dateRange: Scalars['DateRange']['output'],
) => {
  if (!dateRange[1]) {
    throw Error('Upper bound cannot be null');
  }
  return parseToISODate(
    sub(new Date(dateRange[1]), {
      days: 1,
    }),
  );
};
