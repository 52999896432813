import { NavLink } from 'components/Links/NavLink';
import styled from 'styled-components';

export const NavItem = styled(NavLink)`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: ${p => p.theme.onSurfaceVariant};
  --mdc-ripple-color: ${p => p.theme.onSurfaceVariant};

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    outline-offset: -0.125rem;
  }

  &.activeMenu {
    color: ${p => p.theme.onSecondaryContainer};
    --mdc-ripple-color: ${p => p.theme.onSurface};
  }

  .mdc-list-item.mdc-list-item--with-one-line {
    border-radius: 1.25rem;
    width: 2rem;
    min-height: 2rem !important;
    justify-content: center;
  }
`;
