import 'react-multi-carousel/lib/styles.css';

import { defaultTheme, HeadlineSmall, IconButton, media } from '@frontend/ui';
import { keyboardArrowLeft, keyboardArrowRight } from '@frontend/ui/icons';
import { useIsSmallScreen } from 'app/lib/use-is-small-screen';
import {
  carouselDescMessages,
  carouselTitleMessages,
  discoverMessages,
} from 'common/messages/discover';
import { FormattedMessage } from 'components/formats';
import { IllustrationCard } from 'components/IllustrationCard';
import { ActionProps } from 'containers/Discover/utils/get-filtered-actions';
import React from 'react';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  button {
    box-shadow:
      0 0.25rem 0.3125rem rgba(0, 0, 0, 0.08),
      0 0.0625rem 0.625rem rgba(0, 0, 0, 0.08),
      0 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.08);
    position: absolute;
    background: ${p => p.theme.surface};
    top: calc(50% - 1.5rem);
    border: 0.0625rem solid ${p => p.theme.surfaceVariant};
  }
`;

const Header = styled(HeadlineSmall)`
  margin-bottom: 1.5rem;
  display: block;
`;

interface CarouselState {
  currentSlide: number;
  slidesToShow: number;
  totalItems: number;
}

interface ButtonGroupProps {
  carouselState: CarouselState;
  next: () => void;
  previous: () => void;
}

// eslint-disable-next-line
const ButtonGroup = (props: any) => {
  const {
    next,
    previous,
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = props as ButtonGroupProps;

  return (
    <ButtonWrapper>
      {currentSlide !== 0 && (
        <IconButton
          style={{ left: '-1.125rem' }}
          size="small"
          label="back"
          icon={keyboardArrowLeft}
          onClick={() => previous()}
        />
      )}
      {totalItems - currentSlide - slidesToShow > 0 && (
        <IconButton
          style={{ right: '-1.25rem' }}
          size="small"
          label="back"
          icon={keyboardArrowRight}
          onClick={() => next()}
        />
      )}
    </ButtonWrapper>
  );
};

const CustomCarousel = styled(Carousel)`
  margin-right: -0.75rem;

  ${media.lessThan('tablet')`
    padding-bottom: 1rem;
  `}

  .react-multi-carousel-track {
    padding-left: 0.125rem !important;
  }
  .react-multiple-carousel__arrow {
    display: none;
  }
  .react-multi-carousel-track {
    padding-left: 0.75rem;
  }
  .react-multi-carousel-dot button {
    width: 0.5rem;
    height: 0.5rem;
    border: 0.0625rem solid ${p => p.theme.primary};
    background-color: white;
    margin-right: 0.5rem;
  }
  .react-multi-carousel-dot--active button {
    background-color: ${p => p.theme.primary};
  }
  .react-multi-carousel-dot-list {
    bottom: -1rem;
  }
`;

const responsive = {
  // For breakpoints, both max and min are required, so setting a big enough value to desktop max
  desktop: {
    breakpoint: { max: 3000, min: defaultTheme.breakpoints.desktop * 16 },
    items: 3,
  },
  tablet: {
    breakpoint: {
      max: defaultTheme.breakpoints.desktop * 16,
      min: defaultTheme.breakpoints.tablet * 16,
    },
    items: 2,
  },
  mobile: {
    breakpoint: { max: defaultTheme.breakpoints.tablet * 16, min: 0 },
    items: 1,
  },
};

interface Props {
  actions: ActionProps[];
}

export const ActionsCarousel: React.FC<Props> = ({ actions }) => {
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.tablet * 16);

  const cards = actions.map(({ primaryLink, action, messageValues }) => (
    <IllustrationCard
      key={action}
      link={primaryLink}
      title={
        <FormattedMessage
          select={action}
          messages={carouselTitleMessages}
          values={messageValues}
        />
      }
      description={
        <FormattedMessage
          select={action}
          messages={carouselDescMessages}
          values={messageValues}
        />
      }
      action={action}
    />
  ));

  return (
    <>
      <Header>
        <FormattedMessage {...discoverMessages.carouselHeader} />
      </Header>
      <div style={{ position: 'relative' }}>
        <CustomCarousel
          responsive={responsive}
          removeArrowOnDeviceType="mobile"
          renderButtonGroupOutside={!isSmallScreen}
          customButtonGroup={!isSmallScreen ? <ButtonGroup /> : undefined}
          showDots={isSmallScreen}
        >
          {cards}
        </CustomCarousel>
      </div>
    </>
  );
};
