import {
  APP_BAR_HEIGHT,
  Icon,
  IconButton,
  labelLargeCSS,
  media,
  Menu,
  MENU_ITEM_HEIGHT,
  MenuSection,
  MenuSurfaceAnchor,
  NAVIGATION_BAR_HEIGHT,
  Ripple,
  Separator,
} from '@frontend/ui';
import {
  business,
  faceFilled,
  logout,
  manageAccountsFilled,
  settings,
  switchAccount,
} from '@frontend/ui/icons';
import { formatRegistrationNumber } from '@frontend/utils';
import { commonMessages, menuMessages } from 'common/messages';
import { a11yMessages } from 'common/messages/a11y';
import { FormattedMessage, useIntl } from 'components/formats';
import { MenuItem } from 'components/Menu';
import { PlainButton } from 'components/PlainButton';
import { useCurrentUser } from 'contexts/current-user';
import React, { useId, useState } from 'react';
import styled from 'styled-components';
import { isAdminView as _isAdminView } from 'utils/is-admin-view';

import { CompanySelectionMenu } from '../company-selection-menu';
import { LanguageSelectionMenu } from '../language-selection-menu';

export const FEATURE_PREVIEW_SETTINGS_PARENT_ID =
  'feature-preview-settings-parent-id';

const StyledMenu = styled(Menu)`
  margin-top: 3.5rem;
  width: 17.5rem;

  // The max height of the menu mustn't span beyond the height
  // of the viewport. We subtract margins for the height of the
  // App bar and one default menu item.
  max-height: calc(
    100vh - (${APP_BAR_HEIGHT}px + ${MENU_ITEM_HEIGHT.default}rem)
  ) !important;

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.lessThan('tablet')`
  // For tablet devices we ensure that the user menu
  // doesn't overlap the navigation bar.
  max-height: calc(
    100vh - (${APP_BAR_HEIGHT}px + ${NAVIGATION_BAR_HEIGHT}rem + 1rem)
  ) !important;
`}
`;

const InitialsButton = styled(PlainButton)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Initials = styled.div`
  ${labelLargeCSS}
  color: ${p => p.theme.onPrimary};
  background-color: ${p => p.theme.primary};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
`;

export const UserMenu: React.FC = () => {
  const {
    currentUser: { currentCompany, accessibleCompanies },
  } = useCurrentUser();
  const givenName = currentCompany?.membershipGivenName;
  const lastName = currentCompany?.membershipLastName;

  const [isOpen, toggleIsOpen] = useState(false);
  const [subMenu, setSubMenu] = useState<React.ReactNode | null>(null);
  const { formatMessage } = useIntl();
  const isAdminView = _isAdminView();
  const id = useId();

  return (
    <MenuSurfaceAnchor>
      <StyledMenu
        open={isOpen}
        onClose={() => {
          toggleIsOpen(false);
        }}
        label={formatMessage(a11yMessages.userMenu)}
        id={id}
      >
        {subMenu || (
          <>
            <MenuSection>
              {givenName && lastName && (
                <MenuItem
                  disabled
                  icon={<Icon icon={faceFilled} size="medium" decorative />}
                  title={`${givenName} ${lastName}`}
                />
              )}
              <MenuItem
                icon={<Icon icon={settings} size="medium" decorative />}
                link="/settings"
                title={<FormattedMessage {...menuMessages.profile} />}
              />
            </MenuSection>
            <Separator />
            {currentCompany && (
              <>
                <MenuSection>
                  <MenuItem
                    key={currentCompany.id}
                    image={{ image: currentCompany.logoUrl }}
                    title={currentCompany.displayName}
                    description={`${formatRegistrationNumber(
                      currentCompany.registrationNumber,
                    )}`}
                    disabled
                  />
                </MenuSection>
                <Separator />
              </>
            )}
            {(!!currentCompany?.isAdmin || accessibleCompanies.length > 1) && (
              <>
                <MenuSection>
                  {currentCompany?.isAdmin && (
                    <MenuItem
                      title={
                        <FormattedMessage
                          {...menuMessages.view}
                          values={{ isAdminView }}
                        />
                      }
                      link={isAdminView ? '/' : '/admin'}
                      icon={
                        isAdminView ? (
                          <Icon size="medium" icon={switchAccount} decorative />
                        ) : (
                          <Icon size="medium" icon={business} decorative />
                        )
                      }
                    />
                  )}
                  {accessibleCompanies.length > 1 && (
                    <CompanySelectionMenu setSubMenu={setSubMenu} />
                  )}
                </MenuSection>
                <Separator />
              </>
            )}
            <MenuSection>
              <LanguageSelectionMenu setSubMenu={setSubMenu} />
            </MenuSection>
            <Separator />
            <MenuSection>
              <MenuItem
                icon={<Icon icon={logout} size="medium" decorative />}
                link="/logout"
                title={<FormattedMessage {...menuMessages.logout} />}
              />
            </MenuSection>
          </>
        )}
      </StyledMenu>
      {givenName || lastName ? (
        <Ripple>
          <InitialsButton
            id={FEATURE_PREVIEW_SETTINGS_PARENT_ID}
            aria-label={formatMessage(commonMessages.openMenu)}
            onClick={() => {
              toggleIsOpen(_isOpen => !_isOpen);
              setSubMenu(null);
            }}
            aria-haspopup="menu"
            aria-controls={id}
            aria-expanded={isOpen}
          >
            <Initials>
              {givenName?.charAt(0)}
              {lastName?.charAt(0)}
            </Initials>
          </InitialsButton>
        </Ripple>
      ) : (
        <IconButton
          id={FEATURE_PREVIEW_SETTINGS_PARENT_ID}
          icon={manageAccountsFilled}
          label={formatMessage(commonMessages.openMenu)}
          onClick={() => {
            toggleIsOpen(_isOpen => !_isOpen);
            setSubMenu(null);
          }}
          aria-haspopup="menu"
          aria-controls={id}
          aria-expanded={isOpen}
        />
      )}
    </MenuSurfaceAnchor>
  );
};
