import { SearchableSelectField, useField } from '@frontend/formik';
import { SearchableSelectOption } from '@frontend/ui';
import { formatNaturalPersonIdentifier } from '@frontend/utils';
import {
  selectEmployeeQuery,
  selectEmployeeQueryVariables,
} from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-user';
import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'utils/use-query';

import { adminShortcutMessages } from '../messages';
import { SELECT_EMPLOYEE_QUERY } from './graphql/queries';

export const SEARCH_DEBOUNCE = 300;

interface ShouldSkipSearchParams {
  search: string | undefined;
  searchIsUpdatedThroughSelect: boolean;
}

export const shouldSkipSearch = (params: ShouldSkipSearchParams) =>
  !params.search ||
  params.search.length < 3 ||
  params.searchIsUpdatedThroughSelect;

export interface Props {
  name: string;
  inModal?: boolean;
  required?: boolean;
}

export const SelectEmployeeUserAccountField: React.FC<Props> = ({
  inModal,
  name,
  required,
}) => {
  const [{ value }] = useField<SearchableSelectOption>(name);

  const [search, setSearch] = useState<string>();
  const [debouncedSearch] = useDebounce(search, SEARCH_DEBOUNCE);

  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { data, previousData, loading, error } = useQuery<
    selectEmployeeQuery,
    selectEmployeeQueryVariables
  >(SELECT_EMPLOYEE_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: shouldSkipSearch({
      search: debouncedSearch,
      searchIsUpdatedThroughSelect: value?.label === debouncedSearch,
    }),
    variables: {
      companyId: currentCompany?.id ?? '',
      search: debouncedSearch ?? '',
    },
  });

  const _data = data ?? previousData;

  const options: SearchableSelectOption[] =
    _data?.company?.memberships &&
    !shouldSkipSearch({
      search,
      searchIsUpdatedThroughSelect: value?.label === search,
    })
      ? _data.company.memberships.edges.map(edge => {
          const {
            givenName,
            lastName,
            naturalPersonIdentifiers,
            userAccountId,
          } = edge.node;

          const [nid] = naturalPersonIdentifiers;
          const _nid = nid ? formatNaturalPersonIdentifier(nid) : '';
          return {
            label: `${givenName} ${lastName} (${_nid})`,
            value: userAccountId,
          };
        })
      : [];

  return (
    <>
      {loading && <TopLoading />}
      <SearchableSelectField
        dense
        fixed
        label={
          <FormattedMessage {...adminShortcutMessages.firstNameLastName} />
        }
        name={name}
        options={options}
        search={search}
        onSearchChange={setSearch}
        required={required}
      />
      {error && <GraphQlError error={error} inModal={inModal} />}
    </>
  );
};
