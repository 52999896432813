import { AnchorButton, Icon } from '@frontend/ui';
import { warning } from '@frontend/ui/icons';
import EXTERNAL_URLS from 'app/lib/external-urls';
import { outdatedBrowserMessages } from 'common/messages/outdatedBrowser';
import { FormattedMessage } from 'components/formats';
import { detect } from 'detect-browser';
import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  background-color: ${p => p.theme.inverseSurface};
  padding: 1.5rem;
  position: relative;
  z-index: 10000;
  color: ${p => p.theme.onInverseSurface};

  h3 {
    color: ${p => p.theme.orangeContainer};
  }

  h3,
  p {
    margin: 0;
  }
`;

const IconHolder = styled.div`
  padding-top: 22px;
`;

const StyledIcon = styled(Icon)`
  color: ${p => p.theme.orangeContainer};
  font-size: 3.25rem;
  margin-right: 1rem;
`;

const Item = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

interface State {
  link: string;
  outdatedBrowser: boolean;
}

const LINKS = {
  ie: EXTERNAL_URLS.ie,
  edge: EXTERNAL_URLS.edge,
};

class OutdatedBrowser extends React.PureComponent<object, State> {
  constructor(props) {
    super(props);

    this.state = {
      outdatedBrowser: false,
      link: '',
    };
  }

  public componentDidMount() {
    const browser = detect();
    if (browser && browser.name) {
      const { name, version } = browser;

      if (!version) {
        return;
      }

      const versionInt = parseInt(version, 10);

      if (
        (name === 'ie' && versionInt < 11) ||
        (name === 'edge' && versionInt < 15)
      ) {
        this.setState({ outdatedBrowser: true, link: LINKS[name] });
      }
    }
  }

  public render() {
    const { outdatedBrowser, link } = this.state;

    if (!outdatedBrowser) {
      return null;
    }

    return (
      <Message
        aria-live="assertive"
        role="alert"
        aria-labelledby="outdated-browser-title"
        aria-describedby="outdated-browser-description"
      >
        <Item>
          <IconHolder>
            <StyledIcon icon={warning} decorative />
          </IconHolder>
        </Item>
        <Item>
          <h3 id="outdated-browser-title">
            <FormattedMessage
              {...outdatedBrowserMessages.outdatedBrowserTitle}
            />
          </h3>
          <p id="outdated-browser-description">
            <FormattedMessage
              {...outdatedBrowserMessages.outdatedBrowserDesc}
            />
          </p>
          <p>
            <AnchorButton href={link} target="_blank" tonal>
              <FormattedMessage
                {...outdatedBrowserMessages.updateBrowserLink}
              />
            </AnchorButton>
          </p>
        </Item>
      </Message>
    );
  }
}

export default OutdatedBrowser;
