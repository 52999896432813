import { Logo, media } from '@frontend/ui';
import DefaultA from 'components/DefaultA';
import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  text-align: center;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;

  ${media.lessThan('desktop')`
    padding: 1rem 1rem 1.5rem 1rem;
  `}
`;

const LogoWrapper = styled.div`
  margin-top: 2.5rem;
`;

export const AuthFooter: React.FC = () => (
  <Footer>
    <DefaultA href={`https://${window.env.HOST}`}>
      <LogoWrapper>
        <Logo height={16} width={78} />
      </LogoWrapper>
    </DefaultA>
  </Footer>
);
