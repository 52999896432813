import { ApolloQueryResult } from '@apollo/client';
import { client } from 'app/apollo/client';
import {
  AuthenticationMethod,
  googleAnalyticsCompanyQuery,
  googleAnalyticsQuery,
} from 'app/apollo/graphql/types';
import { getUserPersistedCompanyId } from 'app/browser-store';
import { EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { allowed, THIRD_PARTY_COOKIES } from 'features/cookie-banner';
import React, { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { matchPath, useLocation } from 'react-router';
import { useQuery } from 'utils/use-query';

import {
  GOOGLE_ANALYTICS_COMPANY_QUERY,
  GOOGLE_ANALYTICS_QUERY,
} from './graphql/queries';

interface EventParams {
  registrationNumber?: string;
}

/**
 * Retrieves the company registration number for the company, so that it can be included
 * in the event params when tracking a page view.
 *
 * This pattern is highly debatable.
 *
 * 1. CurrentUserContext persists the company id in the browser store.
 * 2. The current company id is retrieved in this file when we attempt to track a page.
 * 3. With the company id, we're querying the company to obtain the registration number.
 *
 * This is a very fragile solution, and we should refactor it.
 * */
async function getCompanyRegisrationNumber(
  userAccountId?: string,
): Promise<EventParams> {
  try {
    if (!userAccountId) {
      throw new Error();
    }

    const companyId = await getUserPersistedCompanyId(userAccountId);

    if (!companyId) {
      throw new Error();
    }

    // This query is typically just pulled from cache as it's already fetched
    // by the CurrentUserContext. This design is questionable though.
    const res: ApolloQueryResult<googleAnalyticsCompanyQuery> =
      await client.query({
        query: GOOGLE_ANALYTICS_COMPANY_QUERY,
        variables: { id: companyId },
      });

    if (!res.data.company) {
      throw new Error();
    }

    const { registrationNumber } = res.data.company;

    return { registrationNumber };
  } catch {
    // Do nothing, but prevent unhandled promise rejection.
    return {};
  }
}

// Initialize google analytics
export const initGA = (condition: boolean) => {
  if (condition) {
    ReactGA.initialize(window.env.GOOGLE_TAG, {
      gtagOptions: {
        page_title: '',
      },
    });
  }
};

const BENEFIT_PATH = `${EMPLOYEE_ROOT_PATHS.benefits}/:id`;

interface Props {
  children: React.ReactElement;
}

/**
 * Google Analytics component that tracks page views. For users that have
 * company membership, the registration number of the company is included
 * in the event params.
 *
 * This implementation reads companyId from the browser store. This solution is
 * opaque and easily breaks.
 */
export const GoogleAnalytics: React.FC<Props> = ({ children }) => {
  const { data } = useQuery<googleAnalyticsQuery>(GOOGLE_ANALYTICS_QUERY);
  const { pathname } = useLocation();
  const [cookies] = useCookies([THIRD_PARTY_COOKIES]);

  /**
   * We use a custom page_view event within benefits as we don't have the
   * benefit type in the URL, only the benefit ID.
   * This match allows us to check if we are on the benefit view so that
   * we can skip the regular page_view event here.
   */
  const benefitMatch = matchPath(pathname, { path: BENEFIT_PATH });

  /**
   * Tracks the page view event in google analytics.
   */
  const trackPage = useMemo(
    () => async () => {
      if (allowed(cookies.adv_third_party_cookies)) {
        const params = await getCompanyRegisrationNumber(data?.viewer?.id);
        ReactGA.event('page_view', params);
      }
    },
    [cookies.adv_third_party_cookies, data?.viewer?.id],
  );

  useEffect(() => {
    if (
      !data?.session ||
      data.session.authenticationMethod ===
        AuthenticationMethod.BACKSTAGE_IMPERSONATION
    ) {
      return;
    }
    initGA(allowed(cookies.adv_third_party_cookies));
  }, [cookies.adv_third_party_cookies, data]);

  useEffect(() => {
    if (benefitMatch) {
      return;
    }
    trackPage();
  }, [pathname, trackPage, benefitMatch]);

  return children;
};
