import { Figure, Icon } from '@frontend/ui';
import { beachAccess } from '@frontend/ui/icons';
import {
  collectiveAgreementCardQuery,
  collectiveAgreementCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { dashboardMessages } from 'common/messages/dashboard';
import { workInformationMessages } from 'common/messages/employees';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { COLLECTIVE_AGREEMENT_LABEL } from 'utils/constants';
import { useQuery } from 'utils/use-query';

import { COLLECTIVE_AGREEMENT_CARD_QUERY } from './graphql/queries';
import { getCollectiveAgreementCardData } from './utils/get-collective-agreement-card-data';

export const CollectiveAgreementCard: React.FC = () => {
  const {
    currentUser: { currentCompany, userAccountId },
  } = useCurrentUser();

  const { data } = useQuery<
    collectiveAgreementCardQuery,
    collectiveAgreementCardQueryVariables
  >(COLLECTIVE_AGREEMENT_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    isEmpty: d => !getCollectiveAgreementCardData(d),
    skip: !currentCompany,
    variables: { userAccountId, companyId: currentCompany?.id ?? '' },
  });

  const collecitveAgreementCardData = getCollectiveAgreementCardData(data);

  if (!collecitveAgreementCardData) {
    return null;
  }

  const {
    collectiveAgreementRoute,
    collectiveAgreementTotalCompensation,
    collectiveAgreementType,
  } = collecitveAgreementCardData;

  return (
    <CardGridCell>
      <SnapshotCard
        link={
          collectiveAgreementRoute && {
            to: `/pension/${collectiveAgreementRoute}`,
            label: <FormattedMessage {...commonMessages.showBenefit} />,
          }
        }
        icon={
          <Icon icon={beachAccess} color="primary" size="medium" decorative />
        }
        title={<FormattedMessage {...commonMessages.occupationalPension} />}
        figure1={
          <Figure
            size="medium"
            title={
              collectiveAgreementTotalCompensation ? (
                <FormattedCurrency
                  value={collectiveAgreementTotalCompensation}
                  currency="SEK"
                />
              ) : (
                COLLECTIVE_AGREEMENT_LABEL[collectiveAgreementType]
              )
            }
            description={
              collectiveAgreementTotalCompensation ? (
                <FormattedMessage
                  {...dashboardMessages.collectiveAgreementPremiumPerMonth}
                  values={{
                    collectiveAgreement:
                      COLLECTIVE_AGREEMENT_LABEL[collectiveAgreementType],
                  }}
                />
              ) : (
                <FormattedMessage
                  {...workInformationMessages.informationAboutYourPension}
                />
              )
            }
          />
        }
      />
    </CardGridCell>
  );
};
