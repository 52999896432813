import { Button } from '@frontend/ui';
import { CalendarEvent, google, ics, office365, outlook } from 'calendar-link';
import DefaultA from 'components/DefaultA';
import { FormattedMessage, useIntl } from 'components/formats';
import { MenuItem } from 'components/Menu';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { pensionTransferMessages } from 'features/pension-transfer/messages';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  scheduledTime: string;
}

const StyledModalBody = styled(ModalBody)`
  padding: 0 0.5rem 1.5rem;

  > * {
    margin-bottom: 0 !important;
  }
`;

export const AddToCalendar: React.FC<Props> = ({ scheduledTime }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const event: CalendarEvent = {
    title: formatMessage(pensionTransferMessages.calendarEventTitle),
    description: formatMessage(pensionTransferMessages.calendarEventDesc),
    start: scheduledTime,
    duration: [30, 'minutes'],
  };

  const googleLink = google(event);
  const outlookLink = outlook(event);
  const office365Link = office365(event);
  const icsLink = ics(event);

  return (
    <>
      <Button text onClick={() => setIsOpen(_isOpen => !_isOpen)}>
        <FormattedMessage {...pensionTransferMessages.addToCalendar} />
      </Button>
      <Modal
        size="xsmall"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <ModalHeader>
          <FormattedMessage {...pensionTransferMessages.addToCalendar} />
        </ModalHeader>
        <StyledModalBody>
          <DefaultA href={googleLink} target="_blank">
            <MenuItem
              title={
                <FormattedMessage
                  {...pensionTransferMessages.calendarGoogleOption}
                />
              }
              noIcon
            />
          </DefaultA>
          <DefaultA href={outlookLink} target="_blank">
            <MenuItem title="Outlook.com" noIcon />
          </DefaultA>
          <DefaultA href={office365Link} target="_blank">
            <MenuItem title="Office365" noIcon />
          </DefaultA>
          <DefaultA href={icsLink}>
            <MenuItem
              title={
                <FormattedMessage
                  {...pensionTransferMessages.calendarIcsOption}
                />
              }
              noIcon
            />
          </DefaultA>
        </StyledModalBody>
      </Modal>
    </>
  );
};
