/* eslint-disable */
import 'core-js/proposals';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';

import ReactModal from 'react-modal';
ReactModal.setAppElement('#app');
import { createRoot } from 'react-dom/client';

import Root from 'app';
import { initBrowserStore } from 'app/browser-store';
import Provider from 'app/Provider';
import { Route } from 'react-router-dom';
import { APP_ID } from 'utils/constants';

initBrowserStore();

export const basename = document
  .getElementsByTagName('base')[0]!
  .getAttribute('href')!
  .replace(/\/$/, '');

async function renderApp(Component: React.FC) {
  const container = document.getElementById(APP_ID);

  if (container) {
    const root = createRoot(container);
    root.render(
      <Provider>
        <Route component={Component} />
      </Provider>,
    );
  }
}

renderApp(Root);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(
        process.env.NODE_ENV === 'development'
          ? '/service-worker.js'
          : 'service-worker.js',
      )
      .then(registration => {
        console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
