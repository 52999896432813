import {
  BodySmall,
  bodySmallCSS,
  ContentContainer as _ContentContainer,
  IconButton,
  Logo,
  media,
  NAVIGATION_BAR_HEIGHT,
  Separator,
  SnapshotCardImage,
  SnapshotCircle,
} from '@frontend/ui';
import { arrowUpward } from '@frontend/ui/icons';
import nordeaPulse from 'assets/nordeaPulse.svg';
import { footerMessages } from 'common/messages/footer';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { scrollTop } from 'components/LinkScrollTo';
import getYear from 'date-fns/getYear';
import React from 'react';
import styled from 'styled-components';

import { Contact } from './containers/Contact';

const ContentContainer = styled(_ContentContainer)`
  display: flex;
`;

const FooterContainer = styled.footer`
  margin-bottom: 3rem;
  padding: 1.5rem 0;
  background-color: ${p => p.theme.surface};
  border: 0.0625rem solid ${p => p.theme.surfaceVariant};
  border-radius: 1.75rem;
  ${media.lessThan('tablet')`
    margin-bottom: calc(3rem + ${NAVIGATION_BAR_HEIGHT}rem);
  `}
`;

const Container = styled.div`
  padding: 0 1.5rem;
`;

const CircleWrapper = styled.div`
  padding-bottom: 1.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0 1rem;
`;

const Copyright = styled.div`
  margin-top: 1.5rem;
  ${bodySmallCSS}
`;

export const Footer: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ContentContainer>
      <FooterContainer>
        <Container>
          <CircleWrapper>
            <SnapshotCircle noBorder>
              <SnapshotCardImage src={nordeaPulse} alt="" />
            </SnapshotCircle>
          </CircleWrapper>
          <Contact />
        </Container>
        <Separator style={{ marginTop: '0.5rem' }} />
        <Container>
          <Wrapper>
            <Logo width={115} height={24} />
          </Wrapper>
          <TextGrid>
            <BodySmall>
              <FormattedMessage {...footerMessages.investmentDisclaimer} />
            </BodySmall>
          </TextGrid>
          <div style={{ position: 'relative' }}>
            <Copyright>{`Copyright © ${getYear(
              new Date(),
            )} Nordea Node`}</Copyright>
            <IconButton
              onClick={() => scrollTop()}
              icon={arrowUpward}
              color="primary"
              size="medium"
              outlined
              label={formatMessage(footerMessages.scrollTop)}
              style={{ position: 'absolute', right: 0, bottom: 0 }}
            />
          </div>
        </Container>
      </FooterContainer>
    </ContentContainer>
  );
};
