import { DatePickerField, SelectField } from '@frontend/formik';
import { SearchableSelectOption } from '@frontend/ui';
import { EmploymentActivityType } from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'common/messages';
import { employmentStatusMessages } from 'common/messages/status';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import {
  Employment,
  NO_EMPLOYMENT,
  SelectEmployeeEmploymentField,
} from 'features/admin-shortcuts/select-employee-employment';
import React from 'react';
import * as Yup from 'yup';

export interface EnterDetailsFormValues {
  absenceType: EmploymentActivityType | '';
  effectiveDate: string;
  employment: SearchableSelectOption<Employment> | null;
}

export const enterDetailsValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    absenceType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    employment: Yup.object()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'has employment',
        message: intl.formatMessage(validationMessages.noEmployments),
        test: (option: SearchableSelectOption<Employment>) =>
          option.value.employmentId !== NO_EMPLOYMENT,
      }),
  });

export const enterDetailsInitialValues: EnterDetailsFormValues = {
  absenceType: '',
  effectiveDate: '',
  employment: null,
};

export const EnterDetailsHeader: React.FC = () => (
  <FormattedMessage {...adminShortcutMessages.registerAbsence} />
);

export const EnterDetailsBody: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SelectEmployeeEmploymentField inModal name="employment" required />
      <SelectField
        dense
        fixed
        label={<FormattedMessage {...adminShortcutMessages.absenceType} />}
        name="absenceType"
        options={Object.values(EmploymentActivityType).map(type => ({
          label: formatMessage({
            select: type,
            messages: employmentStatusMessages,
          }),
          value: type,
        }))}
        required
      />
      <DatePickerField
        fixed
        dense
        name="effectiveDate"
        label={<FormattedMessage {...commonMessages.effectiveDate} />}
        required
      />
    </>
  );
};
