import { Icon, Illustration, MenuSection, SubMenuHeader } from '@frontend/ui';
import { arrowRight, check } from '@frontend/ui/icons';
import { menuMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { LANGUAGES } from 'components/LanguageSelector';
import { MenuItem } from 'components/Menu';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';

interface Props {
  setSubMenu: (subMenuContent: React.ReactNode) => void;
}

export const LanguageSelectionMenu: React.FC<Props> = ({ setSubMenu }) => {
  const {
    currentUser: { userAccountId },
  } = useCurrentUser();
  const { locale: currentLocale, setLocale } = useIntlContext();

  const currentLanguage =
    LANGUAGES.find(language => language.locale === currentLocale) ??
    LANGUAGES[0];

  const languageSubMenu = (
    <>
      <SubMenuHeader
        title={<FormattedMessage {...menuMessages.chooseLanguage} />}
        onGoBack={() => setSubMenu(null)}
      />
      <MenuSection>
        {LANGUAGES.map(({ locale, label, icon }) => (
          <MenuItem
            role="menuitemcheckbox"
            aria-checked={locale === currentLocale}
            key={locale}
            title={label}
            icon={<Illustration size="small" src={icon} alt="" />}
            onClick={() => {
              setLocale(locale, userAccountId);
            }}
            trailingIcon={
              locale === currentLocale && (
                <Icon
                  size="medium"
                  icon={check}
                  color="onSurfaceVariant"
                  decorative
                />
              )
            }
          />
        ))}
      </MenuSection>
    </>
  );

  return (
    <MenuItem
      setSubMenu={() => setSubMenu(languageSubMenu)}
      title={currentLanguage.label}
      icon={<Illustration size="small" src={currentLanguage.icon} alt="" />}
      trailingIcon={<Icon icon={arrowRight} decorative />}
    />
  );
};
