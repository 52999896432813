import { useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams, toNumber } from '@frontend/utils';
import success from 'assets/illustrations/success.svg';
import { commonMessages } from 'common/messages';
import { FormattedMessage, FormattedNumber } from 'components/formats';
import { afterFadeout, Modal, ModalBody, ModalHeader } from 'components/Modal';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { FormValues } from '..';

export const EditSalarySuccessModal = () => {
  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search);

  const isOpen = 'edit-salary-success' in search;

  const { values, resetForm } = useFormikContext<FormValues>();

  const handleClose = () => {
    stripSearchParams(history, location, ['edit-salary-success']);
    afterFadeout(resetForm);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} size="medium">
      <ModalHeader illustration={success}>
        <FormattedMessage {...adminShortcutMessages.editSalarySuccess} />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage
          {...adminShortcutMessages.editSalarySuccessDescription}
          values={{
            employee: values.userAccount?.label,
            value: <FormattedNumber value={toNumber(values.amount) ?? 0} />,
            effectiveDate: new Date(values.effectiveDate),
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button onClick={handleClose} text>
            <FormattedMessage {...commonMessages.close} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

export const getEditSalarySuccessLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'edit-salary-success',
});
