import { SelectField, useFormikContext } from '@frontend/formik';
import { ReadOnlyField } from '@frontend/ui';
import { getEffectiveDateOptions } from '@frontend/utils';
import {
  changeBenefitPackageQuery,
  changeBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'common/messages';
import { FormattedMessage, IntlShape } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-user';
import { startOfMonth } from 'date-fns';
import { adminShortcutMessages } from 'features/admin-shortcuts/messages';
import React from 'react';
import { useQuery } from 'utils/use-query';
import * as Yup from 'yup';

import { FormValues } from '../..';
import { CHANGE_BENEFIT_PACKAGE_QUERY } from '../../graphql/queries';

export const enterDetailsValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    benefitPackage: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    effectiveDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface EnterDetailsFormValues {
  benefitPackage: string;
  effectiveDate: string;
}

export const enterDetailsInitialValues: EnterDetailsFormValues = {
  benefitPackage: '',
  effectiveDate: '',
};

export const EnterDetailsHeader: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <FormattedMessage
      {...adminShortcutMessages.changeBenefitPackageForEmployee}
      values={{
        employee: values.userAccount?.label,
      }}
    />
  );
};

export const EnterDetailsBody: React.FC = () => {
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const { values } = useFormikContext<FormValues>();

  const companyId = currentCompany?.id;
  const userAccountId = values.userAccount?.value;

  const { data, loading, error } = useQuery<
    changeBenefitPackageQuery,
    changeBenefitPackageQueryVariables
  >(CHANGE_BENEFIT_PACKAGE_QUERY, {
    errorPolicy: 'all',
    skip: !values.userAccount?.value,
    variables: {
      companyId: companyId ?? '',
      userAccountId: userAccountId ?? '',
    },
  });

  const benefitPackageOptions =
    data?.company?.benefitPackages?.edges.map(({ node }) => ({
      label: node.name,
      value: node.id,
    })) ?? [];

  const currentBenefitPackage = data?.membership?.membershipBenefitPackage;

  const notBefore = currentBenefitPackage
    ? new Date(startOfMonth(new Date()))
    : undefined;

  const effectiveDateOptions = getEffectiveDateOptions(new Date(), notBefore);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} inModal />}
      {currentBenefitPackage && (
        <ReadOnlyField
          label={
            <FormattedMessage
              {...adminShortcutMessages.currentBenefitPackage}
            />
          }
          value={currentBenefitPackage.benefitPackage.name}
        />
      )}
      <SelectField
        dense
        disabled={loading}
        fixed
        label={
          <FormattedMessage {...adminShortcutMessages.newBenefitPackage} />
        }
        name="benefitPackage"
        options={benefitPackageOptions}
        required
      />
      <SelectField
        dense
        fixed
        name="effectiveDate"
        options={effectiveDateOptions}
        label={<FormattedMessage {...commonMessages.effectiveDate} />}
        required
      />
    </>
  );
};
