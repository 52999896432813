import { useMutation } from '@apollo/client';
import {
  currentUserContextQuery,
  currentUserContextQuery_viewer_UserAccount as Viewer,
  currentUserContextQuery_viewer_UserAccount_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Company,
  updateAppDataMutation,
  UpdateUserAccountInput,
  userAccountDetails_UserAccount_economicProfile_EconomicProfile as EconomicProfile,
} from 'app/apollo/graphql/types';
import {
  getUserPersistedCompanyId,
  setUserPersistedCompanyId,
} from 'app/browser-store';
import { EMPLOYEE_ROOT_PATHS } from 'app/pages/root-paths';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Optional } from 'utils/types';
import { useQuery } from 'utils/use-query';

import { UPDATE_APP_DATA_MUTATION } from './graphql/mutations';
import { CURRENT_USER_CONTEXT_QUERY } from './graphql/queries';
import { getCompanyInfo } from './utils/get-company-info';

export enum FeatureType {
  ONBOARDING_TOUR = 'ONBOARDING_TOUR',
  SETTINGS_TOUR = 'SETTINGS_TOUR',
  ADMIN_SHORTCUTS_TOUR = 'ADMIN_SHORTCUTS_TOUR',
}

export enum FeatureTypeStatus {
  COMPLETED = 'COMPLETED',
  DISMISSED = 'DISMISSED',
}

type AppData = { [key in FeatureType]?: FeatureTypeStatus };

export interface CurrentUserCompany {
  displayName: string;
  hasJobOffers: boolean;
  hasTotalCompensation: boolean;
  id: string;
  isAdmin: boolean;
  isContentAdmin: boolean;
  isFinanceAdmin: boolean;
  isHRAdmin: boolean;
  isSuperAdmin: boolean;
  managedBy: string;
  /**
   * `birthdate` of the current user as defined by the current company membership.
   * This data is managed by the employer and is not universal for the user account.
   */
  membershipBirthdate: string | null;
  /**
   * `givenName` of the current user as defined by the current company membership.
   * This data is managed by the employer and is not universal for the user account.
   */
  membershipGivenName: string | null;
  /**
   * `lastName` of the current user as defined by the current company membership.
   * This data is managed by the employer and is not universal for the user account.
   */
  membershipLastName: string | null;
  registrationNumber: string;
  logoUrl?: string;
}

interface Poa {
  /**
   * Designates whether or not the POA is effective (active) for the user.
   * The value null designates that POA has never been active.
   */
  effective: boolean | null;
  /**
   * The date range for the POA
   */
  range: [string, string | null] | null;
}

export interface CurrentUser {
  currentUser: {
    accessibleCompanies: Company[];
    economicProfile: EconomicProfile | null;
    email: string | null;
    isFriSignedWithEmail: boolean;
    isPpmConnected: boolean;
    poa: Poa;
    userAccountId: string;
    appData?: AppData | null;
    currentCompany?: CurrentUserCompany;
  };
  setAppData: (records: {
    [key in FeatureType]?: FeatureTypeStatus;
  }) => Promise<void>;
  setCurrentCompany: (companyId: string) => void;
}

export const CurrentUserContext = React.createContext<CurrentUser>({
  currentUser: {
    userAccountId: '',
    email: null,
    accessibleCompanies: [],
    isFriSignedWithEmail: false,
    economicProfile: null,
    poa: {
      effective: null,
      range: null,
    },
    isPpmConnected: false,
    appData: null,
  },
  setCurrentCompany: (companyId: string) => companyId,
  setAppData: async () => undefined,
});

export const useCurrentUser = (): CurrentUser => useContext(CurrentUserContext);

export const withCurrentUser = Component =>
  function ThemedComponent(props) {
    const { currentUser, setCurrentCompany } = useCurrentUser();

    return (
      <Component
        {...props}
        currentUser={currentUser}
        setCurrentCompany={setCurrentCompany}
      />
    );
  };

const isValidCurrentUser = (
  currentUser: Optional<CurrentUser, 'currentUser'>,
): currentUser is CurrentUser => !!currentUser.currentUser;

type State = Optional<
  Omit<CurrentUser, 'setCurrentCompany' | 'setAppData'>,
  'currentUser'
>;

interface Props {
  children: React.ReactNode;
}

export const CurrentUserContextProvider: React.FC<Props> = ({ children }) => {
  const [state, set] = useState<State>();
  const { currentUser } = state ?? {};

  const { data, loading, error } = useQuery<currentUserContextQuery>(
    CURRENT_USER_CONTEXT_QUERY,
  );

  const [updateUserAccount] = useMutation<updateAppDataMutation>(
    UPDATE_APP_DATA_MUTATION,
  );

  const setCurrentCompany = useCallback(
    (companyId: string) => {
      if (currentUser) {
        const currentCompany = getCompanyInfo({
          accessibleCompanies: currentUser.accessibleCompanies,
          companyId,
        });

        if (!currentCompany) {
          return;
        }

        set(_state =>
          _state?.currentUser
            ? {
                ..._state,
                currentUser: { ..._state?.currentUser, currentCompany },
              }
            : undefined,
        );
        setUserPersistedCompanyId(currentUser.userAccountId, companyId);
      }
    },
    [currentUser],
  );

  const setAppData = useCallback(
    async (records: {
      [key in FeatureType]?: FeatureTypeStatus;
    }): Promise<void> => {
      if (!currentUser) {
        return;
      }

      const input: UpdateUserAccountInput = {
        id: currentUser.userAccountId,
        appData: {
          ...(currentUser.appData ?? {}),
          ...records,
        },
      };

      try {
        updateUserAccount({
          variables: {
            input,
          },
          optimisticResponse: {
            updateUserAccount: {
              userAccount: {
                __typename: 'UserAccount',
                id: currentUser.userAccountId,
                appData: {
                  ...(currentUser.appData ?? {}),
                  ...records,
                },
              },
            },
          },
        });
      } catch {
        // No point in handling this error, try-catch just
        // fixes potential unhandled promise rejections
      }
    },
    [currentUser],
  );

  useEffect(() => {
    const setCurrentUserData = async (viewer: Viewer) => {
      const accessibleCompanies =
        viewer.memberships?.edges.map(e => e.node) ?? [];
      const activeEmployerCompany = accessibleCompanies.find(company =>
        company.employments?.edges
          .map(({ node }) => node)
          .find(({ period }) => !!period[0] && period[1] === null),
      );
      const companyId =
        currentUser?.currentCompany?.id ??
        (await getUserPersistedCompanyId(viewer.id)) ??
        activeEmployerCompany?.company.id ??
        accessibleCompanies[0]?.company?.id;

      const currentCompany = companyId
        ? getCompanyInfo({
            accessibleCompanies,
            companyId,
          })
        : undefined;

      // XXX: We need to set the companyId in the browser store so that
      // our google analytics component can retrieve the current company, as
      // this component is not a child of the current user context provider.
      // This should probably be refactored.
      await setUserPersistedCompanyId(viewer.id, companyId);

      const poaEffective = viewer.poa ? !viewer.poa.disabled : null;
      const poaEffectiveRange = viewer.poa?.effective ?? null;
      const isPpmConnected = (viewer.insurances?.totalCount ?? 0) > 0;

      set(_state => ({
        ..._state,
        currentUser: {
          userAccountId: viewer.id,
          email: viewer.email,
          isFriSignedWithEmail: viewer.hasSignedFri && !!viewer.email,
          economicProfile: viewer.economicProfile,
          appData: viewer.appData,
          currentCompany,
          accessibleCompanies,
          poa: {
            effective: poaEffective,
            range: poaEffectiveRange,
          },
          isPpmConnected,
        },
      }));
    };

    if (data?.viewer) {
      setCurrentUserData(data.viewer);
    }
  }, [data]);

  const value: Optional<CurrentUser, 'currentUser'> = useMemo(
    () => ({
      setCurrentCompany,
      currentUser,
      setAppData,
    }),
    [currentUser],
  );

  if (loading || (data?.viewer && !currentUser)) {
    return <TopLoading />;
  }

  if (!isValidCurrentUser(value)) {
    return (
      <EmptyStatePage parentLink={EMPLOYEE_ROOT_PATHS.index} error={error} />
    );
  }

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
