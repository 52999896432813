import { internalPostOverview } from 'common/fragments';
import gql from 'graphql-tag';

export const INTERNAL_POSTS_SECTION_QUERY = gql`
  query internalPostsSectionQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      internalPosts {
        edges {
          node {
            ...internalPostOverview
          }
        }
      }
    }
  }
  ${internalPostOverview}
`;
