import gql from 'graphql-tag';

export const FITNESS_CONTRIBUTION_CARD_QUERY = gql`
  query fitnessContributionCardQuery(
    $userAccountId: ID!
    $membershipCompanyId: ID!
    $benefitTypeNames: [String!]
    $hasMembership: Boolean!
    $entitlementsCompanyId: ID
  ) {
    company(id: $membershipCompanyId) {
      id
      totalCompensationConfiguration {
        companyId
        wellness
      }
    }
    membership(companyId: $membershipCompanyId, userAccountId: $userAccountId)
      @include(if: $hasMembership) {
      id
      fitnessContributionBalance
    }
    entitlements(
      companyId: $entitlementsCompanyId
      userAccountId: $userAccountId
      benefitTypeNames: $benefitTypeNames
    ) {
      edges {
        node {
          benefit {
            id
            type
            content {
              locale
            }
            configuration {
              data
            }
          }
        }
      }
    }
  }
`;
