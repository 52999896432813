import { NumberField } from '@frontend/formik';
import { WizardContentCard } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import { suffixMessages, validationMessages } from 'common/messages';
import { economicProfileMessages } from 'common/messages/economic-profile';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { SideSheetSection } from 'components/SideSheet';
import { PageProps } from 'containers/FormikWizard';
import { useIntlContext } from 'containers/IntlProviderWrapper';
import { WithSideSheet } from 'containers/WithSideSheet';
import React from 'react';
import * as Yup from 'yup';

import { FormValues } from '../..';
import {
  EconomicProfile,
  getEconomicProfileRatios,
} from '../../lib/get-economic-profile-ratio';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    monthlyExpenses: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .nullable(),
    monthlyIncome: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .nullable(),
  });

type Props = FormValues & PageProps<FormValues>;

export const EconomyMonthly: React.FC<Props> = ({
  direction,
  assets,
  liabilities,
  monthlyExpenses,
  monthlyIncome,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const getSpendingRatioWarning = (profile: EconomicProfile) => {
    const ratios = getEconomicProfileRatios(profile);

    if (ratios.spendingRatio && ratios.spendingRatio >= 1) {
      return (
        <FormattedMessage {...economicProfileMessages.spendingRatioWarning} />
      );
    }

    return undefined;
  };

  const warning = getSpendingRatioWarning({
    assets: toNumber(assets) ?? null,
    liabilities: toNumber(liabilities) ?? null,
    monthlyIncome: toNumber(monthlyIncome) ?? null,
    monthlyExpenses: toNumber(monthlyExpenses) ?? null,
  });

  const sideSheetContent = (
    <>
      <SideSheetSection>
        <FormattedMessage {...economicProfileMessages.riskSideSheet} />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage
          {...economicProfileMessages.economyMonthlySideSheetOne}
        />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage
          {...economicProfileMessages.economyMonthlySideSheetTwo}
        />
      </SideSheetSection>
      <SideSheetSection>
        <FormattedMessage {...economicProfileMessages.advinansDataPolicy} />
      </SideSheetSection>
    </>
  );

  return (
    <WithSideSheet help={sideSheetContent}>
      {({ helpButton }) => (
        <WizardContentCard
          slideFrom={direction}
          header={
            <FormattedMessage
              {...economicProfileMessages.economyMonthlyTitle}
            />
          }
          description={
            <FormattedMessage {...economicProfileMessages.economyMonthlyDesc} />
          }
          helpButton={helpButton}
        >
          <NumberField
            dense
            name="monthlyIncome"
            label={
              <FormattedMessage
                {...economicProfileMessages.monthlyIncomeLabel}
              />
            }
            affix={formatMessage(suffixMessages.krPerMonth)}
            helperText={
              <FormattedMessage
                {...economicProfileMessages.economyMonthlyIncomeHelper}
              />
            }
            decimalScale={0}
            required
            locale={locale}
          />
          <NumberField
            dense
            name="monthlyExpenses"
            label={
              <FormattedMessage
                {...economicProfileMessages.monthlyExpensesLabel}
              />
            }
            affix={formatMessage(suffixMessages.krPerMonth)}
            helperText={
              <FormattedMessage
                {...economicProfileMessages.economyMonthlyExpensesHelper}
              />
            }
            decimalScale={0}
            required
            locale={locale}
          />
          {!!warning && (
            <NotificationCard type="warning">{warning}</NotificationCard>
          )}
        </WizardContentCard>
      )}
    </WithSideSheet>
  );
};
