import { useCallback, useEffect, useState } from 'react';

interface State {
  hide: boolean;
  scrollPosition: number;
}

interface Offset {
  offsetValue?: number;
}

/**
 * @returns the scroll position of the document body
 */
const getScrollPosition = () => document.body.getBoundingClientRect().top;

/**
 * @returns true iff the current scroll position passed is strictly
 * smaller than the passed previous scroll position, otherwise false
 */
const hasScrolledDown = (
  currentScrollPosition: number,
  prevScrollPosition: number,
) => currentScrollPosition < prevScrollPosition;

/**
 * @returns true iff the passed scroll position is strictly smaller
 * than the passed offset value, otherwise false
 */
const hasPassedOffset = (scrollPosition: number, offset?: Offset) =>
  offset?.offsetValue ? scrollPosition < -offset.offsetValue : true;

/**
 * This hook can be used to toggle parts of the app when
 * the page is scrolled down
 *
 * @returns true iff the last vertical scroll event scolled the page
 * downwards and the new scroll position has surpassed a potential offset,
 * otherwise false
 */
export const useHideOnScroll = (offset?: Offset): boolean => {
  const [{ hide }, setState] = useState<State>({
    hide: false,
    scrollPosition: 0,
  });

  const handleScroll = useCallback(() => {
    const scrollPosition = getScrollPosition();
    const isOffsetPassed = hasPassedOffset(scrollPosition, offset);

    if (isOffsetPassed) {
      setState(({ scrollPosition: previousScrollPosition }) => ({
        hide: hasScrolledDown(scrollPosition, previousScrollPosition),
        scrollPosition,
      }));
    }
  }, [offset]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return hide;
};
