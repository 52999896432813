import { ellipsisCSS, Icon, IconContent, titleLargeCSS } from '@frontend/ui';
import {
  addCircleOutline,
  healthAndSafety,
  personalInjury,
  sick,
} from '@frontend/ui/icons';
import { commonBenefitMessages } from 'common/messages/benefits';
import { FormattedMessage } from 'components/formats';
import React from 'react';
import { MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

export enum InsuranceType {
  'ACCIDENT' = 'ACCIDENT',
  'HEALTH' = 'HEALTH',
  'LIFE' = 'LIFE',
  'SICKNESS' = 'SICKNESS',
}

const INSURANCE_ITEM_ICON: Record<InsuranceType, IconContent> = {
  LIFE: addCircleOutline,
  ACCIDENT: personalInjury,
  SICKNESS: sick,
  HEALTH: healthAndSafety,
};

const INSURANCE_ITEM_LABEL: Record<InsuranceType, MessageDescriptor> = {
  LIFE: commonBenefitMessages.lifeShort,
  ACCIDENT: commonBenefitMessages.accidentShort,
  SICKNESS: commonBenefitMessages.sicknessShort,
  HEALTH: commonBenefitMessages.healthShort,
};

const InsuranceItemContainer = styled.div`
  display: flex;
  svg {
    margin-right: 0.75rem;
    flex-shrink: 0;
  }
`;

const Label = styled.span`
  color: ${p => p.theme.onSurface};
  ${ellipsisCSS}
  ${titleLargeCSS}
`;

interface Props {
  type: InsuranceType;
}

export const InsuranceItem: React.FC<Props> = ({ type }) => (
  <InsuranceItemContainer>
    <Icon
      icon={INSURANCE_ITEM_ICON[type]}
      color="primary"
      size="medium"
      decorative
    />
    <Label>
      <FormattedMessage {...INSURANCE_ITEM_LABEL[type]} />
    </Label>
  </InsuranceItemContainer>
);
