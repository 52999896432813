import gql from 'graphql-tag';

export const UPGRADE_FRI_OVERLAY_QUERY = gql`
  query upgradeFriOverlayQuery($userAccountId: ID!, $companyId: ID!) {
    session {
      authenticationMethod
    }
    membership(userAccountId: $userAccountId, companyId: $companyId) {
      id
      email
    }
  }
`;
