import gql from 'graphql-tag';

export const pensionStubItem = gql`
  fragment pensionStubItem on ExternalInsurance {
    id
    category
    managementType
    insuranceNumber
    name
    productCode
    report {
      date
    }
    plusSuggestion {
      id
      status
      instructions {
        action {
          __typename
        }
      }
    }
  }
`;
