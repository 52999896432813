import { Figure, Icon } from '@frontend/ui';
import { travelExplore } from '@frontend/ui/icons';
import {
  vacationCardQuery,
  vacationCardQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/admin/employees/employee';
import { commonMessages } from 'common/messages';
import { dashboardMessages } from 'common/messages/dashboard';
import { totalCompensationMessages } from 'common/messages/total-compensation';
import { FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { LinkItem, SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from 'utils/use-query';

import { VACATION_CARD_QUERY } from './graphql/queries';
import { getVacationCardData } from './utils/get-vacation-card-data';

interface CardFigureProps {
  figure: number;
  label: React.ReactNode;
}

interface VacationCardComponentProps {
  link?: LinkItem;
  vacationDaysRelevant?: CardFigureProps;
  vacationDaysTotal?: CardFigureProps;
}

export const VacationCardComponent: React.FC<VacationCardComponentProps> = ({
  vacationDaysTotal,
  vacationDaysRelevant,
  link,
}) => (
  <SnapshotCard
    link={link}
    icon={
      <Icon icon={travelExplore} color="primary" size="medium" decorative />
    }
    title={<FormattedMessage {...totalCompensationMessages.vacation} />}
    figure1={
      vacationDaysTotal && (
        <Figure
          size="medium"
          title={
            <span>
              <FormattedMessage
                {...commonMessages.xDays}
                values={{
                  count: vacationDaysTotal.figure,
                }}
              />
            </span>
          }
          description={vacationDaysTotal.label}
        />
      )
    }
    figure2={
      vacationDaysRelevant && (
        <Figure
          size="medium"
          title={
            <span>
              <FormattedMessage
                {...commonMessages.xDays}
                values={{
                  count: vacationDaysRelevant.figure,
                }}
              />
            </span>
          }
          description={vacationDaysRelevant.label}
        />
      )
    }
  />
);

interface Props {
  notLinked?: boolean;
  totalCompensation?: boolean;
}

type MatchParams = Partial<EmployeeRouteMatchParams>;

const isAdminView = (params: MatchParams): params is EmployeeRouteMatchParams =>
  'userAccountId' in params;

export const VacationCard: React.FC<Props> = ({
  notLinked,
  totalCompensation,
}) => {
  const { currentUser } = useCurrentUser();
  const { params } = useRouteMatch<MatchParams>();
  const userAccountId = isAdminView(params)
    ? params.userAccountId
    : currentUser.userAccountId;

  const { data } = useQuery<vacationCardQuery, vacationCardQueryVariables>(
    VACATION_CARD_QUERY,
    {
      suspend: true,
      errorPolicy: 'all',
      isEmpty: d => !getVacationCardData(d, totalCompensation),
      skip: !currentUser.currentCompany?.hasTotalCompensation,
      variables: {
        userAccountId: userAccountId ?? currentUser.userAccountId,
        companyId: currentUser.currentCompany?.id ?? '',
      },
    },
  );

  const vacationCardData = getVacationCardData(data, totalCompensation);

  if (!vacationCardData) {
    return null;
  }

  const {
    vacationDaysPaid,
    vacationDaysSaved,
    vacationDaysUnpaid,
    vacationDaysAdvance,
    vacationDaysPerYear,
    vacationDaysTotalValue,
  } = vacationCardData;

  const vacationDaysTotal: CardFigureProps = {
    figure: vacationDaysTotalValue,
    label: (
      <FormattedMessage
        {...totalCompensationMessages.remaining}
        values={{ count: vacationDaysTotalValue }}
      />
    ),
  };
  const vacationDaysRelevant: CardFigureProps = {
    figure: vacationDaysPerYear || 0,
    label: (
      <FormattedMessage
        {...totalCompensationMessages.daysPerYear}
        values={{ count: vacationDaysPerYear || 0 }}
      />
    ),
  };
  if (vacationDaysSaved) {
    vacationDaysRelevant.figure = vacationDaysSaved;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysSaved}
        values={{ count: vacationDaysSaved }}
      />
    );
  } else if (vacationDaysPaid) {
    vacationDaysRelevant.figure = vacationDaysPaid;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysPaid}
        values={{ count: vacationDaysPaid }}
      />
    );
  } else if (vacationDaysAdvance) {
    vacationDaysRelevant.figure = vacationDaysAdvance;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysAdvance}
        values={{ count: vacationDaysAdvance }}
      />
    );
  } else if (vacationDaysUnpaid) {
    vacationDaysRelevant.figure = vacationDaysUnpaid;
    vacationDaysRelevant.label = (
      <FormattedMessage
        {...totalCompensationMessages.daysUnpaid}
        values={{ count: vacationDaysUnpaid }}
      />
    );
  }

  return (
    <CardGridCell>
      <VacationCardComponent
        link={
          notLinked
            ? undefined
            : {
                to: '/total-compensation',
                label: <FormattedMessage {...dashboardMessages.showVacation} />,
              }
        }
        vacationDaysTotal={vacationDaysTotal}
        vacationDaysRelevant={vacationDaysRelevant}
      />
    </CardGridCell>
  );
};
