import { BankIdProgressStatus } from 'features/auth/common/constants';
import { DocumentNode } from 'graphql';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface State {
  autoStartToken?: string;
  onCancelMutation?: DocumentNode;
  progressStatus?: BankIdProgressStatus;
  qrData?: string;
  ticketId?: string;
}

interface ContextProps {
  clearState: () => void;
  setState: (contextState: React.SetStateAction<State>) => void;
  state: State;
}

const SignBankIdContext = createContext<ContextProps>({
  state: {},
  setState: (contextState: State) => contextState,
  clearState: () => undefined,
});

export const useSignBankIdContext = (): ContextProps =>
  useContext(SignBankIdContext);

interface Props {
  children: React.ReactNode;
}

export const SignBankIdContextProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<State>({});
  const clearState = () => setState({});
  const value = useMemo(
    () => ({
      state,
      setState,
      clearState,
    }),
    [state],
  );

  return (
    <SignBankIdContext.Provider value={value}>
      {children}
    </SignBankIdContext.Provider>
  );
};
