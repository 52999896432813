import {
  buttonFocusVisibleAnimationBase,
  Chip,
  Icon,
  IconContent,
} from '@frontend/ui';
import { linkCss } from 'components/DefaultLink';
import { Link } from 'components/Links/Link';
import React, { MouseEventHandler } from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const ChipLink = styled(Link)`
  ${linkCss}
  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:focus-visible {
    outline: none;

    > * {
      ${buttonFocusVisibleAnimationBase()};
    }
  }
`;

interface CommonProps {
  leadingIcon: IconContent;
  text: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

interface ChipWithLink extends CommonProps, Pick<LinkProps, 'to'> {
  onClick?: never;
}

interface ChipWithOnClick extends CommonProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: never;
}

export type Props = ChipWithLink | ChipWithOnClick;

const isChipWithLink = (chip: Props): chip is ChipWithLink => 'to' in chip;

export const AssistChip: React.FC<Props> = props => {
  const commonProps = {
    text: props.text,
    leadingIcon: (
      <Icon icon={props.leadingIcon} size="small" color="primary" decorative />
    ),
    disabled: props.disabled,
    loading: props.loading,
  };

  if (isChipWithLink(props)) {
    // Disabled link chips must not add the
    // wrapping link to avoid being clickable
    if (props.disabled) {
      return <Chip {...commonProps} />;
    }

    return (
      <ChipLink to={props.to}>
        <Chip {...commonProps} clickable />
      </ChipLink>
    );
  }

  return <Chip {...commonProps} onClick={props.onClick} />;
};
