import { BenefitType } from '@frontend/benefit-types';
import { Figure, Icon } from '@frontend/ui';
import { supportAgent } from '@frontend/ui/icons';
import {
  personalAdviceDashboardCardQuery,
  personalAdviceDashboardCardQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'common/messages';
import { personalAdviceInfoMessages } from 'common/messages/personal-advice';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { CardGridCell } from 'components/GridCell';
import { SnapshotCard } from 'components/SnapshotCard';
import { useCurrentUser } from 'contexts/current-user';
import React from 'react';
import { useQuery } from 'utils/use-query';

import { PERSONAL_ADVICE_DASHBOARD_CARD_QUERY } from './graphql/queries';
import { getPersonalAdviceCardData } from './utils/get-personal-advice-card-data';

const personalAdvice: BenefitType = 'nordea_node_personal_advice';

export const PersonalAdviceCard: React.FC = () => {
  const {
    currentUser: { currentCompany, userAccountId },
  } = useCurrentUser();

  const { data } = useQuery<
    personalAdviceDashboardCardQuery,
    personalAdviceDashboardCardQueryVariables
  >(PERSONAL_ADVICE_DASHBOARD_CARD_QUERY, {
    suspend: true,
    errorPolicy: 'all',
    isEmpty: d => !getPersonalAdviceCardData(d),
    skip: !currentCompany,
    variables: {
      userAccountId,
      companyId: currentCompany?.id ?? '',
      benefitTypeNames: [personalAdvice],
    },
  });

  const personalAdviceCardData = getPersonalAdviceCardData(data);

  if (!personalAdviceCardData) {
    return null;
  }

  const {
    benefitId,
    upcomingOrLatestMeetingDate,
    bookedMeeting,
    hasUpcomingMeeting,
    entitlement: personalAdviceEntitlement,
    earliestNextMeetingDate,
  } = personalAdviceCardData;

  return (
    <CardGridCell>
      <SnapshotCard
        link={{
          to: `/benefits/${benefitId}`,
          label: <FormattedMessage {...commonMessages.showBenefit} />,
        }}
        icon={
          <Icon icon={supportAgent} color="primary" size="medium" decorative />
        }
        title={
          <FormattedMessage
            {...personalAdviceInfoMessages.dashboardCardTitle}
          />
        }
        figure1={
          <Figure
            size="medium"
            title={
              upcomingOrLatestMeetingDate ? (
                <FormattedDate
                  value={upcomingOrLatestMeetingDate}
                  dateStyle="short"
                  timeStyle={bookedMeeting?.time ? 'short' : undefined}
                />
              ) : (
                <FormattedMessage
                  {...personalAdviceInfoMessages.latestAdvisoryMeetingEmptyState}
                />
              )
            }
            description={
              <FormattedMessage
                {...(hasUpcomingMeeting
                  ? personalAdviceInfoMessages.upcomingAdvisoryMeetingLabel
                  : personalAdviceInfoMessages.latestAdvisoryMeetingLabel)}
              />
            }
          />
        }
        figure2={
          !hasUpcomingMeeting &&
          personalAdviceEntitlement.latestMeetingDate &&
          earliestNextMeetingDate ? (
            <Figure
              size="medium"
              title={<FormattedDate value={earliestNextMeetingDate} />}
              description={
                <FormattedMessage
                  {...personalAdviceInfoMessages.earliestNextAdvisoryMeetingLabel}
                />
              }
            />
          ) : null
        }
      />
    </CardGridCell>
  );
};
