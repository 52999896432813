import { defineMessages } from 'react-intl';
import { FundRiskLevel } from 'utils/constants';

import { commonMessages } from './';

export const securityMessages = defineMessages({
  allocation: {
    id: 'securityMessages.allocation',
    defaultMessage: 'Innehav',
  },
  yearlyFee: {
    id: 'securityMessages.yearlyFee',
    defaultMessage: 'Avgift',
  },
  riskScore: {
    id: 'securityMessages.riskScore',
    defaultMessage: 'Risk',
  },
  share: {
    id: 'securityMessages.share',
    defaultMessage: 'Andel',
  },
  value: {
    id: 'securityMessages.value',
    defaultMessage: 'Värde',
  },
  feeFootnote: {
    id: 'securityMessages.feeFootnote',
    defaultMessage: `<p>Om bolaget inte levererar uppgifter om årlig avgift, visar vi avgift från fondens faktablad. Detta kan leda till att vi redovisar en annan årlig avgift än den du faktiskt betalar.</p>
    <p>Kontots värde är ett uppskattat värde baserat på information hämtad {date}. Detta kan leda till att vi redovisar ett annat värde än det faktiska värdet.</p>`,
  },
  aboutFund: {
    id: 'securityMessages.aboutFund',
    defaultMessage: 'Om {name}',
  },
  regularFee: {
    id: 'securityMessages.regularFee',
    description: 'Regular fee',
    defaultMessage: 'Ordinarie avgift',
  },
  regularFeeAbbreviated: {
    id: 'securityMessages.regularFeeAbbreviated',
    description: 'Regular fee abbreviated',
    defaultMessage: 'Ord. avgift',
  },
  tableRiskScore: {
    id: 'securityMessages.tableRiskScore',
    defaultMessage: '{riskScore} av 7',
  },
  fundType: {
    id: 'securityMessages.fundType',
    defaultMessage: 'Fondtyp',
  },
  document: {
    id: 'securityMessages.document',
    defaultMessage: 'Faktablad',
  },
  keyFigures: {
    id: 'securityMessages.keyFigures',
    defaultMessage: 'Nyckeltal',
  },
  aum: {
    id: 'securityMessages.aum',
    defaultMessage: 'Förvaltat kapital',
  },
  navCourse: {
    id: 'securityMessages.navCourse',
    defaultMessage: 'NAV-kurs',
  },
  peRatio: {
    id: 'securityMessages.peRatio',
    defaultMessage: 'P/E-tal',
  },
  psRatio: {
    id: 'securityMessages.psRatio',
    defaultMessage: 'P/S-tal',
  },
  pcfRatio: {
    id: 'securityMessages.pcfRatio',
    defaultMessage: 'P/CF-tal',
  },
  pbRatio: {
    id: 'securityMessages.pbRatio',
    defaultMessage: 'P/B-tal',
  },
  roe: {
    id: 'securityMessages.roe',
    defaultMessage: 'Avkastning på eget kapital (ROE)',
  },
  roa: {
    id: 'securityMessages.roa',
    defaultMessage: 'Avkastning på totalt kapital (ROA)',
  },
  fundCompany: {
    id: 'securityMessages.fundCompany',
    defaultMessage: 'Fondbolag',
  },
  website: {
    id: 'securityMessages.website',
    defaultMessage: 'Hemsida',
  },
  ucitsFund: {
    id: 'securityMessages.ucitsFund',
    defaultMessage: 'UCITS-fond',
  },
  isin: {
    id: 'securityMessages.isin',
    defaultMessage: 'ISIN',
  },
  comparativeIndex: {
    id: 'securityMessages.comparativeIndex',
    defaultMessage: 'Jämförelseindex',
  },
  nBiggestHoldings: {
    id: 'securityMessages.nBiggestHoldings',
    defaultMessage: 'Fondens {count} största innehav',
  },
  industries: {
    id: 'securityMessages.industries',
    defaultMessage: 'Branscher',
  },
  nBiggestIndustries: {
    id: 'securityMessages.nBiggestIndustries',
    defaultMessage: 'Fondens {count} största branscher',
  },
  countries: {
    id: 'securityMessages.countries',
    defaultMessage: 'Länder',
  },
  nBiggestCountries: {
    id: 'securityMessages.nBiggestCountries',
    defaultMessage: 'Fondens {count} största länder',
  },
  riskAndReturn: {
    id: 'securityMessages.riskAndReturn',
    defaultMessage: 'Risk och avkastning',
  },
  riskAndReturnDesc: {
    id: 'securityMessages.riskAndReturnDesc',
    defaultMessage:
      'Risk- och avkastningsindikatorn visar sambandet mellan risk och avkastningsmöjligheter för fonden. Indikatorn bygger på historik data som inte är en garanti för framtida risk och avkastning. En högre siffra innebär högre risk.',
  },
  nAreas: {
    id: 'securityMessages.nAreas',
    defaultMessage: '{count, plural, one {# område} other {# områden}}',
  },
  nIndustries: {
    id: 'securityMessages.nIndustries',
    defaultMessage: '{count, plural, one {# bransch} other {# branscher}}',
  },
  positiveClimateAction: {
    id: 'securityMessages.positiveClimateAction',
    defaultMessage: 'Positivt klimatarbete',
  },
  controversialIndustry: {
    id: 'securityMessages.controversialIndustry',
    defaultMessage: 'Ifrågasatta branscher',
  },
  co2Risk: {
    id: 'securityMessages.co2Risk',
    defaultMessage: 'CO2-risk',
  },
  showFund: {
    id: 'securityMessages.showFund',
    defaultMessage: 'Visa fonden',
  },
  changeSortingForAllocations: {
    id: 'securityMessages.changeSortingForAllocations',
    defaultMessage: 'Ändra sortering för fördelning',
  },

  // Legacy messages below this point.
  // The id:s are intentionally wrong here to persist translations in PO Editor
  internalFundCode: {
    id: 'pension.plus.components.suggestionTable.internalFundCode',
    defaultMessage: `Fondkod`,
  },
  split: {
    id: 'portfolioMessages.split',
    description: 'Split',
    defaultMessage: 'Fördelning',
  },
  splitAt: {
    id: 'portfolioMessages.splitAt',
    description: 'Split at {data}',
    defaultMessage: 'Fördelning år {date}',
  },
  investmentStrategy: {
    id: 'portfolioMessages.investmentStrategy',
    defaultMessage: 'Investeringsstrategi',
  },
  goalDistributionAtAge: {
    id: 'portfolioMessages.goalDistributionAtAge',
    defaultMessage: `Målfördelning vid {age} år`,
  },
  goalDistributionAtYear: {
    id: 'portfolioMessages.goalDistributionAtYear',
    defaultMessage: `Målfördelning år {date}`,
  },
  fondSummary: {
    id: 'portfolioMessages.fondSummary',
    description: 'Fund summary',
    defaultMessage: 'Fondsammanfattning',
  },
  currency: {
    id: 'portfolioMessages.currency',
    description: 'Currency',
    defaultMessage: 'Valuta',
  },
  feeCalculationDesc: {
    id: 'portfolioMessages.feeCalculationDesc',
    description: 'Fee calculation description',
    defaultMessage:
      'Avgifterna på ditt sparande bestäms som en årlig andel av det totala värdet på ditt sparande. Avgifter medför att avkastningen på ditt sparande blir lägre än vad den skulle varit utan avgifter.',
  },
  amountEarned: {
    id: 'portfolioMessages.amountEarned',
    defaultMessage: `Utveckling`,
  },
  returnsEarnedSinceStart: {
    id: 'portfolioMessages.returnsEarnedSinceStart',
    defaultMessage: `{returnsEarned} sedan start`,
  },
  totalValue: {
    id: 'portfolios.totalValue',
    defaultMessage: 'Totalt värde',
  },
});

export const fundRiskLevelMessages = defineMessages<FundRiskLevel>({
  HIGH: {
    id: 'fundRiskLevelMessages.HIGH',
    defaultMessage: 'Hög',
  },
  MEDIUM: {
    id: 'fundRiskLevelMessages.medium',
    defaultMessage: 'Medel',
  },
  LOW: {
    id: 'fundRiskLevelMessages.low',
    defaultMessage: 'Låg',
  },
});

export const fundRiskLevelSecondaryMessages = defineMessages<FundRiskLevel>({
  HIGH: commonMessages.highRisk,
  MEDIUM: {
    id: 'fundRiskLevelSecondaryMessages.medium',
    defaultMessage: 'Medelrisk',
  },
  LOW: commonMessages.lowRisk,
});
