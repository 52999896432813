export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M711-42q-12 2-21.5-6.5T680-71q0-12 8-20.5t20-10.5q57-9 99-51t51-99q2-12 10.5-20t19.5-8q14 0 23 9.5t7 21.5q-11 81-68.5 138.5T711-42ZM71-680q-14 0-22.5-9.5T42-711q11-81 68.5-138.5T249-918q12-2 21.5 6.5T280-889q0 12-8 20.5T252-858q-57 9-99 51t-51 99q-2 12-11 20t-20 8Zm179 105q-8 8-8 19.5t8 22.5q25 31 28.5 71.5T255-384q-5 6-4.5 14t5.5 13q7 7 15.5 6.5T285-358q31-41 32.5-97T284-553l305-305q15-15 35.5-15t35.5 15q14 14 15 34.5T660-787L455-582q-6 6-6 14t5 14q6 5 14 5.5t14-5.5l262-262q14-14 35.5-14t35.5 14q14 14 14 35.5T815-745L554-484q-6 6-6 14.5t6 14.5q5 6 13.5 6t14.5-6l219-219q15-15 35.5-15t35.5 15q14 14 14 35.5T872-603L624-356q-6 6-6 14t6 14q6 6 14 6.5t14-5.5l163-163q16-16 36.5-15t34.5 15q14 14 15 34.5T886-419L603-136q-48 48-109 72T369.5-40Q306-40 245-64t-109-72q-48-48-72-109T40-369.5Q40-433 64-494t72-109l184-184q14-14 35.5-15t36.5 14q14 14 14 35.5T392-717L250-575Z',
        transform: 'scale(0.025) translate(0, 960)',
      },
    },
  ],
};
