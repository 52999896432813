import { FeatureType } from 'contexts/current-user';
import browserStore from 'localforage';

const BROWSER_STORE_NAME = 'frontend-app';
const LOCALE_KEY = 'locale';
const FEATURE_PREVIEW_SNOOZED = 'snoozed';

const PERSISTED_STORAGE_KEYS = [LOCALE_KEY];

export async function clearBrowserStore(): Promise<void> {
  try {
    const keys = await browserStore.keys();
    keys.forEach(async key => {
      if (!PERSISTED_STORAGE_KEYS.includes(key)) {
        await browserStore.removeItem(key);
      }
    });
  } catch {
    throw new Error();
  }
}

export const initBrowserStore = () => {
  browserStore.config({
    name: BROWSER_STORE_NAME,
  });
};

export const snoozeFeaturePreview = async (
  feature: FeatureType,
): Promise<string> => browserStore.setItem(feature, FEATURE_PREVIEW_SNOOZED);

export const getIsFeaturePreviewSnoozed = async (
  feature: FeatureType,
): Promise<boolean> => {
  const featurePreviewState: string | null | undefined =
    await browserStore.getItem(feature);

  if (featurePreviewState) {
    return featurePreviewState === FEATURE_PREVIEW_SNOOZED;
  }
  return false;
};

export const setLocale = (locale: string): Promise<string | null> =>
  browserStore.setItem(LOCALE_KEY, locale);
export const getLocale = (): Promise<string | null | undefined> =>
  browserStore.getItem(LOCALE_KEY);

interface PersistedUserData {
  companyId?: string;
}

export const getPersistedUserData = (
  userId,
): Promise<PersistedUserData | null | undefined> =>
  browserStore.getItem(userId);

/**
 * Sets the companyId for the user in the browser store.
 * If the user already has a companyId set, it will be removed.
 */
export const setUserPersistedCompanyId = async (
  userId,
  companyId: string,
): Promise<PersistedUserData> => {
  // Retrive all keys from the browser store
  const keys = await browserStore.keys();

  // Loop through all keys. Due to backstage impersonation, the key might be a userAccountId
  // which is different from the currently logged in user, therefore we must iterate through all keys.
  await Promise.all(
    keys.map(async key => {
      const itemForKey: PersistedUserData | null =
        await browserStore.getItem(key);

      // If a key is found with a companyId, remove it
      if (itemForKey?.companyId) {
        await browserStore.removeItem(key);
      }
    }),
  );

  // Set the companyId for the user
  return browserStore.setItem(userId, { companyId });
};

export const getUserPersistedCompanyId = async (
  userId: string,
): Promise<string | undefined> => {
  const userData = await getPersistedUserData(userId);
  if (!userData || !userData.companyId) {
    return undefined;
  }
  return userData.companyId;
};

export { browserStore };
