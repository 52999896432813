import { pageInfoDetails } from 'common/fragments';
import gql from 'graphql-tag';

export const COMPANY_CUSTOM_TYPES_QUERY = gql`
  query companyCustomTypesQuery(
    $companyId: ID!
    $id: String
    $superType: SUPER_TYPE
    $search: String
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    company(id: $companyId) {
      id
      customTypes(
        id: $id
        superType: $superType
        search: $search
        first: $first
        before: $before
        after: $after
        last: $last
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            id
            name
            superType
            unitCode
            currency
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_BOOKKEEPING_ACCOUNTS_QUERY = gql`
  query companyBookkeepingAccountsQuery(
    $includeDefault: Boolean!
    $companyId: ID!
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    company(id: $companyId) {
      id
      bookkeepingAccounts(
        includeDefault: $includeDefault
        first: $first
        before: $before
        after: $after
        last: $last
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            accountKey
            customAccountName
            customAccountNumber
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_REMUNERATION_TYPES_QUERY = gql`
  query companyRemunerationTypesQuery(
    $companyId: ID!
    $search: String
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    company(id: $companyId) {
      id
      remunerationTypes(
        search: $search
        first: $first
        before: $before
        after: $after
        last: $last
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            id
            name
            currency
            unitCode
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;
