import { Loading } from '@frontend/ui';
import { client } from 'app/apollo/client';
import { clearBrowserStore } from 'app/browser-store';
import { getCsrfTokenFromCookie } from 'app/helper';
import axios from 'axios';
import { loginMessages } from 'common/messages/login';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps<
  object,
  object,
  { from?: string; loggedOut?: boolean }
>;

export const Logout: React.FC<Props> = ({
  location: { state },
  history: { push },
}) => {
  const [logoutError, setLogoutError] = useState<boolean>(false);

  const clearState = useCallback(async (): Promise<void> => {
    try {
      await client.resetStore();
      await clearBrowserStore();

      push({
        pathname: '/login',
        search: state?.from ? `?next=${state.from}` : undefined,
        state: { ...state, loggedOut: true },
      });
    } catch {
      setLogoutError(true);
    }
  }, [push, state]);

  const logout = useCallback(async (): Promise<void> => {
    setLogoutError(false);

    const csrfToken = getCsrfTokenFromCookie();

    if (!csrfToken) {
      clearState();
      return;
    }

    try {
      await axios.delete(`${window.env.API}/session`, {
        withCredentials: true,
        headers: { 'X-Csrf-Token': csrfToken },
      });
      clearState();
    } catch {
      setLogoutError(true);
    }
  }, [clearState]);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div>
      {logoutError ? (
        <NotificationCard type="warning">
          <FormattedMessage {...loginMessages.logoutError} />
        </NotificationCard>
      ) : (
        <Loading message={<FormattedMessage {...loginMessages.loggingOut} />} />
      )}
    </div>
  );
};
