import {
  EffectOfLoss,
  PensionTransferProduct,
  PositiveImpactImportance,
  ProductCapability,
  ProductExperience,
  RebalancePreference,
  ReduceNegativeImpactImportance,
  RiskRewardPreference,
  SustainablePreference,
  TransferReason,
} from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const pensionTransferMessages = defineMessages({
  transferPension: {
    id: 'pensionTransferMessages.transferPension',
    defaultMessage: 'Flytta pension',
  },
  bookMeeting: {
    id: 'pensionTransferMessages.bookMeeting',
    defaultMessage: 'Boka möte',
  },
  bookMeetingBanner: {
    id: 'pensionTransferMessages.bookMeetingBanner',
    defaultMessage: 'Boka ett rådgivningsmöte för att flytta din pension',
  },
  bookingConfirmationBanner: {
    id: 'pensionTransferMessages.bookingConfirmationBanner',
    defaultMessage:
      'Du har ett rådgivningsmöte bokat den {scheduledTime, date, long} kl. {scheduledTime, time, short}.',
  },
  addToCalendar: {
    id: 'pensionTransferMessages.addToCalendar',
    defaultMessage: 'Lägg till i kalendern',
  },
  editBooking: {
    id: 'pensionTransferMessages.editBooking',
    defaultMessage: 'Boka om',
  },
  calendarGoogleOption: {
    id: 'pensionTransferMessages.calendarGoogleOption',
    defaultMessage: 'Google Kalender',
  },
  calendarIcsOption: {
    id: 'pensionTransferMessages.calendarIcsOption',
    defaultMessage: 'Annan kalender',
  },
  calendarEventTitle: {
    id: 'pensionTransferMessages.calendarEventTitle',
    defaultMessage: 'Flyttrådgivning',
  },
  calendarEventDesc: {
    id: 'pensionTransferMessages.calendarEventDesc',
    defaultMessage: 'Rådgivningsmöte för att flytta din pension',
  },
  pensionCanBeTransferred: {
    id: 'pensionTransferMessages.pensionCanBeTransferred',
    defaultMessage: 'Denna pension kan flyttas',
  },
  pensionIsBeingMoved: {
    id: 'pensionTransferMessages.pensionIsBeingMoved',
    defaultMessage: 'Denna pension håller på att flyttas',
  },
  splashTitle: {
    id: 'pensionTransferMessages.splashTitle',
    defaultMessage: 'Hej! Välkommen till vår flyttrådgivning.',
  },
  splashDescr: {
    id: 'pensionTransferMessages.splashDescr',
    defaultMessage:
      'Under rådgivningen går vi igenom vilka pensioner du bör flytta, hittar det alternativ som passar dig bäst och upprättar ett flyttuppdrag som du skriver under digitalt.',
  },
  confirmAdviceTitle: {
    id: 'pensionTransferMessages.confirmAdviceTitle',
    defaultMessage: 'Godkänn villkor innan du fortsätter',
  },
  confirmAdviceDesc: {
    id: 'pensionTransferMessages.confirmAdviceDesc',
    defaultMessage:
      'Genom att fortsätta, godkänner du <adviceHref>Avtal för Nordea Nodes Flyttrådgivning</adviceHref> och <privacyPolicyHref>Nordea Nodes policy för behandling av personuppgifter</privacyPolicyHref> samt bekräftar att du tagit del av <preContractInfoHref>Nordea Nodes förköpsinformation.</preContractInfoHref>',
  },
  chooseSourcesHeader: {
    id: 'pensionTransferMessages.chooseSourcesHeader',
    defaultMessage: 'Vilka pensionskonton vill du flytta?',
  },
  chooseSourcesDescr: {
    id: 'pensionTransferMessages.chooseSourcesDescr',
    defaultMessage:
      'Välj de pensionskonton du vill flytta. Konton som inte väljs eller inte finns med i listan kommer inte att flyttas och är inte en del av rådgivningen.',
  },
  totalTransferAmount: {
    id: 'pensionTransferMessages.totalTransferAmount',
    defaultMessage: 'Total flyttsumma',
  },
  addPensionAccount: {
    id: 'pensionTransferMessages.addPensionAccount',
    defaultMessage: 'Lägg till pensionskonto',
  },
  transferCostHeader: {
    id: 'pensionTransferMessages.transferCostHeader',
    defaultMessage: 'Vad kostar det att flytta min pension?',
  },
  transferCostDescr: {
    id: 'pensionTransferMessages.transferCostDescr',
    defaultMessage: `
    <p>Vi tar inte ut någon avgift när du flyttar din pension till oss, men däremot kan bolaget som du flyttar från välja att ta ut en flyttavgift. Ta reda på eventuella avgifter genom att höra av dig till ditt nuvarande pensionsbolag.</p>
    <p>Från och med 1 april 2021 får en flytt av ett pensionskonto inte kosta mer än ca 600 kronor. Det gäller alla fond- eller depåförsäkringar (ej traditionella försäkringar) som är tecknade efter 1 juli 2007.</p>`,
  },
  chooseDstHeader: {
    id: 'pensionTransferMessages.chooseDstHeader',
    defaultMessage: 'Vilket pensionskonto vill du flytta till?',
  },
  chooseDstDescription: {
    id: 'pensionTransferMessages.chooseDstDescription',
    defaultMessage:
      'Du kan flytta din pension till ett existerande pensionskonto eller skapa ett nytt. Om du väljer att flytta kapitalet till ett existerande pensionskonto kommer det investeras enligt den nuvarande fördelningen på det kontot.',
  },
  successTitle: {
    id: 'pensionTransferMessages.successTitle',
    defaultMessage: 'Grattis! Din pensionsflytt är nu påbörjad.',
  },
  successDescr: {
    id: 'pensionTransferMessages.successDescr',
    defaultMessage:
      'Vi kommer skicka flyttuppdraget till det mottagande försäkringsbolaget som kommer ta hand om din flytt. Beroende på ditt nuvarande försäkringsbolag kan din flytt ta mellan 1 och 4 månader.',
  },
  summaryTitle: {
    id: 'pensionTransferMessages.summaryTitle',
    defaultMessage: 'Sammanfattning av rådgivning och flyttuppdrag',
  },
  summaryDescription: {
    id: 'pensionTransferMessages.summaryDescription',
    defaultMessage:
      'För att påbörja flytt av din pension, skriv under flyttuppdraget',
  },
  suggestionSourceHeader: {
    id: 'pensionTransferMessages.suggestionSourceHeader',
    defaultMessage: 'Du kommer att flytta följande pensioner',
  },
  suggestionNeedHeader: {
    id: 'pensionTransferMessages.suggestionNeedHeader',
    defaultMessage: 'Du har uttryckt följande krav och behov',
  },
  editPensionAccount: {
    id: 'pensionTransferMessages.editPensionAccount',
    defaultMessage: 'Ändra pensionskonto',
  },
  confirmRemoval: {
    id: 'pensionTransferMessages.confirmRemoval',
    defaultMessage: 'Ta bort försäkringen?',
  },
  newPensionAccount: {
    id: 'pensionTransferMessages.newPensionAccount',
    defaultMessage: 'Nytt pensionskonto',
  },
  customerNeedsIntro: {
    id: 'pensionTransferMessages.customerNeedsIntro',
    defaultMessage: 'Välj förvaltningsform att flytta till',
  },
  customerNeedsIntroDescr: {
    id: 'pensionTransferMessages.customerNeedsIntroDescr',
    defaultMessage:
      'För att veta till vilket konto vi ska flytta ditt/dina valda pensionskonton, behöver vi ställa några enkla frågor om vilken förvaltningsform som är lämplig för dig.',
  },
  openNewPensionAccount: {
    id: 'pensionTransferMessages.openNewPensionAccount',
    defaultMessage: 'Öppna nytt pensionskonto',
  },
  managementTypeQn: {
    id: 'pensionTransferMessages.managementTypeQn',
    defaultMessage:
      'Vilket uttalande om förvaltningsform stämmer bäst överens med dina preferenser?',
  },
  managementTypeManual: {
    id: 'pensionTransferMessages.managementTypeManual',
    defaultMessage:
      'Jag är en investerare som vill välja fonder eller aktier själv',
  },
  managementTypeAuto: {
    id: 'pensionTransferMessages.managementTypeAuto',
    defaultMessage: 'Jag söker en förvaltning som sköter sig själv',
  },
  riskPreferenceQn: {
    id: 'pensionTransferMessages.riskPreferenceQn',
    defaultMessage:
      'Vilket uttalande om förhållande mellan risk och avkastning stämmer bäst överens med dina preferenser?',
  },
  riskPreferenceRisky: {
    id: 'pensionTransferMessages.riskPreferenceRisky',
    defaultMessage:
      'Jag söker en automatisk förvaltning där jag accepterar en högre risk i utbyte mot en högre förväntad avkastning',
  },
  riskPreferenceGuaranteed: {
    id: 'pensionTransferMessages.riskPreferenceGuaranteed',
    defaultMessage:
      'Jag söker en automatisk förvaltning där min pension har en lägre risk i utbyte mot en lägre förväntad avkastning',
  },
  productCapabilityQn: {
    id: 'pensionTransferMessages.productCapabilityQn',
    defaultMessage:
      'Vilket uttalande om investeringsmöjligheter stämmer bäst överens med dina preferenser?',
  },
  productCapabilityFunds: {
    id: 'pensionTransferMessages.productCapabilityRebalancing',
    defaultMessage: 'Jag vill bara investera i fonder ',
  },
  productCapabilityStocks: {
    id: 'pensionTransferMessages.productCapabilityStocks',
    defaultMessage:
      'Jag vill investera i ett så brett utbud av värdepapper och fonder som möjligt, inklusive aktier',
  },
  effectOfLossQn: {
    id: 'pensionTransferMessages.effectOfLossQn',
    defaultMessage:
      'Hur väl skulle du klara dig ekonomiskt om du förlorade delar av eller hela det flyttade kapitalet?',
  },
  effectOfLossIneffective: {
    id: 'pensionTransferMessages.effectOfLossIneffective',
    defaultMessage:
      'Jag har tillräckliga tillgångar och andra inkomster för att klara mig även om jag förlorade hela det pensionskapital som jag vill flytta',
  },
  effectOfLossPartialInEffective: {
    id: 'pensionTransferMessages.effectOfLossPartialInEffective',
    defaultMessage:
      'Jag har tillräckliga tillgångar för att klara mig även om jag förlorade en del av kapitalet, men skulle få svårare att klara mig om jag förlorade hela det pensionkapital som jag vill flytta',
  },
  effectOfLossAdverse: {
    id: 'pensionTransferMessages.effectOfLossAdverse',
    defaultMessage:
      'Jag skulle få svårt att klara mig om inte en större del av det pensionkapital som jag vill flytta är garanterat',
  },
  sustainablePreferenceQn: {
    id: 'pensionTransferMessages.sustainablePreferenceQn',
    defaultMessage: 'Vill du ha fokus på hållbarhet i dina investeringar?',
  },
  sustainablePreferenceSustainable: {
    id: 'pensionTransferMessages.sustainablePreferenceSustainable',
    defaultMessage: 'Ja, jag vill ha fokus på hållbara investeringar',
  },
  sustainablePreferenceBroad: {
    id: 'pensionTransferMessages.sustainablePreferenceBroad',
    defaultMessage: 'Nej, jag vill hellre investera så brett som möjligt',
  },
  sustainablePreferenceCollasible1Title: {
    id: 'pensionTransferMessages.sustainablePreferenceCollasible1Title',
    defaultMessage:
      'Vad innebär det att ha fokus på hållbarhet i sina investeringar?',
  },
  sustainablePreferenceCollasible1Body: {
    id: 'pensionTransferMessages.sustainablePreferenceCollasible1Body',
    defaultMessage: `
    <b>Om hållbarhet</b>
    <p>När vi pratar om hållbarhet menar vi ESG - vilket står för Environment (miljö), Social (samhälle) och Governance (god bolagsstyrning). Dessa faktorer är relevanta för dina investeringar eftersom företag både påverkar och påverkas av dem.</p>
    <b>Minska negativ påverkan</b>
    <p>Ett företags affärsverksamhet eller produkter kan ha en betydande negativ påverkan på miljö och samhälle. Detta mäts med indikatorer för negativa konsekvenser (PAI). Vi fokuserar på följande negativa konsekvenser:</p>
    <ul>
      <li>Klimatpåverkan: Bolag som minskar klimatpåverkan genom att ha ett lågt koldioxidavtryck (CO2-utsläpp) och/eller genom att dra ner på sin exponering mot fossila bränslen</li>
      <li>Inga kontroversiella vapen: Bolag som inte är involverade i produktion av illegala vapen som till exempel klustervapen eller antipersonella minor, samt produktion, underhåll eller utveckling av kärnvapen</li>
      <li>Samhällsansvar: Bolag som har en policy för och/eller på annat sätt efterlever principerna i FN:s Global Compact*</li>
      <li>Jämställdhet: Bolag som beaktar jämställdhet i styrelsen</li>
    </ul>
    <p>*FN:s Global Compact är ett strategiskt initiativ för globala bolag som förbinder sig att följa ansvarsfulla affärsprinciper inom mänskliga rättigheter, arbetsrätt, miljö och motarbetande av korruption.</p>
    <b>Positivt bidrag</b>
    <p>Ett företag kan bidra positivt till miljö eller samhälle och betraktas som en hållbar investering om det uppfyller alla kriterier nedan.</p>
    <ul>
      <li>Produkter och tjänster: Bolaget måste bidra med produkter och tjänster som är gynnsamma för människor och vår värld, till exempel förnybar energi eller hälsovård.</li>
      <li>T.ex. arbetsrätt eller skatteefterlevnad: Bolagets produktion får inte orsaka väsentlig skada med tanke på miljömässiga eller sociala mål, till exempel låga koldioxidutsläpp eller reducerat avfall.</li>
      <li>God bolagsstyrning: Bolaget måste följa praxis för god bolagsstyrning, till exempel arbetsrätt eller skatteefterlevnad.</li>
    </ul>
    `,
  },
  sustainablePreferenceCollasible2Title: {
    id: 'pensionTransferMessages.sustainablePreferenceCollasible2Title',
    defaultMessage: 'Varför frågar vi om det här?',
  },
  sustainablePreferenceCollasible2Body: {
    id: 'pensionTransferMessages.sustainablePreferenceCollasible2Body',
    defaultMessage: `
    <p>I och med den ökade fokuseringen på hållbarhet frågar vi alla kunder om hållbarhetspreferenser. Vi gör detta för att kunna erbjuda dig det som passar dig.</p>
    <p>Vi utgår från att du ska kunna investera med ett hållbarhetsfokus utan att ge avkall på din långsiktiga avkastning.</p>
    `,
  },
  reduceNegativeImpactPreferenceQn: {
    id: 'pensionTransferMessages.reduceNegativeImpactPreferenceQn',
    defaultMessage:
      'Hur viktigt är det för dig att dina investeringar arbetar för att minska negativa påverkan på miljö och samhälle?',
  },
  reduceNegativeImpactImportant: {
    id: 'pensionTransferMessages.reduceNegativeImpactImportant',
    defaultMessage: 'Viktigt',
  },
  reduceNegativeImpactNotImportant: {
    id: 'pensionTransferMessages.reduceNegativeImpactNotImportant',
    defaultMessage: 'Inte viktigt',
  },
  positiveImpactPreferenceQn: {
    id: 'pensionTransferMessages.positiveImpactPreferenceQn',
    defaultMessage:
      'Hur viktigt är det för dig att dina investeringar bidrar positivt till miljö och samhälle?',
  },
  positiveImpactImportant: {
    id: 'pensionTransferMessages.positiveImpactImportant',
    defaultMessage: 'Viktigt',
  },
  positiveImpactNotImportant: {
    id: 'pensionTransferMessages.positiveImpactNotImportant',
    defaultMessage: 'Inte viktigt',
  },
  policyholder: {
    id: 'pensionTransferMessages.policyholder',
    defaultMessage: 'Försäkringstagare',
  },
  policyholderIdentityNumber: {
    id: 'pensionTransferMessages.policyholderIdentityNumber',
    defaultMessage: `Person-/organisationsnummer`,
  },
  policyholderHeader: {
    id: 'pensionTransferMessages.policyholderHeader',
    defaultMessage: 'Välj ägare av det nya pensionskontot',
  },
  policyholderInfoHeader: {
    id: 'pensionTransferMessages.policyholderInfoHeader',
    defaultMessage: 'Vad innebär det att vara ägare av ett pensionskonto?',
  },
  policyholderInfoDescr: {
    id: 'pensionTransferMessages.policyholderInfoDescr',
    defaultMessage: `
    <p>En tjänstepension ägs normalt av en arbetsgivare. Ett privatägd pensionskonto innebär istället att du står som ägare och inte behöver din arbetsgivares underskrift för att teckna eller flytta det i framtiden.</p>
    `,
  },
  privatePensionDivorceInfoHeader: {
    id: 'pensionTransferMessages.privatePensionDivorceInfoHeader',
    defaultMessage:
      'Pensionskonton som du äger ingår i bodelning vid skilsmässa',
  },
  privatePensionDivorceInfoDesc: {
    id: 'pensionTransferMessages.privatePensionDivorceInfoDesc',
    defaultMessage: `
    <p>Vid en eventuell skilsmässa ingår normalt pensionskonton som du äger i bodelningen. Om du väljer att stå som ägare av pensionskontot som du flyttar till kan den alltså komma att ingå i en bodelning.</p>
    <p>Ovanstående gäller inte pensionskonton som ägs av en arbetsgivare.</p>
    `,
  },
  privatePensionOptionLabel: {
    id: 'pensionTransferMessages.privatePensionOptionLabel',
    defaultMessage:
      'Jag ska äga den nya försäkringen. Jag är införstådd med att den normalt kommer ingå i bodelningen vid en eventuell skilsmässa.',
  },
  ourRecommendation: {
    id: 'pensionTransferMessages.ourRecommendation',
    defaultMessage: 'Vår rekommendation',
  },
  policyholderSummaryHeader: {
    id: 'pensionTransferMessages.policyholderSummaryHeader',
    defaultMessage: 'Ägare av nytt pensionskonto',
  },
  policyholderPrivateDescription: {
    id: 'pensionTransferMessages.policyholderPrivateDescription',
    defaultMessage: `
    <p>
      Du kommer att bli ägare av det nya pensionskontot som du flyttar till.
      Eftersom du äger pensionskontot, ingår den normalt i bodelningen vid en eventuell skilsmässa.
    </p>
    
    <info>
      Genom att skriva under flyttuppdraget intygar du att du är införstådd
      med att det nya pensionskontot normalt ingår i bodelningen
      vid en eventuell skilsmässa.
    </info>
    `,
  },
  signHeader: {
    id: 'pensionTransferMessages.signHeader',
    defaultMessage: 'Skriv under',
  },
  signDescription: {
    id: 'pensionTransferMessages.signDescription',
    defaultMessage: `
    <p>
      Efter att rådgivningen är avslutad kommer du få tillgång till en dokumentation av rådgivningen.
      Vi lagrar dokumentationen och en inspelning av rådgivningen i 10 år.
    </p>
    <p>
      Genom att skriva under intygar du att uppgifterna ovan stämmer samt att du tagit del av och godkänner följande:
    </p>`,
  },
  policyholderEmployerDescription: {
    id: 'pensionTransferMessages.policyholderEmployerDescription',
    defaultMessage:
      '{companyName} kommer att bli ägare av det nya pensionskontot som du flyttar till. Denna arbetsgivare måste godkänna om du vill flytta försäkringen igen. Eftersom en arbetsgivare äger pensionskontot ingår den normalt inte i bodelningen vid en eventuell skilsmässa.',
  },
  destinationHeader: {
    id: 'pensionTransferMessages.destinationHeader',
    defaultMessage: 'Du kommer att flytta till följande pensionskonto',
  },
  recommendationDescription: {
    id: 'pensionTransferMessages.recommendationDescription',
    defaultMessage:
      '<p>Vi anser att följande förvaltningsform motsvarar dina krav och behov vid flytt av pension baserat på de uppgifter du har lämnat vid rådgivningstillfället. Rekommendationen avser endast val av förvaltningsform och inte placeringsrådgivning.</p>',
  },
  recommendationDescriptionWithSustainability: {
    id: 'pensionTransferMessages.recommendationDescriptionWithSustainability',
    defaultMessage:
      '<p>Vi anser att följande förvaltningsform motsvarar dina krav och behov vid flytt av pension baserat på de uppgifter du har lämnat vid rådgivningstillfället. Rekommendationen möjliggör investeringar som är i linje med dina angivna hållbarhetspreferenser. Rekommendationen avser endast val av förvaltningsform och inte placeringsrådgivning.</p>',
  },
  deviatedHeader: {
    id: 'pensionTransferMessages.deviatedHeader',
    defaultMessage: 'Du har valt en annan förvaltningsform',
  },
  deviatedDescription: {
    id: 'pensionTransferMessages.deviatedDescription',
    defaultMessage:
      '<p>Vi anser att ovanstående förvaltningsform motsvarar dina krav och behov av försäkringsskydd vid flytt av pension, grundat på de uppgifter du lämnat och som vi känner till om dig. Vi avråder dig att välja en annan förvaltningsform än den vi rekommenderat.</p>',
  },
  showFees: {
    id: 'pensionTransferMessages.showFees',
    defaultMessage: 'Visa avgifter',
  },
  hideFees: {
    id: 'pensionTransferMessages.hideFees',
    defaultMessage: 'Dölj avgifter',
  },
  deviatedProductTitle: {
    id: 'pensionTransferMessages.deviatedProductTitle',
    defaultMessage: 'Vill du välja en annan förvaltningsform?',
  },
  hasDeviatedProductTitle: {
    id: 'pensionTransferMessages.hasDeviatedProductTitle',
    defaultMessage: 'Du har valt en annan förvaltningsform',
  },
  deviatedProductDescr: {
    id: 'pensionTransferMessages.deviatedProductDescr',
    defaultMessage:
      '<p>Vi anser att ovanstående förvaltningsform motsvarar dina krav och behov av försäkringsskydd vid flytt av pension, grundat på de uppgifter du lämnat och som vi känner till om dig. Vi avråder dig att välja en annan förvaltningsform än den vi rekommenderat.</p>',
  },
  deviate: {
    id: 'pensionTransferMessages.deviate',
    defaultMessage: 'Välj en annan förvaltningsform',
  },
  deviateModalDesc: {
    id: 'pensionTransferMessages.deviateModalDesc',
    defaultMessage:
      'Om du inte vill följa vår rekommendation kan du välja ett annat alternativ som du anser passar dig bättre. Välj det alternativ du vill flytta din pension till i listan nedan.',
  },
  deviateModalOptionsHeader: {
    id: 'pensionTransferMessages.deviateModalOptionsHeader',
    defaultMessage: 'Andra alternativ',
  },
  transferReasons: {
    id: 'pensionTransferMessages.transferReasons',
    defaultMessage: 'Varför vill du flytta din pension?',
  },
  transferReasonsDescription: {
    id: 'pensionTransferMessages.transferReasonsDescription',
    defaultMessage:
      'För att kunna ge dig rätt råd behöver vi förstå varför du vill flytta din pension. Välj ett eller flera alternativ som stämmer överens med dina krav och behov.',
  },
});

export const productExperienceTitle = defineMessages<PensionTransferProduct>({
  NordeaFond: {
    id: 'productExperienceTitle.NordeaFund',
    defaultMessage: 'Har du tidigare erfarenhet av fondförsäkring?',
  },
  NordeaTrad: {
    id: 'productExperienceTitle.NordeaTrad',
    defaultMessage:
      'Har du tidigare erfarenhet av traditionellt förvaltad försäkring?',
  },
  Nordnet: {
    id: 'productExperienceTitle.Nordnet',
    defaultMessage: 'Har du tidigare erfarenhet av depåförsäkring?',
  },
});

export const productExperienceDescription = defineMessages({
  description: {
    id: 'productExperienceDescription.description',
    defaultMessage:
      'Vi behöver säkerställa att du förstår den förvaltningsform vi rekommenderar.',
  },
});

export const productExperienceQuestionPositive =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionPositive.NordeaFund',
      defaultMessage: 'Ja, jag har erfarenhet av fondförsäkring',
    },
    NordeaTrad: {
      id: 'productExperienceQuestionPositive.NordeaTrad',
      defaultMessage:
        'Ja, jag har erfarenhet av traditionellt förvaltad försäkring',
    },
    Nordnet: {
      id: 'productExperienceQuestionPositive.Nordnet',
      defaultMessage: 'Ja, jag har erfarenhet av depåförsäkring',
    },
  });

export const productExperienceQuestionNegative =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionNegative.NordeaFund',
      defaultMessage: 'Nej, jag har inte erfarenhet av fondförsäkring',
    },
    NordeaTrad: {
      id: 'productExperienceQuestionNegative.NordeaTrad',
      defaultMessage:
        'Nej, jag har inte erfarenhet av traditionellt förvaltad försäkring',
    },
    Nordnet: {
      id: 'productExperienceQuestionNegative.Nordnet',
      defaultMessage: 'Nej, jag har inte erfarenhet av depåförsäkring',
    },
  });

export const productExperienceQuestionNegativeDescription =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionNegativeDescription.NordeaFund',
      defaultMessage:
        'Fondförsäkring innebär att du investerar i fonder som kan både öka och minska i värde och det är inte säkert att du får tillbaka dina investerade pensionspengar.',
    },
    NordeaTrad: {
      id: 'productExperienceQuestionNegativeDescription.NordeaTrad',
      defaultMessage:
        'Traditionellt förvaltad försäkring innebär att du lämnar över ansvaret för förvaltningen av dina pensionspengar till försäkringsbolaget.',
    },
    Nordnet: {
      id: 'productExperienceQuestionNegativeDescription.Nordnet',
      defaultMessage:
        'Depåförsäkring innebär att du själv ansvarar för att investera dina pensionspengar i fonder eller aktier som kan både öka och minska i värde.',
    },
  });

export const productExperienceQuestionNegativeConfirmation =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionNegativeConfirmation.NordeaFund',
      defaultMessage: 'Jag förstår vad en fondförsäkring innebär',
    },
    NordeaTrad: {
      id: 'productExperienceQuestionNegativeConfirmation.NordeaTrad',
      defaultMessage:
        'Jag förstår vad en traditionell förvaltad försäkring innebär',
    },
    Nordnet: {
      id: 'productExperienceQuestionNegativeConfirmation.Nordnet',
      defaultMessage: 'Jag förstår vad en depåförsäkring innebär',
    },
  });

export const productExperienceQuestionCollapsible1Title =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionCollapsible1Title.NordeaFund',
      defaultMessage: 'Vad är en fondförsäkring?',
    },
    NordeaTrad: {
      id: 'productExperienceQuestionCollapsible1Title.NordeaTrad',
      defaultMessage: 'Vad är traditionellt förvaltad försäkring?',
    },
    Nordnet: {
      id: 'productExperienceQuestionCollapsible1Title.Nordnet',
      defaultMessage: 'Vad är depåförsäkring?',
    },
  });

export const productExperienceQuestionCollapsible1Body =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'productExperienceQuestionCollapsible1Body.NordeaFund',
      defaultMessage: `
      <p>I en fondförsäkring väljer du själv i vilka fonder pensionskapitalet ska placeras i och kan därför själv bestämma vilken risknivå du vill ha. Det är du som bestämmer i vilken utsträckning sparandet är hållbart. Det finns ingen garanti för det investerade beloppet och det är alltså inte säkert att du får tillbaka hela ditt insatta sparkapital. Försäkringens värde följer värdeutvecklingen i de fonder du valt. Dina fondandelar kan både öka och minska i värde.</p>
      <p>Utbudet av fonder varierar mellan olika försäkringsbolag. Om du inte aktivt väljer fonder själv när du tecknar fondförsäkringen så placeras pengarna i ett förval, så kallad entrélösning.</p>
      `,
    },
    NordeaTrad: {
      id: 'productExperienceQuestionCollapsible1Body.NordeaTrad',
      defaultMessage: `
        <p>I en traditionellt förvaltad försäkring är det försäkringsbolaget som placerar dina pengar i en blandning av räntebärande värdepapper, aktier och fastigheter. Bolaget ger sedan någon form av garanti för ditt sparande.</p>
        <p>En vanlig form av garanti är att du garanteras ett visst belopp när det är dags för utbetalning från försäkringen. Det garanterade beloppet baseras på:</p>
        <li>hur stor andel av premien (inbetalningarna) som ligger till grund för beräkningen av garantin</li>
        <li>aktuell garantiränta.</li>
        <p>Garantin byggs successivt upp av dina inbetalningar. För närvarande är det vanligt att ca 80 % av de inbetalda premierna används som underlag för att beräkna ditt försäkringsbelopp, det vill säga ett garanterat belopp för utbetalningen.</p>
      `,
    },
    Nordnet: {
      id: 'productExperienceQuestionCollapsible1Body.Nordnet',
      defaultMessage: `
        <p>I en depåförsäkring placerar du själv dina pengar valfritt i exempelvis aktier, fonder och andra slag av värdepapper. Det finns inga garantier för ditt sparande, pengarna kan både öka och minska i värde och det är inte säkert att du får tillbaka det investerade kapitalet. Försäkringens värde följer värdeutvecklingen i de placeringar du valt.</p>
        <p>I en depåförsäkring är det upp till dig att investera kapitalet i de värdepapper du vill, annars hamnar pengarna på likvidkontot.  Det är även du som bestämmer i vilken utsträckning sparandet är hållbart genom de investeringar du väljer att göra.</p>
      `,
    },
  });

export const productExperienceQuestionFundsCollapsible = defineMessages({
  title: {
    id: 'productExperienceQuestionFundsCollapsible.title',
    defaultMessage: 'Vad är fonder?',
  },
  body: {
    id: 'productExperienceQuestionFundsCollapsible.body',
    defaultMessage: `
    <p>En fond beskrivs enklast som en sammansättning av underliggande tillgångar, som aktier och räntepapper. Att spara i fonder innebär att äga andelar i fonden och därmed de underliggande tillgångarna. Bakom en fond står ett fondbolag som tar ut avgifter för att förvalta och administrera fonden.</p>
    <p>Aktiefonder ger chans till högre avkastning än räntefonder men innebär högre risk. Fonder kan både öka och minska i värde och det är inte säkert att du får tillbaka det investerade kapitalet.</p>
    `,
  },
});

export const suggestionMessages = defineMessages({
  title: {
    id: 'suggestionMessages.title',
    defaultMessage: 'Vår rekommendation',
  },
  fees: {
    id: 'suggestionMessages.fees',
    defaultMessage: 'Avgifter',
  },
  restoreRecommendation: {
    id: 'suggestionMessages.restoreRecommendation',
    defaultMessage: 'Återställ rekommendation',
  },
});

export const suggestionProductNameMessages =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'suggestionProductNameMessages.NordeaFund',
      defaultMessage: 'Nordea Tjänstepension Fond',
    },
    NordeaTrad: {
      id: 'suggestionProductNameMessages.NordeaTrad',
      defaultMessage: 'Nordea Tjänstepension Garanti',
    },
    Nordnet: {
      id: 'suggestionProductNameMessages.Nordnet',
      defaultMessage: 'Nordnet Tjänstepension',
    },
  });

export const suggestionProductDescriptionMessages =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'suggestionProductDescriptionMessages.NordeaFund',
      defaultMessage:
        'Nordea Tjänstepension Fond är en fondförsäkring där ditt pensionssparande antingen förvaltas automatiskt eller där du väljer egna fonder att investera i. Kapitalet som flyttas placeras i entrélösningen Nordea Pensionsportfölj där risken i ditt sparande automatiskt justeras över tid för att ge dig möjlighet till högre avkastning som ung och minskar risken inför pensionen. Entrélösningen innebär hög risk och om du vill ha en annan risknivå på ditt pensionskonto, rekommenderar vi dig att göra Nordea Nodes placeringsrådgivning. Om du istället vill välja egna fonder kan du själv logga in på nordea.se och göra bytet när flytten är klar. Väljer du att flytta kapitalet till ett existerande pensionskonto så kommer det investeras enligt den nuvarande fördelningen på det kontot.',
    },
    NordeaTrad: {
      id: 'suggestionProductDescriptionMessages.NordeaTrad',
      defaultMessage:
        'Nordea Tjänstepension Garanti är en traditionellt förvaltad försäkring och innebär att 85 procent av de premier som betalats in garanteras vid avtalad utbetalningstidpunkt och att du inte kan påverka hur dina pengar är investerade. Därutöver garanteras ett månatligt belopp. Det passar dig som vill trygga dina pengar inför och under pension. ',
    },
    Nordnet: {
      id: 'suggestionProductDescriptionMessages.Nordnet',
      defaultMessage:
        'Nordnet Tjänstepension är en depåförsäkring och passar dig som vill investera i det största utbudet av värdepapper, inklusive tusentals aktier. För att investera din pension i aktier ska du ha en hög risktolerans och förmåga att bära förluster och bör ha aktuellt erfarenhet av att handla med aktier sedan tidigare. Du måste själv logga in på Nordnet för att köpa de värdepapper som du vill investera i, annars placeras din pension på likvidkontot. Om du väljer att flytta kapitalet till ett existerande pensionskonto kommer det investeras enligt den nuvarande fördelningen på det kontot.',
    },
  });

export const suggestionProductFeesMessages =
  defineMessages<PensionTransferProduct>({
    NordeaFond: {
      id: 'suggestionProductFeesMessages.NordeaFund',
      defaultMessage: `
        <ul>
          <li>Årsavgift: 0 kr</li>
          <li>Kapitalavgift: 0 %</li>
          <li>Fondavgifter: 0,10-2,0 % beroende på fondval</li>
        </ul>
      `,
    },
    NordeaTrad: {
      id: 'suggestionProductFeesMessages.NordeaTrad',
      defaultMessage: `
      <ul>
        <li>Årsavgift: 0-180 kr</li>
        <li>Kapitalavgift: 0,50-0,75 %</li>
        <li>Kapitalförvaltningsavgift: 0,13-0,19 %</li>
      </ul>
    `,
    },
    Nordnet: {
      id: 'suggestionProductFeesMessages.Nordnet',
      defaultMessage: `
      <ul>
        <li>Årsavgift: 0 kr</li>
        <li>Kapitalavgift: 0 %</li>
        <li>Fondavgifter: Enligt Nordnets prislista</li>
      </ul>
    `,
    },
  });
export const rebalancePreferenceSummaryMessages =
  defineMessages<RebalancePreference>({
    AUTO: {
      id: 'rebalancePreferenceSummaryMessages.AUTO',
      defaultMessage:
        '<check>Jag söker en förvaltning som sköter sig själv</check>',
    },
    MANUAL: {
      id: 'rebalancePreferenceSummaryMessages.MANUAL',
      defaultMessage:
        '<check>Jag är en investerare som vill välja fonder eller aktier själv</check>',
    },
  });

export const riskRewardPreferenceSummaryMessages =
  defineMessages<RiskRewardPreference>({
    RISKY: {
      id: 'riskRewardPreferenceSummaryMessages.RISKY',
      defaultMessage:
        '<check>Jag söker en automatisk förvaltning där jag accepterar en högre risk i utbyte mot en högre förväntad avkastning</check>',
    },
    GUARANTEED: {
      id: 'riskRewardPreferenceSummaryMessages.GUARANTEED',
      defaultMessage:
        '<check>Jag söker en automatisk förvaltning där min pension har en lägre risk i utbyte mot en lägre förväntad avkastning</check>',
    },
  });

export const productCapabilitySummaryMessages =
  defineMessages<ProductCapability>({
    FUNDS: {
      id: 'productCapabilitySummaryMessages.FUNDS',
      defaultMessage: '<check>Jag vill bara investera i fonder</check>',
    },
    STOCKS: {
      id: 'productCapabilitySummaryMessages.STOCKS',
      defaultMessage:
        '<check>Jag vill investera i ett så brett utbud av värdepapper och fonder som möjligt, inklusive aktier</check>',
    },
  });

export const effectOfLossSummaryMessages = defineMessages<EffectOfLoss>({
  INEFFECTIVE: {
    id: 'effectOfLossSummaryMessages.INEFFECTIVE',
    defaultMessage:
      '<check>Jag har tillräckliga tillgångar och andra inkomster för att klara mig även om jag förlorade hela det pensionskapital som jag vill flytta</check>',
  },
  PARTIAL_EFFECTIVE: {
    id: 'effectOfLossSummaryMessages.PARTIAL_EFFECTIVE',
    defaultMessage:
      '<check>Jag har tillräckliga tillgångar för att klara mig även om jag förlorade en del av kapitalet, men skulle få svårare att klara mig om jag förlorade hela det pensionskapital som jag vill flytta</check>',
  },
  ADVERSE: {
    id: 'effectOfLossSummaryMessages.ADVERSE',
    defaultMessage:
      '<check>Jag skulle få svårt att klara mig om inte en större del av det pensionskapital som jag vill flytta är garanterat</check>',
  },
});

export const sustainabilitySummaryMessages =
  defineMessages<SustainablePreference>({
    SUSTAINABLE: {
      id: 'sustainabilitySummaryMessages.SUSTAINABLE',
      defaultMessage:
        '<check>Jag vill ha fokus på hållbara investeringar</check>',
    },
    BROAD: {
      id: 'sustainabilitySummaryMessages.BROAD',
      defaultMessage:
        '<check>Jag vill inte ha fokus på hållbarhet utan vill hellre investera så brett som möjligt</check>',
    },
  });

export const reduceNegativeImpactImportanceSummaryMessages =
  defineMessages<ReduceNegativeImpactImportance>({
    IMPORTANT: {
      id: 'reduceNegativeImpactImportanceSummaryMessages.IMPORTANT',
      defaultMessage:
        '<check>Jag tycker det är viktigt att mina investeringar arbetar för att minska negativa påverkan på miljö och samhälle</check>',
    },
    NOT_IMPORTANT: {
      id: 'reduceNegativeImpactImportanceSummaryMessages.NOT_IMPORTANT',
      defaultMessage:
        '<check>Jag tycker inte det är viktigt att mina investeringar arbetar för att minska negativa påverkan på miljö och samhälle</check>',
    },
  });

export const positiveImpactImportanceSummaryMessages =
  defineMessages<PositiveImpactImportance>({
    IMPORTANT: {
      id: 'positiveImpactImportanceSummaryMessages.IMPORTANT',
      defaultMessage:
        '<check>Jag tycker det är viktigt att mina investeringar bidrar positivt till miljö och samhälle</check>',
    },
    NOT_IMPORTANT: {
      id: 'positiveImpactImportanceSummaryMessages.NOT_IMPORTANT',
      defaultMessage:
        '<check>Jag tycker inte det är viktigt att mina investeringar bidrar positivt till miljö och samhälle</check>',
    },
  });

export const productExperienceSummaryMessages =
  defineMessages<ProductExperience>({
    HAS_FUND_EXPERIENCE: {
      id: 'productExperienceSummaryMessages.HAS_FUND_EXPERIENCE',
      defaultMessage: '<check>Jag har erfarenhet av fondförsäkring</check>',
    },
    HAS_TRAD_EXPERIENCE: {
      id: 'productExperienceSummaryMessages.HAS_TRAD_EXPERIENCE',
      defaultMessage:
        '<check>Jag har erfarenhet av traditionellt förvaltad försäkring</check>',
    },
    HAS_DEPA_EXPERIENCE: {
      id: 'productExperienceSummaryMessages.HAS_DEPA_EXPERIENCE',
      defaultMessage: '<check>Jag har erfarenhet av depåförsäkring</check>',
    },
    HAS_FUND_UNDERSTANDING: {
      id: 'productExperienceSummaryMessages.HAS_FUND_UNDERSTANDING',
      defaultMessage:
        '<check>Jag förstår att fondförsäkring innebär att jag investerar i fonder som kan både öka och minska i värde och det är inte säkert att jag får tillbaka mina investerade pensionspengar</check>',
    },
    HAS_TRAD_UNDERSTANDING: {
      id: 'productExperienceSummaryMessages.HAS_TRAD_UNDERSTANDING',
      defaultMessage:
        '<check>Jag förstår att traditionellt förvaltad försäkring innebär att jag lämnar över ansvaret för förvaltningen av mina pensionspengar till försäkringsbolaget</check>',
    },
    HAS_DEPA_UNDERSTANDING: {
      id: 'productExperienceSummaryMessages.HAS_DEPA_UNDERSTANDING',
      defaultMessage:
        '<check>Jag förstår att depåförsäkring innebär att jag själv ansvarar för att investera mina pensionspengar i fonder eller aktier som kan både öka och minska i värde</check>',
    },
  });

export const destinationInsuranceSummaryMessages = defineMessages({
  existingInsurance: {
    id: 'destinationInsuranceSummaryMessages.existingInsurance',
    defaultMessage:
      '<check>Jag vill flytta intjänad pension till ett existerande pensionskonto</check>',
  },
  newInsurance: {
    id: 'destinationInsuranceSummaryMessages.newInsurance',
    defaultMessage:
      '<check>Jag vill flytta intjänad pension till ett nytt pensionskonto</check>',
  },
});

export const policyholderSummaryMessages = defineMessages({
  employer: {
    id: 'policyholderSummaryMessages.employer',
    defaultMessage:
      '<check>{companyName} ska äga det nya pensionskontot</check>',
  },
  private: {
    id: 'policyholderSummaryMessages.private',
    defaultMessage:
      '<check>Jag vill vara ägare av det nya pensionskontot</check>',
  },
});

export const transferReasonsMessages = defineMessages<TransferReason>({
  CONSOLIDATION: {
    id: 'transferReasonsMessages.CONSOLIDATION',
    defaultMessage: 'Jag vill samla min pension på ett ställe',
  },
  LOWER_FEES: {
    id: 'transferReasonsMessages.LOWER_FEES',
    defaultMessage: 'Jag vill sänka mina pensionsavgifter',
  },
  RISK_ADJUSTMENT: {
    id: 'transferReasonsMessages.RISK_ADJUSTMENT',
    defaultMessage: 'Jag vill anpassa risknivån i mitt sparande',
  },
  AUTO_MGMT_RISK: {
    id: 'transferReasonsMessages.AUTO_MGMT_RISK',
    defaultMessage:
      'Jag vill ha en automatisk förvaltning och riskjustering av min pension',
  },
  SUSTAINABLE: {
    id: 'transferReasonsMessages.SUSTAINABLE',
    defaultMessage: 'Jag vill välja hållbara placeringar för min pension',
  },
});
