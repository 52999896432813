import { defineMessages } from 'react-intl';

export const upgradeFriMessages = defineMessages({
  wizardHeader: {
    id: 'upgradeFriMessages.wizardHeader',
    defaultMessage: 'Hämta information',
  },
  contentHeader: {
    id: 'upgradeFriMessages.contentHeader',
    defaultMessage: 'Hämta information automatiskt',
  },
  contentLede: {
    id: 'upgradeFriMessages.contentLede',
    defaultMessage:
      'För att ansluta till Pensionsmyndigheten eller lämna en informationsfullmakt, som möjliggör att vi automatiskt kan hämta information om dina pensioner och försäkringar, behöver du godkänna våra villkor samt ange en e-postadress där vi kan nå dig.',
  },
  emailFieldHelperText: {
    id: 'upgradeFriMessages.emailFieldHelperText',
    defaultMessage:
      'Vi använder din e-post för att skicka viktig information om ditt konto',
  },
  acceptFriTerm: {
    id: 'upgradeFriMessages.acceptFriTerm',
    defaultMessage:
      'Genom att gå vidare godkänner du <friHref>allmänna villkor för Nordea Node Informationstjänst</friHref> samt <privacyPolicyHref>policy för behandling av personuppgifter</privacyPolicyHref>.',
  },
  upgradeFriSuccess: {
    id: 'upgradeFriMessages.upgradeFriSuccess',
    defaultMessage: 'Välkommen till Nordea Node!',
  },
  updateSessionHeader: {
    id: 'upgradeFriMessages.updateSessionHeader',
    defaultMessage: 'För att gå vidare, identifiera dig med BankID',
  },
  updateSessionDesc: {
    id: 'upgradeFriMessages.updateSessionDesc',
    defaultMessage:
      'För ökad säkerhet måste du identifiera dig med BankID för att gå vidare. Om du inte har BankID kan du inte använda de här funktionerna.',
  },
  identifyWithBankId: {
    id: 'upgradeFriMessages.identifyWithBankId',
    defaultMessage: 'Identifiera dig med BankID',
  },
});
