import { featurePreviewMessages } from 'common/messages/feature-preview';
import { FormattedMessage } from 'components/formats';
import { FEATURE_PREVIEW_SETTINGS_PARENT_ID } from 'features/layout/app-bar/user-menu';
import React from 'react';

import { OnboardingTourTooltip } from '../OnboardingTourTooltip';

export const Step8 = () => (
  <OnboardingTourTooltip
    position="bottomLeft"
    title={<FormattedMessage {...featurePreviewMessages.step8Header} />}
    description={<FormattedMessage {...featurePreviewMessages.step8Body} />}
    parentId={FEATURE_PREVIEW_SETTINGS_PARENT_ID}
  />
);
