export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M3 13.5 11 2v11.5Zm9.5 0q.3-.7.65-2.45.35-1.75.35-3.55 0-1.8-.337-3.7-.338-1.9-.663-2.8 1.525.45 3.038 1.675Q17.05 3.9 18.263 5.6q1.212 1.7 1.975 3.738Q21 11.375 21 13.5ZM9 19q-.9 0-1.675-.425T6 17.5q-.35.375-.762.7-.413.325-.913.525-.875-.65-1.487-1.613Q2.225 16.15 2 15h20q-.225 1.15-.837 2.112-.613.963-1.488 1.613-.5-.2-.912-.525-.413-.325-.763-.7-.575.65-1.337 1.075Q15.9 19 15 19t-1.675-.425Q12.55 18.15 12 17.5q-.55.65-1.325 1.075Q9.9 19 9 19Zm-7 4v-2h1q.8 0 1.562-.25Q5.325 20.5 6 20q.675.5 1.438.738.762.237 1.562.237t1.55-.237Q11.3 20.5 12 20q.675.5 1.438.738.762.237 1.562.237t1.55-.237Q17.3 20.5 18 20q.7.5 1.45.75T21 21h1v2h-1q-.775 0-1.525-.188-.75-.187-1.475-.562-.725.375-1.475.562Q15.775 23 15 23q-.775 0-1.525-.188-.75-.187-1.475-.562-.725.375-1.475.562Q9.775 23 9 23q-.775 0-1.525-.188-.75-.187-1.475-.562-.725.375-1.475.562Q3.775 23 3 23Z',
      },
    },
  ],
};
