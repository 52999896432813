import { LinearProgress as _LinearProgress } from '@frontend/ui';
import { a11yMessages } from 'common/messages/a11y';
import { useIntl } from 'components/formats';
import React from 'react';
import styled from 'styled-components';

const LinearProgress = styled(_LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  transform: scaleY(0.5);
  transform-origin: top;
`;

export const TopLoading: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <LinearProgress
      label={formatMessage(a11yMessages.loading)}
      aria-live="polite"
    />
  );
};
