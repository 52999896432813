import gql from 'graphql-tag';

export const COMPANY_CAR_CARD_QUERY = gql`
  query companyCarCardQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      companyCarMonthlyValue
    }
  }
`;
