import { pensionTransferMessages } from 'features/pension-transfer/messages';
import { defineMessages } from 'react-intl';

import { commonMessages } from './index';

export const discoverMessages = defineMessages({
  carouselHeader: {
    id: 'discoverMessages.carouselHeader',
    description: 'What can we help you with?',
    defaultMessage: 'Vad kan vi hjälpa dig med?',
  },
});

export const heroTitleMessages = defineMessages({
  bookTransferMeeting: {
    id: 'heroTitleMessages.bookTransferMeeting',
    defaultMessage: 'Boka ett kostnadsfritt möte för flytta din pension',
  },
  optimizePension: {
    id: 'heroTitleMessages.optimizePension',
    defaultMessage: 'Samla din pension på ett ställe',
  },
  advinansPlus: {
    id: 'heroTitleMessages.advinansPlus',
    defaultMessage: 'Skräddarsydd placerings- och försäkringsrådgivning',
  },
  insuranceDiscount: {
    id: 'heroTitleMessages.insuranceDiscount',
    defaultMessage: 'Försäkra dig själv och din familj',
  },
});

export const heroLedeMessages = defineMessages({
  bookTransferMeeting: {
    id: 'heroLedeMessages.bookTransferMeeting',
    defaultMessage:
      'Nu kan du boka ett möte för att få hjälp att flytta din pension, få en bättre överblick och sänka dina avgifter.',
  },
  optimizePension: {
    id: 'heroLedeMessages.optimizePension',
    defaultMessage:
      'Om du har pension från gamla jobb är det lätt att flytta till oss för att få en bättre överblick, sänka dina avgifter och investera hållbart. Sammanställ, analysera och få hjälp av en rådgivare du kan lita på.',
  },
  advinansPlus: {
    id: 'heroLedeMessages.advinansPlus',
    defaultMessage:
      'Som svensk har du ett stort ansvar att själv välja hur ditt pensionskapital placeras. Att välja rätt kan vara svårt samtidigt som skillnaden mellan olika val har en stor inverkan på utfallet. Därför skapade vi vår pensionsrådgivning - en pensionsrådgivning som gör det tryggt och enkelt att välja rätt. Försäkringsrådgivning utifrån din familjesituation ingår.',
  },
  insuranceDiscount: {
    id: 'heroLedeMessages.insuranceDiscount',
    defaultMessage:
      'I samarbete med Euro Accident erbjuder vi dig att utöka ditt försäkringsskydd utifrån dina behov. Du tecknar enkelt försäkringen digitalt hos Euro Accident.',
  },
});

export const heroBodyMessages = defineMessages({
  bookTransferMeeting: {
    id: 'heroBodyMessages.bookTransferMeeting',
    defaultMessage: `
        <check>100 % digital rådgivning när det passar dig</check>
        <check>Få hjälp av en InsureSec-licensierad rådgivare med vilket alternativ som är bäst för dig</check>
        <check>Slå ihop dina gamla pensioner till en billigare och bättre lösning</check>
    `,
  },
  optimizePension: {
    id: 'heroBodyMessages.optimizePension',
    defaultMessage: `
    <check>Låt oss sammanställa dina gamla pensioner helt automatiskt</check>
    <check>Få hjälp av en InsureSec-licensierad rådgivare med vilket alternativ som är bäst för dig</check>
    <check>Slå ihop dina gamla pensioner till en billigare och bättre lösning</check>`,
  },
  advinansPlus: {
    id: 'heroBodyMessages.advinansPlus',
    defaultMessage: `
        <check>Skapa tillväxt som ung och trygghet inför pensionen</check>
        <check>Få en högre pension genom att betala lägre avgifter</check>
        <check>Ta kontroll över hur dina pengar investeras</check>
    `,
  },
});

export const heroPrimaryLinkMessage = defineMessages({
  bookTransferMeeting: pensionTransferMessages.bookMeeting,
  optimizePension: commonMessages.getStarted,
  advinansPlus: {
    id: 'heroPrimaryLinkMessage.advinansPlus',
    defaultMessage: 'Kom igång',
  },
  insuranceDiscount: {
    id: 'heroPrimaryLinkMessage.insuranceDiscount',
    defaultMessage: 'Till erbjudandet',
  },
});

export const heroSecondaryLinkMessage = defineMessages({
  optimizePension: {
    id: 'heroSecondaryLinkMessage.optimizePension',
    defaultMessage: 'Läs mer om att flytta pension till oss',
  },
});

export const carouselTitleMessages = defineMessages({
  bookTransferMeeting: {
    id: 'carouselTitleMessages.bookTransferMeeting',
    defaultMessage: 'Få kostnadsfri rådgivning',
  },
  changeSx: {
    id: 'carouselTitleMessages.changeSx',
    defaultMessage: 'Ändra löneväxling',
  },
  bonusExchange: {
    id: 'carouselTitleMessages.bonusExchange',
    defaultMessage: 'Bonusväxla till högre pension',
  },
  occupationalPension: {
    id: 'carouselTitleMessages.occupationalPension',
    defaultMessage: 'Investera din tjänstepension',
  },
  optimizePension: {
    id: 'carouselTitleMessages.optimizePension',
    defaultMessage: 'Samla din pension på ett ställe',
  },
  salaryExchange: {
    id: 'carouselTitleMessages.salaryExchange',
    defaultMessage: 'Löneväxla till högre pension',
  },
  monthlySavings: {
    id: 'carouselTitleMessages.monthlySavings',
    defaultMessage: 'Börja månadsspara',
  },
  advinansPlus: {
    id: 'carouselTitleMessages.advinansPlus',
    defaultMessage: 'Se till att du är rätt försäkrad',
  },
  insuranceDiscount: {
    id: 'carouselTitleMessages.insuranceDiscount',
    defaultMessage: 'Försäkra dig och din familj',
  },
});

export const carouselDescMessages = defineMessages({
  bookTransferMeeting: {
    id: 'carouselDescMessages.bookTransferMeeting',
    defaultMessage:
      'Boka ett möte med en av våra rådgivare för att flytta din pension till oss.',
  },
  changeSx: {
    id: 'carouselDescMessages.changeSx',
    defaultMessage: 'Ansök om att höja, sänka eller avsluta din löneväxling.',
  },
  bonusExchange: {
    id: 'carouselDescMessages.bonusExchange',
    defaultMessage:
      'Bonusväxla upp till {max, number, ::currency/SEK .} och höj din pension. Växla innan den {untilDate, date, ::dMMMM}.',
  },
  occupationalPension: {
    id: 'carouselDescMessages.occupationalPension',
    defaultMessage:
      'Få en personlig investeringsstrategi eller välj exakt vilka fonder du vill ha.',
  },
  optimizePension: {
    id: 'carouselDescMessages.optimizePension',
    defaultMessage:
      'Få en överblick och sänk dina avgifter med hjälp av en rådgivare du kan lita på.',
  },
  salaryExchange: {
    id: 'carouselDescMessages.salaryExchange',
    defaultMessage:
      'Löneväxla och öka ditt pensionssparande med upp till {max, number, ::currency/SEK .} per månad.',
  },
  monthlySavings: {
    id: 'carouselDescMessages.monthlySavings',
    defaultMessage:
      'Sparar du till något? Få pengarna att växa genom att månadsspara.',
  },

  advinansPlus: {
    id: 'carouselDescMessages.advinansPlus',
    defaultMessage:
      'Se till att du och din familj har rätt skydd om något skulle hända.',
  },
  insuranceDiscount: {
    id: 'carouselDescMessages.insuranceDiscount',
    defaultMessage:
      'Utöka ditt försäkringsskydd utifrån dina behov. Helt digitalt.',
  },
});
