import {
  ContentContainer,
  Grid,
  GridCell,
  NewsCardLayoutSkeleton,
  Section,
  SectionHeaderSkeleton,
  SnapshotCardActionSkeleton,
  SnapshotCardContentSkeleton,
  SnapshotCardFigureSkeleton,
  SnapshotCardLayoutSkeleton,
} from '@frontend/ui';
import { CardGridCell } from 'components/GridCell';
import React from 'react';
import styled from 'styled-components';

const ChartSkeleton = styled(SnapshotCardContentSkeleton)`
  height: 5.5rem;
`;

export const Loading: React.FC = () => (
  <ContentContainer>
    <Section>
      <Grid>
        <GridCell span={8}>
          <SnapshotCardLayoutSkeleton
            figure1={<ChartSkeleton />}
            action={<SnapshotCardActionSkeleton />}
          />
        </GridCell>
        {[1, 2, 3, 4].map(i => (
          <CardGridCell key={i}>
            <SnapshotCardLayoutSkeleton
              figure1={<SnapshotCardFigureSkeleton />}
              action={<SnapshotCardActionSkeleton />}
            />
          </CardGridCell>
        ))}
      </Grid>
    </Section>
    <Section>
      <Grid>
        <GridCell span={4}>
          <SectionHeaderSkeleton />
        </GridCell>
      </Grid>
      <Grid>
        {[1, 2, 3].map(i => (
          <GridCell span={4} key={i}>
            <NewsCardLayoutSkeleton />
          </GridCell>
        ))}
      </Grid>
      <Grid />
    </Section>
  </ContentContainer>
);
