import { Icon } from '@frontend/ui';
import {
  analytics,
  article,
  homeFilled,
  loyalty,
  money,
  payment,
  people,
  settings,
  swapHorizontalCircle,
  work,
} from '@frontend/ui/icons';
import { commonMessages, menuMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { MenuItem } from 'components/Menu';
import { CurrentUserCompany } from 'contexts/current-user';
import React from 'react';

import { isBenefitsActive } from '../../lib';

interface Props {
  currentCompany: CurrentUserCompany;
  hasSalaryExchange?: boolean;
}

export const AdminMenuContent: React.FC<Props> = ({
  hasSalaryExchange,
  currentCompany: {
    isAdmin,
    isHRAdmin,
    isContentAdmin,
    isFinanceAdmin,
    hasJobOffers,
  },
}) => (
  <>
    <MenuItem
      navigation
      icon={<Icon icon={homeFilled} size="medium" decorative />}
      link="/admin"
      title={<FormattedMessage {...menuMessages.home} />}
      exact
    />
    {(isHRAdmin || isContentAdmin) && (
      <>
        {isHRAdmin && (
          <MenuItem
            navigation
            icon={<Icon icon={people} size="medium" decorative />}
            link="/admin/employees"
            title={<FormattedMessage {...menuMessages.employees} />}
          />
        )}
        {isHRAdmin && (
          <MenuItem
            navigation
            icon={<Icon icon={loyalty} size="medium" decorative />}
            link="/admin/benefits/benefit-packages"
            isActive={isBenefitsActive}
            title={<FormattedMessage {...menuMessages.benefits} />}
          />
        )}
        {isContentAdmin && (
          <MenuItem
            navigation
            icon={<Icon icon={article} size="medium" decorative />}
            link="/admin/internal-news"
            title={<FormattedMessage {...menuMessages.news} />}
          />
        )}
        {hasJobOffers && (isHRAdmin || isContentAdmin) && (
          <MenuItem
            navigation
            icon={<Icon icon={work} size="medium" decorative />}
            link="/admin/job-offers"
            title={<FormattedMessage {...menuMessages.jobOffers} />}
          />
        )}
      </>
    )}
    {(isHRAdmin || isFinanceAdmin) && (
      <>
        <MenuItem
          navigation
          icon={<Icon icon={payment} size="medium" decorative />}
          link="/admin/invoices"
          title={<FormattedMessage {...menuMessages.invoices} />}
        />
        <MenuItem
          navigation
          icon={<Icon icon={analytics} size="medium" decorative />}
          link="/admin/reports"
          title={<FormattedMessage {...menuMessages.reportsAndFiles} />}
        />
        {isHRAdmin && (
          <MenuItem
            navigation
            icon={<Icon icon={money} size="medium" decorative />}
            link="/admin/payslips"
            title={<FormattedMessage {...commonMessages.payslips} />}
          />
        )}
        {isHRAdmin && hasSalaryExchange && (
          <MenuItem
            navigation
            icon={<Icon icon={swapHorizontalCircle} size="medium" decorative />}
            link="/admin/salary-exchanges"
            title={<FormattedMessage {...menuMessages.salaryExchange} />}
          />
        )}
        {isAdmin && (
          <MenuItem
            navigation
            icon={<Icon icon={settings} size="medium" decorative />}
            link="/admin/company"
            title={<FormattedMessage {...menuMessages.companySettings} />}
          />
        )}
      </>
    )}
  </>
);
