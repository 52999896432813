import {
  Icon,
  LabelMedium,
  MenuItem,
  NavigationRail as NavigationRailUI,
  ScrollableMenuArea as ScrollableMenuAreaUI,
} from '@frontend/ui';
import {
  accountTree,
  analytics,
  article,
  beachAccess,
  homeFilled,
  loyalty,
  money,
  payment,
  people,
  shield,
} from '@frontend/ui/icons';
import { menuMessages } from 'common/messages';
import { FormattedMessage } from 'components/formats';
import { NavItem as NavItemComponent } from 'components/NavItem';
import { useNavigationContext } from 'containers/NavigationDrawer/containers/NavigationContext';
import { CurrentUserCompany, useCurrentUser } from 'contexts/current-user';
import React from 'react';
import styled from 'styled-components';
import { isAdminView } from 'utils/is-admin-view';

const NavigationRailComponent = styled(NavigationRailUI)`
  padding-left: env(safe-area-inset-right, 0.75rem);
`;

const ScrollableMenuArea = styled(ScrollableMenuAreaUI)`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavItem = styled(NavItemComponent)`
  flex: 0 1 auto;
  height: auto;
  margin-bottom: 0.75rem;
  padding-bottom: 0.125rem;

  &:last-child {
    margin-bottom: 5.55rem;
  }

  .mdc-list-item.mdc-list-item--with-one-line {
    width: 3.5rem;
  }
`;

const Label = styled(LabelMedium)`
  text-align: center;
`;

interface NavItemProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  link: string;
  show: boolean;
  exact?: boolean;
  hideOnNavBar?: boolean;
}

export const getUserNavItems = (
  currentCompany?: CurrentUserCompany,
): NavItemProps[] => [
  {
    link: '/',
    icon: <Icon icon={homeFilled} size="medium" decorative />,
    exact: true,
    label: <FormattedMessage {...menuMessages.home} />,
    show: true,
  },
  {
    link: '/benefits',
    icon: <Icon icon={loyalty} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.benefits} />,
    show: true,
  },
  {
    link: '/pension',
    icon: <Icon icon={beachAccess} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.pension} />,
    show: true,
  },
  {
    link: '/insurances',
    icon: <Icon icon={shield} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.insurance} />,
    show: true,
  },
  {
    link: '/total-compensation',
    icon: <Icon icon={money} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.totalCompensation} />,
    show: !!currentCompany?.hasTotalCompensation,
    hideOnNavBar: true,
  },
  {
    link: '/internal-news',
    icon: <Icon icon={article} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.news} />,
    show: !!currentCompany,
    hideOnNavBar: true,
  },
  {
    link: '/connected-services',
    icon: <Icon icon={accountTree} size="medium" decorative />,
    show: true,
    label: <FormattedMessage {...menuMessages.connectedServices} />,
    hideOnNavBar: true,
  },
];

export const getAdminNavItems = (
  currentCompany: CurrentUserCompany,
): NavItemProps[] => [
  {
    link: '/admin',
    icon: <Icon icon={homeFilled} size="medium" decorative />,
    exact: true,
    label: <FormattedMessage {...menuMessages.home} />,
    show: true,
  },
  {
    link: '/admin/employees',
    icon: <Icon icon={people} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.employees} />,
    show: currentCompany.isHRAdmin,
  },
  {
    link: '/admin/benefits/benefit-packages',
    icon: <Icon icon={loyalty} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.benefits} />,
    show: currentCompany.isHRAdmin,
  },
  {
    link: '/admin/invoices',
    icon: <Icon icon={payment} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.invoices} />,
    show: currentCompany.isHRAdmin || currentCompany.isFinanceAdmin,
    hideOnNavBar: true,
  },
  {
    link: '/admin/reports',
    icon: <Icon icon={analytics} size="medium" decorative />,
    label: <FormattedMessage {...menuMessages.reportsAndFiles} />,
    show: currentCompany.isHRAdmin || currentCompany.isFinanceAdmin,
  },
];

export const NavigationRail: React.FC = () => {
  const { isRailOpen, isDrawerOpen } = useNavigationContext();
  const {
    currentUser: { currentCompany },
  } = useCurrentUser();

  const _navItems = isAdminView()
    ? currentCompany
      ? getAdminNavItems(currentCompany)
      : []
    : getUserNavItems(currentCompany);

  const navItems = _navItems.filter(({ show }) => !!show);

  if (!navItems.length) {
    return null;
  }

  return (
    // On small screens, the navigation rail remains open to prevent the UI from appearing jumpy
    // when opening the navigation drawer. However, to ensure proper a11y,
    // we need to set `aria-hidden` to true for the rail when the drawer is open.
    <NavigationRailComponent hide={!isRailOpen} aria-hidden={isDrawerOpen}>
      <ScrollableMenuArea>
        {navItems.map(({ label, link, exact, icon }, index) => (
          <NavItem
            key={index}
            to={link}
            exact={exact}
            activeClassName="activeMenu"
            role="menuitem"
          >
            <MenuItem navigation icon={icon} role="none" />
            <Label>{label}</Label>
          </NavItem>
        ))}
      </ScrollableMenuArea>
    </NavigationRailComponent>
  );
};
