import { defineMessages } from 'react-intl';

export const outdatedBrowserMessages = defineMessages({
  outdatedBrowserTitle: {
    id: 'outdatedBrowserMessages.outdatedBrowserTitle',
    description: 'Update your browser',
    defaultMessage: 'Uppdatera din webbläsare',
  },
  outdatedBrowserDesc: {
    id: 'outdatedBrowserMessages.outdatedBrowserDesc',
    defaultMessage:
      'Du har en gammal webbläsare. Uppdatera din webbläsare för bättre säkerhet, högre hastighet och den bästa upplevelsen.',
  },
  updateBrowserLink: {
    id: 'outdatedBrowserMessages.updateBrowserLink',
    description: 'Update browser',
    defaultMessage: 'Uppdatera webbläsare',
  },
});
