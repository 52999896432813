import { defineMessages } from 'react-intl';

export const economicProfileMessages = defineMessages({
  hasEconomicProfileChangedHeader: {
    id: 'portfolios.components.economicProfileFormWrapper.hasEconomicProfileChangedHeader',
    defaultMessage: 'Ska någon av uppgifterna ovan ändras?',
  },
  spendingRatioWarning: {
    id: 'portfolios.components.economicProfileFormWrapper.spendingRatioWarning',
    defaultMessage:
      'Du har höga utgifter i förhållande till din inkomst. Vi avråder dig från att starta ett riskfyllt sparande där du kan förlora pengar. Försök istället att minska dina utgifter.',
  },
  debtRatioWarning: {
    id: 'portfolios.components.economicProfileFormWrapper.debtRatioWarning',
    defaultMessage:
      'Du är högt belånad i förhållande till din inkomst. Vi avråder dig från att starta ett riskfyllt sparande där du kan förlora pengar. Försök istället att minska dina skulder.',
  },
  verifyEconomicDetails: {
    id: 'portfolios.verifyEconomicDetails',
    defaultMessage:
      'För att kunna ge dig råd behöver vi känna till grundläggande uppgifter om din ekonomi.',
  },
  title: {
    id: 'portfolios.economicProfile.title',
    defaultMessage: 'Uppgifter om dig',
  },
  collectiveAgreement: {
    id: 'portfolios.economicProfile.collectiveAgreement',
    defaultMessage: 'Kollektivavtal',
  },
  advinansDataPolicy: {
    id: 'portfolios.economicProfile.advinansDataPolicy',
    defaultMessage: `
    <h1>Hur hanterar ni denna information?</h1>
    <p>Informationen används endast för att ge dig rätt rådgivning, uppskatta din framtida pension och för att förhindra penningtvätt. Vi säljer aldrig vidare din information.</p>`,
  },
  monthlyIncomeLabel: {
    id: 'portfolios.components.economicProfileFormSection.monthlyIncomeLabel',
    defaultMessage: 'Inkomst före skatt',
  },
  monthlyIncomeText: {
    id: 'portfolios.components.economicProfileFormSection.monthlyIncomeText',
    defaultMessage: 'Lön och andra inkomster under en normal månad',
  },
  monthlyExpensesLabel: {
    id: 'portfolios.components.economicProfileFormSection.monthlyExpensesLabel',
    defaultMessage: 'Utgifter',
  },
  monthlyExpensesText: {
    id: 'portfolios.components.economicProfileFormSection.monthlyExpensesText',
    defaultMessage: 'Utgifter under en normal månad',
  },
  assetsLabel: {
    id: 'portfolios.components.economicProfileFormSection.assetsLabel',
    defaultMessage: 'Tillgångar',
  },
  assetsText: {
    id: 'portfolios.components.economicProfileFormSection.assetsText',
    defaultMessage:
      'Bostad (din andel), bankkonton, värdepapper och andra tillgångar (inte pension)',
  },
  liabilitiesLabel: {
    id: 'portfolios.components.economicProfileFormSection.liabilitiesLabel',
    defaultMessage: 'Lån och skulder',
  },
  liabilitiesTabLabel: {
    id: 'portfolios.components.economicProfileFormSection.liabilitiesTabLabel',
    defaultMessage: 'Lån och skulder',
  },
  liabilitiesText: {
    id: 'portfolios.components.economicProfileFormSection.liabilitiesText',
    defaultMessage:
      'Bostadslån (din andel), billån, kreditkort och andra skulder',
  },
  collectiveAgreementLabel: {
    id: 'portfolios.components.economicProfileFormSection.collectiveAgreementLabel',
    defaultMessage: `Välj nuvarande kollektivavtal`,
  },
  hasCollectiveAgreementChangedHeader: {
    id: 'portfolios.components.economicProfileFormSection.hasCollectiveAgreementChangedHeader',
    defaultMessage: `Har du en kollektivavtalad tjänstepension?`,
  },
  header: {
    id: 'portfolioMessages.header',
    defaultMessage: 'Ekonomisk situation',
  },

  // Legacy messages below this point.
  // The id:s are intentionally wrong here to persist translations in PO Editor
  economicProfileSuccess: {
    id: 'portfolioMessages.economicProfileSuccess',
    defaultMessage: `Din ekonomiska profil har nu sparats.`,
  },
  economyMonthlyTitle: {
    id: 'portfolioMessages.economyMonthlyTitle',
    defaultMessage: 'Hur ser din vardagsekonomi ut?',
  },
  economyMonthlyDesc: {
    id: 'portfolioMessages.economyMonthlyDesc',
    defaultMessage: 'Vi använder informationen för att anpassa våra råd.',
  },
  economyMonthlyIncomeHelper: {
    id: 'portfolioMessages.economyMonthlyIncomeHelper',
    defaultMessage: 'Lön och andra inkomster under en normal månad',
  },
  economyMonthlyExpensesHelper: {
    id: 'portfolioMessages.economyMonthlyExpensesHelper',
    defaultMessage: 'Utgifter under en normal månad',
  },
  riskSideSheet: {
    id: 'portfolioMessages.riskSideSheetTitle',
    defaultMessage: `
    <h1>Varför frågar vi detta?</h1>
    <p>För att ge dig råd måste vi förstå dina förutsättningar att ta risk. Du kan alltid uppdatera dina svar under din profil.</p>`,
  },
  economyMonthlySideSheetOne: {
    id: 'portfolioMessages.economyMonthlySideSheetOne',
    defaultMessage: `
    <h1>Vad menas med inkomst?</h1>
    <p>Med inkomst menas din lön eller inkomst från näringsverksamhet men också kapitalinkomster, bidrag och pensionsutbetalningar. Ange totalsumman före skatt.</p>`,
  },
  economyMonthlySideSheetTwo: {
    id: 'portfolioMessages.economyMonthlySideSheetTwo',
    defaultMessage: `
    <h1>Vad menas med utgifter?</h1>
    <p>Med utgifter menas boendekostnader, mat, försäkringar, telefonräkningar och så vidare under en normal månad. Uppskatta din andel om du bor ihop med någon. Om du har barn ska du räkna in dina utgifter för barnet.</p>`,
  },
  economyBalanceSideSheetOne: {
    id: 'portfolioMessages.economyBalanceSideSheetOne',
    defaultMessage: `
    <h1>Vad menas med tillgångar?</h1>
    <p>Med tillgångar menas värdet av din bostad (din andel), pengar på bankkonton samt aktier, fonder och andra värdepapper som du kan sälja. Om du har tillgångar tillsammans med någon ska du uppskatta din andel. Räkna inte med pensionspengar.</p>`,
  },
  economyBalanceSideSheetTwo: {
    id: 'portfolioMessages.economyBalanceSideSheetTwo',
    defaultMessage: `
    <h1>Vad menas med skulder?</h1>
    <p>Med skulder menas bolån (din andel), billån, kreditkortsskulder, privatlån, studielån och andra skulder.</p>`,
  },
  economyBalanceTitle: {
    id: 'portfolioMessages.economyBalanceTitle',
    defaultMessage: 'Vad har du för tillgångar och skulder?',
  },
  economyBalanceDesc: {
    id: 'portfolioMessages.economyBalanceDesc',
    defaultMessage: 'Vi använder informationen för att anpassa våra råd.',
  },
});
