const emptyStringRegEx = / /g;

/**
 * @returns the value for the provided css variable
 *
 * @param prop the css variable which value should be retrieved
 */
export const getCssVariableValue = (prop: string) =>
  getComputedStyle(document.documentElement)
    .getPropertyValue(prop.replace('var(', '').replace(')', ''))
    .replace(emptyStringRegEx, '');
